<template>
  <div
    :class="{
      'rounded-lg bg-white p-2 shadow dark:bg-black dark:shadow-none dark:ring-1 dark:ring-neutral-700':
        searchResults.length,
    }"
    class="z-[999999] min-h-px min-w-px overflow-hidden text-sm"
  >
    <span
      v-if="canHaveNoFocus && searchResults.length"
      class="mb-2 px-3 text-xs text-neutral-500"
    >
      <ArrowRightOnRectangleIcon class="inline h-4 w-4" /> ... or pick existing
      entries instead of creating a new one
    </span>
    <!-- 
      :highlightIndex="searchFocusIndex" -->
    <OutputDisplay
      v-if="searchOutput.length"
      v-model="outputComputed"
      :entry="entry"
      ref="output"
      :display="{
        name: true,
        status: true,
        links: false,
        description: false,
        schedule: true,
        output: true,
        input: false,
        config: false,
        routine: false,
        time_trackings: false,
      }"
      :sorting="searchSorting"
      :limit="10"
      position="search"
      @onEntryClick="
        (entry) => {
          $emit('onEntrySelect', entry);
        }
      "
      :disableRouting="true"
      :filter="filterSearchResults"
    >
      <template v-slot:entry-top-right="{ index }">
        <span
          :class="[
            'ml-3 flex-none text-xs font-semibold',
            searchFocusIndex != index && 'opacity-0',
          ]"
        >
          <kbd class="font-sans">Enter</kbd>
        </span>
      </template>
    </OutputDisplay>
  </div>
</template>

<script>
import { ArrowRightOnRectangleIcon } from "@heroicons/vue/24/outline";

import { Filtering } from "../mixins/Filtering";
import { Searching } from "../mixins/Searching";
import { Sorting } from "../mixins/Sorting";
import { Statusing } from "../mixins/Statusing";

export default {
  props: {
    entry: Object,
    query: String,
    canHaveNoFocus: Boolean,
    filterSearchResults: {
      type: Function,
      default: () => true,
    },
  },
  components: { ArrowRightOnRectangleIcon },
  mixins: [Filtering, Sorting, Searching, Statusing],
  watch: {
    query: function () {
      this.searchEntriesByName(this.query);
    },
    "searchResults.length": function () {
      this.searchFocusIndex = this.canHaveNoFocus ? -1 : 0;
    },
  },
  mounted() {
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  },
  unmounted() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
  },
  data() {
    return {
      keyInterval: null,
      keyIsDown: false,
    };
  },
  computed: {
    outputComputed() {
      return { id: this.$nanoid(), data: this.searchOutput };
    },
  },
  methods: {
    onKeyDown(e) {
      if (e.key === "Tab") {
        e.preventDefault();
        e.stopPropagation();
      }
      if (e.key === "Enter") {
        if (this.searchFocusIndex >= 0) {
          e.preventDefault();
          e.stopPropagation();
          this.$emit(
            "onEntrySelect",
            this.$refs.output.entries[this.searchFocusIndex],
          );
        }
      }
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        e.stopPropagation();
        if (!this.keyIsDown) {
          this.keyIsDown = true;
          // Clear any existing interval
          if (this.keyInterval) {
            clearInterval(this.keyInterval);
          }

          // Set a new interval
          this.keyInterval = setInterval(() => {
            if (e.key === "ArrowDown") {
              this.incrementFocusIndex();
            } else if (e.key === "ArrowUp") {
              this.decrementFocusIndex();
            }
          }, 200); // Change the focus index every 200ms
        }
      }
    },

    incrementFocusIndex() {
      if (this.searchOutput.length) {
        // If the search focus index is less than the length of the search results
        if (this.searchFocusIndex < this.$refs.output.entries.length - 1) {
          // Increment the search focus index
          this.searchFocusIndex++;
        } else {
          // Otherwise set the search focus index to 0
          this.searchFocusIndex = 0;
        }
      }
    },
    decrementFocusIndex() {
      if (this.searchOutput.length) {
        // If the search focus index is greater than 0
        if (this.searchFocusIndex > 0 ? -1 : 0) {
          // Decrement the search focus index
          this.searchFocusIndex--;
        } else {
          // Otherwise set the search focus index to the length of the search results
          this.searchFocusIndex = this.$refs.output.entries.length - 1;
        }
      }
    },
    onKeyUp(e) {
      switch (e.key) {
        case "Tab":
        case "ArrowDown":
          e.preventDefault();
          e.stopPropagation();
          this.keyIsDown = false;
          clearInterval(this.keyInterval);
          this.keyInterval = null;
          this.incrementFocusIndex();
          break;
        case "ArrowUp":
          e.preventDefault();
          e.stopPropagation();
          this.keyIsDown = false;
          clearInterval(this.keyInterval);
          this.keyInterval = null;
          this.decrementFocusIndex();
          break;
      }
    },
  },
};
</script>
