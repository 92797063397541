<template>
  <div ref="dynamicDiv" class="relative h-full w-full">
    <svg ref="dynamicSvg" class="absolute inset-0">
      <defs>
        <linearGradient
          :id="'lineGradient_' + color"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
        >
          <stop offset="0%" :stop-color="colors[color][300]" />
          <!-- Tailwind blue-500 -->
          <stop offset="100%" :stop-color="colors[color][400]" />
          <!-- Tailwind purple-500 -->
        </linearGradient>
      </defs>
      <path
        ref="dynamicPath"
        fill="none"
        :stroke="'url(#lineGradient_' + color + ')'"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
import colors from "tailwindcss/colors";
export default {
  props: {
    percentage: {
      type: Number,
      default: 50,
      validator: (value) => value >= 0 && value <= 100,
    },
    strokeWidth: {
      type: Number,
      default: 6,
    },
    color: {
      type: String,
      default: "neutral",
    },
  },
  watch: {
    percentage: function () {
      this.animateLine(this.currentPercentage, this.percentage, 2000); // Animate over 2000 milliseconds
    },
    "$store.getters.timestamp": function () {
      if (this.animationFrameId === null) {
        setTimeout(() => {
          if (this.animationFrameId === null) {
            this.animateLine(0, this.percentage, 2000); // Animate over 2000 milliseconds
          }
        }, 300);
      }
    },
  },
  data() {
    return {
      colors: colors,
      currentPercentage: 0,
      animationFrameId: null,
    };
  },
  mounted() {
    setTimeout(() => {
      this.animateLine(0, this.percentage, 2000); // Animate over 2000 milliseconds
    }, 300);

    window.addEventListener("resize", this.updateSvgPath);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateSvgPath);
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  },
  methods: {
    // Cubic ease-out function
    easeOutCubic(t) {
      return --t * t * t + 1;
    },
    animateLine(start, end, duration) {
      const startTime = performance.now();
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        let progress = elapsedTime / duration;

        if (progress < 1) {
          progress = this.easeOutCubic(progress);
          this.currentPercentage = start + (end - start) * progress;
          this.animationFrameId = requestAnimationFrame(animate);
        } else {
          this.currentPercentage = end; // Ensure we end at the target percentage
        }

        this.updateSvgPath();
      };

      this.animationFrameId = requestAnimationFrame(animate);
    },
    updateSvgPath() {
      const div = this.$refs.dynamicDiv;
      const width = div.offsetWidth;
      const height = div.offsetHeight;
      const pathRadius = 12; // Tailwind's rounded-lg equivalent
      const strokeWidth = this.strokeWidth; // Stroke width
      const inset = strokeWidth / 2; // Adjusting for stroke width

      const d = `M ${pathRadius + inset},${inset}
                 H ${width - pathRadius - inset}
                 A ${pathRadius},${pathRadius} 0 0 1 ${width - inset},${
                   pathRadius + inset
                 }
                 V ${height - pathRadius - inset}
                 A ${pathRadius},${pathRadius} 0 0 1 ${
                   width - pathRadius - inset
                 },${height - inset}
                 H ${pathRadius + inset}
                 A ${pathRadius},${pathRadius} 0 0 1 ${inset},${
                   height - pathRadius - inset
                 }
                 V ${pathRadius + inset}
                 A ${pathRadius},${pathRadius} 0 0 1 ${
                   pathRadius + inset
                 },${inset}`;
      const pathElement = this.$refs.dynamicPath;
      pathElement.setAttribute("d", d);

      // Calculate the total length of the path
      const pathLength = pathElement.getTotalLength();

      // Set the stroke-dasharray and stroke-dashoffset based on the percentage
      const dashArray = pathLength;
      const dashOffset = pathLength * (1 - this.currentPercentage / 100);
      pathElement.style.strokeDasharray = dashArray;
      pathElement.style.strokeDashoffset = dashOffset + 1;
      pathElement.style.strokeWidth = strokeWidth;
    },
  },
};
</script>

<style>
.dynamic-div {
  width: 100%; /* or any other dynamic measurement */
  height: 100%; /* or any other dynamic measurement */
  position: relative;
}
</style>
