import moment from "moment";
import { nanoid } from "nanoid";

export function buildHousehold(demo) {
  const household_id = nanoid();
  demo.addEntry({
    id: household_id,
    name: "Household",
    description: {
      content: "Chores that need to be done.",
    },
    output: {
      data: [{ key: "links", op: "any", val: [household_id] }],
    },
    input: {
      data: { links: [household_id] },
    },
    links: [demo.e("More Views").id],
  });

  demo.addEntry({
    name: "Bring out Trash",
    completion: {
      completed: true,
      completed_at: moment.utc().add(-2, "days").format("YYYY-MM-DD 07:35:00"),
    },
    schedule: {
      date: moment.utc().add(-2, "days").format("YYYY-MM-DD"),
      time: demo.time("07:30:00"),
    },
    links: [demo.e("Household").id],
  });
  demo.addEntry({
    name: "Water the Plants",
    completion: { completed: false, completed_at: null },
    schedule: {
      date: moment.utc().format("YYYY-MM-DD"),
      time: demo.time("08:00:00"),
    },
    links: [demo.e("Household").id],
  });
  const grocery_shopping_id = nanoid();
  demo.addEntry({
    id: grocery_shopping_id,
    name: "Grocery Shopping",
    completion: { completed: false, completed_at: null },
    schedule: {
      date: moment.utc().format("YYYY-MM-DD"),
      time: demo.time("18:00:00"),
    },
    input: {
      data: {
        completion: { completed: false },
        links: [grocery_shopping_id],
      },
    },
    output: {
      data: [{ key: "links", op: "any", val: [grocery_shopping_id] }],
    },
    links: [demo.e("Household").id],
  });
  demo.addEntry({
    name: "Apples",
    completion: { completed: false, completed_at: null },
    links: [demo.e("Grocery Shopping").id],
  });
  demo.addEntry({
    name: "Cheese",
    completion: { completed: false, completed_at: null },
    links: [demo.e("Grocery Shopping").id],
  });
  demo.addEntry({
    name: "Bread",
    completion: { completed: false, completed_at: null },
    links: [demo.e("Grocery Shopping").id],
  });
  demo.addEntry({
    name: "Butter",
    completion: { completed: false, completed_at: null },
    links: [demo.e("Grocery Shopping").id],
  });
  demo.addEntry({
    name: "Clean Windows",
    completion: { completed: false, completed_at: null },
    schedule: {
      date: moment.utc().add(1, "days").format("YYYY-MM-DD"),
      time: demo.time("18:00:00"),
    },
    links: [demo.e("Household").id],
  });
}
