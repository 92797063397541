<template>
  <TextInput
    v-model="value"
    @update:modelValue="(val) => $emit('update:modelValue', [val])"
    :clearOnEnter="false"
    :blurOnEnter="true"
    placeholder="Enter text..."
    class="-m-1 p-1 text-sm"
  />
</template>

<script>
import TextInput from "../helpers/TextInput.vue";
export default {
  props: {
    modelValue: null,
  },
  data() {
    return {
      value: this.modelValue[0],
    };
  },
  components: { TextInput },
};
</script>
