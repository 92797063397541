<template>
  <div
    :class="{
      'font-bold ': highlightToday && date.isSame($moment(), 'day'),
    }"
  >
    {{ date.format(format) }}
  </div>
</template>

<script>
export default {
  props: {
    date: Object,
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
    highlightToday: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
