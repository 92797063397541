<template>
  <div>
    <div
      class="mx-auto w-full sm:max-w-xl md:max-w-2xl md:px-8 lg:max-w-3xl xl:max-w-5xl"
    >
      <div class="flex flex-col gap-4">
        <Unverified v-if="!user.verified" class="mb-4" />

        <PanelHelper>
          <div class="px-4 sm:px-0">
            <h3
              class="text-base font-semibold leading-7 text-neutral-900 dark:text-neutral-500"
            >
              User Information
            </h3>
            <p
              class="mt-1 max-w-2xl text-sm leading-6 text-neutral-500 dark:text-neutral-600"
            >
              Personal details.
            </p>
          </div>
          <div class="mt-6 border-t border-neutral-100 dark:border-neutral-500">
            <dl class="divide-y divide-neutral-100 dark:divide-neutral-500">
              <div
                v-for="(item, index) in details"
                :key="index"
                class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
              >
                <dt
                  class="text-sm font-medium leading-6 text-neutral-900 dark:text-neutral-300"
                >
                  {{ item.title }}
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-2 sm:mt-0 dark:text-neutral-400"
                >
                  {{ item.value }}
                </dd>
              </div>
              <!-- <div
            v-if="isActive"
            class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
          >
            <dt class="text-sm font-medium leading-6 text-neutral-900">
              Active until
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-2 sm:mt-0"
            >
              {{
                moment.utc(user.active_until).local().format("YYYY-MM-DD HH:mm")
              }}
              <AlertHelper>
                <template #title> No worries! </template>
                Subscriptions will undergo a big change soon, stay tuned
              </AlertHelper>
            </dd>
          </div>
          <div v-else class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-neutral-900">
              Inactive since
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-2 sm:mt-0"
            >
              {{
                moment.utc(user.active_until).local().format("YYYY-MM-DD HH:mm")
              }}
            </dd>
          </div> -->
              <!-- <div
            v-if="3 > 4 && user.verified"
            class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
          >
            <dt class="text-sm font-medium leading-6 text-neutral-900">
              Subscription
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-2 sm:mt-0"
            >
              <div v-if="!loadingSubscriptions">
                <div v-if="subscriptions.length">
                  <span
                    v-if="subscriptions[0].cancel_at"
                    style="
                      color: var(--color-red);
                      text-transform: uppercase;
                      font-size: 0.865rem;
                      font-weight: bold;
                    "
                  >
                    Cancelled
                  </span>
                  <span
                    v-else
                    style="
                      color: var(--color-green);
                      text-transform: uppercase;
                      font-size: 0.865rem;
                      font-weight: bold;
                    "
                  >
                    Active
                  </span>
                  <br />
                  <a :href="portalURL"> Manage Subscription </a>
                </div>
                <div v-else-if="sessionId">
                  <p>No subscription</p>
                  <StripeCheckout
                    v-if="showCheckout"
                    ref="checkoutRef"
                    :pk="publishableKey"
                    :session-id="sessionId"
                  />
                  <a @click="submit" class="cursor-pointer">Subscribe</a>
                </div>
              </div>
              <div v-else>Loading...</div>
            </dd>
          </div>

          <div v-else class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt class="text-sm font-medium leading-6 text-neutral-900">Tier</dt>
            <dd
              class="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-2 sm:mt-0"
            >
              Early Bird
            </dd>
          </div> -->

              <!-- <div
            v-if="false && user.verified"
            class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
          >
            <dt class="text-sm font-medium leading-6 text-neutral-900">
              API Token
            </dt>
            <dd
              class="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-2 sm:mt-0"
            >
              <div>
                <code>
                  {{
                    user.api_token
                      .split("")
                      .map((c) => (showApiToken ? c : "x"))
                      .join("")
                  }} </code
                >&nbsp;
                <a
                  @click="showApiToken = !showApiToken"
                  v-html="showApiToken ? 'hide' : 'show'"
                />
              </div>
              <div>
                <small style="font-weight: bold"
                  >Your API token gives you full read and modify access to your
                  Pensive data. Please treat it like a password and be careful
                  when you share it.</small
                >
              </div>
            </dd>
          </div> -->
            </dl>
          </div>
        </PanelHelper>
        <PanelHelper class="mt-2">
          <form>
            <div class="space-y-12">
              <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <label
                    for="mode"
                    class="block text-sm font-medium leading-6 text-neutral-900 dark:text-neutral-300"
                    >Mode</label
                  >
                  <div class="mt-2">
                    <select
                      v-model="user.settings.prefers_color_scheme"
                      @change="
                        (event) => {
                          (user.settings = {
                            ...user.settings,
                            prefers_color_scheme: event.target.value,
                          }),
                            $store.dispatch('push', {
                              event: 'user_update',
                              params: { user: user },
                              user: user,
                            });
                        }
                      "
                      id="mode"
                      name="mode"
                      class="block w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-300 dark:ring-neutral-700"
                    >
                      <option value="system">System preference</option>
                      <option value="light">Always Light mode</option>
                      <option value="dark">Always Dark mode</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </PanelHelper>
      </div>
    </div>
  </div>
</template>

<script>
//import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import moment from "moment";
import Unverified from "./Unverified.vue";

export default {
  name: "SubscriptionComponent",
  components: {
    //StripeCheckout,
    Unverified,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    subscriptions() {
      return this.$store.getters.subscriptions;
    },
    isActive() {
      return moment().unix() <= moment(this.user.active_until).unix();
    },
    details() {
      return [
        {
          title: "Full name",
          value: this.user.fullname,
        },
        {
          title: "Email address",
          value: this.user.email,
        },
        {
          title: "Timezone",
          value: this.user.timezone,
        },
      ];
    },
  },
  data() {
    this.publishableKey =
      process.env.NODE_ENV === "production"
        ? "pk_live_51MjPE1DONrNfdT6AfWgTdMSh6KRj05vePn6AY5Uq6IlCB45ERT7K5GJ7T8lcudkwDRNN4b2nonEj37XKiA8Ocr090005ihksck"
        : "pk_test_51MjPE1DONrNfdT6ATalHegQHUkO9XKz5sbJjPUeNMQDRZopnqV977FJhJByr7KyDJ9yMMJ3MLfKULNWZkH7SIWPX00eDL1Gy74";

    return {
      moment: moment,
      showApiToken: false,
      sessionId: null,
      portalURL: null,
      loadingSubscriptions: true,
      showCheckout: false,
    };
  },
  mounted() {
    if (this.user.verified) {
      axios.get("stripe/session").then((response) => {
        this.sessionId = response.data.id;
      });
      axios.get("stripe/portal").then((response) => {
        this.portalURL = response.data.url;
      });
      this.$store.dispatch("retrieveSubscriptions").then(() => {
        this.loadingSubscriptions = false;
      });
    }
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.showCheckout = true;
      this.$nextTick(() => {
        this.$refs.checkoutRef.redirectToCheckout();
      });
    },
    getCurrentPeriod(sub) {
      return moment(new Date(sub.current_period_end * 1000)).format(
        "YYYY-MM-DD HH:mm:ss",
      );
    },
  },
};
</script>

<style scoped>
a {
  color: var(--color-text-secondary);
  font-weight: bold;
}
a:hover {
  color: var(--color-text-primary);
  font-weight: bold;
}
</style>
