
import { addonBlueprint } from "@/addonBlueprint";

export const TimeTrackingsMixin = {

    data() {
        return {
            interval: null,
            activeDuration: "00:00:00",
            progressPercentage: 0,
        };
    },
    // mounted() {
    //     this.getactiveDuration();
    //     this.interval = setInterval(() => {
    //         this.getactiveDuration();
    //     }, 1000);
    // },
    // beforeUnmount() {
    //     clearInterval(this.interval);
    // },
    methods: {
        getActiveTimeTrackingOfEntry(entry) {
            return entry.time_trackings.find(
                (tt) => tt.user_id == this.user.id && !tt.end_at,
            );
        },
        startTimeTracking(entry = null) {

            if (this.time_trackings.find((tt) => { return tt.user_id == this.user.id && tt.end_at == null })) {
                return;
            }

            const target = entry ? entry : this.entry;
            if (target) {

                const time_track = addonBlueprint("time_track", target, this);
                target.time_trackings?.push(time_track);
                this.user.time_trackings.push(time_track);

                this.$store.dispatch("push", {
                    event: "entry_update",
                    params: { entry: target },
                    entry: target,
                    undo: 1,
                });
                this.getactiveDuration();
            } else {
                console.log("No entry to start tracking")
            }
        },
        stopTimeTracking(entry = null) {
            if (!this.$store.getters.time_trackings.find((tt) => { return tt.user_id == this.user.id && tt.end_at == null })) {
                return;
            }

            const target = entry || this.entry;
            const activeTimeTracking = entry ? this.getActiveTimeTrackingOfEntry(entry) : this.userHasActiveTimeTracking;
            // console.log(activeTimeTracking)
            if (!activeTimeTracking) return;
            const currentTime = this.$moment.utc();
            // const startTime = this.$moment.utc(activeTimeTracking.start_time);
            // const elapsedTime = this.$moment.duration(currentTime.diff(startTime));

            activeTimeTracking.end_at = currentTime.format("YYYY-MM-DD HH:mm:ss");

            if (this.userHasInEntryActiveTimeTracking) {
                this.userHasInEntryActiveTimeTracking.end_at =
                    activeTimeTracking.end_at;
            }

            // activeTimeTracking.duration = elapsedTime.asSeconds();

            this.$store.dispatch("push", {
                event: "entry_update",
                params: { entry: target },
                entry: target,
                undo: 1,
            });
        },

        getDurationOfTimeTracking(time_tracking) {
            const startTime = this.$moment.utc(time_tracking.start_at);
            const endTime = this.$moment.utc(time_tracking.end_at);
            const elapsedTime = this.$moment.duration(endTime.diff(startTime));

            return (
                (elapsedTime.hours() ? elapsedTime.hours() + "h " : "") +
                elapsedTime.minutes() +
                "m " +
                elapsedTime.seconds() +
                "s"
            );
        },
        getactiveDuration() {
            if (!this.userHasActiveTimeTracking) return;
            const activeTimeTracking = this.userHasActiveTimeTracking;
            const currentTime = this.$moment.utc();
            const startTime = this.$moment.utc(activeTimeTracking.start_at);
            const elapsedTime = this.$moment.duration(currentTime.diff(startTime));

            this.activeDuration =
                elapsedTime.hours().toString().padStart(2, '0') +
                ":" +
                elapsedTime.minutes().toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                }) +
                ":" +
                elapsedTime.seconds().toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                });


            // console.log(this.entry?.name, this.activeDuration)

            if (this.entry?.schedule?.duration)
                this.progressPercentage = Math.min((elapsedTime.asSeconds() / this.entry.schedule?.duration) * 100, 100);


        },
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        userHasActiveTimeTracking() {
            return this.user.time_trackings.find(
                (tt) => tt.user_id == this.user.id && !tt.end_at,
            );
        },
        userHasInEntryActiveTimeTracking() {
            return this.entry?.time_trackings.find(
                (tt) => tt.user_id == this.user.id && !tt.end_at,
            );
        },
        activelyTrackedTime() {
            const activeTimeTracking = this.userHasActiveTimeTracking;
            const currentTime = this.$moment.utc();
            const startTime = this.$moment.utc(activeTimeTracking.start_time);
            const elapsedTime = this.$moment.duration(currentTime.diff(startTime));

            return (
                elapsedTime.hours() +
                ":" +
                elapsedTime.minutes() +
                ":" +
                elapsedTime.seconds() +
                ":"
            );
        },
        scheduleDuration() {
            return this.$moment.utc(this.entry.schedule?.duration * 1000).format("H:mm:ss");
        },
    },
};
