<template>
  <section data-test="date_time_picker">
    <DatePicker ref="date" :entry="entry" v-model="date" :disabled="disabled" />
    <TimePicker ref="time" :entry="entry" v-model="time" :disabled="disabled" />
  </section>
</template>

<script>
import moment from "moment";
import DatePicker from "../builders/DatePicker.vue";
import TimePicker from "../builders/TimePicker.vue";
import { Scheduling } from "../mixins/Scheduling";

export default {
  name: "DateTimePicker",
  mixins: [Scheduling],
  components: { DatePicker, TimePicker },
  props: {
    entry: Object,
    modelValue: [Object, String],
    disabled: Boolean,
  },
  mounted() {
    this.extractInfo();
  },
  data() {
    return {
      date: null,
      time: null,
    };
  },
  computed: {
    validatedValue() {
      if (
        this.date == null ||
        this.time == null ||
        (this.date && this.date.op && this.date.op === "null") ||
        (this.time && this.time.op && this.time.op === "null")
      )
        return null;
      return this.date + " " + this.time;
    },
  },

  watch: {
    modelValue() {
      this.extractInfo();
    },
    validatedValue() {
      if (this.validatedValue != this.modelValue)
        this.$emit("update:modelValue", this.validatedValue);
    },
  },
  methods: {
    extractInfo() {
      if (this.modelValue) {
        const datetime = moment.utc(this.modelValue);
        this.date = datetime.format("YYYY-MM-DD");
        this.time = datetime.format("HH:mm:ss");
      }
    },
  },
};
</script>
