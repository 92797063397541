import { initHistory } from "@/addonBlueprint";
import moment from "moment";

export const Crud = {
    methods: {
        deleteEntry(entry = null) {
            if (entry === null) entry = this.entry;
            entry.deleted_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
            this.$store.getters.entries
                .filter((e) => e.links.includes(entry.id))
                .forEach((e) => {
                    e.links.splice(
                        e.links.findIndex((l) => l == entry.id),
                        1,
                    );
                });
            if (!entry.temp)
                this.$store.dispatch("push", {
                    event: "entry_delete",
                    params: { id: entry.id },
                    entry: entry,
                });
            if (this.$route.params.id == entry.id) this.$router.back();
        },
        undeleteEntry(entry = null) {
            if (entry === null) entry = this.entry;
            entry.deleted_at = null;
            if (!entry.temp)
                this.$store.dispatch("push", {
                    event: "entry_update",
                    params: { entry: entry },
                    entry: entry,
                });
        },
        updateObject(entry, fn) {

            const histories = [];


            const entryProxy = new Proxy(entry, {
                get: (target, key) => {
                    if (typeof target[key] === 'object' && target[key] !== null) {
                        const before = JSON.parse(JSON.stringify(target[key]));
                        return new Proxy(target[key], {
                            set: (addon, prop, value) => {
                                addon[prop] = value;
                                if (JSON.stringify(before) != JSON.stringify(addon))
                                    histories.push({ ...initHistory(target, this.$store.getters.user.id), ...{ 'field': key, 'before': before, 'after': addon } });
                                return target;
                            },
                        });
                    }
                    return target[key];
                },
                set: (target, prop, value) => {
                    if (JSON.stringify(target[prop]) != JSON.stringify(value))
                        histories.push({ ...initHistory(target, this.$store.getters.user.id), ...{ 'field': prop, 'before': target[prop], 'after': value } });
                    target[prop] = value;
                    return target;
                },
            });

            fn(entryProxy);

            if (histories.length) {
                console.log(histories)
            }

            this.$store.dispatch('history', [...this.$store.getters.histories, ...histories]);

            if (!entry.temp) {
                this.$store.dispatch("push", {
                    event: "entry_update",
                    params: { entry: entry },
                    entry: entry,
                });
                histories.forEach((history) => {
                    this.$store.dispatch("push", {
                        event: "history_create",
                        params: { history: history },
                        history: history,
                        entry: entry,
                    });
                });
            }
        }
    },
};
