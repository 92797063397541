import moment from "moment";
import { nanoid } from "nanoid";

export function buildSelfCare(demo) {
  const self_care_id = nanoid();
  demo.addEntry({
    id: self_care_id,
    name: "Self Care",
    description: {
      content: "These things are good for you.\nLearn more about this in the [Best Practices](https://pensive.io/best-practices#tracking-list)",
    },
    output: {
      data: [{ key: "links", op: "any", val: [self_care_id] }],
    },
    input: {
      data: {
        completion: { completed: false, completed_at: null },
        links: [self_care_id],
      },
    },
    anchor: {
      position: demo.entries.filter((e) => e.anchor).length,
    },
  });
  demo.addEntry({
    name: "Take a Walk",
    completion: {
      completed: false,
      completed_at: null,
    },
    schedule: {
      date: moment.utc().format("YYYY-MM-DD"),
      time: demo.time("15:00:00"),
    },
    links: [demo.e("Self Care").id],
  });

  demo.addEntry({
    name: "Go to the Gym",
    completion: {
      completed: false,
      completed_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    schedule: {
      date: moment.utc().add(1, "day").format("YYYY-MM-DD"),
      time: demo.time("17:00:00"),
    },
    links: [demo.e("Self Care").id],
  });

  demo.addEntry({
    name: "Practice Piano",
    completion: {
      completed: false,
      completed_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    schedule: {
      date: moment.utc().add(2, "day").format("YYYY-MM-DD"),
      time: demo.time("20:00:00"),
    },
    links: [demo.e("Self Care").id],
  });

  demo.addEntry({
    name: "Meditate",
    completion: {
      completed: false,
      completed_at: null,
    },
    schedule: {
      date: moment.utc().format("YYYY-MM-DD"),
      time: demo.time("07:15:00"),
    },
    links: [demo.e("Self Care").id],
  });

  demo.addEntry({
    name: "Take a Bath",
    completion: {
      completed: false,
      completed_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    schedule: {
      date: moment.utc().add(3, "day").format("YYYY-MM-DD"),
      time: demo.time("20:00:00"),
    },
    links: [demo.e("Self Care").id],
  });

  demo.addEntry({
    name: "Weighting",
    completion: {
      completed: true,
      completed_at:
        moment.utc().add(-2, "weeks").format("YYYY-MM-DD") + " 07:05:00",
    },
    schedule: {
      date: moment.utc().add(-30, "day").format("YYYY-MM-DD"),
      time: demo.time("07:00:00"),
    },
    links: [demo.e("Self Care").id],
  });
}
