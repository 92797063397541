import moment from "moment";
import { nanoid } from "nanoid";

export function buildInbox(demo) {
  var id = nanoid();
  let inbox = demo.addEntry({
    name: "Inbox",
    description: {
      content: "Your inbox. A good place to write down whats on your mind.\nLearn more about this in the [Best Practices](https://pensive.io/best-practices#inbox)",
    },
    output: {
      data: [
        { key: "anchor", op: "is not set", val: [] },
        { key: "links", op: "not any", val: [] },
      ],
    },
    sorting: {
      data: [{ field: ["date", "created"], dir: 1 }],
    },
    input: {
      data: {},
    },
    anchor: {
      position: demo.entries.filter((e) => e.anchor).length,
    },
  });
  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Call Mom",
    created_at: moment.utc().add(-2, "days").format("YYYY-MM-DD HH:mm:ss"),
    completion: {
      completed: false,
      completed_at: null,
    },
    schedule: {
      date: moment.utc().format("YYYY-MM-DD"),
      time: demo.time("16:30:00"),
    },
  });
  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Buy Gift for Dad",
    created_at: moment.utc().add(-2, "days").format("YYYY-MM-DD HH:mm:ss"),
    completion: {
      completed: false,
      completed_at: null,
    },
    schedule: {
      date: moment.utc().add(1, "days").format("YYYY-MM-DD"),
      time: demo.time("10:00:00"),
    },
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Buy Flowers",
    created_at: moment.utc().add(-1, "days").format("YYYY-MM-DD HH:mm:ss"),
    completion: {
      completed: false,
      completed_at: null,
    },
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: 'Quote: "The only way to do great work is to love what you do." - Steve Jobs',
  });

  return inbox;
}
