<template>
  <div v-if="output" class="flex flex-wrap gap-2">
    <!-- <TransitionGroupHelper
      :leave="{
        translateY: [0, 20],
        opacity: [1, 0],
      }"
    > -->

    <AspectTypePicker
      v-if="permissions.type"
      v-model="output.type"
      :size="size"
      :variant="variant"
      :color="color"
      @update:modelValue="
        (type) => {
          output = { ...output, ...{ type: type } };
        }
      "
    />

    <GroupingPicker
      v-if="permissions.group"
      v-model="output.grouping"
      :size="size"
      :variant="variant"
      :color="color"
      @update:modelValue="
        (grouping) => {
          output = { ...output, ...{ grouping: grouping } };
        }
      "
      data-test="entry_output_grouping_button"
    />
    <PopoverHelper
      v-if="permissions.filter"
      id="output_builder"
      :canOverflow="true"
      data-test="output_builder"
      @close="
        temporaryFilters !== null
          ? (output = { ...output, ...{ data: temporaryFilters } })
          : null
      "
    >
      <template v-slot:button>
        <ButtonComponent
          :size="size"
          :variant="variant"
          :color="color"
          title="Filter Output"
        >
          <IconHelper name="filter" size="16" />
          <span v-if="variant != 'minimal'" class="hidden md:block">
            Filters:
            <span v-if="output?.data">{{ output.data.length }}</span>
          </span>
          <ChevronDownIcon
            v-if="variant != 'minimal'"
            :class="'text-neutral-300'"
            class="h-4 w-4 transition duration-150 ease-in-out group-hover:text-neutral-300/80"
            aria-hidden="true"
          />
        </ButtonComponent>
      </template>
      <template v-slot="{ close }">
        <div
          class="flex w-[320px] flex-col gap-4 p-3 md:w-[500px] lg:w-[700px]"
        >
          <LabelHelper>
            <FilterIcon size="16" stroke-width="1.75" /> Filters
          </LabelHelper>

          <OutputBuilder
            id="output_builder_inner"
            v-model="output.data"
            :entry="entry"
            @onChange="
              (data) => {
                temporaryFilters = data;
              }
            "
            @update:modelValue="
              (data) => {
                close();
                output = { ...output, ...{ data: data } };
              }
            "
          />
          <a
            href="https://help.pensive.io/outputs"
            target="_blank"
            class="text-sm text-blue-500 hover:text-blue-700 hover:underline"
          >
            <QuestionMarkCircleIcon class="mr-1 inline h-4 w-4" />
            <span>Learn more about filters</span>
          </a>
        </div>
      </template>
    </PopoverHelper>

    <PopoverHelper v-if="permissions.sort" :canOverflow="true">
      <template v-slot:button>
        <ButtonComponent
          :size="size"
          :variant="variant"
          :color="color"
          data-test="entry_output_settings_sorting_button"
          title="Sort Output"
        >
          <ChevronUpDownIcon class="h-4 w-4" />

          <span v-if="variant != 'minimal'" class="hidden md:block">
            Sort:
            <span v-if="output?.sorting">{{ output.sorting.length }}</span>
          </span>

          <ChevronDownIcon
            v-if="variant != 'minimal'"
            :class="'text-neutral-300'"
            class="h-4 w-4 transition duration-150 ease-in-out group-hover:text-neutral-300/80"
            aria-hidden="true"
          />
        </ButtonComponent>
      </template>
      <div class="flex w-[320px] flex-col gap-4 p-3 md:w-[500px] lg:w-[700px]">
        <LabelHelper>
          <ChevronUpDownIcon class="h-4 w-4" />
          <span>Sorting</span>
        </LabelHelper>
        <SortingBuilder
          v-model="output.sorting"
          @update="
            (e) => {
              output = { ...output, ...{ sorting: e } };
            }
          "
        />
        <a
          href="https://help.pensive.io/outputs#sortings"
          target="_blank"
          class="text-sm text-blue-500 hover:text-blue-700 hover:underline"
        >
          <QuestionMarkCircleIcon class="mr-1 inline h-4 w-4" />
          <span>Learn more about sorting</span>
        </a>
      </div>
    </PopoverHelper>
    <div v-if="showDelete">
      <ButtonComponent
        @click="output = null"
        :size="size"
        :variant="variant"
        :color="color"
        title="Delete Output"
      >
        <TrashIcon class="h-4 w-4" />
        <span v-if="variant != 'minimal'"> Delete</span>
      </ButtonComponent>
    </div>
    <!-- </TransitionGroupHelper> -->
  </div>

  <div v-else>
    <ButtonComponent
      @click="output = addonBlueprint('output', entry, this)"
      :size="size"
      :variant="variant"
      :color="color"
      data-test="entry_output_create"
      title="Add Output"
    >
      <IconHelper name="ListPlus" size="20" />

      <span v-if="!['round', 'minimal'].includes(variant)"> Output </span>
    </ButtonComponent>
  </div>
</template>
<script setup>
import { addonBlueprint } from "@/addonBlueprint";

import { FilterIcon } from "lucide-vue-next";
</script>
<script>
import AspectTypePicker from "../builders/AspectTypePicker.vue";
import GroupingPicker from "../builders/GroupingPicker.vue";
import OutputBuilder from "../builders/OutputBuilder.vue";
import SortingBuilder from "../builders/SortingBuilder.vue";

import { Addon } from "../mixins/Addon";

export default {
  mixins: [Addon],
  inject: {
    renderType: {
      default: "list",
    },
  },
  props: {
    showDelete: {
      type: Boolean,
      default: true,
    },

    permissions: {
      type: Object,
      default: () => ({
        type: true,
        group: true,
        filter: true,
        sort: true,
      }),
    },
  },

  components: {
    AspectTypePicker,
    GroupingPicker,
    OutputBuilder,
    SortingBuilder,
    // ICONS
    FilterIcon,
  },

  data() {
    return {
      temporaryFilters: null,
    };
  },

  computed: {
    output: {
      get() {
        return this.modelValue;
      },
      set(output) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
  },
};
</script>
