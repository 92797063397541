<template>
  <div v-if="entry" @click.stop>
    <div
      v-if="activelyTimeTrackedEntry && activelyTimeTrackedEntry.id == entry.id"
    >
      <ButtonComponent
        type="button"
        ref="reference"
        @click="() => stopTimeTracking(entry)"
        data-test="time_trackings_settings_action_stop"
        :variant="variant"
        :size="size"
        title="Stop time tracking"
      >
        <IconHelper name="stop" size="20" />
        <span v-if="variant != 'round'"> {{ activeDuration }} </span>

        <ButtonComponent
          v-if="variant == 'round'"
          variant="round"
          size="xs"
          color="blue"
          class="!absolute right-0 top-0 -translate-y-1/3 translate-x-2/3 text-xs hover:bg-blue-600"
        >
          {{ activeDuration }}
        </ButtonComponent>
      </ButtonComponent>
    </div>
    <div v-else class="inline-flex items-start gap-x-2">
      <ButtonComponent
        type="button"
        ref="reference"
        :disabled="activelyTimeTrackedEntry ? true : false"
        @click="() => startTimeTracking(entry)"
        data-test="time_trackings_settings_action_start"
        :variant="variant"
        :size="size"
        :title="
          activelyTimeTrackedEntry
            ? 'Already tracking ' + activelyTimeTrackedEntry.name
            : 'Start time tracking'
        "
        :color="color"
        :class="{
          'cursor-not-allowed': activelyTimeTrackedEntry != null,
        }"
      >
        <IconHelper
          name="play"
          size="20"
          class="group-hover/button:text-blue-500"
        />
        <span v-if="variant != 'round'">Track</span>
      </ButtonComponent>

      <PopoverHelper :teleport="true" class="relative flex items-center">
        <template v-slot:button>
          <ButtonComponent
            :variant="variant"
            :size="size"
            :color="color"
            title="Time Trackings"
          >
            <IconHelper name="timer" size="20" />
          </ButtonComponent>
        </template>

        <template v-slot="{ close }">
          <div class="relative mx-4 my-2">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center">
              <span
                class="flex items-center gap-2 bg-white px-2 text-sm text-gray-500"
              >
                Time Trackings
              </span>
            </div>
          </div>
          <TimeTrackingsWrapper
            v-model="time_trackings"
            :entry="entry"
            class=""
          />

          <OutputDisplay
            v-model="outputComputed"
            :entry="entry"
            :filter="(e) => e.time_trackings.length"
            :display="{
              name: true,
              status: false,
              links: false,
              description: false,
              schedule: false,
              output: false,
              input: false,
              columns: false,
              leftover: false,
              settings: true,
              sense: true,
              time_trackings: false,
            }"
            :sorting="[{ field: 'updated_at', dir: -1 }]"
            position="contextmenu"
            @onEntryClick="
              () => {
                close();
              }
            "
            :editable="false"
            class="w-96 p-5 text-sm"
          >
            <template v-slot:output-before="{ entries }">
              <div class="flex space-x-3">
                <div class="min-w-0 flex-1">
                  <p class="text-sm font-semibold text-gray-900">
                    <a>
                      Linked Entries Duration:
                      {{ getTotalDurationOfEntries(entries) }}
                    </a>
                  </p>
                </div>
              </div>
            </template>
            <template v-slot:entry-top-right="{ entry }">
              {{
                $moment
                  .utc(
                    entry.time_trackings
                      .filter((tt) => tt.end_at !== null)
                      .map((tt) => {
                        return (
                          $moment.utc(tt.end_at).unix() -
                          $moment.utc(tt.start_at).unix()
                        );
                      })
                      .reduce((a, b) => a + b, 0) * 1000,
                  )
                  .format("HH:mm:ss")
              }}
            </template>
          </OutputDisplay>
        </template>
      </PopoverHelper>
    </div>
  </div>
</template>

<script>
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Crud } from "../mixins/Crud";
import { Filtering } from "../mixins/Filtering";
import TimeTrackingsWrapper from "./partials/TimeTrackingsWrapper.vue";
import { Tracking } from "../mixins/Tracking";
import { TimeTrackingsMixin } from "./mixins/TimeTrackingsMixin";
import { Addon } from "../mixins/Addon";

export default {
  mixins: [
    Addon,
    Scheduling,
    Statusing,
    Crud,
    Filtering,
    Tracking,
    TimeTrackingsMixin,
  ],
  components: { TimeTrackingsWrapper },
  computed: {
    time_trackings: {
      get() {
        return this.entry.time_trackings;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    outputComputed() {
      return {
        data: [
          {
            key: "links",
            op: "any",
            val: [this.entry.id],
          },
        ],
      };
    },
  },
  methods: {},
};
</script>
