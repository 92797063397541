<template>
  <div>
    <button
      v-if="!entry.description"
      role="button"
      @click="
        () => {
          createDescription();
          focusEditor();
        }
      "
    >
      <slot name="button">
        <ButtonComponent
          :variant="variant"
          :size="size"
          :color="color"
          :colorWeight="colorWeight"
          title="Add Description"
          data-test="entry_description_create"
        >
          <IconHelper name="Text" size="20" />
          <span v-if="variant != 'round'"> Description </span>
        </ButtonComponent>
      </slot>
    </button>
    <button v-else role="button" @click="description = null">
      <slot name="button">
        <ButtonComponent
          @click="description = null"
          :variant="variant"
          :size="size"
          title="Remove Description"
          data-test="entry_description_delete"
        >
          <IconHelper name="Text" size="20" />
          <ButtonComponent
            :variant="variant"
            size="xs"
            color="red"
            class="!absolute right-0 top-0 -translate-y-1/3 translate-x-1/3 hover:bg-red-600"
          >
            <XMarkIcon class="h-3 w-3" />
          </ButtonComponent>
          <span v-if="variant != 'round'">Remove Description</span>
        </ButtonComponent>
      </slot>
    </button>
  </div>
</template>

<script>
import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "../mixins/Addon";
import { EditUtilities } from "../mixins/EditUtilities";

export default {
  mixins: [Addon, EditUtilities],
  data() {
    return {
      setTimeout: setTimeout,
      name: "description",
    };
  },
  computed: {
    description: {
      get() {
        return this.modelValue;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.description = value;
        if (!this.entry.temp)
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
            undo: 1,
          });
      },
    },
  },
  methods: {
    createDescription() {
      this.description = addonBlueprint("description", this.entry, this);
    },
    focusEditor() {
      setTimeout(() => {
        let editor = null;
        if (this.$route.params.id2) {
          editor = document.querySelector(
            "#entry-modal-page-wrapper #description_editor [contenteditable]",
          );
        } else {
          editor = document.querySelector(
            "#description_editor [contenteditable]",
          );
        }
        if (editor) {
          editor.focus();
        }
      }, 200);
    },
  },
};
</script>

<style scoped>
section:not(:has([contenteditable="true"])) {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>
