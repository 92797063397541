import moment from "moment";

export const Sorting = {
  methods: {
    sortEntries(sorting) {
      if (typeof sorting == "undefined") return;
      if (sorting === null) return;

      return (a, b) => {
        for (var i = 0; i < sorting.length; i++) {
          var dir = sorting[i].dir;
          var field = null;
          var attribute = null;
          if (sorting[i].field && typeof sorting[i].field == "object") {
            [field, attribute] = sorting[i].field;
          } else {
            field = sorting[i].field;
          }

          switch (field) {
            case "name":
              if (a.name.toLowerCase() > b.name.toLowerCase()) return dir;
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -dir;
              break;

            case "date":
              switch (attribute) {
                case "created":
                  if (
                    moment(a.created_at ?? "1970-01-01 01:00:00").unix() >
                    moment(b.created_at ?? "1970-01-01 01:00:00").unix()
                  )
                    return dir;
                  if (
                    moment(a.created_at ?? "1970-01-01 01:00:00").unix() <
                    moment(b.created_at ?? "1970-01-01 01:00:00").unix()
                  )
                    return -dir;
                  break;
                case "updated":
                  if (
                    moment(a.updated_at ?? "1970-01-01 01:00:00").unix() >
                    moment(b.updated_at ?? "1970-01-01 01:00:00").unix()
                  )
                    return dir;
                  if (
                    moment(a.updated_at ?? "1970-01-01 01:00:00").unix() <
                    moment(b.updated_at ?? "1970-01-01 01:00:00").unix()
                  )
                    return -dir;
                  break;
              }
              break;
            case "completed_at":
              if (
                moment(a.completed_at ?? "1970-01-01 01:00:00").unix() >
                moment(b.completed_at ?? "1970-01-01 01:00:00").unix()
              )
                return dir;
              if (
                moment(a.completed_at ?? "1970-01-01 01:00:00").unix() <
                moment(b.completed_at ?? "1970-01-01 01:00:00").unix()
              )
                return -dir;
              break;
            case "updated_at":
              if (
                moment(a.updated_at ?? "1970-01-01 01:00:00").unix() >
                moment(b.updated_at ?? "1970-01-01 01:00:00").unix()
              )
                return dir;
              if (
                moment(a.updated_at ?? "1970-01-01 01:00:00").unix() <
                moment(b.updated_at ?? "1970-01-01 01:00:00").unix()
              )
                return -dir;
              break;

            // case "completion":
            //   switch (attribute) {
            //     case "completed":
            //       if (
            //         a.completion.completed == true &&
            //         b.completion.completed == false
            //       )
            //         return dir;
            //       if (
            //         a.completion.completed == false &&
            //         b.completion.completed == true
            //       )
            //         return -dir;
            //       break;
            //     case "completed_at":
            //       if (
            //         typeof a.completion != "undefined" &&
            //         typeof a.completion.completed_at != "undefined"
            //       ) {
            //         if (
            //           moment(
            //             a.completion.completed_at ?? "1970-01-01 01:00:00"
            //           ).unix() >
            //           moment(
            //             b.completion.completed_at ?? "1970-01-01 01:00:00"
            //           ).unix()
            //         )
            //           return dir;
            //         if (
            //           moment(
            //             a.completion.completed_at ?? "1970-01-01 01:00:00"
            //           ).unix() <
            //           moment(
            //             b.completion.completed_at ?? "1970-01-01 01:00:00"
            //           ).unix()
            //         )
            //           return -dir;
            //       }
            //       break;
            //   }
            //   break;
            case "schedule":
              switch (attribute) {
                case "date":
                  if (
                    (typeof a.schedule == "undefined" || a.schedule == null ||
                      a.schedule.date == null) &&
                    typeof b.schedule != "undefined" && b.schedule != null &&
                    b.schedule.date != null
                  )
                    return -dir;
                  if (
                    typeof a.schedule != "undefined" && a.schedule != null &&
                    a.schedule.date != null &&
                    (typeof b.schedule == "undefined" || b.schedule == null ||
                      b.schedule.date == null)
                  )
                    return dir;

                  if (
                    moment(a.schedule?.date).unix() >
                    moment(b.schedule?.date).unix()
                  )
                    return dir;
                  if (
                    moment(a.schedule?.date).unix() <
                    moment(b.schedule?.date).unix()
                  )
                    return -dir;
                  break;
                case "time":
                  if (
                    (moment.utc(a.schedule?.time, "HH:mm:ss").local().format("HH:mm:ss") || "00:00:00") >
                    (moment.utc(b.schedule?.time, "HH:mm:ss").local().format("HH:mm:ss") || "00:00:00")
                  )
                    return dir;
                  if (
                    (moment.utc(a.schedule?.time, "HH:mm:ss").local().format("HH:mm:ss") || "00:00:00") <
                    (moment.utc(b.schedule?.time, "HH:mm:ss").local().format("HH:mm:ss") || "00:00:00")
                  )
                    return -dir;
                  return 0;
              }
              break;

            case "priority":
              if ((a.priority?.level || 3.5) < (b.priority?.level || 3.5))
                return dir;
              if ((a.priority?.level || 3.5) > (b.priority?.level || 3.5))
                return -dir;
              break;
            case "procrastination":
              if ((a.procrastination?.count || 0) > (b.procrastination?.count || 0))
                return dir;
              if ((a.procrastination?.count || 0) < (b.procrastination?.count || 0))
                return -dir;
              break;
            case "anchor":
              if ((a.anchor?.position || 0) > (b.anchor?.position || 0))
                return dir;
              if ((a.anchor?.position || 0) < (b.anchor?.position || 0))
                return -dir;
              break;
            case "status":
              if ((this.getStatusById(a.status_id)?.position || 0) > (this.getStatusById(b.status_id)?.position || 0))
                return dir;
              if ((this.getStatusById(a.status_id)?.position || 0) < (this.getStatusById(b.status_id)?.position || 0))
                return -dir;
              break;
          }
        }
        return 0;
      };
    },
    sortByPosition(dir) {
      return (a, b) => {
        if ((a.position || 0) > (b.position || 0))
          return dir;
        if ((a.position || 0) < (b.position || 0))
          return -dir;
        return 0;
      }
    }
  },
};
