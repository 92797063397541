<template>
  <EditorContent :editor="editor" />
</template>

<script>
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import { Link } from "@tiptap/extension-link";
import { Editor, EditorContent } from "@tiptap/vue-3";

export default {
  components: {
    EditorContent,
  },

  props: {
    modelValue: {
      type: String,
      default: "",
    },
    editable: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
  },

  emits: ["update:modelValue"],

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    modelValue(value) {
      // // HTML
      // const isSame = this.editor.getHTML() === value;

      // // JSON
      // // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      // if (isSame) {
      //   return;
      // }

      this.editor.commands.setContent(value);
    },
    editable(value) {
      this.editor.setEditable(value);
    },
  },

  mounted() {
    // console.log("Mounting TextEditor", this.modelValue);
    this.editor = new Editor({
      editable: this.editable,
      extensions: [
        StarterKit,
        Link,
        Markdown,
        Placeholder.configure({
          // Use a placeholder:
          placeholder: this.placeholder,
          // Use different placeholders depending on the node type:
          // placeholder: ({ node }) => {
          //   if (node.type.name === 'heading') {
          //     return 'What’s the title?'
          //   }

          //   return 'Can you add some further context?'
          // },
        }),
      ],
      content: this.modelValue,
      placeholder: "Start typing...",
      onBlur: () => {
        // if (
        //   this.modelValue?.length &&
        //   this.editor.storage.markdown.getMarkdown() == this.modelValue
        // )
        //   return;
        // console.log(this.editor.get());
        // HTML
        this.$emit("update:modelValue", this.editor.getHTML());
        // this.$emit(
        //   "update:markdown",
        //   this.editor.storage.markdown.getMarkdown(),
        // );

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },
    });
  },

  beforeUnmount() {
    // this.editor.destroy();
  },
};
</script>
