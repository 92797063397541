<template>
  <div
    v-if="entry && schedule"
    data-test="entry_schedule_settings"
    @click.stop
    class="flex flex-wrap items-center gap-x-2 gap-y-4 text-neutral-500"
  >
    <ButtonComponent :variant="variant" :size="size">
      <DatePicker
        ref="date"
        :entry="entry"
        v-model="date"
        @update:modelValue="updateDate"
        @update:recurrence="
          (value) => {
            schedule = { ...schedule, recurrence: value };
          }
        "
        data-test="entry_schedule_settings_date"
      />
    </ButtonComponent>
    <ButtonComponent :variant="variant" :size="size">
      <TimePicker
        ref="time"
        :entry="entry"
        :otherentries="otherentries"
        v-model="time"
        @update:modelValue="updateTime"
        @update:duration="
          (value) => {
            schedule = { ...schedule, duration: value };
          }
        "
        data-test="entry_schedule_settings_time"
      />
    </ButtonComponent>
  </div>
  <button
    v-else
    role="button"
    @click="createSchedule"
    data-test="entry_schedule_create"
  >
    <slot name="button_create">
      <ButtonComponent
        title="Add a schedule"
        :variant="variant"
        :size="size"
        :color="color"
      >
        <ClockIcon size="20" />
        <span v-if="variant != 'round'">Schedule</span>
      </ButtonComponent>
    </slot>
  </button>
</template>

<script>
import DatePicker from "../builders/DatePicker.vue";
import TimePicker from "../builders/TimePicker.vue";
import { Addon } from "../mixins/Addon";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Crud } from "../mixins/Crud";
import { addonBlueprint } from "@/addonBlueprint";
import { ClockIcon } from "lucide-vue-next";

export default {
  name: "ScheduleComponent",
  mixins: [Addon, Scheduling, Statusing, Crud],
  components: { DatePicker, TimePicker, ClockIcon },
  props: {
    otherentries: Array,
  },
  data() {
    return {
      date: this.entry.schedule?.date,
      time: this.entry.schedule?.time,
    };
  },
  watch: {
    entry: function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
        this.time = this.entry.schedule?.time;
      }
    },
    "entry.schedule": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
        this.time = this.entry.schedule?.time;
      }
    },
    "entry.schedule.date": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.date = this.entry.schedule?.date;
      }
    },
    "entry.schedule.time": function (n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.time = this.entry.schedule?.time;
      }
    },
  },
  computed: {
    schedule: {
      get() {
        return this.modelValue;
      },
      set(schedule) {
        this.entry.schedule = schedule;
        if (!this.entry.temp)
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
          });
      },
    },
  },
  methods: {
    createSchedule() {
      this.schedule = addonBlueprint("schedule", this.entry, this);
    },
    updateDate(val) {
      //this.updateObject(this.entry, (entry) => {
      if (this.entry.schedule === null) return;

      if (val.op && val.op === "null" && this.entry.schedule?.time === null) {
        this.schedule = null;
      } else {
        // TODO need to move this part over to Addon Mixin updateAddon()
        this.setEntrySchedule(this.entry, {
          date: this.interpretDate({ date: val }),
          time: this.entry.schedule?.time,
        });
        //});
      }
      !this.entry.temp
        ? this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
          })
        : null;
    },
    updateTime(val) {
      if (this.entry.schedule === null) return;

      if (
        (val === null || (val && val.op && val.op === "null")) &&
        this.entry.schedule?.date === null
      ) {
        this.schedule = null;
      } else {
        // TODO need to move this part over to Addon Mixin updateAddon()
        this.setEntrySchedule(this.entry, {
          date: this.entry.schedule?.date,
          time: this.interpretTime({ time: val }),
        });
      }

      !this.entry.temp
        ? this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: this.entry },
            entry: this.entry,
          })
        : null;
    },
  },
};
</script>
