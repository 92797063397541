<template>
  <div class="flex flex-col gap-y-1">
    <draggable
      class="list-group"
      v-model="statuses"
      group="statuses"
      handle="[data-handle]"
      @change="updatePositions"
      itemKey="name"
    >
      <template #item="{ element }">
        <div class="flex flex-wrap items-start gap-2">
          <a
            data-handle
            class="flex cursor-move gap-1 self-center"
            data-test="status_position_handle"
          >
            <ChevronUpDownIcon
              class="h-5 w-5 text-neutral-900 dark:text-neutral-400"
            />
          </a>
          <ColorPicker
            v-model="element.color"
            @update="
              (color) => {
                element.color = color;
                updateStatus(element);
              }
            "
            :hideLabel="true"
            class="flex-1"
          />

          <TextInput
            v-model="element.name"
            @update="updateStatus(element)"
            class="flex-1"
          />
          <StatusTypePicker
            v-model="element.type"
            @update:modelValue="
              (type) => {
                element.type = type;
                updateStatus(element);
              }
            "
            :options="['open', 'custom', 'done']"
            :size="size"
            :variant="variant"
            :color="color"
          />
          <ButtonComponent
            @click="deleteStatus(element)"
            :data-test="'delete_status_' + $slugify(element.name)"
            :size="size"
            :variant="variant"
            :color="color"
          >
            <TrashIcon class="h-5" />
          </ButtonComponent>
        </div>
      </template>
    </draggable>

    <div class="flex flex-wrap items-start gap-2" data-test="status_new_status">
      <ColorPicker
        v-model="newStatus.color"
        @update="(color) => (newStatus.color = color)"
        :hideLabel="true"
        class="flex-1"
        data-test="status_new_status_color"
      />

      <TextInput
        v-model="newStatus.name"
        class="flex-1"
        placeholder="Add Status"
        data-test="status_new_status_name"
      />

      <StatusTypePicker
        v-model="newStatus.type"
        :options="['open', 'custom', 'done']"
        :size="size"
        :variant="variant"
        :color="color"
        data-test="status_new_status_type"
      />
      <ButtonComponent
        @click="addStatus"
        :size="size"
        :variant="variant"
        :color="color"
        data-test="status_new_status_add"
      >
        <PlusIcon class="h-5" />
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import {
  ChevronUpDownIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/vue/24/outline";
import ColorPicker from "../helpers/ColorPicker.vue";
import TextInput from "../helpers/TextInput.vue";
import { statusBlueprint } from "@/addonBlueprint";
import StatusTypePicker from "../builders/StatusTypePicker.vue";

import draggable from "vuedraggable";
import { Addon } from "../mixins/Addon";

export default {
  mixins: [Addon],
  computed: {
    space() {
      return this.$store.getters.space;
    },
    statuses: {
      get() {
        return this.modelValue;
      },
      set(statuses) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.statuses = statuses;

        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
  },
  components: {
    ColorPicker,
    TextInput,
    TrashIcon,
    PlusIcon,
    StatusTypePicker,
    draggable,
    ChevronUpDownIcon,
  },
  data() {
    return {
      newStatus: statusBlueprint(this.entry, this),
    };
  },
  methods: {
    updatePositions: function () {
      this.statuses = this.statuses.map((s, i) => {
        s.position = i;
        return s;
      });
    },
    addStatus() {
      // Set position of new status
      this.newStatus.position = this.statuses.length;
      this.statuses = [...this.statuses, this.newStatus];

      //this.space.statuses.push(this.newStatus);
      this.newStatus = statusBlueprint(this.entry, this);
    },
    updateStatus(status) {
      this.$store.dispatch("push", {
        event: "status_update",
        params: { status: status },
        entry: this.entry,
      });

      this.space.statuses = this.space.statuses.map((s) => {
        if (s.id === status.id) {
          return status;
        }
        return s;
      });
    },
    deleteStatus(status) {
      this.$store.dispatch("push", {
        event: "status_delete",
        params: { id: status.id },
        entry: this.entry,
      });

      // Find all entries with that status and remove
      this.$store.getters.entries
        .filter((e) => e.status_id === status.id)
        .forEach((e) => {
          e.status_id = null;
          this.$store.dispatch("push", {
            event: "entry_update",
            params: { entry: e },
            entry: e,
          });
        });

      // Remove status from global space statuses list
      // this.space.statuses = this.space.statuses.filter(
      //   (s) => s.id !== status.id,
      // );
      // Remove status from entry statuses list for modelValue:update
      this.statuses = this.statuses.filter((s) => s.id !== status.id);

      this.$nextTick(() => {
        this.updatePositions();
      });
    },
  },
};
</script>
