import { nanoid } from "nanoid";

export function buildLogics(demo) {
  var id = null;

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Logics",
    description: {
      content: "Some of the advanced stuff for proper day planning.",
    },
    links: [demo.e("More Views").id],
    priority: {
      level: 4,
    },
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Every Day",
    description: {
      content: "Things that happen every day.",
    },
    output: {
      data: [
        { key: "links", op: "any", val: [id] },
        {
          key: ["completion", "completed_at"],
          op: "lt",
          val: [{ op: "today" }],
        },
        { key: ["completion", "completed"], op: "eq", val: [1] },
      ],
    },
    links: [demo.e("Logics").id],
  });

  demo.e("Take a Walk")?.links.push(demo.e("Every Day").id);
  demo.e("Morning Routine")?.links.push(demo.e("Every Day").id);

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Every Two Days",
    description: {
      content: "Things that happen every other day.",
    },
    output: {
      data: [
        { key: "links", op: "any", val: [id] },
        {
          key: ["completion", "completed_at"],
          op: "lt",
          val: [{ op: "yesterday" }],
        },
        { key: ["completion", "completed"], op: "eq", val: [1] },
      ],
    },
    links: [demo.e("Logics").id],
  });

  demo.e("Go to the Gym")?.links.push(demo.e("Every Two Days").id);
  demo.e("Bring out Trash")?.links.push(demo.e("Every Two Days").id);
  demo.e("Water the Plants")?.links.push(demo.e("Every Two Days").id);

  demo.e("Morning Routine")?.links.push(demo.e("Every Two Days").id);

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Every Three Days",
    description: {
      content: "Things that happen every 3 days.",
    },
    output: {
      data: [
        { key: "links", op: "any", val: [id] },
        {
          key: ["completion", "completed_at"],
          op: "lt",
          val: [{ op: "lastXDays", x: 2 }],
        },
        { key: ["completion", "completed"], op: "eq", val: [1] },
      ],
    },
    links: [demo.e("Logics").id],
  });

  demo.e("Practice Piano")?.links.push(demo.e("Every Three Days").id);

  demo.e("Morning Routine")?.links.push(demo.e("Every Three Days").id);

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Every Week",
    description: {
      content: "Things that happen every week.",
    },
    output: {
      data: [
        { key: "links", op: "any", val: [id] },
        {
          key: ["completion", "completed_at"],
          op: "lt",
          val: [{ op: "lastXWeeks", x: 1 }],
        },
        { key: ["completion", "completed"], op: "eq", val: [1] },
      ],
    },
    links: [demo.e("Logics").id],
  });

  demo.e("Grocery Shopping")?.links.push(demo.e("Every Week").id);
  demo.e("Send Update to Team")?.links.push(demo.e("Every Week").id);

  demo.e("Morning Routine")?.links.push(demo.e("Every Week").id);

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Every Month",
    description: {
      content: "Things that happen every month.",
    },
    output: {
      data: [
        { key: "links", op: "any", val: [id] },
        {
          key: ["completion", "completed_at"],
          op: "lt",
          val: [{ op: "lastXMonths", x: 1 }],
        },
        { key: ["completion", "completed"], op: "eq", val: [1] },
      ],
    },
    links: [demo.e("Logics").id],
  });

  demo.e("Check Finances")?.links.push(demo.e("Every Month").id);
  demo.e("Weighting")?.links.push(demo.e("Every Month").id);

  demo.e("Morning Routine")?.links.push(demo.e("Every Month").id);

  demo.addEntry({
    name: "Tasks · Obsolete",
    description: {
      content: "Tasks you might want to delete.",
    },
    output: {
      data: [
        { key: "links", op: "not any", val: [id] },
        {
          key: ["completion", "completed_at"],
          op: "lt",
          val: [{ op: "lastXMonths", x: 1 }],
        },
        { key: ["completion", "completed"], op: "eq", val: [1] },
      ],
    },
    links: [demo.e("Logics").id],
  });
}
