<template>
  <div
    class="flex flex-col gap-2 divide-y dark:divide-neutral-700"
    data-test="sorting_builder"
  >
    <fieldset
      v-for="(s, k) in value"
      :key="k"
      class="flex flex-wrap gap-2 pt-2 first:pt-0"
    >
      <SelectMenu v-model="s.field" :options="fields" :openWhenEmpty="true" />
      <SelectMenu
        v-model="s.dir"
        :options="[
          { title: 'Ascending', value: 1 },
          { title: 'Descending', value: -1 },
        ]"
        :openWhenEmpty="true"
        data-test="sorting_builder_direction"
      />
      <ButtonComponent
        @click="[value.splice(k, 1)]"
        data-test="sorting_builder_delete_row"
        color="secondary"
      >
        <TrashIcon class="h-4 w-4" />
      </ButtonComponent>
    </fieldset>
    <div class="pt-2">
      <ButtonComponent
        @click.stop="addElement"
        color="secondary"
        data-test="sorting_builder_add"
      >
        <PlusSmallIcon class="h-4 w-4" /> Add
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import { EditUtilities } from "../mixins/EditUtilities";
import { PlusSmallIcon, TrashIcon } from "@heroicons/vue/24/outline";
export default {
  mixins: [EditUtilities],
  components: {
    TrashIcon,
    PlusSmallIcon,
  },
  data() {
    return {
      cursor: null,
      showLinkSearch: false,
      fields: [
        { title: "Name", value: "name" },
        { title: "Status", value: "status" },
        { title: "Created", value: ["date", "created"] },
        { title: "Updated", value: ["date", "updated"] },
        { title: "Completed at", value: "completed_at" },
        { title: "Date", value: ["schedule", "date"] },
        { title: "Time", value: ["schedule", "time"] },
        { title: "Priority", value: "priority" },
        { title: "Procrastination", value: "procrastination" },
      ],
    };
  },
  methods: {
    addElement() {
      this.value.push({
        field: null,
        dir: 1,
      });
    },
  },
};
</script>
