<template>
  <ApplicationShell>
    <router-view v-slot="{ Component }">
      <Transition
        appear
        @enter="animationEnter"
        @leave="animationLeave"
        :css="false"
      >
        <component :is="Component" />
      </Transition>
    </router-view>
  </ApplicationShell>
</template>

<script>
export default {
  provide() {
    return {
      depth: 0,
      display: {},
    };
  },
  data() {
    return {
      scrolling: false,
      scrollingX: false,
      scrollAmount: 0,
      scrollAmountX: 0,
      clockInterval: null,
      delayAnimation: 0,
    };
  },
  watch: {
    "user.settings.prefers_color_scheme": function () {
      this.checkDarkMode();
    },
    "$route.name": {
      handler: function (n, o) {
        if (o == "onboarding" && n == "entry") {
          this.delayAnimation = 1000;
        }
        // document.querySelector("#main").style.overflow = "hidden";
      },
      // immediate: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
  },
  mounted() {
    // Bind the onMouseMove method to the Vue instance and register it
    // this.boundOnMouseMove = this.onMouseMove.bind(this);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("touchmove", this.onMouseMove);

    this.checkDarkMode();
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", this.checkDarkMode);

    setTimeout(() => {
      this.clockInterval = setInterval(() => {
        this.$store.dispatch("clock");
      }, 1000);
    }, 1000 - new Date().getMilliseconds());

    this.checkWindowWidth();
  },

  beforeUnmount() {
    // Unregister the event listener
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("touchmove", this.onMouseMove);
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .removeEventListener("change", this.checkDarkMode);

    clearInterval(this.clockInterval);
  },

  methods: {
    animationEnter(el, done) {
      if (this.$Cypress) {
        done();
        return;
      }
      this.$anime({
        targets: el,
        translateY: [100, 0],
        opacity: [0, 1],
        duration: 2000,
        delay: 500,
        complete: () => {
          done();
          // document.querySelector("#main").style.overflow = null;
        },
      });
      this.delayAnimation = 0;
    },
    animationLeave(el, done) {
      if (this.$Cypress) {
        done();
        return;
      }
      this.$anime({
        targets: el,
        translateY: [0, -100],
        opacity: [1, -0.2],
        duration: 2000,
        complete: done,
        begin: () => {
          // el.style.top = "0";
          el.style.position = "absolute";
          el.style.width = "100%";
        },
      });
    },
    checkDarkMode() {
      switch (this.user?.settings.prefers_color_scheme) {
        case "dark":
          return document.documentElement.classList.add("dark");
        case "light":
          return document.documentElement.classList.remove("dark");
        default:
          if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            return document.documentElement.classList.add("dark");
          } else {
            return document.documentElement.classList.remove("dark");
          }
      }
    },
    calcWindowWidth() {
      const width = window.innerWidth;
      if (width < 640 && this.$store.getters.widthSize !== "xs") {
        this.$store.commit("setWidthSize", "xs");
      } else if (
        width >= 640 &&
        width < 768 &&
        this.$store.getters.widthSize !== "sm"
      ) {
        this.$store.commit("setWidthSize", "sm");
      } else if (
        width >= 768 &&
        width < 1024 &&
        this.$store.getters.widthSize !== "md"
      ) {
        this.$store.commit("setWidthSize", "md");
      } else if (
        width >= 1024 &&
        width < 1280 &&
        this.$store.getters.widthSize !== "lg"
      ) {
        this.$store.commit("setWidthSize", "lg");
      } else if (width >= 1280 && this.$store.getters.widthSize !== "xl") {
        this.$store.commit("setWidthSize", "xl");
      }
    },
    checkWindowWidth() {
      this.calcWindowWidth();
      window.addEventListener("resize", this.calcWindowWidth);
    },
    onMouseMove(event) {
      this.scrollAmount = 0;
      this.scrollAmountX = 0;
      if (this.$store.getters.dragged.length) {
        var target = event.target;
        if (event.type == "touchmove") {
          event = event.touches[0];
          target = document.elementFromPoint(event.clientX, event.clientY);
        }

        const edgeThreshold = 100; // Pixels from the edge of the window
        const maxScrollAmount = 20; // Maximum pixels to scroll each animation frame

        const scrollableXParent = this.findScrollableXParent(target);
        const scrollableYParent = this.findScrollableYParent(target);

        if (scrollableXParent) {
          // console.log(event.target, target);
          // console.log(scrollableXParent);
          // console.log(
          //   scrollableXParent.scrollWidth,
          //   scrollableXParent.clientWidth,
          // );
          const distanceFromRightEdge =
            scrollableXParent.getBoundingClientRect().left +
            scrollableXParent.offsetWidth -
            event.clientX;

          const distanceFromLeftEdge =
            event.clientX - scrollableXParent.getBoundingClientRect().left;

          if (distanceFromLeftEdge < edgeThreshold) {
            // Increase scroll speed as the mouse gets closer to the top edge
            this.scrollAmountX =
              -maxScrollAmount * (1 - distanceFromLeftEdge / edgeThreshold);
          } else if (distanceFromRightEdge < edgeThreshold) {
            // Increase scroll speed as the mouse gets closer to the bottom edge
            this.scrollAmountX =
              maxScrollAmount * (1 - distanceFromRightEdge / edgeThreshold);
          }

          if (this.scrollAmountX !== 0) {
            // console.log("scrollAmountX", this.scrollAmountX);
            this.startScrollingX(scrollableXParent);
          } else {
            this.stopScrollingX();
          }
        }

        if (scrollableYParent) {
          const distanceFromTopEdge = event.clientY;
          const distanceFromBottomEdge = window.innerHeight - event.clientY;

          if (distanceFromTopEdge < edgeThreshold) {
            // Increase scroll speed as the mouse gets closer to the top edge
            this.scrollAmount =
              -maxScrollAmount * (1 - distanceFromTopEdge / edgeThreshold);
          } else if (distanceFromBottomEdge < edgeThreshold) {
            // Increase scroll speed as the mouse gets closer to the bottom edge
            this.scrollAmount =
              maxScrollAmount * (1 - distanceFromBottomEdge / edgeThreshold);
          }

          if (this.scrollAmount !== 0) {
            this.startScrollingY(scrollableYParent);
          } else {
            this.stopScrollingY();
          }
        }
      }
    },

    findScrollableXParent(element) {
      if (!element) return null;
      let parent = element.parentElement;
      while (parent) {
        if (parent.id === "entry-modal-page-wrapper") {
          return parent;
        }
        if (parent.scrollWidth > parent.clientWidth + 10) {
          return parent;
        }
        parent = parent.parentElement;
      }
      return null;
    },
    findScrollableYParent(element) {
      if (!element) return null;
      let parent = element.parentElement;
      while (parent) {
        if (parent.id === "entry-modal-page-wrapper") {
          return parent;
        }
        const style = window.getComputedStyle(parent);
        const overflowY = style.overflowY;
        if (
          (overflowY === "auto" || overflowY === "scroll") &&
          parent.scrollHeight > parent.clientHeight
        ) {
          return parent;
        }
        parent = parent.parentElement;
      }
      return null;
    },
    startScrollingY(element) {
      if (this.scrolling) return;
      this.scrolling = true;

      const scroll = () => {
        if (!this.scrolling) return;
        if (this.$route.params.id2) {
          document
            .querySelector("#entry-modal-page-wrapper")
            .scrollBy(0, this.scrollAmount);
        } else {
          element.scrollBy(0, this.scrollAmount);
        }
        requestAnimationFrame(scroll);
      };

      requestAnimationFrame(scroll);
    },

    startScrollingX(element) {
      if (this.scrollingX) return;
      this.scrollingX = true;

      const scroll = () => {
        if (!this.scrollingX) return;
        if (this.$route.params.id2 && 3 > 4) {
          document
            .querySelector("#entry-modal-page-wrapper")
            .scrollBy(this.scrollAmountX, 0);
        } else {
          element.scrollBy(this.scrollAmountX, 0);
        }
        requestAnimationFrame(scroll);
      };

      requestAnimationFrame(scroll);
    },

    stopScrollingY() {
      this.scrolling = false;
      this.scrollAmount = 0;
    },
    stopScrollingX() {
      this.scrollingX = false;
      this.scrollAmountX = 0;
    },
  },
};
</script>
