<template>
  <div>
    <PopoverHelper>
      <template #button>
        <ButtonComponent :size="size" :variant="variant" :color="color">
          <InboxIcon v-if="modelValue == 'open'" class="h-5" />
          <Cog8ToothIcon v-if="modelValue == 'custom'" class="h-5" />
          <CheckBadgeIcon v-if="modelValue == 'done'" class="h-5" />

          <ChevronDownIcon
            class="mt-0.5 h-4 w-4 text-neutral-300/70 transition duration-150 ease-in-out group-hover:text-neutral-300/80"
            aria-hidden="true"
          />
        </ButtonComponent>
      </template>

      <div
        class="relative grid min-w-max gap-8 p-7 lg:grid-cols-1 dark:text-neutral-100"
      >
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', 'open')"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="status_type_set_open"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12"
          >
            <InboxIcon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-100">
              Open
            </p>
            <p class="text-sm text-gray-500">
              This status is considered as not started
            </p>
          </div>
        </PopoverButton>
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', 'custom')"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="status_type_set_custom"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12"
          >
            <Cog8ToothIcon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-100">
              Custom
            </p>
            <p class="text-sm text-gray-500">
              This status is considered as a custom status
            </p>
          </div>
        </PopoverButton>
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', 'done')"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="status_type_set_done"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12"
          >
            <CheckBadgeIcon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-100">
              Done
            </p>
            <p class="max-w-64 text-sm text-gray-500">
              This status is considered as completed. This will trigger
              reschuduling of the entry and overdue entries will not be marked
              as such.
            </p>
          </div>
        </PopoverButton>
      </div>
    </PopoverHelper>
  </div>
</template>
<script setup>
import { PopoverButton } from "@headlessui/vue";
import {
  CheckBadgeIcon,
  Cog8ToothIcon,
  InboxIcon,
} from "@heroicons/vue/24/outline";
</script>
<script>
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
};
</script>
