export const Lazying = {
  props: {
    disableInitialization: Boolean,
  },
  data() {
    return {
      isInitialized: this.disableInitialization || this.$store.getters.dragged.length, // window.popStateDetected || this.$store.getters.initialized,
    }
  },
  mounted() {
    if (typeof this.isVisibleInViewport == 'undefined' || this.disableViewportChecking) {
      this.$onIdle(() => {
        this.isInitialized = true;
        if (typeof this.pullData === 'function')
          this.pullData();
      });
    }
  },
};
