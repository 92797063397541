<template>
  <div class="flex flex-col gap-y-1" data-test="senses_settings">
    <draggable
      v-if="senses.length"
      class="list-group"
      v-model="senses"
      group="senses"
      handle="[data-handle]"
      @change="updatePositions"
      itemKey="name"
    >
      <template #item="{ element }">
        <div class="flex flex-wrap items-start gap-2">
          <a
            data-handle
            class="flex cursor-move gap-1 self-center"
            data-test="status_position_handle"
          >
            <ChevronUpDownIcon class="h-5 w-5 text-gray-900" />
          </a>

          <ColorPicker
            v-model="element.color"
            @update="
              (color) => {
                element.color = color;
                updateSense(element);
              }
            "
            :hideLabel="true"
            class="flex-1"
          />
          <TextInput
            v-model="element.name"
            @update="updateSense(element)"
            class="flex-1 rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          />

          <button
            @click="(senseInConfig = element), (open = true)"
            class="rounded-md bg-white px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <Cog6ToothIcon class="h-4 w-4" />
          </button>

          <button
            @click="deleteSense(element)"
            type="button"
            class="rounded-md bg-white px-2 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            :data-test="'delete_sense_' + $slugify(element.name)"
          >
            <TrashIcon class="h-4 w-4" />
          </button>
        </div>
      </template>
    </draggable>

    <div>
      <ButtonComponent
        @click="addSense"
        :color="color"
        data-test="add_sense_button"
      >
        <PlusIcon class="h-4 w-4" /> Sense
      </ButtonComponent>
    </div>
    <ModalHelper :show="open" @close="open = false">
      <template #title> Sense Configuration</template>
      <SenseBuilder
        v-model="senseInConfig"
        :entry="entry"
        @update:modelValue="
          (sense) =>
            (senses = senses.map((s) => (s.id == sense.id ? sense : s)))
        "
      />
    </ModalHelper>
  </div>
</template>

<script>
import {
  ChevronUpDownIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/vue/24/outline";
import TextInput from "../helpers/TextInput.vue";
import { addonBlueprint } from "@/addonBlueprint";

import draggable from "vuedraggable";
import ColorPicker from "../helpers/ColorPicker.vue";
import SenseBuilder from "../builders/SenseBuilder.vue";
import { Addon } from "../mixins/Addon";

export default {
  mixins: [Addon],
  computed: {
    space() {
      return this.$store.getters.space;
    },
    senses: {
      get() {
        return this.modelValue;
      },
      set(senses) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.senses = senses;

        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
  },
  components: {
    TextInput,
    TrashIcon,
    PlusIcon,
    draggable,
    ChevronUpDownIcon,
    ColorPicker,
    SenseBuilder,
  },
  data() {
    return {
      open: false,
      senseInConfig: null,
      newSense: addonBlueprint("sense", this.entry, this),
    };
  },
  methods: {
    updatePositions: function () {
      this.senses = this.senses.map((s, i) => {
        s.position = i;
        return s;
      });
    },
    addSense() {
      // Set position of new status
      this.newSense.position = this.senses.length;
      this.senses = [...this.senses, this.newSense];

      //this.space.statuses.push(this.newSense);
      this.newSense = addonBlueprint("sense", this.entry, this);
    },
    updateSense(sense) {
      this.$store.dispatch("push", {
        event: "sense_update",
        params: { sense: sense },
        entry: this.entry,
      });
    },
    deleteSense(sense) {
      this.$store.dispatch("push", {
        event: "sense_delete",
        params: { id: sense.id },
        entry: this.entry,
      });
      this.senses = this.senses.filter((s) => s.id !== sense.id);
      this.$nextTick(() => {
        this.updatePositions();
      });
    },
  },
};
</script>
