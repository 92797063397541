export function buildToday(demo) {
  demo.addEntry({
    name: "Tasks · Today",
    description: {
      content:
        `These are your tasks for the day. Be productive. Focus.\n
Learn more about this in the [Best Practices](https://pensive.io/best-practices#daily-todos)`,
    },
    output: {
      data: [
        { key: ["completion", "completed"], op: "is set", val: [] },
        { key: ["schedule", "date"], op: "eq", val: [{ op: "today" }] },
      ],
    },
    sorting: {
      data: [
        { field: ["schedule", "date"], dir: 1 },
        { field: ["schedule", "time"], dir: 1 },
      ],
    },
    input: {
      data: {
        completion: { completed: false },
        schedule: {
          date: { op: "today" },
          time: null,
        },
      },
    },
    anchor: {
      position: demo.entries.filter((e) => e.anchor).length,
    },
  });

  demo.addEntry({
    name: "Tasks · Tomorrow",
    description: {
      content: "A sneak peak of what's coming tomorrow.",
    },
    output: {
      data: [
        { key: ["completion", "completed"], op: "is set", val: [] },
        { key: ["schedule", "date"], op: "eq", val: [{ op: "tomorrow" }] },
      ],
    },
    sorting: {
      data: [{ field: ["schedule", "time"], dir: 1 }],
    },
    input: {
      data: {
        completion: { completed: false },
        schedule: { date: { op: "tomorrow" } },
      },
    },
    links: [demo.e("Tasks · Today").id],
  });

  demo.addEntry({
    name: "Tasks · Upcoming",
    description: {
      content: "A sneak peak of what's coming in the next week.",
    },
    output: {
      data: [
        { key: ["completion", "completed"], op: "is set", val: [] },
        { key: ["schedule", "date"], op: "gt", val: [{ op: "tomorrow" }] },
        {
          key: ["schedule", "date"],
          op: "lt",
          val: [{ op: "nextXDays", x: 7 }],
        },
      ],
    },
    sorting: {
      data: [{ field: ["schedule", "time"], dir: 1 }],
    },
    input: {
      data: {
        completion: { completed: false },
        schedule: { date: { op: "tomorrow" } },
      },
    },
    links: [demo.e("Tasks · Tomorrow").id],
  });

  demo.addEntry({
    name: "Tasks · Overdue",
    description: {
      content:
        "These are tasks which are overdue, maybe you should re-schedule them.",
    },
    output: {
      data: [
        { key: ["completion", "completed"], op: "neq", val: [1] },
        { key: ["schedule", "date"], op: "lt", val: [{ op: "today" }] },
      ],
    },
    sorting: {
      data: [{ field: ["schedule", "date"], dir: 1 }],
    },
    links: [demo.e("Tasks · Today").id],
  });

  demo.addEntry({
    name: "In the Morning",
    description: {
      content: "Easy and calming tasks should go here.",
    },
    output: {
      data: [
        { key: ["schedule", "date"], op: "lte", val: [{ op: "today" }] },
        { key: ["schedule", "time"], op: "lte", val: [demo.time("08:00:00")] },
      ],
    },
    sorting: {
      data: [{ field: ["schedule", "time"], dir: 1 }],
    },
    input: {
      data: {
        schedule: {
          time: demo.time("07:30:00"),
        },
      },
    },
    links: [demo.e("Tasks · Today").id],
  });

  demo.addEntry({
    name: "At Noon",
    description: {
      content: "Heavy stuff should go here.",
    },
    output: {
      data: [
        { key: ["schedule", "date"], op: "lte", val: [{ op: "today" }] },
        { key: ["schedule", "time"], op: "gt", val: ["08:00:00"] },
        { key: ["schedule", "time"], op: "lt", val: ["18:00:00"] },
      ],
    },
    sorting: {
      data: [{ field: ["schedule", "time"], dir: 1 }],
    },
    input: {
      data: {
        schedule: {
          time: demo.time("13:00:00"),
        },
      },
    },
    links: [demo.e("Tasks · Today").id],
  });

  demo.addEntry({
    name: "At Evening",
    description: {
      content: "Energizing and relaxing stuff should go here.",
    },
    output: {
      data: [
        { key: ["schedule", "date"], op: "lte", val: [{ op: "today" }] },
        { key: ["schedule", "time"], op: "gte", val: ["18:00:00"] },
      ],
    },
    sorting: {
      data: [{ field: ["schedule", "time"], dir: 1 }],
    },
    input: {
      data: {
        schedule: {
          time: demo.time("18:30:00"),
        },
      },
    },
    links: [demo.e("Tasks · Today").id],
  });
}
