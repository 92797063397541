export const EditUtilities = {
  props: {
    modelValue: null,
  },
  data() {
    return {
      value:
        typeof this.modelValue == "object"
          ? JSON.parse(JSON.stringify(this.modelValue))
          : this.modelValue,
      edit: false,
      cursor: null,
    };
  },
  watch: {
    /**
     * If any external cause of change happened,
     * we will adjust the value properly.
     */
    modelValue: {
      handler() {
        this.value =
          typeof this.modelValue == "object"
            ? JSON.parse(JSON.stringify(this.modelValue))
            : this.modelValue;
      },
      deep: true,
    },

    value: {
      handler() {
        if (JSON.stringify(this.value) != JSON.stringify(this.modelValue)) {
          this.$emit("update", this.value);
          this.$emit("update:modelValue", this.value);
        }
        /**
         * Note: We need to check if the value
         * is of type object because the OptionsSelect
         * component will double fire otherwise, resulting
         * in an edit state after clicking an option.
         *
        if (typeof this.value == "object") {
          if (JSON.stringify(this.value) != JSON.stringify(this.modelValue)) {
            this.edit =
              JSON.stringify(this.value) != JSON.stringify(this.modelValue);

          }
        }*/
      },
      deep: true,
    },
    /**
     * This watcher will go into edit state
     * As soon as anything inside value changes.
     */
    /*value: {
      handler() {
        console.log("value.handler")
        /**
         * Note: We need to check if the value
         * is of type object because the OptionsSelect
         * component will double fire otherwise, resulting
         * in an edit state after clicking an option.
         *
        if (typeof this.value == "object") {
          if (JSON.stringify(this.value) != JSON.stringify(this.modelValue)) {
            this.edit =
              JSON.stringify(this.value) != JSON.stringify(this.modelValue);

          }
        }
      },
      deep: true,
    },*/
    /**
     * This watcher will observe the edit state,
     * when we leave it somehow, we will do
     * appropiate changes to the state via emitting.
     */
    edit: function () {
      if (this.edit) {
        /**
         * If edit was set to true, we want to 
         * focus a ref named edit, if possible.
         */
        //this.$nextTick(() => this.$refs.edit?.focus())
      } else {
        /**
         * The cursor is used in OutputBuilder for
         * handling select states in the building
         * process. Here we set it to null
         *
         * This is not required by every Component
         * but isn't a problem actually.
         */
        //this.cursor = null;

        /**
         * When a component leaves edit state, we check
         * every $ref for an edit property and will set it
         * also to false.
         * (Setting to this.edit will also result in false
         * due to the condition in this function)
         */
        for (const [key] of Object.entries(this.$refs)) {
          if (
            this.$refs[key] &&
            this.$refs[key][0] &&
            typeof this.$refs[key][0].edit != "undefined"
          ) {
            this.$refs[key][0].edit = this.edit;
          }
        }

        //if (typeof this.cursor != "undefined")
        //  this.value = this.cursor;

        /**
         * Some components want to clean a value
         * before emitting it to the parent.
         */
        if (typeof this.validatedValue != "undefined")
          this.value = this.validatedValue;

        /**
         * If the new value is any different to the old state
         * in modelValue, we will emit it to the parent.
         */
        if (JSON.stringify(this.value) != JSON.stringify(this.modelValue)) {
          this.$emit("update", this.value);
          this.$emit("update:modelValue", this.value);
        }
      }
    },
  },
  methods: {
    cancelEdit() {
      this.value =
        typeof this.modelValue == "object"
          ? JSON.parse(JSON.stringify(this.modelValue))
          : this.modelValue;
      this.edit = false;
    },
    checkEditFocus(e) {
      if (!this.$el.contains(e.relatedTarget)) {
        this.edit = false;
      }
      this.$emit("onBlur");
    },
  },
};
