<template>
  <TransitionRoot v-if="entry" as="template" :show="open">
    <Dialog
      as="div"
      class="relative z-[250]"
      @close="$emit('hide')"
      data-test="right_sidebar"
    >
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-300 sm:duration-300"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-300"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                id="right-sidebar"
                class="pointer-events-auto w-screen max-w-md"
              >
                <div
                  class="flex h-full flex-col overflow-y-scroll border-l bg-white py-6 dark:border-l dark:border-neutral-700 dark:bg-black"
                >
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle
                        class="text-base font-semibold leading-6 text-gray-900"
                      >
                        <!-- Panel title --></DialogTitle
                      >
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 dark:bg-black dark:text-neutral-500 dark:focus:ring-neutral-900"
                          @click="$emit('hide')"
                          data-test="right_sidebar_close_button"
                        >
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="relative mt-6 flex flex-1 flex-col gap-2 px-4 sm:px-6"
                  >
                    <ConstructionArea />
                    <!-- <TransitionGroupHelper v-if="showContent"> -->
                    <div
                      v-for="(item, index) in panels"
                      :key="index"
                      class="flex flex-col gap-2"
                      :data-delay="index * 100"
                    >
                      <!-- Start: Title Bar-->
                      <div class="relative">
                        <div
                          class="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div
                            class="w-full border-t border-neutral-300 dark:border-neutral-700"
                          />
                        </div>
                        <div class="relative flex items-center justify-between">
                          <span
                            class="bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
                          >
                            {{ item.title }}
                          </span>
                          <div
                            v-if="item.help"
                            class="flex gap-2 bg-white pl-2 dark:bg-black"
                          >
                            <a :href="item.help" target="_blank">
                              <button
                                role="button"
                                class="flex items-center gap-1 text-xs font-bold uppercase text-neutral-500 hover:text-neutral-700"
                              >
                                <QuestionMarkCircleIcon class="h-5 w-5" />
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                      <!-- End: Title Bar-->
                      <component
                        :is="item.component"
                        :entry="entry"
                        v-model="item.model"
                        v-bind="item.bind"
                        position="dialog"
                        class="rounded border p-2 dark:border-neutral-800"
                        size="sm"
                        color="secondary"
                      />
                    </div>
                    <!-- </TransitionGroupHelper> -->

                    <div
                      class="bg-gray-50 p-2 dark:bg-neutral-950 dark:text-neutral-500"
                    >
                      <div
                        v-if="entry.completed_at"
                        class="flow-root rounded-md px-2 py-2"
                      >
                        <span class="flex items-center">
                          <span
                            class="text-sm font-medium text-gray-900 dark:text-neutral-500"
                          >
                            Completed
                          </span>
                        </span>
                        <CompletedAtComponent v-model="entry" />
                      </div>
                      <div class="flow-root rounded-md px-2 py-2">
                        <span class="flex items-center">
                          <span
                            class="text-sm font-medium text-gray-900 dark:text-neutral-500"
                          >
                            Updated
                          </span>
                        </span>
                        <span
                          class="block select-text text-sm text-gray-500 dark:text-neutral-500"
                        >
                          {{
                            moment
                              .utc(entry.updated_at)
                              .local()
                              .format("YYYY-MM-DD HH:mm:ss")
                          }}
                        </span>
                      </div>
                      <div class="flow-root rounded-md px-2 py-2">
                        <span class="flex items-center">
                          <span
                            class="text-sm font-medium text-gray-900 dark:text-neutral-500"
                          >
                            Created
                          </span>
                        </span>
                        <span
                          class="block select-text text-sm text-gray-500 dark:text-neutral-500"
                        >
                          {{
                            moment
                              .utc(entry.created_at)
                              .local()
                              .format("YYYY-MM-DD HH:mm:ss")
                          }}
                        </span>
                      </div>
                    </div>

                    <div class="h-4" />
                    <DeleteComponent
                      :modelValue="entry"
                      @onDelete="() => (open = false)"
                    />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
</script>

<script>
import { Addon } from "../mixins/Addon";
import { PlusSmallIcon, TrashIcon } from "@heroicons/vue/24/outline";
import SortingBuilder from "../builders/SortingBuilder.vue";
import LinksBuilder from "../links/partials/LinksBuilder.vue";
import DeleteComponent from "../addons/DeleteComponent.vue";
import CompletedAtComponent from "../completion/CompletedAtComponent.vue";
import moment from "moment";
import { Rendering } from "../mixins/Rendering";
import { Statusing } from "../mixins/Statusing";

export default {
  provide() {
    // use function syntax so that we can access `this`
    return {
      position: "modal",
      renderType: "board",
    };
  },
  mixins: [Addon, Rendering, Statusing],
  components: {
    TrashIcon,
    SortingBuilder,
    PlusSmallIcon,
    LinksBuilder,
    DeleteComponent,
  },
  props: {
    show: Boolean,
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.$emit("hide");
      },
    },
    show() {
      this.showContent = false;
      this.open = this.show;
      if (this.open) {
        this.$nextTick(() => {
          this.showContent = true;
        });
      }
    },
  },
  computed: {
    panels() {
      return [
        {
          component: "LinksSettings",
          title: "Links",
          model: this.entry.links,
          help: "https://help.pensive.io/links",
        },
        {
          component: "OutputSettings",
          title: "Output",
          model: this.entry.output,
          help: "https://help.pensive.io/outputs",
        },
        {
          component: "InputSettings",
          title: "Input",
          model: this.entry.input,
          help: "https://help.pensive.io/inputs",
        },
        {
          component: "PrioritySettings",
          title: "Priority",
          model: this.entry.priority,
          help: "https://help.pensive.io/priorities",
        },
        {
          component: "RoutineSettings",
          title: "Routine",
          model: this.entry.routine,
          help: "https://help.pensive.io/routines",
        },
        {
          component: "AnchorSettings",
          title: "Favorite",
          model: this.entry.anchor,
          help: "https://help.pensive.io/anchors",
        },
        {
          component: "SensesSettings",
          title: "Senses",
          model: this.entry.senses,
          help: "https://help.pensive.io/senses",
        },
        {
          component: "StatusSettings",
          title: "Status",
          model: this.entry.status,
          bind: {
            statuses: this.getAvailableStatuses(this.entry),
          },
          help: "https://help.pensive.io/statuses",
        },
        {
          component: "ColorSettings",
          title: "Color",
          model: this.entry.color,
        },
        {
          component: "TemplateSettings",
          title: "Template",
        },
        {
          component: "StatusesSettings",
          title: "Custom Statuses",
          model: this.entry.statuses,
        },
        {
          component: "CustomFieldsSettings",
          title: "Custom Fields",
          model: this.entry.custom_fields,
        },
        // this.module("links"),
        // this.module("output", { classes: "flex flex-wrap gap-2" }),
        // this.module("input"),
        // this.module("priority"),
        // this.module("routine"),
        // this.module("anchor"),
        // this.module("senses"),
        // this.module("status"),
        // this.module("color"),
        // this.module("templates"),
        // this.module("statuses"),
        // this.module("custom_fields"),
      ];
    },
  },
  data() {
    return {
      window: window,
      open: this.show,
      moment: moment,
      showContent: false,
    };
  },
};
</script>
