import moment from "moment";
import { nanoid } from "nanoid";

export function buildMoreViews(demo) {
  var id = null;

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "More Views",
    description: {
      content: "Everything that's not so important.",
    },
    output: {
      data: [{ key: "links", op: "any", val: [id] }],
    },
    anchor: {
      position: demo.entries.filter((e) => e.anchor).length,
    },
    sorting: {
      data: [{ field: "priority", dir: -1 },{ field: "name", dir: 1 }],
    },
  });

  // MOVIES

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Movies List",
    description: {
      content: "Movies I'd like to watch",
    },
    output: {
      data: [{ key: "links", op: "any", val: [id] }],
    },
    input: {
      data: {
        completion: { completed: false },
        links: [id],
      },
    },
    links: [demo.e("More Views").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "The Godfather",
    completion: {
      completed: false,
      completed_at: null,
    },
    links: [demo.e("Movies List").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "The Dark Knight",
    completion: {
      completed: true,
      completed_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    links: [demo.e("Movies List").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Lord of the Rings",
    completion: {
      completed: true,
      completed_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    links: [demo.e("Movies List").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Fight Club",
    completion: {
      completed: false,
      completed_at: null,
    },
    links: [demo.e("Movies List").id],
  });

  // BOOKS

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Books List",
    description: {
      content: "Books I'd like to read.",
    },
    output: {
      data: [{ key: "links", op: "any", val: [id] }],
    },
    input: {
      data: {
        completion: { completed: false },
        links: [id],
      },
    },
    links: [demo.e("More Views").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Atomic Habits",
    completion: {
      completed: true,
      completed_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    links: [demo.e("Books List").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Harry Potter",
    completion: {
      completed: true,
      completed_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    links: [demo.e("Books List").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Sapiens",
    completion: {
      completed: false,
      completed_at: null,
    },
    links: [demo.e("Books List").id],
  });

  // TRAVEL

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Travel Destinations",
    description: {
      content: "Places I'd like to visit.",
    },
    output: {
      data: [{ key: "links", op: "any", val: [id] }],
    },
    input: {
      data: {
        completion: { completed: false },
        links: [id],
      },
    },
    links: [demo.e("More Views").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Venice",
    completion: {
      completed: false,
      completed_at: null,
    },
    links: [demo.e("Travel Destinations").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Bruges",
    completion: {
      completed: true,
      completed_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    },
    links: [demo.e("Travel Destinations").id],
  });
}
