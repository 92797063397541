<template>
  <div class="flex flex-col items-center gap-y-4">
    <div class="relative flex aspect-square flex-1 flex-col justify-center">
      <CircleProgressBarHelper
        :percentage="100"
        :strokeWidth="12"
        :radius="400"
        :padding="6"
        class="absolute h-full w-full opacity-5 dark:opacity-20"
      />
      <CircleProgressBarHelper
        :percentage="(counter / duration) * 100"
        :strokeWidth="24"
        :radius="400"
        :color="running ? color : 'neutral'"
        class="absolute h-full w-full"
      />

      <div
        class="pointer-events-none -mx-6 grid select-none grid-cols-8 gap-x-0.5 font-mono lg:-mx-12 lg:text-6xl"
      >
        <div class="col-span-2 flex overflow-visible">
          <SmoothText
            :text="remainingHours[0]"
            :enter="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [0, 1],
              translateY: ['70%', '0%'],
            }"
            :leave="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [1, 0],
              translateY: ['0%', '-70%'],
            }"
            align="center"
          />
          <SmoothText
            :text="remainingHours[1]"
            :enter="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [0, 1],
              translateY: ['70%', '0%'],
            }"
            :leave="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [1, 0],
              translateY: ['0%', '-70%'],
            }"
            align="center"
          />
        </div>
        <span class="text-center"> : </span>
        <div class="col-span-2 flex overflow-visible">
          <SmoothText
            :text="remainingMinutes[0]"
            :enter="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [0, 1],
              translateY: ['70%', '0%'],
            }"
            :leave="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [1, 0],
              translateY: ['0%', '-70%'],
            }"
            align="center"
          />
          <SmoothText
            :text="remainingMinutes[1]"
            :enter="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [0, 1],
              translateY: ['70%', '0%'],
            }"
            :leave="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [1, 0],
              translateY: ['0%', '-70%'],
            }"
            align="center"
          />
        </div>
        <span class="text-center"> : </span>
        <div class="col-span-2 flex overflow-visible">
          <SmoothText
            :text="remainingSeconds[0]"
            :enter="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [0, 1],
              translateY: ['70%', '0%'],
            }"
            :leave="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [1, 0],
              translateY: ['0%', '-70%'],
            }"
            align="center"
          />
          <SmoothText
            :text="remainingSeconds[1]"
            :enter="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [0, 1],
              translateY: ['70%', '0%'],
            }"
            :leave="{
              easing: 'easeOutQuint',
              duration: 750,
              opacity: [1, 0],
              translateY: ['0%', '-70%'],
            }"
            align="center"
          />
        </div>
      </div>
    </div>
    <div class="text-center">
      <ButtonComponent v-if="running" @click="running = false">
        Stop <CircleStopIcon />
      </ButtonComponent>
      <ButtonComponent v-if="!running" @click="running = true">
        Start <CirclePlayIcon />
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import { TimeTrackingsMixin } from "../timetrackings/mixins/TimeTrackingsMixin";
import { Tracking } from "../mixins/Tracking";
import { CirclePlayIcon, CircleStopIcon } from "lucide-vue-next";
import CircleProgressBarHelper from "../helpers/CircleProgressBarHelper.vue";

export default {
  mixins: [TimeTrackingsMixin, Tracking],
  components: { CirclePlayIcon, CircleStopIcon, CircleProgressBarHelper },
  props: {
    modelValue: Number,
    entry: Object,
    color: {
      type: String,
      default: "neutral",
    },
    autostart: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      running: false,
      counter: 0,
      interval: null,
    };
  },
  watch: {
    running: function () {
      if (this.running) {
        this.interval = setInterval(this.countdown, 1000);
        this.$emit("onStart");
      } else {
        clearInterval(this.interval);
        this.$emit("onStop");
      }
    },
  },
  mounted() {
    if (this.autostart) {
      this.running = true;
    }
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    countdown() {
      this.currentTime = this.$moment();
      if (this.counter < this.duration) {
        this.counter += 1;
      }
    },
  },
  computed: {
    remainingHours() {
      return Math.floor((this.duration - this.counter) / 3600)
        .toString()
        .padStart(2, "0")
        .split("");
    },
    remainingMinutes() {
      return (Math.floor((this.duration - this.counter) / 60) % 60)
        .toString()
        .padStart(2, "0")
        .split("");
    },
    remainingSeconds() {
      return ((this.duration - this.counter) % 60)
        .toString()
        .padStart(2, "0")
        .split("");
    },
    duration: {
      get() {
        return this.modelValue;
      },
      set(target) {
        this.$emit("update:modelValue", target);
      },
    },
    user() {
      return this.$store.state.user;
    },
  },
};
</script>
