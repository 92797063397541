<template>
  <div
    class="-mx-1.5 -my-2 box-border rounded-lg bg-white px-3 py-2 shadow focus:!bg-red-500"
    @keydown.tab.exact="handleTab"
    @keydown.shift.tab="handleShiftTab"
    @keydown.arrow-up="handleArrowUp"
    @keydown.arrow-down="handleArrowDown"
    @keydown.enter="handleEnter"
  >
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      const focusableElements = this.getFocusableElements();
      if (focusableElements.length > 0) {
        focusableElements[0].focus();
      }
    });
  },
  methods: {
    getFocusableElements() {
      return this.$el.querySelectorAll(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex]:not([tabindex="-1"]), [contenteditable]',
      );
    },
    handleTab(event) {
      const focusableElements = this.getFocusableElements();
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (document.activeElement === lastElement) {
        event.preventDefault();
        firstElement.focus();
      }
    },
    handleShiftTab(event) {
      const focusableElements = this.getFocusableElements();
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (document.activeElement === firstElement) {
        event.preventDefault();
        lastElement.focus();
      }
    },
    handleArrowUp(event) {
      const focusableElements = this.getFocusableElements();
      const currentIndex = Array.prototype.indexOf.call(
        focusableElements,
        document.activeElement,
      );

      if (currentIndex > 0) {
        event.preventDefault();
        focusableElements[currentIndex - 1].focus();
      } else {
        event.preventDefault();
        focusableElements[focusableElements.length - 1].focus();
      }
    },
    handleArrowDown(event) {
      const focusableElements = this.getFocusableElements();
      const currentIndex = Array.prototype.indexOf.call(
        focusableElements,
        document.activeElement,
      );

      if (currentIndex < focusableElements.length - 1) {
        event.preventDefault();
        focusableElements[currentIndex + 1].focus();
      } else {
        event.preventDefault();
        focusableElements[0].focus();
      }
    },
    handleEnter(event) {
      const activeElement = document.activeElement;
      if (activeElement) {
        event.preventDefault();
        activeElement.click();
      }
    },
  },
};
</script>
