<template>
  <div class="flex flex-col">
    <TimeTrackingsRow
      :modelValue="time_trackings[index]"
      @update:modelValue="
        (time_tracking) => {
          if (time_tracking) {
            $store.dispatch('push', {
              event: 'time_tracking_update',
              params: { time_tracking: time_tracking },
              entry: entry,
              undo: 1,
            });
            time_trackings[index] = time_tracking;
          } else {
            $store.dispatch('push', {
              event: 'time_tracking_delete',
              params: { id: time_trackings[index].id },
              entry: entry,
              undo: 1,
            });
            time_trackings = time_trackings.filter((_, i) => i !== index);
          }
        }
      "
      v-for="(_, index) in time_trackings.sort(
        (a, b) =>
          $moment.utc(b.start_at).unix() - $moment.utc(a.start_at).unix(),
      )"
      :entry="entry"
      :key="index"
      :mode="mode"
    />
  </div>
</template>

<script>
import TimeTrackingsRow from "./TimeTrackingsRow.vue";

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    entry: Object,
    mode: {
      type: String,
      default: "entry",
    },
  },
  components: { TimeTrackingsRow },
  computed: {
    time_trackings: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
  },
};
</script>
