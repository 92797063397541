<template>
  <span
    class="flex gap-2 text-xs uppercase text-neutral-600 dark:text-neutral-400"
  >
    <slot />
  </span>
</template>

<script>
export default {};
</script>
