import moment from "moment";
import { nanoid } from "nanoid";

export function buildPriorization(demo) {
  const prioritize_id = nanoid();

  demo.addEntry({
    name: "Is Urgent",
    input: {
      data: { priority: { level: 1 } },
    },
    links: [prioritize_id],
  });
  demo.addEntry({
    name: "Is High",
    input: {
      data: { priority: { level: 2 } },
    },
    links: [prioritize_id],
  });
  demo.addEntry({
    name: "Is Medium",
    input: {
      data: { priority: { level: 3 } },
    },
    links: [prioritize_id],
  });
  demo.addEntry({
    name: "Is Low",
    input: {
      data: { priority: { level: 4 } },
    },
    links: [prioritize_id],
  });

  demo.addEntry({
    id: prioritize_id,
    name: "Prioritize",
    description: {
      content: "Sometimes a bit reprioritization is required.",
    },
    output: {
      data: [
        {
          key: ["date", "updated"],
          op: "lt",
          val: [{ op: "lastXWeeks", x: 4 }],
        },
        { key: ["completion", "completed"], op: "eq", val: [0] },
      ],
    },
    links: [
      demo.e("Is Urgent").id,
      demo.e("Is High").id,
      demo.e("Is Medium").id,
      demo.e("Is Low").id,
      demo.e("More Views").id,
    ],
  });

  demo.addEntry({
    name: "Call Hardware Store",
    completion: {
      completed: false,
      completed_at: null,
    },
    priority: {
      level: 1,
    },
    schedule: {
      date: moment.utc().add(1, "days").format("YYYY-MM-DD"),
      time: demo.time("09:30:00"),
    },
    created_at: moment.utc().add(-6, "weeks").format("YYYY-MM-DD HH:mm:ss"),
    updated_at: moment.utc().add(-6, "weeks").format("YYYY-MM-DD HH:mm:ss"),
    links: [demo.e("Hobby Project").id],
  });

  demo.addEntry({
    name: "Ask for a raise",
    completion: {
      completed: false,
      completed_at: null,
    },
    priority: {
      level: 4,
    },
    schedule: {
      date: moment.utc().add(5, "days").format("YYYY-MM-DD"),
      time: demo.time("12:30:00"),
    },
    procrastination: {
      count: 2,
    },
    created_at: moment.utc().add(-5, "weeks").format("YYYY-MM-DD HH:mm:ss"),
    updated_at: moment.utc().add(-5, "weeks").format("YYYY-MM-DD HH:mm:ss"),
    links: [demo.e("Business").id],
  });
}
