<template>
  <!-- <Menu>
    <div
      @click.stop
      class="relative flex min-w-48 flex-col rounded-2xl bg-white p-2 shadow-xl"
      data-test="contextmenu"
    >
      <slot />

      <StatusSettings
        v-model="status"
        :entry="entry"
        :statuses="getAvailableStatuses(this.entry)"
        class="w-full"
      >
        <template #button>
          <MenuItem v-slot="{ active }">
            <ButtonComponent
              color="white"
              :class="{
                'bg-neutral-50': active,
                'hover:bg-neutral-50': active,
              }"
              class="group/button w-full bg-transparent !font-medium !shadow-none hover:bg-neutral-50 hover:!ring-0"
              data-test="contextmenu_status_button"
            >
              <span
                :class="[
                  'bg-' +
                    (status?.color || 'neutral') +
                    '-400 ml-1 inline-block h-2 w-2 flex-shrink-0 rounded-full',
                ]"
                aria-hidden="true"
              />
              <div class="flex flex-1 items-center justify-between">
                <span>Status</span>
                <ChevronRightIcon class="h-3 w-3" />
              </div>
            </ButtonComponent>
          </MenuItem>
        </template>
      </StatusSettings>

      <ScheduleSettings
        v-model="entry"
        :entry="entry"
        :variant="entry.schedule ? 'minimal' : 'default'"
        :class="{
          'justify-evenly py-1 ': entry.schedule,
          'rounded-none !shadow-none  ': !entry.schedule,
        }"
        class="w-full px-2 hover:bg-neutral-50 dark:bg-black dark:hover:bg-neutral-900"
      />

      <TimeTrackingsSettings
        v-model="entry.time_trackings"
        :entry="entry"
        variant="minimal"
        size="sm"
        class="h-8 w-full justify-between px-2.5 py-1.5 hover:bg-neutral-50 dark:bg-black dark:hover:bg-neutral-900"
      />

      <PopoverHelper placement="right" :teleport="true">
        <template #button>
          <ButtonComponent
            color="white"
            class="group/button w-full bg-transparent !font-medium !shadow-none hover:bg-neutral-50 hover:!ring-0"
            data-test="contextmenu_links_button"
          >
            <LinkIcon class="w-4 group-hover/button:text-sky-500" />
            <div class="flex flex-1 items-center justify-between">
              <span>Links</span>
              <ChevronRightIcon class="h-3" />
            </div>
          </ButtonComponent>
        </template>
        <div class="w-48">
          <LinksSettings
            v-model="entry.links"
            :entry="entry"
            class="flex-col !items-stretch gap-y-0 divide-y"
            size="sm"
          />
        </div>
      </PopoverHelper>

    </div>
  </Menu> -->
  <div class="ps_dropdown__menu">
    <Menu as="div" data-test="contextmenu">
      <MenuItems ref="items" static class="focus-visible:outline-none">
        <ScheduleSettings
          v-model="entry.schedule"
          :entry="entry"
          variant="menu"
          class="w-full"
        >
          <template v-slot:button_date="{ date }">
            {{ date }}
          </template>
          <template v-slot:button_create>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                size="sm"
                :color="active ? 'neutral' : 'white'"
                colorWeight="100"
                :active="active"
                data-test="contextmenu_schedule_button"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="clock"
                  size="16"
                  :class="{ 'text-indigo-500': active }"
                />
                <span class="whitespace-nowrap"> Schedule </span>
              </ButtonComponent>
            </MenuItem>
          </template>
        </ScheduleSettings>

        <StatusSettings
          v-model="status"
          :entry="entry"
          :statuses="getAvailableStatuses(this.entry)"
          class="w-full"
        >
          <template #button>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                color="white"
                size="sm"
                :active="active"
                class="group/button w-full rounded-md"
                data-test="contextmenu_status_button"
              >
                <!-- <span
                  v-if="status"
                  :class="[
                    'bg-' +
                      (status?.color || 'neutral') +
                      '-400 ml-1 inline-block h-2 w-2 flex-shrink-0 rounded-full',
                  ]"
                  aria-hidden="true"
                /> -->
                <IconHelper
                  v-if="status"
                  name="circle"
                  :color="status.color"
                  size="16"
                  strokeWidth="2.5"
                />
                <IconHelper v-else name="circle-dashed" size="16" />

                <div class="flex flex-1 items-center justify-between">
                  <span>Status</span>
                  <ChevronRightIcon class="h-3 w-3" />
                </div>
              </ButtonComponent>
            </MenuItem>
          </template>
        </StatusSettings>

        <PopoverHelper placement="right" :teleport="true">
          <template #button>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                color="white"
                size="sm"
                :active="active"
                class="w-full rounded-md"
                data-test="contextmenu_links_button"
              >
                <IconHelper
                  name="Link2"
                  size="16"
                  :class="{ 'text-sky-500': active }"
                />
                <div class="flex flex-1 items-center justify-between">
                  <span>Links</span>
                  <ChevronRightIcon class="h-3" />
                </div>
              </ButtonComponent>
            </MenuItem>
          </template>
          <div class="w-48 p-2">
            <LinksSettings
              v-model="entry.links"
              :entry="entry"
              class="flex-col !items-stretch gap-y-1"
              size="sm"
              color="white"
            />
          </div>
        </PopoverHelper>

        <hr class="-mx-2 my-2 dark:border-neutral-700" />

        <PrioritySettings v-model="entry.priority" :entry="entry">
          <template #button>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                size="sm"
                :color="active ? 'neutral' : 'white'"
                colorWeight="100"
                :active="active"
                data-test="contextmenu_priority_button"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="flag"
                  size="16"
                  :class="{
                    [`text-${
                      entry.priority?.level == 1
                        ? 'red'
                        : entry.priority?.level == 2
                        ? 'orange'
                        : entry.priority?.level == 3
                        ? 'yellow'
                        : 'neutral'
                    }-500`]: active,
                  }"
                />
                <span class="whitespace-nowrap"> Priority </span>
              </ButtonComponent>
            </MenuItem>
          </template>
        </PrioritySettings>
        <ColorSettings v-model="entry.color" :entry="entry">
          <template #button>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                size="sm"
                :color="active ? 'neutral' : 'white'"
                :active="active"
                data-test="contextmenu_color_button"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="swatch-book"
                  size="16"
                  :class="{ [`text-${entry.color || 'neutral'}-500`]: active }"
                />
                <span class="whitespace-nowrap"> Color </span>
              </ButtonComponent>
            </MenuItem>
          </template>
        </ColorSettings>

        <!-- <DescriptionSettings v-model="entry" :entry="entry" class="w-full">
          <template #button>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                size="sm"
                :color="active ? 'neutral' : 'white'"
                :active="active"
                data-test="contextmenu_color_button"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="text"
                  size="16"
                  :class="{ 'text-neutral-500': active }"
                />
                <span class="whitespace-nowrap"> Description </span>
              </ButtonComponent>
            </MenuItem>
          </template>
        </DescriptionSettings> -->

        <hr class="-mx-2 my-2 dark:border-neutral-700" />

        <AnchorSettings
          v-model="entry.anchor"
          :entry="entry"
          class="flex flex-col !gap-0"
        >
          <template #button_create>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                size="sm"
                :color="active ? 'neutral' : 'white'"
                :active="active"
                data-test="contextmenu_color_button"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="heart"
                  size="16"
                  :class="{ 'text-red-500': active }"
                />
                <span class="whitespace-nowrap"> Mark as Favorite </span>
              </ButtonComponent>
            </MenuItem>
          </template>
          <template #button_anchor_move_up>
            <MenuItem v-if="position == 'left'" v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                size="sm"
                :color="active ? 'neutral' : 'white'"
                :active="active"
                data-test="contextmenu_color_button"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="arrow-up"
                  size="16"
                  :class="{ 'text-red-500': active }"
                />
                <span class="whitespace-nowrap"> Move up </span>
              </ButtonComponent>
            </MenuItem>
            <span v-else />
          </template>
          <template #button_anchor_move_down>
            <MenuItem v-if="position == 'left'" v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                size="sm"
                :color="active ? 'neutral' : 'white'"
                :active="active"
                data-test="contextmenu_color_button"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="arrow-down"
                  size="16"
                  :class="{ 'text-red-500': active }"
                />
                <span class="whitespace-nowrap"> Move down </span>
              </ButtonComponent>
            </MenuItem>
            <span v-else />
          </template>
          <template #button_delete>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                size="sm"
                :color="active ? 'neutral' : 'white'"
                :active="active"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="heart"
                  size="16"
                  :class="{ 'text-red-500': active }"
                />
                <span class="whitespace-nowrap"> Unmark as Favorite </span>
              </ButtonComponent>
            </MenuItem>
          </template>
        </AnchorSettings>

        <hr class="-mx-2 my-2 dark:border-neutral-700" />

        <!-- <PopoverHelper placement="right">
          <template #button>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                color="white"
                size="sm"
                :active="active"
                data-test="contextmenu_advanced_button"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="code-xml"
                  size="16"
                  :class="{ 'text-indigo-500': active }"
                />
                <div class="flex flex-1 items-center justify-between">
                  <span>Advanced</span>
                  <ChevronRightIcon class="h-3 w-3" />
                </div>
              </ButtonComponent>
            </MenuItem>
          </template>
          <div class="flex w-48 flex-col p-2">
            <RoutineSettings
              v-model="entry.routine"
              :entry="entry"
              color="white"
            >
              <template #button>
                <MenuItem v-slot="{ active }">
                  <ButtonComponent
                    variant="menu"
                    color="white"
                    size="sm"
                    :active="active"
                    class="w-full rounded-md"
                    data-test="contextmenu_routine_button"
                  >
                    <IconHelper
                      name="repeat"
                      size="16"
                      :class="{ 'text-indigo-500': active }"
                    />
                    <div class="flex flex-1 items-center justify-between">
                      <span>Routine</span>
                    </div>
                  </ButtonComponent>
                </MenuItem>
              </template>
              <template #button_routine_configure>
                <MenuItem v-slot="{ active }">
                  <ButtonComponent
                    variant="menu"
                    color="white"
                    size="sm"
                    :active="active"
                    class="w-full rounded-md"
                    data-test="contextmenu_routine_button"
                  >
                    <IconHelper
                      name="repeat"
                      size="16"
                      :class="{ 'text-indigo-500': active }"
                    />
                    <div class="flex flex-1 items-center justify-between">
                      <span>Routine</span>
                    </div>
                  </ButtonComponent>
                </MenuItem>
              </template>
            </RoutineSettings>

            <MenuItem v-slot="{ active }">
              <ButtonComponent
                @click="
                  $router.push({
                    name: 'entry',
                    params: { id: entry.id },
                    query: { sensesSettings: 1 },
                  })
                "
                variant="menu"
                color="white"
                size="sm"
                :active="active"
                class="w-full rounded-md"
                data-test="contextmenu_routine_button"
              >
                <IconHelper
                  name="radio"
                  size="16"
                  :class="{ 'text-yellow-500': active }"
                />
                <div class="flex flex-1 items-center justify-between">
                  <span>Senses</span>
                </div>
              </ButtonComponent>
            </MenuItem>
          </div>
        </PopoverHelper> -->

        <TemplateSettings
          @close="
            () => {
              this.$emit('close');
            }
          "
          :entry="entry"
          size="sm"
          class="w-full"
        >
          <template #button>
            <MenuItem v-slot="{ active }">
              <ButtonComponent
                variant="menu"
                color="white"
                size="sm"
                :active="active"
                data-test="entry_template_settings_button"
                class="w-full rounded-md"
              >
                <IconHelper
                  name="puzzle"
                  size="16"
                  :class="{ 'text-purple-500': active }"
                />
                <div class="flex flex-1 items-center justify-between">
                  <span>Templates</span>
                  <ChevronRightIcon class="h-3 w-3" />
                </div>
              </ButtonComponent>
            </MenuItem>
          </template>
        </TemplateSettings>

        <hr class="-mx-2 my-2 dark:border-neutral-700" />

        <MenuItem as="div" v-slot="{ active }">
          <ButtonComponent
            variant="menu"
            color="white"
            size="sm"
            :active="active"
            @click="$store.dispatch('selected', entry), $emit('close')"
            data-test="select_entry"
            class="w-full rounded-md"
          >
            <IconHelper
              name="CopyPlus"
              v-if="
                $store.getters.selected.findIndex((e) => e.id == entry.id) ===
                -1
              "
              size="16"
              :class="{ 'text-amber-500': active }"
            />
            <IconHelper
              name="CopyMinus"
              v-else
              size="16"
              :class="{ 'text-amber-500': active }"
            />
            <span
              v-if="
                $store.getters.selected.findIndex((e) => e.id == entry.id) ===
                -1
              "
              class="whitespace-nowrap"
              >Select Entry</span
            >
            <span v-else class="whitespace-nowrap">Unselect Entry</span>
          </ButtonComponent>
        </MenuItem>

        <MenuItem as="div" v-slot="{ active }">
          <ButtonComponent
            variant="menu"
            color="white"
            size="sm"
            :active="active"
            @click="duplicateEntry"
            data-test="duplicate_entry"
            class="w-full rounded-md"
          >
            <IconHelper
              name="files"
              :size="16"
              :class="{ 'text-blue-500': active }"
            />
            <span class="whitespace-nowrap">Duplicate Entry</span>
          </ButtonComponent>
        </MenuItem>

        <MenuItem as="div" v-slot="{ active }">
          <ButtonComponent
            variant="menu"
            color="white"
            size="sm"
            :active="active"
            @click="deleteEntry"
            data-test="delete_entry"
            class="w-full rounded-md"
          >
            <IconHelper
              name="trash"
              :size="16"
              :class="{ 'text-red-500': active }"
            />
            <span class="whitespace-nowrap">Delete Entry</span>
          </ButtonComponent>
        </MenuItem>

        <!-- <ButtonComponent
          v-if="3 > 4"
          role="button"
          @click="$store.dispatch('share', shareNode)"
          class="group/button w-full bg-transparent !shadow-none hover:bg-neutral-50"
          data-test="share_entry"
        >
          <TrashIcon class="h-4 w-4 group-hover/button:text-teal-500" />
          <span class="whitespace-nowrap"> Share </span>
        </ButtonComponent> -->
      </MenuItems>
    </Menu>

    <div
      class="flex basis-full flex-col gap-1 rounded-lg bg-gray-50 p-2 dark:bg-neutral-950"
    >
      <div v-if="entry.completed_at" class="flow-root rounded-md">
        <span class="flex items-center">
          <span class="text-xs font-medium text-gray-900 dark:text-neutral-100">
            Completed
          </span>
        </span>
        <CompletedAtComponent v-model="entry" class="font-mono text-xs" />
      </div>
      <div class="flow-root rounded-md">
        <span class="flex items-center">
          <span class="text-xs font-medium text-gray-900 dark:text-neutral-100">
            Updated
          </span>
        </span>
        <span class="block select-text font-mono text-xs text-gray-500">
          {{
            moment.utc(entry.updated_at).local().format("YYYY-MM-DD HH:mm:ss")
          }}
        </span>
      </div>
      <div class="flow-root rounded-md">
        <span class="flex items-center">
          <span class="text-xs font-medium text-gray-900 dark:text-neutral-100">
            Created
          </span>
        </span>
        <span class="block select-text font-mono text-xs text-gray-500">
          {{
            moment.utc(entry.created_at).local().format("YYYY-MM-DD HH:mm:ss")
          }}
        </span>
      </div>
    </div>
  </div>

  <!-- <ModalHelper :show="showSenseModal" @close="showSenseModal = false">
    <SensesSettings v-model="entry.senses" :entry="entry" />
  </ModalHelper> -->
</template>

<script>
import moment from "moment";
// import {
//   ArrowDownOnSquareStackIcon,
//   ArrowUpOnSquareStackIcon,
//   DocumentDuplicateIcon,
//   LinkIcon,
//   PuzzlePieceIcon,
//   TrashIcon,
//   CodeBracketSquareIcon,
// } from "@heroicons/vue/24/outline";

import CompletedAtComponent from "../../completion/CompletedAtComponent.vue";
import { Statusing } from "../../mixins/Statusing";
import { Templating } from "../../mixins/Templating";
import { Schema } from "../../mixins/Schema";
import { Rendering } from "../../mixins/Rendering";

// import EntryComponent from "./EntryComponent.vue";

export default {
  inject: {
    overrideEntries: {
      default: null,
    },
  },
  // provide() {
  //   // use function syntax so that we can access `this`
  //   return {
  //     position: "contextmenu",
  //   };
  // },
  mixins: [Statusing, Templating, Schema, Rendering],
  props: {
    modelValue: Object,
    position: String,
    hide: {
      type: Array,
      default() {
        return [];
      },
    },
    otherentries: [], // fixes console warning
    shareNode: null,
  },
  data() {
    return {
      moment: moment,
      showSenseModal: false,
    };
  },
  mounted() {
    // Focus the first menu item when the component is mounted
    this.$nextTick(() => {
      this.$refs.items.$el.focus();
    });
  },
  components: {
    // TrashIcon,
    // PuzzlePieceIcon,
    // LinkIcon,
    CompletedAtComponent,
    // ArrowDownOnSquareStackIcon,
    // ArrowUpOnSquareStackIcon,
    // DocumentDuplicateIcon,
    // CodeBracketSquareIcon,
  },
  computed: {
    render() {
      return [
        // // this.module("status"),
        // this.module("color"),
        // this.module("description"),
        // this.module("schedule"),
        // this.module("time_trackings"),
        // this.module("priority"),
        // this.module("routine"),
        // this.module("anchor"),
        // this.module("links"),
        //this.module("templates"),
      ];
    },
    user() {
      return this.$store.getters.user;
    },
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    status: {
      get() {
        return this.getStatusById(this.modelValue.status_id);
      },
      set() {
        //this.setStatus(this.entry, status);
        // if (!this.entry.temp)
        //   this.$store.dispatch("push", {
        //     event: "entry_update",
        //     params: { entry: this.entry },
        //     entry: this.entry,
        //   });
      },
    },
  },
  methods: {
    duplicateEntry() {
      const newId = this.$nanoid();
      const entry = {
        ...this.entrySchema({ id: newId }),
        ...this.getCleanSchema(this.entry, newId),
      };

      this.$store.getters.entries.push(entry);

      this.$store.dispatch("push", {
        event: "entry_create",
        params: { entry: entry },
        entry: entry,
      });
    },
    deleteEntry() {
      this.entry.deleted_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      this.$store.getters.entries
        .filter((e) => e.links.includes(this.entry.id))
        .forEach((e) => {
          e.links.splice(
            e.links.findIndex((l) => l == this.entry.id),
            1,
          );
        });
      if (!this.entry.temp)
        this.$store.dispatch("push", {
          event: "entry_delete",
          params: { id: this.entry.id },
          entry: this.entry,
        });
      if (this.$route.params.id == this.entry.id) this.$router.back();
    },
    undeleteEntry() {
      this.entry.deleted_at = null;
      if (!this.entry.temp)
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
    },
  },
};
</script>
