<template>
  <div data-test="status_builder" class="text-sm">
    <div class="flex flex-wrap items-center gap-1">
      <!-- {{ selected.length }}
      {{ selectedStatuses.length }} -->
      <div v-for="(status, index) in selectedStatuses" :key="index">
        <LabelButton
          @click="
            () => {
              selected.splice(index, 1);
              $emit('update:modelValue', selected);
            }
          "
        >
          {{ status?.name }}
        </LabelButton>
      </div>
      <div class="flex items-center">
        <Listbox
          v-if="
            statuses.filter((s) => {
              return !selectedStatuses.map((s) => s.id).includes(s.id);
            }).length
          "
          as="div"
          v-model="add"
        >
          <div class="relative">
            <ListboxButton
              class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-neutral-600 sm:text-sm sm:leading-6"
              data-test="status_builder_add_button"
            >
              <span class="flex items-center">
                <span class="block truncate"> Select ...</span>
              </span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <ChevronUpDownIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition ease-in duration-100"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute z-10 mt-1 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  as="template"
                  v-for="status in statuses.filter(
                    (s) => !selectedStatuses.map((s) => s.id).includes(s.id),
                  )"
                  :key="status.id"
                  :value="status.id"
                  v-slot="{ active, _selected }"
                  :data-test="'status_option_' + $slugify(status.name)"
                >
                  <li
                    :class="[
                      active ? 'bg-neutral-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                    ]"
                  >
                    <div class="flex items-center">
                      <span
                        :class="[
                          `bg-${status.color}-400`,
                          'inline-block h-2 w-2 flex-shrink-0 rounded-full',
                        ]"
                        aria-hidden="true"
                      />
                      <span
                        :class="[
                          _selected ? 'font-semibold' : 'font-normal',
                          'ml-3 block truncate',
                        ]"
                      >
                        {{ status.name }}
                      </span>

                      <span
                        v-if="status.entry_id"
                        :class="['text-gray-500', 'ml-2 truncate text-xs']"
                        >{{
                          $store.getters.entries.find(
                            (e) => e.id == status.entry_id,
                          )?.name
                        }}
                      </span>
                    </div>

                    <span
                      v-if="_selected"
                      :class="[
                        active ? 'text-white' : 'text-neutral-600',
                        'absolute inset-y-0 right-0 flex items-center pr-4',
                      ]"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable no-unused-vars -->
<script setup>
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
</script>

<script>
import LabelButton from "../helpers/LabelButton.vue";
export default {
  name: "LinksBuilder",
  inject: ["entry"],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LabelButton,
  },
  props: {
    modelValue: Array,
    statuses: Array,
  },
  data() {
    return {
      showSearch: false,
      add: null,
      selected: this.modelValue,
    };
  },
  computed: {
    selectedStatuses() {
      return this.selected
        .map((status_id) =>
          this.$store.getters.statuses.find(
            (status) => status.id === status_id,
          ),
        )
        .filter((s) => s);
    },
  },
  watch: {
    modelValue: {
      handler(n) {
        this.selected = n;
      },
      immediate: true,
    },
    add: function () {
      if (this.add) this.selected.push(this.add);

      this.$emit("update:modelValue", this.selected);

      this.add = null;
    },
  },
};
</script>
