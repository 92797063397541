<template>
  <Listbox as="div" v-model="selected">
    <ListboxLabel
      v-if="$slots['label']"
      class="mb-2 block text-sm font-medium leading-6 text-neutral-900"
    >
      <slot name="label" />
    </ListboxLabel>
    <div class="relative">
      <ListboxButton
        ref="button"
        class="relative w-full cursor-default rounded-md bg-white py-1 pl-2.5 pr-10 text-left text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 focus:outline-none focus:ring-2 focus:ring-neutral-600 sm:text-sm sm:leading-6 dark:bg-black dark:text-neutral-200 dark:ring-neutral-700"
      >
        <span class="block truncate">
          {{
            props.options.find(
              (o) => JSON.stringify(o.value) == JSON.stringify(selected),
            )?.title || "Select"
          }}
        </span>
        <span
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronUpDownIcon
            class="h-5 w-5 text-neutral-400 dark:text-neutral-600"
            aria-hidden="true"
          />
        </span>
      </ListboxButton>
      <Teleport :disabled="!props.teleport" to="body">
        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            ref="floating"
            :style="floatingStyles"
            class="absolute z-10 mt-1 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-neutral-200 focus:outline-none sm:text-sm dark:bg-black dark:ring-neutral-700"
          >
            <ListboxOption
              as="template"
              v-for="option in props.options.filter(
                (o) => typeof o.hidden == 'undefined' || !o.hidden,
              )"
              :key="option.value"
              :value="option.value"
              v-slot="{ active, selected }"
              :data-option="
                typeof option == 'object' && option.value
                  ? option.value
                  : option
              "
              :data-test="
                'option_' +
                $slugify(
                  typeof option == 'object' && option.title
                    ? option.title
                    : option,
                )
              "
            >
              <li
                :class="[
                  active
                    ? 'bg-neutral-900 text-white dark:bg-neutral-200 dark:text-black'
                    : 'text-neutral-900 dark:bg-black dark:text-neutral-200',
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]"
                >
                  {{ option.title }}
                </span>

                <span
                  v-if="selected"
                  :class="[
                    active
                      ? 'text-white dark:text-black'
                      : 'text-neutral-600 dark:text-neutral-400',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </Teleport>
    </div>
  </Listbox>
</template>

<script setup>
import { ref, defineModel, defineProps, nextTick } from "vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
import { useFloating, shift, flip, autoUpdate } from "@floating-ui/vue";

const model = defineModel();
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  openWhenEmpty: Boolean,
  teleport: {
    type: Boolean,
    default: true,
  },
});

const selected = ref(model);
const button = ref(null);

if (props.openWhenEmpty) {
  nextTick(() => {
    if (selected.value == null) {
      button.value.$el.click();
    }
  });
}

const floating = ref(null);

const { floatingStyles } = useFloating(button, floating, {
  //strategy: "fixed",
  placement: "bottom-start",
  whileElementsMounted: autoUpdate,
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>
