<template>
  <div>
    <PopoverHelper>
      <template v-slot:button>
        <ButtonComponent
          :size="size"
          :variant="variant"
          :color="color"
          title="Change Output Type"
          data-test="output_type_picker_button"
        >
          <ListBulletIcon v-if="modelValue == 'list'" class="h-4 w-4" />
          <ViewColumnsIcon v-if="modelValue == 'board'" class="h-4 w-4" />
          <Squares2X2Icon v-if="modelValue == 'gallery'" class="h-4 w-4" />
          <CalendarDaysIcon
            v-if="
              [
                'calendar',
                'calendar_day',
                'calendar_week',
                'calendar_month',
              ].includes(modelValue)
            "
            class="h-4 w-4"
          />
          <CalendarDaysIcon v-if="modelValue == 'tiles'" class="h-4 w-4" />

          <span v-if="variant != 'minimal'" class="hidden md:block">
            Type:
            <span v-if="modelValue">
              {{
                modelValue.charAt(0).toUpperCase() +
                modelValue.slice(1).replace(/_/g, " ")
              }}
            </span>
          </span>

          <ChevronDownIcon
            v-if="variant != 'minimal'"
            class="h-4 w-4 text-neutral-300/70"
            aria-hidden="true"
          />
        </ButtonComponent>
      </template>

      <div class="relative grid w-screen max-w-sm gap-8 p-7 lg:grid-cols-1">
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', 'list')"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="output_type_picker_list_button"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
          >
            <ListBulletIcon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-300">
              List
            </p>
            <p class="text-sm text-gray-500 dark:text-neutral-400">
              Render entries as a list
            </p>
          </div>
        </PopoverButton>
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', 'board')"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="output_type_picker_board_button"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
          >
            <ViewColumnsIcon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-300">
              Board
            </p>
            <p class="text-sm text-gray-500 dark:text-neutral-400">
              Render entries as a kanban board
            </p>
          </div>
        </PopoverButton>
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', 'gallery')"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="output_type_picker_gallery_button"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
          >
            <Squares2X2Icon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-300">
              Gallery
            </p>
            <p class="text-sm text-gray-500 dark:text-neutral-400">
              Render entries as a gallery
            </p>
          </div>
        </PopoverButton>
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', 'calendar')"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="output_type_picker_calendar_button"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
          >
            <CalendarDaysIcon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-300">
              Calendar
            </p>
            <p class="text-sm text-gray-500 dark:text-neutral-400">
              Render entries as a calendar
            </p>
          </div>
        </PopoverButton>
        <!-- <PopoverButton
                as="a"
                @click="$emit('update:modelValue', 'tiles')"
                class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 dark:hover:bg-neutral-900 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50"
              >
                <div
                  class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12"
                >
                  <ViewfinderCircleIcon class="h-5 w-5" />
                </div>
                <div class="ml-4">
                  <p class="text-sm font-medium text-gray-900">Tiles</p>
                  <p class="text-sm text-gray-500">
                    Render entries as small tiles
                  </p>
                </div>
              </PopoverButton> -->
      </div>
    </PopoverHelper>
  </div>
</template>
<script setup>
import {
  CalendarDaysIcon,
  ListBulletIcon,
  Squares2X2Icon,
  ViewColumnsIcon,
  // ViewfinderCircleIcon,
} from "@heroicons/vue/24/outline";

import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { PopoverButton } from "@headlessui/vue";
</script>
<script>
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  props: {
    options: Array,
  },
};
</script>
