<template>
  <PopoverHelper style="position: relative" data-test="completed_at">
    <template v-slot:button>
      <span
        v-if="entry_quick_fix.completed_at"
        :title="
          'Last completed: ' +
          moment
            .utc(entry_quick_fix.completed_at)
            .local()
            .format('YYYY-MM-DD HH:mm:ss')
        "
        style="font-weight: normal"
      >
        {{ moment.utc(entry_quick_fix.completed_at).fromNow() }}
      </span>
      <span
        v-else
        @click="
          [
            (entry_quick_fix.completed_at = moment
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')),
            !entry_quick_fix.temp
              ? $store.dispatch('push', {
                  event: 'entry_update',
                  params: { entry: entry_quick_fix },
                  entry: entry_quick_fix,
                })
              : null,
          ]
        "
        :title="
          'Edit the completion date of &quot;' + entry_quick_fix.name + '&quot;'
        "
        style="font-weight: normal"
      >
        never completed
      </span>
    </template>
    <div class="p-2">
      <DateTimePicker
        v-model="entry_quick_fix.completed_at"
        :entry="entry_quick_fix"
        @update:modelValue="
          !entry_quick_fix.temp
            ? $store.dispatch('push', {
                event: 'entry_update',
                params: { entry: entry_quick_fix },
                entry: entry_quick_fix,
              })
            : null
        "
      />
    </div>
  </PopoverHelper>
</template>

<script>
import { Addon } from "../mixins/Addon";
import moment from "moment";
import DateTimePicker from "../builders/DateTimePicker.vue";

export default {
  name: "CompletionCompletedAt",
  props: {
    id: String,
  },
  mixins: [Addon],
  components: { DateTimePicker },
  data() {
    return {
      entry_quick_fix: this.modelValue,
      moment: moment,
      edit: false,
    };
  },
};
</script>
