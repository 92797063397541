<template>
  <ButtonComponent v-if="entry.deleted_at" @click="undeleteEntry"
    >undelete</ButtonComponent
  >
  <button
    v-else
    @click="deleteEntry"
    :title="'Delete &quot' + entry.name + '&quot;'"
    class="self-start"
  >
    <TrashIcon class="inline h-5 w-5" /> <span> Delete </span>
  </button>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/24/outline";
import moment from "moment";

export default {
  props: {
    modelValue: Object,
  },
  data() {
    return {
      entry: this.modelValue,
    };
  },
  methods: {
    deleteEntry() {
      this.entry.deleted_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      this.$store.getters.entries
        .filter((e) => e.links.includes(this.entry.id))
        .forEach((e) => {
          e.links.splice(
            e.links.findIndex((l) => l == this.entry.id),
            1,
          );
        });
      if (!this.entry.temp)
        this.$store.dispatch("push", {
          event: "entry_delete",
          params: { id: this.entry.id },
          entry: this.entry,
        });
      this.$emit("onDelete", this.entry);
      if (this.$route.params.id == this.entry.id) this.$router.back();
    },
    undeleteEntry() {
      this.entry.deleted_at = null;
      if (!this.entry.temp)
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
    },
  },
  components: { TrashIcon },
};
</script>

<style scoped>
button:hover {
  color: var(--color-red) !important;
}
</style>
