<template>
  <div class="rounded-md bg-yellow-50 p-4 dark:bg-yellow-950">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationTriangleIcon
          class="h-5 w-5 text-yellow-400 dark:text-yellow-600"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-yellow-800 dark:text-yellow-500">
          Email address unverified
        </h3>
        <div class="mt-2 text-sm text-yellow-700">
          <p>Please click on the link we've sent you.</p>
        </div>
        <div class="mt-4">
          <div class="-mx-2 -my-1.5 flex">
            <button
              v-if="!sent"
              @click="resend"
              type="button"
              class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50 dark:bg-yellow-900 dark:text-white"
            >
              Resend Email
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

export default {
  name: "UnverifiedComponent",
  data() {
    return {
      sent: false,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    resend() {
      this.$store
        .dispatch("resendValidation")
        .then((response) => {
          if (response.status === 200) this.sent = true;
        })
        .catch(() => {
          this.message = "An error appeared. Please try again later.";
        });
    },
  },
  components: { ExclamationTriangleIcon },
};
</script>
