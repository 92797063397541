<template>
  <div>
    <div
      class="mx-auto w-full sm:max-w-xl md:max-w-2xl md:px-8 lg:max-w-3xl xl:max-w-5xl"
    >
      <div class="flex flex-col gap-4">
        <ConstructionArea />
        <PanelHelper>
          <div class="px-4 sm:px-0">
            <h3
              class="text-base font-semibold leading-7 text-gray-900 dark:text-neutral-100"
            >
              Space Settings
            </h3>
            <p
              class="mt-1 max-w-2xl text-sm leading-6 text-gray-500 dark:text-neutral-100"
            >
              <!-- Personal details. -->
            </p>
          </div>
          <div class="mt-6 border-t border-gray-100 dark:border-neutral-800">
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt
                  class="text-sm font-medium leading-6 text-gray-900 dark:text-neutral-100"
                >
                  Space Name
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 dark:text-neutral-100"
                >
                  {{ space.name }}
                </dd>
              </div>
            </dl>
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt
                  class="text-sm font-medium leading-6 text-gray-900 dark:text-neutral-100"
                >
                  Subscription
                </dt>
                <dd
                  class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
                >
                  <ButtonComponent
                    color="primary"
                    @click="$router.push({ name: 'subscription' })"
                  >
                    {{ space.active_subscription.name }}
                    <PencilSquareIcon class="h-4 w-4" />
                  </ButtonComponent>
                </dd>
              </div>
            </dl>
          </div>
        </PanelHelper>

        <PanelHelper>
          <div class="px-4 sm:px-0">
            <h3
              class="text-base font-semibold leading-7 text-gray-900 dark:text-neutral-100"
            >
              Space Statuses
            </h3>
            <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
              <!-- Personal details. -->
            </p>
          </div>
          <StatusEditor
            v-model="statuses"
            @update:modelValue="
              (statuses) => {
                space.statuses = statuses;
                $store.dispatch('push', {
                  event: 'space_update',
                  params: { space: space },
                });
              }
            "
          />
        </PanelHelper>
      </div>
    </div>
  </div>
</template>

<script>
import { PencilSquareIcon } from "@heroicons/vue/20/solid";
import StatusEditor from "./forms/StatusEditor.vue";
export default {
  name: "SpacePage",
  components: {
    StatusEditor,
    PencilSquareIcon,
  },
  data() {
    return {
      statuses: this.$store.getters.space?.statuses,
    };
  },
  watch: {
    space: {
      handler() {
        this.statuses = this.$store.getters.space.statuses;
      },
      deep: true,
    },
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
  },
};
</script>
