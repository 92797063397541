<template>
  <div :class="[$style.root, disabled ? 'pointer-events-none' : '']">
    <!-- <Transition
      @enter="
        (el, done) => {
          $anime({
            targets: el,
            opacity: [0, 1],
            translateY: [20, 0],
            complete: done,
          });
        }
      "
      :css="false"
    > -->
    <div
      class="group/routine flex items-center gap-1"
      data-test="entry_routine"
      :data-frequency="routine.frequency"
    >
      <!-- {{
      routine.logs
        .map((l) => l.span_at + " " + l.completed + l.completed_at)
        .reverse()
    }} -->
      <!-- <ModalHelper :show="open" @close="open = false">
        <template #title> Routine Calendar</template>
        <CalendarComponent
          :mode="
            routine.frequency == 'weekly'
              ? 'weeks'
              : routine.frequency == 'monthly'
              ? 'months'
              : 'days'
          "
          v-slot="{ date, span } = slotProps"
        >
          <button
            @click="
              $moment(date).isSameOrBefore($moment(), 'day')
                ? logASpan(
                    getDateSpan(routine.frequency, date),
                    this.routine.frequency,
                    true,
                  )
                : null
            "
            :class="{
              'border-green-500 ':
                routine.logs.find(
                  (l) => l.span_at == getDateSpan(routine.frequency, date),
                )?.completed === 1,
              'border-red-500 ':
                routine.logs.find(
                  (l) => l.span_at == getDateSpan(routine.frequency, date),
                )?.completed === 0,
              'border-neural-500':
                routine.logs.find(
                  (l) => l.span_at == getDateSpan(routine.frequency, date),
                )?.completed === null,
              'cursor-normal pointer-events-none opacity-50': $moment(
                date,
              ).isAfter($moment(), 'day'),
            }"
            class="absolute inset-0 rounded-full border-2"
          >
            {{ $moment(date).format("DD") }}
            <span v-if="span">
              - {{ $moment(date).add(span, "days").format("DD") }}
            </span>
          </button>
        </CalendarComponent>
      </ModalHelper>
      <button
        @click.stop="open = true"
        class="cursor-pointer text-neutral-500 md:opacity-0 md:transition-opacity md:group-hover/routine:opacity-100"
        :class="$route.params.id != entry.id ? 'hidden md:block' : ''"
      >
        <Cog6ToothIcon class="h-5 w-5" />
      </button> -->

      <!-- {{ routine.logs }} -->
      <!-- {{ slots }} -->
      <a
        v-for="(slot, index) in slots"
        :key="index"
        @click.stop="slot.fn"
        class="group/routine_log relative cursor-pointer rounded-full transition-opacity group-hover/routine:!opacity-100"
        :class="`text-${getColor(entry, true)}-500 hover:text-${getColor(
          entry,
          true,
        )}-700 ${
          index == slots.length - 1
            ? 'bg-' +
              getColor(entry, true) +
              '-500 !text-white/75 hover:!text-white'
            : ''
        }`"
        :style="{
          opacity:
            $route.params.id != entry.id ? (index + 1) / slots.length : null,
        }"
        :data-test="'entry_routine_log_index_' + (index + 1 - slots.length)"
      >
        <span
          v-if="typeof slot.log == 'undefined'"
          class="grayscale"
          data-test="entry_routine_log_state_unknown"
        >
          <span
            v-if="index == slots.length - 1 && routine.value > 0"
            class="flex h-6 w-6 items-center justify-center text-xs font-bold"
            >{{ routine.value }}</span
          >
          <QuestionMarkCircleIcon v-else class="h-6 w-6" />
        </span>
        <span
          v-else-if="slot.log.completed === null"
          class="grayscale"
          data-test="entry_routine_log_state_unknown"
        >
          <span
            v-if="index == slots.length - 1 && routine.value > 0"
            class="flex h-6 w-6 items-center justify-center text-xs font-bold"
            >{{ routine.value }}</span
          >
          <QuestionMarkCircleIcon v-else class="h-6 w-6" />
        </span>
        <span
          v-else-if="slot?.log?.completed"
          :class="`${
            typeof slot.log != 'undefined' && slot.log.completed == false
              ? 'invert'
              : ''
          } ${
            typeof slot.log == 'undefined' || slot.log.completed === null
              ? 'grayscale'
              : ''
          }`"
          data-test="entry_routine_log_state_completed"
          ><CheckCircleIcon class="h-6 w-6"
        /></span>
        <span
          v-else
          :class="`${
            typeof slot.log != 'undefined' && slot.log.completed == false
              ? 'invert'
              : ''
          } ${
            typeof slot.log == 'undefined' || slot.log.completed === null
              ? 'grayscale'
              : ''
          }`"
          data-test="entry_routine_log_state_uncompleted"
          ><XCircleIcon class="h-6 w-6"
        /></span>
        <span
          class="pointer-events-none absolute -bottom-12 right-0 z-10 w-max items-center gap-2 truncate rounded-md bg-white px-2 py-1 text-sm opacity-0 drop-shadow-md group-hover/routine_log:flex group-hover/routine_log:opacity-100 group-hover/routine_log:transition-opacity group-hover/routine_log:delay-300 dark:bg-black dark:ring-1 dark:ring-neutral-700"
        >
          <span class="text-center text-xs text-black dark:text-neutral-300">
            {{ slot.date }}
            <span
              v-if="
                typeof slot?.log == 'undefined' || slot?.log?.completed === null
              "
              class="flex items-center gap-1"
              ><QuestionMarkCircleIcon class="h-6 w-6" /> Skipped
            </span>
            <span
              v-else-if="slot?.log?.completed"
              class="flex items-center gap-1"
              ><CheckCircleIcon class="h-6 w-6" /> Completed
            </span>
            <span v-else class="flex items-center gap-1"
              ><XCircleIcon class="h-6 w-6" /> Uncompleted
            </span>
          </span>
        </span>
      </a>

      <!-- <a
      v-if="routine.current < routine.target"
      @click="() => handleRoutineCheck(entry)"
      class="group/routine_log relative cursor-pointer transition-opacity group-hover/routine:!opacity-100"
      :class="`text-${getColor(modelValue, true)}-500 hover:text-${getColor(
        modelValue,
        true,
      )}-700`"
      data-test="entry_routine_current"
    >
      {{ routine.current }} / {{ routine.target }}
    </a> -->
    </div>
    <!-- </Transition> -->
  </div>
</template>

<script>
import moment from "moment";
import { Coloring } from "../mixins/Coloring";
// import CalendarComponent from "../helpers/CalendarComponent.vue";
import { getDateSpan } from "@/datesHelper";
import { Routining } from "../mixins/Routining";

export default {
  props: {
    modelValue: Object,
    entry: Object,
    disabled: Boolean,
  },
  data() {
    return {
      // isInitialized: false,
      open: false,
      getDateSpan: getDateSpan,
    };
  },
  // mounted() {
  //   this.$onIdle(() => {
  //     this.isInitialized = true;
  //   });
  // },
  mixins: [Coloring, Routining],
  computed: {
    routine: {
      get: function () {
        return this.modelValue;
      },
      set(routine) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.routine = routine;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    slots() {
      switch (this.routine.frequency) {
        case "daily":
          return Array.from({ length: 7 }, (_, i) => {
            const span_at = getDateSpan(
              this.routine.frequency,
              moment().add(-i, "days"),
            );

            return {
              date: moment().add(-i, "days").format("YYYY-MM-DD"),
              log: this.getLogBySpan({ entry: this.entry, span_at }),
              fn: () => {
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency,
                });
                if (!this.entry.temp) {
                  this.$store.dispatch("push", {
                    event: "entry_update",
                    params: { entry: this.entry },
                    entry: this.entry,
                  });
                }
              },
            };
          }).reverse();
        case "weekly":
          return Array.from({ length: 4 }, (_, i) => {
            const span_at = getDateSpan(
              this.routine.frequency,
              moment() /*.add(-1, "day")*/
                .add(-i, "week"),
            );
            return {
              date:
                moment().add(-i, "weeks").startOf("week").format("YYYY-MM-DD") +
                " - " +
                moment().add(-i, "weeks").endOf("week").format("YYYY-MM-DD"),
              log: this.getLogBySpan({ entry: this.entry, span_at }),
              fn: () => {
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency,
                });
                if (!this.entry.temp) {
                  this.$store.dispatch("push", {
                    event: "entry_update",
                    params: { entry: this.entry },
                    entry: this.entry,
                  });
                }
              },
            };
          }).reverse();
        case "monthly":
          return Array.from({ length: 3 }, (_, i) => {
            const span_at = getDateSpan(
              this.routine.frequency,
              moment().add(-i, "month"),
            );

            return {
              date:
                moment()
                  .add(-i, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD") +
                " - " +
                moment().add(-i, "month").endOf("month").format("YYYY-MM-DD"),
              log: this.getLogBySpan({ entry: this.entry, span_at }),
              fn: () => {
                this.logASpan({
                  entry: this.entry,
                  span_at: span_at,
                  frequency: this.routine.frequency,
                });
                if (!this.entry.temp) {
                  this.$store.dispatch("push", {
                    event: "entry_update",
                    params: { entry: this.entry },
                    entry: this.entry,
                  });
                }
              },
            };
          }).reverse();
      }
      return [];
    },
  },
  components: {
    // CalendarComponent,
  },
};
</script>

<style module lang="scss">
.root {
  @apply min-h-6;
}

@container calendarEventContent (width < 576px) {
  .root {
    display: none;
  }
}

@container calendarEventContent (height < 48px) {
  .root {
    display: none;
  }
}
</style>
