<template>
  <div>
    <div class="mx-auto sm:w-full sm:max-w-3xl md:max-w-5xl">
      <div class="px-5 pb-5">
        <h1
          class="mb-2 font-serif text-3xl font-bold leading-tight text-neutral-900 sm:text-5xl sm:leading-tight md:mb-10 md:text-7xl md:leading-tight dark:text-neutral-100"
        >
          Welcome to
          <span
            class="rounded-lg bg-neutral-900 px-2 text-white dark:bg-neutral-100 dark:text-black"
            >Pensive</span
          >.
        </h1>
        <TransitionGroup :appear="true" @enter="animationEnter">
          <p
            key="2"
            class="italic text-neutral-500 dark:text-neutral-400"
            data-delay="2000"
          >
            Your Space is ready, but it's waiting for your touch. Let's bring it
            to life.
          </p>
        </TransitionGroup>
      </div>
      <Transition
        @enter="
          (el) => {
            $anime({
              targets: el,
              delay: 500,
              duration: 3000,
              easing: 'easeOutExpo',
              translateY: [100, 0],
              opacity: [0, 1],
              complete: done,
            });
          }
        "
      >
        <div v-if="showBuilder" class="flex flex-col gap-4">
          <div class="md:col-span-4">
            <div
              class="flex flex-col gap-4 rounded-3xl bg-neutral-50 py-5 dark:bg-neutral-900"
            >
              <p
                class="px-5 leading-normal text-neutral-600 dark:text-neutral-300"
              >
                <b class="font-semibold">Select Templates</b> to get started and
                make it uniquely yours.
              </p>
              <PanelHelper
                ref="outer"
                class="box-content"
                style="transition: height 1s cubic-bezier(0.16, 1, 0.3, 1)"
              >
                <ul
                  ref="inner"
                  role="list"
                  class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5"
                >
                  <TransitionGroupHelper
                    :enter="{
                      // easing: 'easeOutExpo',
                      translateY: [50, 0],
                      opacity: [0, 1],
                    }"
                  >
                    <div
                      v-for="(item, index) in selectedTemplates.map((id) =>
                        templates.find((t) => t.id == id),
                      )"
                      :key="item.id"
                      class="relative col-span-2 flex gap-4 rounded-xl border border-neutral-100 p-5 sm:col-span-3 md:col-span-4 lg:col-span-5 dark:border-neutral-900"
                    >
                      <TemplateDisplay
                        :modelValue="item"
                        class="grow rounded-xl"
                        :data-test="'template_' + $slugify(item.name)"
                      >
                        <template #top>
                          <ButtonComponent
                            @click.stop="
                              selectedTemplates.includes(item.id)
                                ? selectedTemplates.splice(
                                    selectedTemplates.indexOf(item.id),
                                    1,
                                  )
                                : selectedTemplates.push(item.id)
                            "
                            variant="minimal"
                            class="group/selected w-[90px] cursor-pointer !justify-center rounded bg-neutral-900 px-2 py-1 text-center text-xs !text-neutral-100"
                            title="Remove this template"
                          >
                            <span class="group-hover/selected:hidden">
                              <IconHelper
                                name="check"
                                size="16"
                                class="inline"
                              />
                              Selected
                            </span>
                            <span class="hidden group-hover/selected:inline">
                              <IconHelper
                                name="trash"
                                size="16"
                                class="inline"
                              />
                              Remove
                            </span>
                          </ButtonComponent>
                        </template>
                      </TemplateDisplay>

                      <div class="flex w-7 flex-col justify-between">
                        <div class="h-7">
                          <ButtonComponent
                            v-if="index > 0"
                            @click.stop="
                              () => {
                                const i = selectedTemplates.indexOf(item.id);
                                if (i > 0) {
                                  const _item = selectedTemplates.splice(
                                    i,
                                    1,
                                  )[0];
                                  selectedTemplates.splice(i - 1, 0, _item);
                                }
                              }
                            "
                            variant="round"
                            color="secondary"
                            size="sm"
                            title="Move up"
                          >
                            <IconHelper name="arrow-up" size="20" />
                          </ButtonComponent>
                        </div>
                        <div class="h-7">
                          <ButtonComponent
                            @click.stop="
                              // applyTemplateToEntry(item, entry), (showTemplates = false)
                              selectedTemplates.includes(item.id)
                                ? selectedTemplates.splice(
                                    selectedTemplates.indexOf(item.id),
                                    1,
                                  )
                                : selectedTemplates.push(item.id)
                            "
                            variant="round"
                            color="secondary"
                            size="sm"
                            title="Remove this template"
                          >
                            <IconHelper name="trash" size="20" />
                          </ButtonComponent>
                        </div>
                        <div class="h-7">
                          <ButtonComponent
                            v-if="index < selectedTemplates.length - 1"
                            @click.stop="
                              () => {
                                const i = selectedTemplates.indexOf(item.id);
                                if (i < selectedTemplates.length - 1) {
                                  const _item = selectedTemplates.splice(
                                    i,
                                    1,
                                  )[0];
                                  selectedTemplates.splice(i + 1, 0, _item);
                                }
                              }
                            "
                            variant="round"
                            color="secondary"
                            size="sm"
                            title="Move down"
                          >
                            <IconHelper name="arrow-down" size="20" />
                          </ButtonComponent>
                        </div>
                      </div>
                    </div>

                    <!-- Start: Recommendations Separator -->
                    <div
                      key="recommendations"
                      class="relative col-span-2 sm:col-span-3 md:col-span-4 lg:col-span-5"
                    >
                      <div
                        class="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div
                          class="w-full border-t border-neutral-100 dark:border-neutral-900"
                        />
                      </div>
                      <div class="relative flex justify-center">
                        <div class="bg-white px-3 dark:bg-black">
                          <div
                            class="rounded-full bg-neutral-50 p-1.5 text-neutral-300 dark:bg-neutral-950 dark:text-neutral-500"
                          >
                            <IconHelper name="plus" size="24" strokeWidth="2" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End: Recommendations Separator -->

                    <div v-for="item in recommendations" :key="item.id">
                      <div
                        @click="addTemplate(item)"
                        class="group flex h-full cursor-pointer flex-col gap-2 rounded-xl border border-neutral-100 p-5 transition-all duration-300 hover:-translate-y-2 hover:shadow-lg dark:border-neutral-900"
                      >
                        <TemplateDisplay
                          :modelValue="item"
                          :showPreview="false"
                          size="sm"
                        />
                        <div class="flex justify-end">
                          <div
                            class="origin-center rounded-full bg-neutral-50 p-1.5 text-neutral-300 transition-all duration-300 group-hover:-translate-y-1 group-hover:bg-neutral-100 group-hover:text-neutral-900 dark:bg-neutral-950 dark:text-neutral-500 dark:group-hover:bg-neutral-900 dark:group-hover:text-neutral-100"
                          >
                            <IconHelper name="plus" size="24" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      key="more-button"
                      v-if="templatesAvailable.length > recommendationsLimit"
                      @click="showTemplateBuilder = true"
                    >
                      <div
                        class="group flex h-full cursor-pointer flex-col gap-2 rounded-xl border border-neutral-100 p-5 transition-all duration-300 hover:-translate-y-2 hover:shadow-lg dark:border-neutral-900"
                      >
                        <div class="flex h-full flex-col justify-between gap-2">
                          <div class="flex flex-col gap-2">
                            <div class="flex items-center justify-between pb-2">
                              <div
                                class="self-start rounded-lg bg-neutral-50 p-2 dark:bg-neutral-900"
                              >
                                <span class="text-neutral-500">
                                  <IconHelper
                                    name="layout-template"
                                    size="24"
                                  />
                                </span>
                              </div>
                            </div>
                            <a
                              class="font-semibold text-neutral-900 dark:text-neutral-400"
                            >
                              <!-- <span class="absolute inset-0 cursor-pointer" aria-hidden="true" /> -->

                              More Templates
                            </a>

                            <p class="text-sm text-neutral-500">
                              Show more templates to help you get started.
                            </p>
                          </div>
                        </div>
                        <div class="flex justify-end">
                          <div
                            class="origin-center rounded-full bg-neutral-50 p-1.5 text-neutral-300 transition-all duration-300 group-hover:-translate-y-1 group-hover:bg-neutral-100 group-hover:text-neutral-900 dark:bg-neutral-950 dark:text-neutral-500 dark:group-hover:bg-neutral-900 dark:group-hover:text-neutral-100"
                          >
                            <IconHelper name="arrow-right" size="24" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TransitionGroupHelper>
                </ul>
              </PanelHelper>

              <div class="flex items-center justify-end gap-4 px-5">
                <span
                  class="leading-normal text-neutral-600 dark:text-neutral-300"
                >
                  <b class="font-semibold">{{ selectedTemplates.length }} </b>
                  Template(s) selected
                </span>
                <ButtonComponent
                  ref="continue"
                  :disabled="!selectedTemplates.length"
                  @click="
                    selectedTemplates.length
                      ? [
                          selectedTemplates
                            .map((id) => templates.find((t) => t.id === id))
                            .forEach((t) => setTemplate(t)),
                          (selected = []),
                        ]
                      : addEmptyEntry()
                  "
                  color="primary"
                  :title="
                    selectedTemplates.length
                      ? null
                      : 'Please select at least one template to continue'
                  "
                  :class="
                    selectedTemplates.length
                      ? null
                      : 'cursor-not-allowed !bg-neutral-500'
                  "
                >
                  Continue
                  <!-- {{ selected.length ? "Continue" : "Skip" }} -->
                  <ArrowRightIcon class="h-4 w-4" />
                </ButtonComponent>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Dialog
        v-if="showTemplateBuilder"
        as="div"
        :open="true"
        @close="handleCloseTemplateBuilder"
        class="relative z-[999] h-px w-px"
      >
        <Transition
          @enter="
            (el, done) => {
              $anime({
                duration: 300,
                easing: 'linear',
                targets: el,
                opacity: [0, 1],
                complete: done,
              });
            }
          "
          @leave="
            (el, done) => {
              $anime({
                duration: 300,
                easing: 'linear',
                targets: el,
                opacity: [1, 0],
                complete: done,
              });
            }
          "
          :css="false"
          appear
        >
          <div class="fixed inset-0 backdrop-blur" />
        </Transition>
        <Transition
          @enter="
            (el, done) => {
              $anime({
                targets: el,
                opacity: [0, 1],
                translateY: [200, 0],
                complete: done,
              });
            }
          "
          @leave="
            (el, done) => {
              $anime({
                targets: el,
                opacity: [1, 0],
                translateY: [0, 200],
                complete: done,
              });
            }
          "
          :css="true"
          appear
        >
          <div
            class="fixed inset-0 z-10 w-screen overflow-y-auto overflow-x-hidden"
            style="scrollbar-gutter: stable"
          >
            <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
            >
              <DialogPanel
                class="relative max-w-[90vw] transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl ring-1 ring-transparent transition-all sm:my-8 sm:w-full sm:px-6 sm:pb-6 md:max-w-3xl lg:max-w-4xl dark:bg-black dark:ring-neutral-700"
              >
                <DialogTitle
                  as="h3"
                  class="sticky top-0 z-10 -mx-4 flex justify-between rounded-b bg-white px-4 py-4 dark:bg-black"
                >
                  <span class="text-xl font-semibold">Templates</span>
                  <ButtonComponent
                    @click="handleCloseTemplateBuilder"
                    color="secondary"
                    size="sm"
                    class="!px-1"
                  >
                    <IconHelper name="x" size="20" />
                  </ButtonComponent>
                </DialogTitle>

                <TemplateBuilder
                  ref="template_builder"
                  :filterTemplates="(t) => !selectedTemplates.includes(t.id)"
                  :hideCustomTemplates="true"
                  @clickTemplate="
                    (template) => handleTemplateBuilderClickTemplate(template)
                  "
                >
                  <template #template_top="{ template }">
                    <transition
                      @enter="animationEnterIsSelectedInTemplateBuilder"
                      @leave="animationLeaveIsSelectedInTemplateBuilder"
                    >
                      <ButtonComponent
                        v-if="
                          selectedTemplatesInTemplateBuilder.includes(
                            template.id,
                          )
                        "
                        variant="minimal"
                        class="group/selected w-[90px] cursor-pointer !justify-center rounded bg-neutral-900 px-2 py-1 text-center text-xs !text-neutral-100"
                        title="Remove this template"
                      >
                        <span class="group-hover/selected:hidden">
                          <IconHelper name="check" size="16" class="inline" />
                          Selected
                        </span>
                        <span class="hidden group-hover/selected:inline">
                          <IconHelper name="trash" size="16" class="inline" />
                          Unselect
                        </span>
                      </ButtonComponent>
                    </transition>
                  </template>
                </TemplateBuilder>
                <div class="mt-4 flex justify-end">
                  <ButtonComponent
                    color="primary"
                    @click="handleCloseTemplateBuilder"
                  >
                    Done
                  </ButtonComponent>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Transition>
      </Dialog>
    </div>
  </div>
</template>

<script setup>
import TemplateBuilder from "../builders/TemplateBuilder.vue";
import { Templating } from "../mixins/Templating";
import { Schema } from "../mixins/Schema";
import { Scheduling } from "../mixins/Scheduling";
import { Applicators } from "../mixins/Applicators";
import { Statusing } from "../mixins/Statusing";
</script>
<script>
export default {
  mixins: [Templating, Statusing, Scheduling, Schema, Applicators],
  components: { TemplateBuilder },
  data() {
    return {
      showBuilder: false,
      resizeObserver: null,
      showTemplateBuilder: false,
      selectedTemplatesInTemplateBuilder: [],
      delayPushSelectedTemplatesInterval: null,
    };
  },
  mounted() {
    this.$store.dispatch("retrieveTemplates").then(() => {
      //   if (this.templates.find((t) => t.name === "Inbox"))
      //     this.selected.push(this.templates.find((t) => t.name === "Inbox").id);
      // if (this.templates.find((t) => t.name === "Daily Tasks List"))
      //   this.selected.push(
      //     this.templates.find((t) => t.name === "Daily Tasks List").id,
      //   );
      //   if (this.templates.find((t) => t.name === "Task Board"))
      //     this.selectedTemplates.push(
      //       this.templates.find((t) => t.name === "Task Board").id,
      //     );
    });
    setTimeout(() => {
      this.showBuilder = true;
      this.$nextTick(() => {
        this.initResizeObserver();
      });
    }, 1500 * 2);
  },
  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },

    recommendations() {
      return this.templatesAvailable.slice(0, this.recommendationsLimit);
    },
    recommendationsLimit() {
      switch (this.$store.getters.widthSize) {
        case "lg":
        case "xl":
          return 4;
        case "sm":
          return 2;
        case "md":
          return 3;
        case "xs":
        default:
          return 3;
      }
    },
  },
  watch: {
    space() {
      if (this.entries !== null && this.entries.length > 0) {
        // console.log("Onboarding watch space: entries found, redirecting...");
        this.$router.push({ name: "home" });
      }
    },
    "entries.length": function () {
      if (this.entries.length > 0) {
        // console.log(
        //   // "Onboarding watch entries length: entries found, redirecting...",
        // );
        // console.log(
        //   "Onboarding watch entries length: entries found, redirecting...",
        // );
        // this.showBuilder = false;
        // setTimeout(() => {
        this.$router.push({
          name: "entry",
          params: { id: this.entries[0].id },
        });
        // }, 500);
      }
    },
  },
  methods: {
    animationEnter(el, done) {
      this.$anime({
        delay: (el) => el.dataset.delay,
        targets: el,
        duration: 2000,
        easing: "easeOutExpo",
        translateX: [100, 0],
        opacity: [0, 1],
        // delay: 600,
        complete: done,
      });
    },

    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          // console.log("Height changed:", newHeight);
          // this.$anime({
          //   targets: this.$refs.outer,
          //   height: newHeight,
          //   easing: "easeOutExpo",
          // });
          if (this.$refs.outer)
            this.$refs.outer.$el.style.height = `${newHeight}px`;
        }
      });
      if (this.$refs.inner) this.resizeObserver.observe(this.$refs.inner);
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
    handleCloseTemplateBuilder() {
      this.delayPushSelectedTemplatesInterval = setInterval(() => {
        if (this.showTemplateBuilder === false) {
          if (this.selectedTemplatesInTemplateBuilder.length > 0) {
            this.selectedTemplates = [
              ...new Set([
                ...this.selectedTemplates,
                ...[this.selectedTemplatesInTemplateBuilder[0]],
              ]),
            ];
            this.selectedTemplatesInTemplateBuilder =
              this.selectedTemplatesInTemplateBuilder.slice(1);

            if (this.selectedTemplatesInTemplateBuilder.length === 0) {
              clearInterval(this.delayPushSelectedTemplatesInterval);
            }
          }
        } else {
          clearInterval(this.delayPushSelectedTemplatesInterval);
        }
      }, 200);

      this.showTemplateBuilder = false;
    },
    handleTemplateBuilderClickTemplate(template) {
      this.$nextTick(() => {
        this.$refs.template_builder.selectedTemplate = null;
      });
      if (this.selectedTemplatesInTemplateBuilder.includes(template.id)) {
        this.selectedTemplatesInTemplateBuilder.splice(
          this.selectedTemplatesInTemplateBuilder.indexOf(template.id),
          1,
        );
      } else {
        this.selectedTemplatesInTemplateBuilder.push(template.id);
      }
    },
    animationEnterIsSelectedInTemplateBuilder(el, done) {
      this.$anime({
        targets: el,
        translateY: [10, 0],
        opacity: [0, 1],
        complete: done,
      });
    },
    animationLeaveIsSelectedInTemplateBuilder(el, done) {
      this.$anime({
        targets: el,
        translateY: [0, 10],
        opacity: [1, -0.2],
        complete: done,
      });
    },
  },
};
</script>
