<template>
  <div class="flex flex-col gap-5" data-test="output_builder">
    <!-- {{ children.map((e) => e.name) }} -->
    <div class="flex flex-col gap-2 divide-y dark:divide-neutral-700">
      <div class="relative">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div
            class="w-full border-t border-neutral-300 dark:border-neutral-700"
          />
        </div>
        <div class="relative flex items-center justify-between">
          <span
            class="bg-white pr-2 text-xs uppercase text-neutral-500 dark:bg-black"
          >
            Filters
          </span>
          <div class="flex gap-2 bg-white pl-2 dark:bg-black">
            <a href="https://help.pensive.io/senses" target="_blank">
              <QuestionMarkCircleIcon class="h-5 w-5" />
            </a>
          </div>
        </div>
      </div>
      <fieldset
        v-for="(o, k) in filters"
        :key="k"
        class="flex flex-col flex-wrap gap-2 pt-2 first:pt-0 md:flex-row md:flex-nowrap"
        data-test="output_builder_row"
      >
        <OptionPicker
          v-model="o.key"
          :options="keys"
          :ref="'key_select_' + k"
          @update:modelValue="
            (key) => {
              o.key = key;
              $emit('update:modelValue', sense);
            }
          "
          color="secondary"
          size="sm"
        />

        <OptionPicker
          v-if="o.key || o.op"
          v-model="o.op"
          :options="ops(o.key)"
          :render="(val) => opToSign(val)"
          @update:modelValue="
            (op) => {
              o.op = op;
              $emit('update:modelValue', sense);
            }
          "
          color="secondary"
          size="sm"
        />

        <component
          v-if="
            vals(o.key, o.op).length &&
            ['DatePicker', 'TimePicker'].includes(vals(o.key, o.op)[0])
          "
          :is="vals(o.key, o.op)[0]"
          v-bind="vals(o.key, o.op)[1]"
          v-model="o.val[0]"
          @update:modelValue="
            (val) => {
              o.val = [val];
              $emit('update:modelValue', sense);
            }
          "
          class="rounded border px-2 py-1 text-xs dark:border-neutral-700"
          color="secondary"
          size="sm"
          data-test="output_builder_val"
        />
        <component
          v-if="
            vals(o.key, o.op).length &&
            !['DatePicker', 'TimePicker'].includes(vals(o.key, o.op)[0])
          "
          :is="vals(o.key, o.op)[0]"
          v-bind="vals(o.key, o.op)[1]"
          v-model="o.val"
          @update:modelValue="
            (val) => {
              o.val = val;
              $emit('update:modelValue', sense);
            }
          "
          :class="{
            'rounded border px-2 py-1 text-xs dark:border-neutral-700':
              vals(o.key, o.op)[0] == 'LinksBuilder',
          }"
          color="secondary"
          size="sm"
          data-test="output_builder_val"
        />
        <ButtonComponent
          @click="filters.splice(k, 1), $emit('update:modelValue', sense)"
          color="secondary"
          size="sm"
          class="ml-auto rounded border p-1 dark:border-neutral-700"
          data-test="output_builder_delete_row"
        >
          <TrashIcon class="h-4 w-4" />
        </ButtonComponent>
      </fieldset>
      <div class="pt-2">
        <ButtonComponent
          @click.stop="
            sense.filters.push({ key: null, op: null, val: [] }),
              $emit('update:modelValue', sense)
          "
          color="secondary"
          class="rounded border p-1 dark:border-neutral-700"
        >
          <PlusSmallIcon class="h-4 w-4" /> Add
        </ButtonComponent>
      </div>

      <PopoverHelper class="pt-2">
        <template #button>
          <ButtonComponent color="secondary">
            <MagnifyingGlassIcon class="h-4 w-4" />
            {{ filterEntries(filters).length }} Entries
          </ButtonComponent>
        </template>
        <div class="min-w-64 p-2">
          <OutputDisplay
            v-model="output"
            :entry="entry"
            :display="{
              name: true,
              description: false,
              schedule: false,
              status: false,
              routine: false,
              priority: false,
              procrastination: false,
              output: false,
            }"
            :editable="false"
            class="min-w-min"
          />
        </div>
      </PopoverHelper>
    </div>

    <div class="flex flex-col gap-2 divide-y dark:divide-neutral-700">
      <div class="flex flex-col gap-x-2 text-xs dark:text-neutral-400">
        <span><strong>Possible Values:</strong></span>
        <span v-for="(key, index) in options" :key="index" class="uppercase">
          {{ keyToHtml(key) }}:
          {{
            filterEntries(filters)
              .map((e) => (key == "count" ? 1 : $resolveField(e, key) || 0))
              .reduce((a, b) => a + b, 0)
          }}
          {{ getUnit(key) }}</span
        >
      </div>
      <fieldset
        v-for="(o, k) in sense.aggregates"
        :key="k"
        class="flex flex-col flex-wrap gap-2 pt-2 first:pt-0 md:flex-row md:flex-nowrap md:items-center"
        data-test="output_builder_row"
      >
        <div class="flex items-center gap-2">
          <span
            :class="[
              'bg-' +
                (getAggregatedResults([o], children).filter((a) => !a.ok).length
                  ? 'red'
                  : 'green') +
                '-400',
              'inline-block h-2 w-2 flex-shrink-0 rounded-full',
            ]"
            class="order-last ml-auto md:order-first md:ml-0"
            aria-hidden="true"
          />
          <OptionPicker
            v-model="o.key"
            :options="options"
            :ref="'key_select_' + k"
            @update:modelValue="
              (key) => {
                o.key = key;
                $emit('update:modelValue', sense);
              }
            "
            color="secondary"
          />
        </div>
        <OptionPicker
          v-if="o.key || o.op"
          v-model="o.op"
          :options="
            ops(o.key) /*.filter((op) =>
              ['gt', 'gte', 'eq', 'neq', 'lte', 'lt'].includes(op),
            )
              */
          "
          :render="(val) => opToSign(val)"
          @update:modelValue="
            (op) => {
              o.op = op;
              $emit('update:modelValue', sense);
            }
          "
          color="secondary"
        />

        <component
          v-if="
            vals(o.key, o.op).length &&
            ['DatePicker', 'TimePicker'].includes(vals(o.key, o.op)[0])
          "
          :is="vals(o.key, o.op)[0]"
          v-bind="vals(o.key, o.op)[1]"
          v-model="o.val[0]"
          @update:modelValue="
            (val) => {
              o.val = [val];
              $emit('update:modelValue', sense);
            }
          "
          color="secondary"
          data-test="output_builder_val"
          class="rounded border px-2 py-1 text-xs dark:border-neutral-700"
        />
        <component
          v-if="
            vals(o.key, o.op).length &&
            !['DatePicker', 'TimePicker'].includes(vals(o.key, o.op)[0])
          "
          :is="vals(o.key, o.op)[0]"
          v-bind="vals(o.key, o.op)[1]"
          v-model="o.val"
          @update:modelValue="
            (val) => {
              o.val = val;
              $emit('update:modelValue', sense);
            }
          "
          data-test="output_builder_val"
          :class="{
            'rounded border px-2 py-1 text-xs dark:border-neutral-700':
              vals(o.key, o.op)[0] == 'LinksBuilder',
          }"
          color="secondary"
        />
        <ButtonComponent
          @click="
            [sense.aggregates.splice(k, 1), $emit('update:modelValue', sense)]
          "
          class="ml-auto rounded border p-1 dark:border-neutral-700"
          data-test="output_builder_delete_row"
          color="secondary"
        >
          <TrashIcon class="h-4 w-4" />
        </ButtonComponent>
      </fieldset>

      <div class="pt-2">
        <ButtonComponent
          @click.stop="
            aggregates.push({ key: null, op: null, val: [] }),
              $emit('update:modelValue', sense)
          "
          color="secondary"
          class="rounded border p-1 dark:border-neutral-700"
        >
          <PlusSmallIcon class="h-4 w-4" /> Add
        </ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
import { Scheduling } from "../mixins/Scheduling";
import { defineAsyncComponent } from "vue";

import {
  AdjustmentsHorizontalIcon,
  MagnifyingGlassIcon,
  PlusSmallIcon,
  TrashIcon,
} from "@heroicons/vue/24/outline";
import { Statusing } from "../mixins/Statusing";
import { Filtering } from "../mixins/Filtering";
// import Output from "../addons/Output.vue";
import { Aggregate } from "../mixins/Aggregate";

export default {
  mixins: [Filtering, Scheduling, Statusing, Aggregate],
  props: {
    modelValue: Object,
    entry: Object,
  },
  components: {
    OptionPicker: defineAsyncComponent(() =>
      import("@/components/builders/OptionPicker.vue"),
    ),
    DatePicker: defineAsyncComponent(() =>
      import("@/components/builders/DatePicker.vue"),
    ),
    TimePicker: defineAsyncComponent(() =>
      import("@/components/builders/TimePicker.vue"),
    ),
    LinksBuilder: defineAsyncComponent(() =>
      import("@/components/links/partials/LinksBuilder.vue"),
    ),
    StatusBuilder: defineAsyncComponent(() =>
      import("@/components/builders/StatusBuilder.vue"),
    ),
    TextPicker: defineAsyncComponent(() =>
      import("@/components/builders/TextPicker.vue"),
    ),
    AdjustmentsHorizontalIcon,
    TrashIcon,
    PlusSmallIcon,
    MagnifyingGlassIcon,
  },
  data() {
    return {
      showLinkSearch: false,
      options: ["count", ["schedule", "duration"]],
    };
  },
  computed: {
    sense: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    children() {
      return this.filterEntries(this.filters);
    },
    filters() {
      return this.sense.filters;
    },
    output() {
      return { data: this.sense.filters };
    },
    aggregates() {
      return this.sense.aggregates;
    },
    statuses() {
      return this.getOwnAvailableStatuses(this.entry, true);
    },
    validatedValue() {
      return this.value;
    },
  },
};
</script>
