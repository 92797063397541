import moment from "moment";
import { nanoid } from "nanoid";

export function buildProject(demo) {

  const project_id = nanoid();
  demo.addEntry({
    id: project_id,
    name: "Hobby Project",
    description: {
      content: "Anything about your hobby: <HOBBY>\nLearn more about this in the [Best Practices](https://pensive.io/best-practices#tracking-list)",
    },
    output: {
      data: [{ key: "links", op: "any", val: [project_id] }],
    },
    input: {
      data: {
        links: [project_id],
      },
    },
    anchor: {
      position: demo.entries.filter((e) => e.anchor).length,
    },
  });

  
  demo.addEntry({
    name: "Build Chair",
    completion: {
      completed: false,
      completed_at: null,
    },
    schedule: {
      date: moment().utc().add(2, 'days').format("YYYY-MM-DD"),
      time: demo.time("17:00:00"),
    },
    links: [demo.e("Hobby Project").id],
  });

  
  demo.addEntry({
    name: "Clean up Garage",
    completion: {
      completed: false,
      completed_at: null,
    },
    schedule: {
      date: moment().utc().add(-2, 'days').format("YYYY-MM-DD"),
      time: demo.time("17:00:00"),
    },
    links: [demo.e("Hobby Project").id],
  });
  demo.addEntry({
    name: "Build Tree House",
    completion: {
      completed: true,
      completed_at: moment.utc().add(-35, "days").format("YYYY-MM-DD 18:00:00"),
    },
    schedule: {
      date: moment().utc().add(-35, 'days').format("YYYY-MM-DD"),
      time: demo.time("17:30:00"),
    },
    links: [demo.e("Hobby Project").id],
  });
  demo.addEntry({
    name: "Remember that paint is almost empty",
    links: [demo.e("Hobby Project").id],
  });
}
