<template>
  <div>
    <PopoverHelper>
      <template #button>
        <ButtonComponent
          :size="size"
          :variant="variant"
          :color="color"
          title="Group Output"
        >
          <Square3Stack3DIcon class="h-4 w-4" />
          <span v-if="variant != 'minimal'" class="hidden md:block">
            Group {{ modelValue?.field ? ":" : null }}
            {{
              modelValue?.field
                ? modelValue.field.charAt(0).toUpperCase() +
                  modelValue.field.slice(1).replace("_", " ")
                : null
            }}
          </span>

          <ChevronDownIcon
            v-if="variant != 'minimal'"
            class="h-4 w-4 text-neutral-300 transition duration-150 ease-in-out group-hover:text-neutral-300/80"
            aria-hidden="true"
          />
        </ButtonComponent>
      </template>
      <div class="relative grid min-w-max gap-8 p-7 lg:grid-cols-1">
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', null)"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="grouping_button_none"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
          >
            <Bars4Icon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-300">
              None
            </p>
            <p class="text-sm text-gray-500 dark:text-neutral-400">
              No grouping, entries are rendered as they come
            </p>
          </div>
        </PopoverButton>
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', { field: 'status', dir: 1 })"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="grouping_button_status"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
          >
            <CheckCircleIcon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-300">
              Status
            </p>
            <p class="text-sm text-gray-500 dark:text-neutral-400">
              Entries are grouped by status
            </p>
          </div>
        </PopoverButton>
        <PopoverButton
          as="a"
          @click="$emit('update:modelValue', { field: 'schedule', dir: 1 })"
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="grouping_button_schedule"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
          >
            <CalendarDaysIcon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-300">
              Schedule · Date
            </p>
            <p class="text-sm text-gray-500 dark:text-neutral-400">
              Entries are grouped by schedule date
            </p>
          </div>
        </PopoverButton>
        <PopoverButton
          as="a"
          @click="
            $emit('update:modelValue', { field: 'schedule_time', dir: 1 })
          "
          class="-m-3 flex cursor-pointer items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500/50 dark:hover:bg-neutral-900"
          data-test="grouping_button_schedule_time"
        >
          <div
            class="flex h-10 w-10 shrink-0 items-center justify-center text-neutral-500 sm:h-12 sm:w-12 dark:text-neutral-300"
          >
            <CalendarDaysIcon class="h-5 w-5" />
          </div>
          <div class="ml-4">
            <p class="text-sm font-medium text-gray-900 dark:text-neutral-300">
              Schedule · Time
            </p>
            <p class="text-sm text-gray-500 dark:text-neutral-400">
              Entries are grouped by schedule time
            </p>
          </div>
        </PopoverButton>
      </div>
      <div v-if="modelValue?.field == 'status'" class="bg-gray-50 p-4">
        <a
          @click="
            $emit('update:modelValue', {
              field: modelValue.field,
              dir: modelValue.dir * -1,
            })
          "
          class="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
        >
          <span class="flex items-center">
            <span class="text-sm font-medium text-gray-900">
              Sort {{ modelValue.dir === 1 ? "Ascending" : "Descending" }}
            </span>
          </span>
          <!-- <span class="block text-sm text-gray-500">
                  Start integrating products and tools
                </span> -->
        </a>
        <!-- <a
                  href="##"
                  class="flow-root rounded-md px-2 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/50"
                >
                  <span class="flex items-center">
                    <span class="text-sm font-medium text-gray-900">
                      Documentation
                    </span>
                  </span>
                  <span class="block text-sm text-gray-500">
                    Start integrating products and tools
                  </span>
                </a> -->
      </div>
    </PopoverHelper>
  </div>
</template>
<script setup>
import { PopoverButton } from "@headlessui/vue";
import {
  Bars4Icon,
  CalendarDaysIcon,
  CheckCircleIcon,
  Square3Stack3DIcon,
} from "@heroicons/vue/24/outline";

import { ChevronDownIcon } from "@heroicons/vue/20/solid";
</script>
<script>
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  props: {
    options: Array,
  },
};
</script>
