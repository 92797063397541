<script setup>
import { computed, defineProps } from "vue";
import * as icons from "lucide-vue-next";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: null,
    default: 24,
  },
  color: String,
  strokeWidth: {
    type: null,
    default: 2,
  },
  defaultClass: String,
});

const icon = computed(
  () =>
    icons[
      props.name.charAt(0).toUpperCase() +
        props.name
          .slice(1)
          .replace(/-([a-z0-9])/g, (match, letter) => letter.toUpperCase())
    ],
);
</script>

<template>
  <!-- <div>
    {{
      props.name.charAt(0).toUpperCase() +
      props.name
        .slice(1)
        .replace(/-([a-z0-9])/g, (match, letter) => letter.toUpperCase())
    }} -->

  <!-- :color="color" -->
  <component
    :is="icon"
    :size="size"
    :class="`text-${color}-500`"
    :stroke-width="strokeWidth"
    :default-class="defaultClass"
  />
  <!-- </div> -->
</template>
