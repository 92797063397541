import { getDateSpan } from "@/datesHelper";
import { addonBlueprint } from "@/addonBlueprint";

import moment from "moment";

export const Routining = {
  computed: {
    routine() {
      return this.entry.routine;
    },
  },
  methods: {
    getSlots(frequency) {

      switch (frequency) {
        case "daily":
          return Array.from({ length: 7 }, (_, i) => {
            const span_at = getDateSpan(
              this.routine.frequency,
              moment().add(-i, "days"),
            );

            return {
              date: moment().add(-i, "days").format("YYYY-MM-DD"),
              log: this.getLogBySpan({ entry: this.entry, span_at }),
              fn: () => this.logASpan({ entry: this.entry, span_at: span_at, frequency: frequency }),
            };
          }).reverse();
        case "weekly":
          return Array.from({ length: 4 }, (_, i) => {
            const span_at = getDateSpan(
              frequency,
              moment()/*.add(-1, "day")*/.add(-i, "week"),
            );
            return {
              date:
                moment().add(-i, "weeks").startOf("week").format("YYYY-MM-DD") +
                " - " +
                moment().add(-i, "weeks").endOf("week").format("YYYY-MM-DD"),
              log: this.getLogBySpan({ entry: this.entry, span_at }),
              fn: () => this.logASpan({ entry: this.entry, span_at: span_at, frequency: frequency }),
            };
          }).reverse();
        case "monthly":
          return Array.from({ length: 3 }, (_, i) => {
            const span_at = getDateSpan(
              frequency,
              moment().add(-i, "month"),
            );

            return {
              date:
                moment()
                  .add(-i, "month")
                  .startOf("month")
                  .format("YYYY-MM-DD") +
                " - " +
                moment().add(-i, "month").endOf("month").format("YYYY-MM-DD"),
              log: this.getLogBySpan({ entry: this.entry, span_at }),
              fn: () => this.logASpan({ entry: this.entry, span_at: span_at, frequency: frequency }),
            };
          }).reverse();
      }
      return [];
    },
    getEndsAt(starts_at, frequency) {
      switch (frequency) {
        case "daily":
          return starts_at;
        case "weekly":
          return moment(starts_at).add(6, "days").format("YYYY-MM-DD");
        case "monthly":
          return moment(starts_at).add(1, "months").format("YYYY-MM-DD");
      }
    },
    getLogBySpan({ entry, span_at }) {
      return entry.routine.logs.find(
        (log) =>
          moment(log.span_at).isSame(span_at, "day"),
      );
    },
    logASpan({ entry, span_at, frequency, force = false } = {}) {
      var log = this.getLogBySpan({ entry: entry, span_at: span_at });
      if (!log) {
        log = addonBlueprint("routine_log", entry);
        log.span_at = span_at;
        entry.routine.logs.push(log);
      }
      if (log.completed === null) {
        if (!force && moment.utc().isBetween(moment(span_at + ' 00:00:00'), moment(this.getEndsAt(span_at, frequency) + ' 23:59:59'), 'days', '[]')) {
          entry.routine.value++;
          if (entry.routine.value >= entry.routine.target) {
            entry.routine.value = 0;
            log.completed = 1;
            log.completed_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
          }
        } else {
          log.completed = 1
          log.completed_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
        }
      } else if (log.completed === 1) {
        log.completed = 0;
        log.completed_at = null;
      } else {
        log.completed = null;
        log.completed_at = null;
      }

      if (force) entry.routine.value = 0;

      // this.$store.dispatch("push", {
      //   event: "routine_update",
      //   params: { routine: entry.routine },
      //   routine: entry.routine,
      //   entry: entry,
      // });
    },
    handleRoutineCheck(entry) {
      const span_at = getDateSpan(entry.routine.frequency, entry.schedule?.date)
      var log = this.getLogBySpan({ entry: entry, span_at: span_at });
      if (!log || log.completed !== 1) {
        this.logASpan({ entry: entry, span_at: span_at, frequency: entry.routine.frequency });
      }
    }
  },
};
