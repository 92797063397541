import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";

export const Auth = {
    data() {
        return {
            message: null,
            loading: false,
        };
    },
    methods: {
        login() {
            if (!this.formData.email || !this.formData.password) {
                this.message = { type: 'warning', message: "Please fill in all fields" };
                return;
            }
            this._trackEvent('Authentication', 'Login', 'Start')
            this.loading = true;
            this.$store
                .dispatch("retrieveToken", {
                    email: this.formData.email,
                    password: this.formData.password,
                })
                .then(() => {
                    this._trackEvent('Authentication', 'Login', 'Success')
                    this.loading = false;
                    this.$router.push({ name: "home" });
                })
                .catch((response) => {
                    this._trackEvent('Authentication', 'Login', 'Error')
                    this.loading = false;
                    if (typeof response == "undefined") {
                        this.message = { type: 'warning', message: "The Server is currently under maintanance." };
                    } else {
                        this.message = { type: 'error', message: "The user credentials were incorrect." };
                    }

                });
        },
        loginWithProvider(provider) {
            this._trackEvent('Authentication', 'Login With Provider', 'Start')
            this.loading = true;
            this.$store
                .dispatch("retrieveTokenProvider", {
                    provider: provider,
                })
                .then((response) => {
                    this._trackEvent('Authentication', 'Login With Provider', 'Success')
                    this.loading = false;
                    if (response.data.url) {
                        window.location.href = response.data.url;
                    } else {
                        this.message = { type: 'error', message: "An error appeared, please contact the support." };
                    }
                })
                .catch((response) => {
                    this._trackEvent('Authentication', 'Login With Provider', 'Error')
                    this.loading = false;
                    if (typeof response == "undefined") {
                        this.message = { type: 'warning', message: "The Server is currently under maintanance." };
                    } else {
                        this.message = { type: 'error', message: "The user credentials were incorrect." };
                    }
                });
        },
        register() {


            if (
                !this.formData.firstname ||
                !this.formData.lastname ||
                !this.formData.email ||
                !this.formData.password ||
                !this.formData.password_confirmation
            ) {
                this.message = { type: 'warning', message: "Please fill in all fields" };
                return;
            }

            this._trackEvent('Authentication', 'Register', 'Start')
            this.loading = true;
            if (this.formData.password != this.formData.password_confirmation) {
                this.message = { type: 'error', message: "The passwords don't match" };
                return;
            }
            this.$store
                .dispatch("register", {
                    firstname: this.formData.firstname,
                    lastname: this.formData.lastname,
                    email: this.formData.email,
                    password: this.formData.password,
                    password_confirmation: this.formData.password_confirmation,
                    timezone: moment.tz.guess(),
                })
                .then(() => {
                    this._trackEvent('Authentication', 'Register', 'Success')
                    this.$store
                        .dispatch("retrieveToken", {
                            email: this.formData.email,
                            password: this.formData.password,
                        })
                        .then(() => {
                            this.$router.push({ name: "home" });
                            this.$store.dispatch("timestamp");
                        })
                        .catch(() => {
                            this.loading = false;
                            this.message = { type: "error", message: "An error appeared" };
                        });
                })
                .catch((error) => {
                    this._trackEvent('Authentication', 'Register', 'Error')
                    this.loading = false;
                    this.message = { type: 'error', message: error.data.message };
                });
        },
        forgot_password() {

            if (!this.formData.email) {
                this.message = { type: 'warning', message: "Please fill in all fields" };
                return;
            }
            this._trackEvent('Authentication', 'Forgot Password', 'Start')
            this.loading = true;
            this.$store
                .dispatch("forgotPassword", {
                    email: this.formData.email,
                })
                .then((response) => {
                    this._trackEvent('Authentication', 'Forgot Password', 'Success')
                    this.loading = false;
                    this.message = { type: 'success', message: response?.data?.status };
                })
                .catch(() => {
                    this._trackEvent('Authentication', 'Forgot Password', 'Error')
                    this.loading = false;
                    this.message = { type: 'error', message: "An error appeared. Please try again later." };
                });
        },
        reset_password() {
            console.log("LEL??", this.formData)
            if (
                !this.formData.password ||
                !this.formData.password_confirmation
            ) {
                console.log("WAS ZU HÖLLE")
                this.message = { type: 'warning', message: "Please fill in all fields" };
                return;
            }
            if (this.formData.password != this.formData.password_confirmation) {
                this.message = { type: 'error', message: "The passwords don't match" };
                return;
            }
            console.log("HWAT?")
            this._trackEvent('Authentication', 'Reset Password', 'Start')
            this.loading = true;
            this.$store
                .dispatch("resetPassword", {
                    email: this.$route.query.email,
                    token: this.$route.params.token,
                    password: this.formData.password,
                    password_confirmation: this.formData.password_confirmation,
                })
                .then((response) => {
                    if (response.status === 200) {
                        this._trackEvent('Authentication', 'Reset Password', 'Success')
                        const isError = response?.data?.status == 'This password reset token is invalid.'
                        this.message = { type: isError ? 'error' : 'success', message: response?.data?.status };
                        this.loading = false;
                        if (!isError)
                            setTimeout(() => {
                                this.$router.push({ name: "login" });
                            }, 2000)
                    } else {
                        this._trackEvent('Authentication', 'Reset Password', 'Error')
                        this.loading = false;
                        this.message = { type: 'error', message: "Something went wrong." };
                    }
                })
                .catch((error) => {
                    this._trackEvent('Authentication', 'Reset Password', 'Error')
                    this.loading = false;
                    this.message = { type: 'error', message: error?.data?.message };
                });
        },
    }
};
