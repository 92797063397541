<template>
  <div
    class="relative flex h-48 w-full flex-col items-center justify-center rounded-lg ring ring-neutral-100"
  >
    <IconHelper
      name="layout-list"
      size="96"
      strokeWidth="1"
      class="text-neutral-200 dark:text-neutral-800"
    />
    <div class="text-lg font-medium text-neutral-300 dark:text-neutral-700">
      Nothing to show here
    </div>
  </div>
</template>

<script>
export default {};
</script>
