<template>
  <MenuItem
    v-for="(priority, index) in priorities"
    :key="index"
    v-slot="{ active }"
  >
    <a
      @click="
        $emit('update:modelValue', {
          ...modelValue,
          ...{ level: priority },
        })
      "
      :class="[
        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        'block px-4 py-2 text-sm',
      ]"
      :data-test="'set_priority_' + priority"
    >
      Priority {{ priority }}
    </a>
  </MenuItem>
  <MenuItem v-slot="{ active }">
    <a
      @click="$emit('update:modelValue', null)"
      :class="[
        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
        'block px-4 py-2 text-sm',
      ]"
    >
      No Priority
    </a>
  </MenuItem>
</template>

<script>
export default {
  props: {
    modelValue: Object,
  },
  data() {
    return {
      priorities: [1, 2, 3, 4],
    };
  },
  computed: {
    priority: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
