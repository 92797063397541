<template>
  <ul v-if="toasts.length" class="z-[999999]">
    <li v-for="toast in toasts" :key="toast.id">
      {{ toast.message }}
    </li>
  </ul>
</template>

<script>
import { nanoid } from "nanoid";
export default {
  name: "ToastsComponent",
  watch: {
    "toasts.length": function () {
      this.toasts
        .filter((t) => !t.id)
        .forEach((t) => {
          const id = nanoid();
          t.id = id;
          setTimeout(() => {
            this.toasts.splice(
              this.toasts.findIndex((t) => t.id == id),
              1,
            );
          }, 5000);
        });
    },
  },
  computed: {
    toasts() {
      return this.$store.getters.toasts;
    },
  },
};
</script>

<style scoped>
ul {
  pointer-events: none;
  position: fixed;
  left: 0;
  bottom: 1rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
ul > li {
  background: var(--color-dark-gray);
  color: var(--color-white);
  padding: 1rem 2rem;
  font-size: var(--font-size-small);
  text-transform: uppercase;
  border-radius: 4px;
}
</style>
