<template>
  <div>
    {{ entry.name }}
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Object,
  },
  computed: {
    entry: {
      get() {
        return this.modelValue;
      },
      set() {
        //this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
