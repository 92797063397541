<template>
  <div v-if="anchor" class="flex flex-wrap gap-2">
    <button
      @click="moveAnchorLeft"
      v-if="
        entries.find((e) => e.anchor?.position == entry.anchor.position - 1)
      "
      role="button"
      data-test="entry_anchor_move_left"
    >
      <slot name="button_anchor_move_up">
        <ButtonComponent
          :variant="variant"
          :size="size"
          :color="color"
          title="Move up"
        >
          <IconHelper name="ArrowUp" size="20" />
          <span v-if="variant != 'round'"> Move up </span>
        </ButtonComponent>
      </slot>
    </button>

    <button
      @click="moveAnchorRight"
      v-if="
        entries.find((e) => e.anchor?.position == entry.anchor.position + 1)
      "
      role="button"
      data-test="entry_anchor_move_right"
    >
      <slot name="button_anchor_move_down">
        <ButtonComponent
          :variant="variant"
          :size="size"
          :color="color"
          title="Move down"
        >
          <IconHelper name="ArrowDown" size="20" />
          <span v-if="variant != 'round'"> Move down </span>
        </ButtonComponent>
      </slot>
    </button>
    <button
      role="button"
      @click="(anchor = null), fixPositions()"
      data-test="entry_anchor_delete"
    >
      <slot name="button_delete">
        <ButtonComponent
          :variant="variant"
          :size="size"
          :color="color"
          :colorWeight="colorWeight"
          title="Unmark as Favorite"
        >
          <IconHelper name="Heart" size="20" />
          <span v-if="variant != 'round'"> Unmark as Favorite </span>

          <ButtonComponent
            v-if="variant == 'round'"
            :variant="variant"
            size="xs"
            color="red"
            colorWeight="500"
            class="!absolute right-0 top-0 -translate-y-1/3 translate-x-1/3 hover:bg-red-600"
          >
            <XMarkIcon class="h-3 w-3" />
          </ButtonComponent>
        </ButtonComponent>
      </slot>
    </button>
  </div>
  <div v-else>
    <button @click="anchor = newAnchor()">
      <slot name="button_create">
        <ButtonComponent
          :variant="variant"
          :size="size"
          :color="color"
          :colorWeight="colorWeight"
          title="Mark as Favorite"
          data-test="entry_anchor_create"
        >
          <IconHelper name="Heart" size="20" />
          <span v-if="variant != 'round'"> Mark as Favorite </span>
        </ButtonComponent>
      </slot>
    </button>
  </div>
</template>

<script>
import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "../mixins/Addon";

export default {
  emits: ["delete"],
  mixins: [Addon],
  computed: {
    anchor: {
      get() {
        return this.modelValue;
      },
      set(anchor) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.anchor = anchor;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
          undo: false,
        });
      },
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },
  },
  methods: {
    newAnchor() {
      return addonBlueprint("anchor", this.entry, this);
    },
    fixPositions() {
      this.entries
        .filter((e) => e.anchor)
        .sort((a, b) => a.anchor.position - b.anchor.position)
        .forEach((e, i) => {
          if (e.anchor.position != i) {
            e.anchor.position = i;
            if (!e.temp)
              this.$store.dispatch("push", {
                event: "anchor_update",
                params: { anchor: e.anchor },
                entry: e,
                undo: true,
              });
          }
          // e.anchor.position = i + 1;
        });
    },
    moveAnchorLeft() {
      let leftEntry = this.entries.find(
        (e) => e.anchor?.position == this.entry.anchor.position - 1,
      );
      leftEntry.anchor.position++;
      this.anchor.position--;

      this.fixPositions();

      if (!this.entry.temp)
        this.$store.dispatch("push", {
          event: "anchor_update",
          params: { anchor: this.entry.anchor },
          entry: this.entry,
          undo: true,
        });
      if (!leftEntry.temp)
        this.$store.dispatch("push", {
          event: "anchor_update",
          params: { anchor: leftEntry.anchor },
          entry: leftEntry,
          undo: true,
        });
    },
    moveAnchorRight() {
      let rightEntry = this.entries.find(
        (e) => e.anchor?.position == this.entry.anchor.position + 1,
      );
      rightEntry.anchor.position--;
      this.anchor.position++;

      this.fixPositions();

      if (!this.entry.temp)
        this.$store.dispatch("push", {
          event: "anchor_update",
          params: { anchor: this.entry.anchor },
          entry: this.entry,
          undo: true,
        });
      if (!rightEntry.temp)
        this.$store.dispatch("push", {
          event: "anchor_update",
          params: { anchor: rightEntry.anchor },
          entry: rightEntry,
          undo: true,
        });
    },
  },
};
</script>
