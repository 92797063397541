import moment from "moment";
import { nanoid } from "nanoid";

export function buildBusiness(demo) {
  var id = nanoid();
  demo.addEntry({
    id: id,
    name: "Business",
    description: {
      content: "Things that need to be done for the job.\nLearn more about this in the [Best Practices](https://pensive.io/best-practices#tracking-list)",
    },
    output: {
      data: [
        { key: "links", op: "any", val: [id] },
        { key: "name", op: "neq", val: ["Routine"] },
      ],
    },
    input: {
      data: {
        completion: { completed: false, completed_at: null },
        links: [id],
      },
    },
    anchor: {
      position: demo.entries.filter((e) => e.anchor).length,
    },
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Send Update to Team",
    completion: {
      completed: false,
      completed_at: null,
    },
    schedule: {
      date: moment.utc().format("YYYY-MM-DD"),
      time: demo.time("11:30:00"),
    },
    links: [demo.e("Business").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Check Finances",
    completion: {
      completed: true,
      completed_at:
        moment.utc().add(-32, "days").format("YYYY-MM-DD") + " 15:00:00",
    },
    schedule: {
      date: moment.utc().add(-32, "days").format("YYYY-MM-DD"),
      time: demo.time("11:30:00"),
    },
    links: [demo.e("Business").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Read Documentation",
    completion: {
      completed: false,
      completed_at: null,
    },
    links: [demo.e("Business").id],
  });

  id = nanoid();
  demo.addEntry({
    id: id,
    name: "Contact Sales Manager",
    completion: {
      completed: false,
      completed_at: null,
    },
    links: [demo.e("Business").id],
  });
}
