import moment from "moment";
import { nanoid } from "nanoid";
import { buildHousehold } from "./demos/Household";

import { buildInbox } from "./demos/Inbox";
import { buildLogics } from "./demos/Logics";
import { buildMoreViews } from "./demos/MoreViews";
import { buildProject } from "./demos/Project";
import { buildRoutines } from "./demos/Routines";
import { buildSelfCare } from "./demos/SelfCare";
import { buildToday } from "./demos/Today";
import { buildBusiness } from "./demos/Business";
import { buildPriorization } from "./demos/Priorization";
import { buildCalendars } from "./demos/Calendars";
import { createTour } from "./demos/onboarding/Tour";

export const Demo = {
  data() {
    return {
      our_id: null
    }
  },
  methods: {
    buildDemo() {
      // var id = "demo";

      /*this.$store.dispatch("set", {
        key: "user",
        val: {
          name: "",
          current_space_id: id,
          datetime_format: "YYYY-MM-DD HH:mm:ss",
          settings: {
            show_navigation: true,
          }
        },
      });

      this.$store.dispatch("set", {
        key: "space",
        val: { id: id, name: "Pensive" },
      });*/

      this.$store.dispatch("set", {
        key: "entries",
        val: [],
      });


      buildToday(this);
      buildInbox(this);
      buildBusiness(this);
      buildSelfCare(this);
      buildProject(this);
      buildMoreViews(this);
      buildCalendars(this);
      buildHousehold(this);
      buildLogics(this);
      buildRoutines(this);
      buildPriorization(this);

      this.$router.push({ name: 'home' })

      this.our_id = this.addEntry({
        name: "Empty",
        anchor: {
          position: this.entries.filter((e) => e.anchor).length,
        },
      }).id;

      this.e("Buy Gift for Dad").links.push(this.e("Dads Birthday").id);

      //if (typeof Cypress == 'undefined' || this.$route.query.reset)
      this.initializeOnboarding();

    },

    e(name) {
      return this.entries.find((e) => e.name == name);
    },
    i(id) {
      return this.entries.find((e) => e.id == id);
    },

    addEntry(data) {
      var entry = {};
      if (!entry.id) entry.id = nanoid();
      if (!entry.created_at)
        entry.created_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      if (!entry.updated_at)
        entry.updated_at = moment.utc().format("YYYY-MM-DD HH:mm:ss");
      if (!entry.deleted_at) entry.deleted_at = null;
      entry.space_id = this.space.id;

      entry.name = data.name;
      entry.links = [];

      entry = { ...entry, ...data };

      for (const [key, value] of Object.entries(entry)) {
        if (
          typeof value == "object" &&
          value !== null &&
          !["links"].includes(key)
        ) {
          if (!entry[key].id) entry[key].id = nanoid();
          if (!entry[key].entry_id) entry[key].entry_id = entry.id;
        }
      }
      this.$store.getters.entries.push(entry);
      this.$store.dispatch("set", {
        key: "entries",
        val: this.$store.getters.entries,
      });

      /*this.$store.dispatch("push", {
        event: "entry_create",
        params: { entry: entry },
        entry: entry,
      });*/

      return entry;
    },
    time(time) {
      return moment(moment().format("YYYY-MM-DD") + " " + time)
        .utc()
        .format("HH:mm:ss");
    },

    initializeOnboarding() {
      setTimeout(() => {
        createTour(this);
        this.tour.start();
      }, 100);
    }

  },
};
