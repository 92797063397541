<template>
  <div>
    DELAY
    <TransitionGroup name="delay" tag="div">
      <slot />
    </TransitionGroup>
  </div>
</template>

<script>
export default {};
</script>

<style>
.delay-leave-to {
  transition-delay: 0.5s; /* delay in ms */
}
</style>
