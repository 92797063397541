export const Rendering = {
    methods: {
        module(name, params) {
            switch (name) {
                case "description":
                    return {
                        if: params?.if ? params.if : () => true,
                        component: "DescriptionSettings",
                        model: this.entry,
                        help: "https://help.pensive.io/descriptions",
                    }
                case "cover_REFACTOR":
                    return {
                        if: () =>
                            this.$route.params.id == this.entry.id &&
                            this.position == "center",
                        title: "CoverSettings",
                        component: "CoverSettings",
                        model: this.entry.cover,

                        bind: {
                            class: params?.classes
                        },
                        on: {
                            'update:modelValue':
                                (cover) => {
                                    console.log(cover);
                                    this.entry.cover = cover;
                                    this.$store.dispatch('push', {
                                        event: 'entry_update',
                                        params: { entry: this.entry },
                                        entry: this.entry,
                                    });
                                }
                        }
                    }
                case "settings_REFACTOR":
                    return {
                        if: () =>
                            this.$route.params.id == this.entry.id &&
                            this.position == "center",
                        title: "Settings",
                        component: "SettingsDisplay",
                        bind: {
                            "data-test": "entry_settings",
                        },
                        on: {
                            'click':
                                () => {
                                    this.$emit('settings')
                                }
                        }
                    }
                case "status":
                    return {
                        if: params?.if ? params.if : () => true,
                        title: "Status",
                        component: "StatusSettings",
                        model: this.getStatusById(this.entry.status_id),
                        bind: {
                            full: true,
                            statuses: this.getAvailableStatuses(this.entry)
                        },
                        on: {
                            'update:modelValue': (status) => {
                                this.setStatus(this.entry, status);
                                if (!this.entry.temp)
                                    this.$store.dispatch("push", {
                                        event: "entry_update",
                                        params: { entry: this.entry },
                                        entry: this.entry,
                                    });
                            }
                        },
                        help: "https://help.pensive.io/statuses",
                    }
                case "schedule":
                    return {
                        if: params?.if ? params.if : () => true,
                        key: "schedule_" + this.entry.schedule?.id,
                        title: "ScheduleSettings",
                        component: "ScheduleSettings",
                        model: this.entry,
                        bind: {
                            otherentries: this.otherentries,
                        },
                        help: "https://help.pensive.io/schedules",
                    }
                case "links":
                    return {
                        if: params?.if ? params.if : () => true,
                        title: "Links",
                        component: "LinksSettings",
                        model: this.entry.links,
                        help: "https://help.pensive.io/links",
                        bind: {
                            class: params?.classes
                        },
                        on: {
                            'update:modelValue': (links) => {
                                this.entry.links = links;
                                    this.$store.dispatch("push", {
                                        event: "entry_update",
                                        params: { entry: this.entry },
                                        entry: this.entry,
                                    });
                            }
                        },
                    }
                case "time_trackings":
                    return {
                        if: params?.if ? params.if : () => true,
                        title: "TimeTracking",
                        component: "TimeTrackingsSettings",
                        model: this.entry.time_trackings,
                        help: "https://help.pensive.io/time_trackings",
                    }
                case "priority":
                    return {
                        if: params?.if ? params.if : () => true, //this.display.routine && this.entry.routine && this.$route.params.id == this.entry.id && this.position == 'center',
                        title: "Priority",
                        component: "PrioritySettings",
                        model: this.entry.priority,
                        bind: {
                            class: params?.classes
                        },
                        on: {
                            'update:modelValue': (priority) => {
                                this.entry.priority = priority;
                                if (!this.entry.temp)
                                    this.$store.dispatch("push", {
                                        event: "entry_update",
                                        params: { entry: this.entry },
                                        entry: this.entry,
                                    });
                            }
                        },
                        help: "https://help.pensive.io/priorities",
                    }
                case "routine":
                    return {
                        if: params?.if ? params.if : () => true, //this.display.routine && this.entry.routine && this.$route.params.id == this.entry.id && this.position == 'center',
                        title: "Routine",
                        component: "RoutineSettings",
                        model: this.entry.routine,
                        bind: {
                            class: params?.class
                        },
                        on: {
                            click: (e) => { e.stopPropagation() },
                            "update:modelValue": (routine) => {
                                this.entry.routine = routine;
                                if (!this.entry.temp)
                                    this.$store.dispatch("push", {
                                        event: "entry_update",
                                        params: { entry: this.entry },
                                        entry: this.entry,
                                    });
                            },
                        },
                        help: "https://help.pensive.io/routines",
                    }
                case "anchor":
                    return {
                        if: () => true,
                        title: "Anchor",
                        component: "AnchorSettings",
                        model: this.entry.anchor,
                        on: {
                            "update:modelValue": (anchor) => {
                                this.entry.anchor = anchor;
                                if (!this.entry.temp)
                                    this.$store.dispatch("push", {
                                        event: "entry_update",
                                        params: { entry: this.entry },
                                        entry: this.entry,
                                    });
                            },
                        },
                        help: "https://help.pensive.io/anchors",
                    }
                case "templates":
                    return {
                        if: () => true,
                        title: "Templates",
                        component: "TemplateSettings",
                        on: {
                            close: () => {
                                this.$emit("close");
                            },
                        },
                        help: "https://help.pensive.io/templates",
                    }
                case "color":
                    return {
                        if: params?.if ? params.if : () => true, //this.display.routine && this.entry.routine && this.$route.params.id == this.entry.id && this.position == 'center',
                        title: "Color",
                        component: "ColorSettings",
                        model: this.entry.color,
                        bind: {
                            //label: true,
                        },
                        on: {
                            "update:modelValue": (color) => {
                                this.entry.color = color;
                                this.$store.dispatch("push", {
                                    event: "entry_update",
                                    params: { entry: this.entry },
                                    entry: this.entry,
                                });
                            },
                        },
                    }
                case "output":
                    return {
                        title: "Output",
                        if: () => params?.if ? params.if : () => true,
                        component: "OutputSettings",
                        model: this.entry.output,
                        bind: {
                            class: params?.class
                        },
                        on: {
                            "update:modelValue": (output) => {
                                this.entry.output = output;
                                this.$store.dispatch("push", {
                                    event: "output_update",
                                    params: { output: this.entry.output },
                                    entry: this.entry,
                                    cb: () =>
                                        this.$store.dispatch("pull", {
                                            filters: this.entry.output.entry,
                                        }),
                                });
                            },
                        },
                        help: "https://help.pensive.io/outputs",
                    }
                case "input":
                    return {
                        title: "Input",
                        if: () => params?.if ? params.if : () => true,
                        component: "InputSettings",
                        model: this.entry.input,
                        on: {
                            "update:modelValue": (input) => {
                                this.entry.input = input;
                                this.$store.dispatch("push", {
                                    event: "entry_update",
                                    params: { entry: this.entry },
                                    entry: this.entry,
                                });
                            },
                        },
                        help: "https://help.pensive.io/inputs",
                    }
                case "senses":
                    return {
                        title: "senses",
                        if: () => params?.if ? params.if : () => true,
                        component: "SensesSettings",
                        model: this.entry.senses,
                        on: {
                            "update:modelValue": (senses) => {
                                this.entry.senses = senses;
                                this.$store.dispatch('push', {
                                    event: 'entry_update',
                                    params: { entry: this.entry },
                                    entry: this.entry,
                                });
                            },
                        },
                        help: "https://help.pensive.io/senses",
                    }

                case "statuses":
                    return {
                        title: "Statuses",
                        if: () => params?.if ? params.if : () => true,
                        component: "StatusesSettings",
                        model: this.entry.statuses,
                        on: {
                            "update:modelValue": (statuses) => {
                                this.entry.statuses = statuses;
                                this.$store.dispatch("push", {
                                    event: "entry_update",
                                    params: { entry: this.entry },
                                    entry: this.entry,
                                });
                            },
                        },
                        help: "https://help.pensive.io/inputs",
                    }
                case "custom_fields":
                    return {
                        title: "Custom Fields",
                        if: () => params?.if ? params.if : () => true,
                        component: "CustomFieldsSettings",
                        model: this.entry.custom_fields,
                        on: {
                            "update:modelValue": (custom_fields) => {
                                this.entry.custom_fields = custom_fields;
                                this.$store.dispatch("push", {
                                    event: "entry_update",
                                    params: { entry: this.entry },
                                    entry: this.entry,
                                });
                            },
                        },
                        help: "https://help.pensive.io/custom-fields-values",
                    }
                default:
                    return null;
            }
        },
        renderDisplay(name, params) {
            switch (name) {
                case "cover_REFACTOR":
                    return {
                        if: params?.if ? params.if : () => true,
                        title: "Cover",
                        component: "CoverDisplay",
                        model: this.entry,
                        bind: {
                            class: params?.class
                        }
                    }

                case "routine_chart_REFACTOR":
                    return {
                        if: () => this.entry.routine && this.renderType == 'gallery' && !this.entry.temp,
                        title: "Routine Chart",
                        component: "ChartHelper",
                        model: this.entry,
                        bind: {
                            labels: this.getSlots(this.entry.routine?.frequency).map(() => ''),
                            datasets: [
                                {
                                    color: this.entry.color || 'neutral',
                                    data: this.getSlots(this.entry.routine?.frequency).map((s) =>
                                        s.log?.completed ? 1 : 0,
                                    ),
                                },
                            ],
                            class: "pointer-events-none absolute bottom-0 left-0 z-[-1] flex h-full w-full items-end"
                        },
                        on: {
                            toggleShowOutput: () => {
                                this.showOutput = !this.showOutput
                                if (this.showOutput) this.showConfig = false
                            }
                        }
                    }
                case "core":
                    return {
                        if: () => true,
                        title: "Core",
                        component: "EntryCore",
                        model: this.entry,
                        bind: {
                            innerClass: this.innerClass,
                            display: this.display,
                            placeholder: this.placeholder,
                            parent: this.parent,
                            otherentries: this.otherentries,
                            showOutput: this.showOutput,
                            containingOutputGroup: this.containingOutputGroup,
                            class: "basis-full",
                            customFields: this.customFields,
                        },
                        on: {
                            toggleShowOutput: () => {
                                this.showOutput = !this.showOutput
                                if (this.showOutput) this.showConfig = false
                            },
                            onNameKeyUp: (name, event) => { this.$emit('onNameKeyUp', name, event) },
                            onKeyUp: (name, event) => { this.$emit('onKeyUp', name, event) },
                            onNameChanged: (name) => { this.$emit('onNameChanged', name) },
                            toggleSettings: () => { this.showConfig = !this.showConfig },
                        }
                    }
                case "status":
                    return {
                        if: () =>
                            this.space &&
                            this.display.status &&
                            (this.$route.params.id == this.entry.id && this.position == 'center'),
                        title: "Status",
                        component: "StatusDisplay",
                        model: this.getStatusById(this.entry.status_id),
                        bind: {
                            full: true,
                            statuses: this.getAvailableStatuses(this.entry)
                        },
                        on: {
                            'update:modelValue': (status) => {
                                this.setStatus(this.entry, status);
                                if (!this.entry.temp)
                                    this.$store.dispatch("push", {
                                        event: "entry_update",
                                        params: { entry: this.entry },
                                        entry: this.entry,
                                    });
                            }
                        }
                    }
                case "priority":
                    return {
                        if: () => this.display.priority && this.entry.priority,
                        component: "PriorityDisplay",
                        model: this.entry.priority,
                        bind: {
                            full: true,
                            statuses: this.getAvailableStatuses(this.entry),
                            class: {
                                "mt-1":
                                    this.position == "center" &&
                                    this.$route.params.id == this.entry.id || this.position == 'column' && this.depth == 2,
                            },
                        },
                        on: {
                            click: (e) => {
                                e.stopPropagation();
                            },

                            "update:modelValue": (priority) => {
                                this.entry.priority = priority;
                                if (!this.entry.temp)
                                    this.$store.dispatch("push", {
                                        event: "entry_update",
                                        params: { entry: this.entry },
                                        entry: this.entry,
                                    });
                            },
                        },
                    }
                case "procrastination":
                    return {
                        if: () => this.display.procrastination && this.entry.procrastination,
                        component: "ProcrastinationDisplay",
                        model: this.entry.procrastination,
                        bind: {
                            class: {
                                "mt-1":
                                    this.position == "center" &&
                                    this.$route.params.id == this.entry.id,
                            },
                        },
                    }
                case "name":
                    return {
                        if: () => this.display.name,
                        component: "NameComponent",
                        model: this.entry,
                        bind: {
                            id:
                                this.$route.params.id == this.entry.id &&
                                    this.position == "center"
                                    ? "name"
                                    : null,
                            placeholder: this.placeholder,
                            disabled:
                                !this.entry.temp &&
                                (this.$windowSize.width < 768 ||
                                    !["center", "column"].includes(this.position) ||
                                    this.$store.getters.dragged.length > 0),
                            class: {
                                "line-clamp-3": this.position == "left",
                                "z-[1] flex-1 _basis-6/12": true,
                            },
                            "data-priority": this.entry.priority?.level,
                        },
                        on: {
                            onKeyUp: (name, event) => this.$emit("onNameKeyUp", name, event),
                            onEnter: () => this.$emit("onNameChanged", this.entry.name),
                        },
                    }
                case "description":
                    return {
                        if: () =>
                            this.display.description &&
                            this.entry.description &&
                            ((this.$route.params.id == this.entry.id &&
                                this.position == "center") ||
                                this.entry.description.content?.length > 0),
                        component: "DescriptionDisplay",
                        model: this.entry.description,
                        bind: {
                            class: "basis-full",
                            disabled: this.$route.params.id != this.entry.id || this.depth > 1,
                        },
                        on: {
                            "update:modelValue": (description) => {
                                this.entry.description = description;
                                if (this.entry.description.content.length)
                                    this.$store.dispatch("push", {
                                        event: "entry_update",
                                        params: { entry: this.entry },
                                        entry: this.entry,
                                    });
                                else {
                                    this.$store.dispatch("push", {
                                        event: "description_delete",
                                        params: { id: this.entry.description.id },
                                        entry: this.entry,
                                    });
                                    this.entry.description = null;
                                }
                            },
                        },
                    }
                case "schedule":
                    return {
                        if: () => this.display.schedule && this.entry.schedule,
                        component: "ScheduleDisplay",
                        model: this.entry,
                        bind: {
                            id:
                                this.$route.params.id == this.entry.id &&
                                    this.position == "center"
                                    ? "schedule"
                                    : null,
                            disabled: false,
                            otherentries: this.otherentries,
                            // class: "flex min-h-[1.5rem] items-center self-start",
                            // style: "flex: 0 0 auto;",
                        },
                    }
                case "links":
                    return {
                        if: params?.if ? params.if : () => true,
                        component: "LinksDisplay",
                        model: this.entry,
                        bind: {
                            class: {
                                ...{
                                    "flex min-h-[1.5rem] items-center self-start": true,
                                    "mt-1":
                                        this.position == "center" &&
                                        this.$route.params.id == this.entry.id || this.position == 'column' && this.depth == 2,
                                }, ...params?.class
                            },
                        },
                    }
                case "time_trackings":
                    return {
                        if: () =>
                            this.display.time_trackings && this.entry.time_trackings.length,
                        component: "TimeTrackingsDisplay",
                        model: this.entry.time_trackings,
                        on: {
                            "update:modelValue": (time_trackings) => {
                                this.entry.time_trackings = time_trackings;
                                this.$store.dispatch("push", {
                                    event: "entry_update",
                                    params: { entry: this.entry },
                                    entry: this.entry,
                                });
                            },
                        },
                    }
                case "routine":
                    return {
                        if: () =>
                            this.display.routine &&
                            this.entry.routine &&
                            !["gsallery", "tiles"].includes(this.renderType),
                        component: "RoutineDisplay",
                        model: this.entry,
                        bind: {
                            class: { "ml-auto justify-end flex-wrap": true, "absolute bottom-2 right-2 w-full": this.renderType == 'gallery' },
                        },
                        on: {
                            click: (e) => {
                                e.stopPropagation();
                            },
                        },
                    }

                case "output":
                    return {
                        if: () =>
                            this.showOutput &&
                            //         this.entry.links.length < 2 &&
                            // !this.entry.output &&
                            this.display.output && (this.position == 'left' || this.root || ["column"].includes(this.position)),
                        title: "Output",
                        component: "OutputDisplay",
                        model: this.entry.output,
                        bind: {
                            filter: (e) => e.id != this.entry.id,
                            inputs: this.inputs ? this.inputs : [...this.linkedEntries],
                            sorting: this.entry.output?.sorting,
                            display: this.display,
                            position: this.position,
                            class: {
                                ...{
                                    "basis-full": this.entry.output,
                                    "ml-5":
                                        this.position == "left" ||
                                        (["center", "column"].includes(this.position) &&
                                            this.$route.params.id != this.entry.id &&
                                            !this.$store.getters.entries
                                                .find((e) => e.id == this.$route.params.id)
                                                ?.links.includes(this.entry.id)),
                                }, ...params?.class
                            },
                        },
                        on: {
                            "update:modelValue": (output) => {
                                this.entry.output = output;
                                this.$store.dispatch("push", {
                                    event: "entry_update",
                                    params: { entry: this.entry },
                                    entry: this.entry,
                                });
                            },
                        },
                    }
                case "input":
                    return {
                        if: () =>
                            // this.showOutput &&
                            // !this.entry.output &&
                            this.display.input && (this.position == "center" && this.$route.params.id == this.entry.id),
                        title: "Input",
                        component: "InputDisplay",
                        model: this.entry.input,
                        bind: {
                            schema: this.entry.input?.data,
                            inputs: this.inputs,
                            display: this.display,
                            position: this.position,
                            class: params?.class
                        },
                        on: {
                            "update:modelValue": (input) => {
                                this.entry.input = input;
                                this.$store.dispatch("push", {
                                    event: "entry_update",
                                    params: { entry: this.entry },
                                    entry: this.entry,
                                });
                            },
                        },
                    }
                case "custom_fields":
                    return {
                        if: () => this.customFields?.length,
                        title: "Custom Fields",
                        component: "EntryCustomFields",
                        model: this.entry,
                        bind: {
                            class: "basis-full",
                            customFields: this.customFields,
                        },
                    }
                default:
                    return null;
            }
        }
    }
};
