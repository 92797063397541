<template>
  <div class="rounded-2xl bg-white p-5 shadow-2xl dark:bg-neutral-950">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "neutral",
    },
  },
};
</script>
