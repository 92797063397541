<template>
  <div v-if="nextTour && !autoStart" class="relative leading-none">
    <span
      :class="{ 'animate-ping': !active }"
      class="absolute inline-flex h-full w-full rounded-full bg-neutral-400 opacity-100"
    ></span>
    <ButtonComponent
      @click.stop="onClick"
      color="secondary"
      :variant="variant"
      :size="size"
      :title="nextTour.name"
      :class="{ 'animate-pulse': active }"
    >
      <span class="sr-only">Help</span>
      <IconHelper
        name="circle-help"
        :size="size == 'xs' ? 16 : size == 'sm' ? 20 : 24"
      />
    </ButtonComponent>
  </div>
</template>

<script>
import { Touring } from "../mixins/Touring";

export default {
  mixins: [Touring],
  props: {
    autoStart: {
      type: Boolean,
      default: false,
    },
    hook: String,
    variant: {
      type: String,
      default: "round",
    },
    size: {
      type: String,
      default: "md",
    },
    showSettings: Boolean,
  },
  data() {
    return {
      active: false,
    };
  },
  mounted() {
    if (this.autoStart && this.nextTour && !this.$Cypress) {
      setTimeout(() => {
        this.active = true;
        this.startTour(this.nextTour);
      }, 2500);
    }
  },
  computed: {
    nextTour() {
      return this.tours?.find((t) => t.hook == this.hook);
    },
  },
  methods: {
    onClick() {
      this.active = true;
      this.startTour(this.nextTour);
    },
  },
};
</script>
