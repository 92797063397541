<template>
  <PopoverHelper
    :closeOnClick="true"
    :isOpen="
      typeof render(modelValue) == 'undefined' || render(modelValue) == null
    "
    data-test="option_picker"
  >
    <template v-slot:button>
      <ButtonComponent
        :variant="variant"
        :size="size"
        :color="color"
        class="whitespace-nowrap uppercase"
      >
        <span
          v-if="
            typeof render(modelValue) != 'undefined' &&
            render(modelValue) != null
          "
          v-html="render(modelValue)"
        />
        <span v-else> select </span>
      </ButtonComponent>
    </template>
    <div
      class="flex max-h-48 flex-col gap-0 overflow-y-auto"
      data-test="optionspicker_options"
    >
      <!--eslint-disable vue/no-v-text-v-html-on-component-->
      <PopoverButton
        as="button"
        v-for="(option, index) in options?.filter(
          (o) => JSON.stringify(o) != JSON.stringify(modelValue),
        )"
        :key="index"
        @click="
          $emit(
            'update:modelValue',
            typeof option == 'object' && option.value ? option.value : option,
          )
        "
        v-html="
          render(
            typeof option == 'object' && option.title ? option.title : option,
          )
        "
        class="flex w-full cursor-pointer items-center justify-between gap-x-2 whitespace-nowrap px-3 py-1 text-xs uppercase leading-6 text-gray-900 hover:bg-gray-50 dark:text-neutral-400 dark:hover:bg-neutral-900"
        :data-test="
          'optionpicker_options_option_' +
          $slugify(
            render(
              typeof option == 'object' && option.value ? option.value : option,
            ),
          )
        "
        :data-option="
          typeof option == 'object' && option.value ? option.value : option
        "
      />
      <!-- class="flex w-full cursor-pointer gap-x-2 whitespace-nowrap px-3 py-1 text-left text-xs uppercase leading-6 text-gray-900 hover:bg-gray-50" -->
    </div>
  </PopoverHelper>
</template>
<script setup>
import { PopoverButton } from "@headlessui/vue";
</script>
<script>
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  props: {
    options: Array,
    render: {
      type: Function,
      default: function (v) {
        return typeof v == "object"
          ? v?.join(" · ").replace("_", " ")
          : v?.replace("_", " ");
      },
    },
  },
};
</script>
