<template>
  <div class="px-6 py-24 sm:py-32 lg:px-8">
    <div class="mx-auto max-w-2xl text-center">
      <h2 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
        One moment please
      </h2>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        We are getting everything ready.
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "LoginProviderComponent",
  computed: {
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },
  },
  created() {
    this.$store
      .dispatch("retrieveProviderCallback", {
        provider: this.$route.params.provider,
        code: this.$route.query.code,
        user: this.$route.query.user,
        oauth_token: this.$route.query.oauth_token,
        oauth_verifier: this.$route.query.oauth_verifier,
        timezone: moment.tz.guess(),
        entries: this.entries,
      })
      .then(() => {
        this.$router.push({ name: "home" });
      })
      .catch(() => {
        this.$router.push({ name: "login" });
      });
  },
};
</script>
