<template>
  <PopoverHelper>
    <template v-slot:button>
      <ButtonComponent
        class="w-full whitespace-nowrap rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-900"
        data-test="use_template_button"
        :size="size"
        :variant="variant"
        :color="color"
      >
        <Squares2X2Icon class="h-4 w-4" />
        Use Template
      </ButtonComponent>
    </template>
    <div class="w-64">
      <PopoverTitle>
        <PuzzlePieceIcon class="h-4 w-4 text-gray-500" aria-hidden="true" />
        Templates
      </PopoverTitle>
      <p
        v-if="
          $store.getters.templates.filter(
            (t) => t.entry_id !== entry.id && !t.global,
          ).length === 0
        "
        class="px-3 py-1 text-sm leading-6 text-gray-700"
      >
        No Templates available
      </p>
      <div
        v-for="(template, index) in $store.getters.templates.filter(
          (t) => t.entry_id !== entry.id && !t.global,
        )"
        :key="index"
        class="flex cursor-pointer items-start gap-x-2 rounded-lg px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 hover:text-black"
      >
        <PopoverButton
          @click="
            applyTemplateToEntry(template, entry),
              // applyInput(
              //   inputSchema(
              //     JSON.parse(
              //       JSON.stringify(template.data).replaceAll(
              //         template.entry_id,
              //         entry.id,
              //       ),
              //     ),
              //     entry,
              //   ),
              //   entry,
              //   true,
              // ),
              $emit('templateSelected', template)
          "
          :title="JSON.stringify(template.data)"
          class="w-full flex-1 text-left"
          :data-test="'select_template_' + $slugify(template.name) + '_button'"
        >
          <!-- {{ template.data }} -->
          {{ template.name }}
          <span v-if="template.description" class="block text-xs text-gray-500">
            {{ template.description }}
          </span>
        </PopoverButton>

        <button
          @click="
            $store.getters.templates.splice(
              $store.getters.templates.findIndex((t) => t.id == template.id),
              1,
            );
            $store.dispatch('push', {
              event: 'template_delete',
              params: { id: template.id },
              entry: entry,
              undo: true,
            });
          "
          class="mt-1 hover:text-red-500"
        >
          <TrashIcon class="h-4 w-4" />
        </button>
      </div>
    </div>
  </PopoverHelper>
</template>

<script>
import { PopoverButton } from "@headlessui/vue";
import { Applicators } from "@/components/mixins/Applicators";
import { Schema } from "@/components/mixins/Schema";
import { Statusing } from "@/components/mixins/Statusing";
import { Scheduling } from "@/components/mixins/Scheduling";
import { Templating } from "@/components/mixins/Templating";
import { Addon } from "@/components/mixins/Addon";

export default {
  mixins: [Schema, Applicators, Statusing, Scheduling, Templating, Addon],
  components: { PopoverButton },
  mounted() {
    this.$store.dispatch("retrieveTemplates");
  },
};
</script>
