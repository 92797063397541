// import { addonBlueprint } from "@/addonBlueprint";

export const Addon = {
    props: {
        modelValue: null,
        entry: Object,
        disabled: Boolean,
        // editable: Boolean,

        variant: {
            type: String,
            default: "default",
        },
        size: {
            type: String,
            default: "md",
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        color: {
            type: String,
            default: "neutral",
        },
        colorWeight: {
            type: String,
            default: "500",
        },
    },
};
