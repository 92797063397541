<template>
  <div
    class="flex flex-col gap-x-8 gap-y-2 border-b pb-2 pt-2 lg:flex-row lg:items-center"
  >
    <span class="whitespace-nowrap text-sm"
      >{{
        $store.getters.users.find((u) => u.id == time_tracking.user_id)
          ?.firstname
      }}
      {{
        $store.getters.users.find((u) => u.id == time_tracking.user_id)
          ?.lastname
      }}</span
    >

    <div
      class="flex flex-col flex-wrap gap-x-4 gap-y-2 whitespace-nowrap text-sm md:flex-1 md:flex-row lg:flex-nowrap"
    >
      <div class="flex-1">
        {{ $moment.utc(time_tracking.start_at).local().format("DD.MM. HH:mm") }}
        -
        {{ $moment.utc(time_tracking.end_at).local().format("DD.MM. HH:mm") }}
      </div>
      <span class="place-self-end font-semibold">{{ duration }}</span>
    </div>
    <div class="inline-flex gap-x-2 place-self-end lg:place-self-auto">
      <PopoverHelper>
        <template #button>
          <ButtonComponent
            variant="minimal"
            size="sm"
            data-test="time_trackings_row_action_edit"
          >
            <SettingsIcon size="20" />
          </ButtonComponent>
        </template>
        <div class="flex flex-col gap-y-2 bg-white p-4 dark:bg-black">
          <LabelHelper> Edit Time Tracking </LabelHelper>
          <div class="flex items-center gap-x-2">
            <input
              :value="
                $moment
                  .utc(time_tracking.start_at)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')
              "
              @blur="
                (event) => {
                  const startAt = $moment(event.target.value);
                  if (!startAt.isValid()) {
                    event.target.value = $moment
                      .utc(time_tracking.start_at)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss');
                  }
                }
              "
              @change="
                (event) => {
                  const startAt = $moment(event.target.value);
                  if (startAt.isValid())
                    time_tracking = {
                      ...time_tracking,
                      start_at: startAt.utc().format('YYYY-MM-DD HH:mm:ss'),
                    };
                }
              "
              type="text"
              class="block w-full min-w-max rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
            />
            <span> - </span>
            <input
              :value="
                $moment
                  .utc(time_tracking.end_at)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')
              "
              @blur="
                (event) => {
                  const endAt = $moment(event.target.value);
                  if (!endAt.isValid()) {
                    event.target.value = $moment
                      .utc(time_tracking.end_at)
                      .local()
                      .format('YYYY-MM-DD HH:mm:ss');
                  }
                }
              "
              @change="
                (event) => {
                  const endAt = $moment(event.target.value);
                  if (endAt.isValid())
                    time_tracking = {
                      ...time_tracking,
                      end_at: endAt.utc().format('YYYY-MM-DD HH:mm:ss'),
                    };
                }
              "
              type="text"
              class="block w-full min-w-max rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
            />
          </div>

          <!-- <div
            class="relative max-w-fit place-self-center rounded-md shadow-sm"
          >
            {{
              Math.ceil(
                $moment
                  .utc(time_tracking.end_at)
                  .diff($moment.utc(time_tracking.start_at), "seconds") / 60,
              )
            }}
            <input
              type="text"
              class="block w-full rounded-md border-0 py-1.5 pl-3 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-neutral-600 sm:text-sm sm:leading-6"
              :value="
                Math.ceil(
                  $moment
                    .utc(time_tracking.end_at)
                    .diff($moment.utc(time_tracking.start_at), 'seconds') / 60,
                )
              "
            />
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
            >
              <span class="text-gray-500 sm:text-sm" id="price-currency"
                >Minutes</span
              >
            </div>
          </div> -->
        </div>
      </PopoverHelper>
      <ButtonComponent
        @click.stop="
          () => {
            time_tracking = null;
          }
        "
        variant="minimal"
        size="sm"
        data-test="time_trackings_row_action_delete"
      >
        <TrashIcon size="20" />
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import { SettingsIcon, TrashIcon } from "lucide-vue-next";

export default {
  components: {
    TrashIcon,
    SettingsIcon,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    entry: Object,
  },
  computed: {
    time_tracking: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    duration() {
      const startTime = this.$moment.utc(this.time_tracking.start_at);
      const endTime = this.$moment.utc(this.time_tracking.end_at);
      const elapsedTime = this.$moment.duration(endTime.diff(startTime));

      return (
        (elapsedTime.hours() ? elapsedTime.hours() + "h " : "") +
        elapsedTime.minutes() +
        "m " +
        elapsedTime.seconds() +
        "s"
      );
    },
  },
};
</script>
