<template>
  <div :class="`mb-2 rounded-md bg-${color}-50 dark:bg-${color}-950 p-4`">
    <div class="flex">
      <div class="flex-shrink-0">
        <IconHelper
          :name="icon"
          size="20"
          :class="`text-${color}-400`"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3 space-y-2">
        <h3
          v-if="$slots['title']"
          :class="`text-sm font-medium text-${color}-800 dark:text-${color}-300`"
        >
          <slot name="title" />
        </h3>
        <div :class="`text-sm text-${color}-700 dark:text-${color}-400`">
          <p>
            <slot />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    variant: {
      type: String,
      default: "info",
    },
  },
  computed: {
    icon() {
      switch (this.variant) {
        case "info":
          return "info";
        case "success":
          return "check-circle";
        case "warning":
          return "circle-alert";
        case "error":
          return "x-circle";
        default:
          return "info";
      }
    },
    color() {
      switch (this.variant) {
        case "info":
          return "blue";
        case "success":
          return "green";
        case "warning":
          return "yellow";
        case "error":
          return "red";
        default:
          return "blue";
      }
    },
  },
};
</script>
