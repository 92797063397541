<template>
  <ButtonComponent
    @click="$emit('delete')"
    :color="color"
    :title="title"
    class="group/label relative inline-flex"
    :variant="variant"
    :size="size"
  >
    <slot />

    <a
      @click="$emit('delete')"
      class="absolute bottom-0 right-0 top-0 hidden w-6 items-center justify-center group-hover/label:flex"
    >
      <TrashIcon class="h-4 w-4" />
    </a>
  </ButtonComponent>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/24/outline";

export default {
  props: {
    action: {
      type: String,
      default() {
        return "&times;";
      },
    },
    color: {
      type: String,
      default() {
        return "neutral";
      },
    },
    title: String,
    variant: {
      type: String,
      default: "default",
    },
    size: {
      type: String,
      default: "md",
    },
  },
  components: { TrashIcon },
};
</script>

<style scoped>
button:hover a {
  display: flex;
  position: absolute;
  right: 0;
  width: 1.5rem;
  top: 0;
  background-color: inherit;
  bottom: 0;
  align-items: center;
  justify-content: center;
}
</style>
