import { nanoid } from "nanoid";

export const Scheduling = {
  methods: {
    relativeDate(date) {
      return date;
    },
    setEntrySchedule(entry, value) {
      if (entry.schedule && value.date) {
        const unixCurrent = this.$moment(entry.schedule.date).unix();
        const unixNew = this.$moment(value.date).unix();
        if (
          !entry.temp &&
          entry.status_id &&
          this.statusIsDone(entry.status_id) == false &&
          unixNew > unixCurrent
        ) {
          if (entry.procrastination) {
            entry.procrastination.count++;
          } else {
            entry.procrastination = {
              id: nanoid(),
              entry_id: entry.id,
              count: 1,
            };
          }
        }
      }

      if (entry.schedule?.time && typeof value.time == "undefined") value.time = entry.schedule.time;
      entry.schedule = { ...entry.schedule, ...value };

      //if (entry.schedule.time === "null") entry.schedule.time = null;
    },
    interpretTime(schedule) {
      var time =
        typeof schedule.time == "string"
          ? this.$moment(this.$moment().format("YYYY-MM-DD " + schedule.time))
          : schedule.time;
      if (time) {
        if (time.op == "now") {
          time = this.$moment.utc();
        }
        if (time.op == "nextXHours") {
          time = this.$moment.utc().add(time.x, "hour");
        }
        if (time.op == "nextXMinutes") {
          time = this.$moment.utc().add(time.x, "minute");
        }
        if (time.op == "nextXSeconds") {
          time = this.$moment.utc().add(time.x, "second");
        }
        if (time.op == "lastXHours") {
          time = this.$moment.utc().add(-time.x, "hour");
        }
        if (time.op == "lastXMinutes") {
          time = this.$moment.utc().add(-time.x, "minute");
        }
        if (time.op == "lastXSeconds") {
          time = this.$moment.utc().add(-time.x, "second");
        }
      }
      if (time === null || time.op == "null") {
        return null;
      }
      return time.minute(Math.round(time.minute() / 5) * 5).format("HH:mm:00");
    },
    interpretDate(schedule, base = null) {
      var date =
        typeof schedule.date == "string"
          ? this.$moment(schedule.date)
          : schedule.date;
      if (base === null)
        base = this.$moment();

      if (date.op == "today") {
        date = base;
      }
      else if (date.op == "tomorrow") {
        date = base.add(1, "day");
      }
      else if (date.op == "yesterday") {
        date = base.add(-1, "day");
      }
      else if (date.op == "nextXDays") {
        date = base.add(date.x, "day");
      }
      else if (date.op == "nextXWeeks") {
        date = base.add(date.x, "week");
      }
      else if (date.op == "nextXMonths") {
        date = base.add(date.x, "month");
      }
      else if (date.op == "nextXYears") {
        date = base.add(date.x, "year");
      }
      else if (date.op == "lastXDays") {
        date = base.add(-date.x, "day");
      }
      else if (date.op == "lastXWeeks") {
        date = base.add(-date.x, "week");
      }
      else if (date.op == "lastXMonths") {
        date = base.add(-date.x, "month");
      }
      else if (date.op == "lastXYears") {
        date = base.add(-date.x, "year");
      }
      else if (date.op == "null") {
        return null;
      }

      if (schedule.time && schedule.time.op) {
        if (schedule.time.op == "nextXHours") {
          date = date.add(schedule.time.x, "hour");
        }
        if (schedule.time.op == "lastXHours") {
          date = date.add(-schedule.time.x, "hour");
        }
        if (schedule.time.op == "nextXMinutes") {
          date = date.add(schedule.time.x, "minutes");
        }
        if (schedule.time.op == "lastXMinutes") {
          date = date.add(-schedule.time.x, "minutes");
        }
        if (schedule.time.op == "nextXSeconds") {
          date = date.add(schedule.time.x, "seconds");
        }
        if (schedule.time.op == "lastXSeconds") {
          date = date.add(-schedule.time.x, "seconds");
        }
      }

      return date.format("YYYY-MM-DD");
    },
    relativeDateToText(date) {
      if (date) {
        if (date.op == "today") {
          return "Today";
        }
        if (date.op == "tomorrow") {
          return "Tomorrow";
        }
        if (date.op == "yesterday") {
          return "Yesterday";
        }
        if (date.op == "nextXDays") {
          return date.x + " days";
        }
        if (date.op == "nextXWeeks") {
          return date.x + " weeks";
        }
        if (date.op == "nextXMonths") {
          return date.x + " months";
        }
        if (date.op == "nextXYears") {
          return date.x + " years";
        }
        if (date.op == "lastXDays") {
          return date.x + " days";
        }
        if (date.op == "lastXWeeks") {
          return date.x + " weeks";
        }
        if (date.op == "lastXMonths") {
          return date.x + " months";
        }
        if (date.op == "lastXYears") {
          return date.x + " years";
        }
      }
      return date;
    },
    handleRecurrence() {
      if (this.statusIsDone(this.entry.status_id) && this.entry.schedule?.recurrence) {
        /**
          * If the object is done and has a recurrence, we need to set the next date
          * but only if there is a possible next open status available
          */
        const nextStatus = this.getNextStatusInType('open', this.getAvailableStatuses(this.entry));
        if (nextStatus) {
          this.setStatus(this.entry, nextStatus);

          this.entry.schedule.date = this.interpretDate({ date: this.entry.schedule.recurrence }, this.$moment(this.entry.schedule.date));

          if (!this.entry.temp)
            this.$store.dispatch("push", {
              event: "entry_update",
              params: { entry: this.entry },
              entry: this.entry,
            });
        }
      }
    },
    isOverdue(entry) {
      return (entry.status_id === null || !this.statusIsDone(entry.status_id)) &&
        entry.schedule && entry.schedule.date && this.$moment(entry.schedule.date).isBefore(this.$moment().startOf('day'));
    },

    modifyRelativeDate(dir, date) {
      switch (dir) {
        case 1:
          switch (date.op) {
            case "today":
              date.op = "tomorrow";
              break;
            case "tomorrow":
              date.op = "nextXDays";
              date.x = 2;
              break;
            case "yesterday":
              date.op = "today";
              break;
            default:
              date.x =
                typeof date.x != "undefined"
                  ? date.op.slice(0, 4) == "next"
                    ? date.x + 1
                    : date.x - 1
                  : 0;
              if (date.x < 0) {
                date.op =
                  date.op.slice(0, 4) == "next"
                    ? "last" + date.op.slice(4)
                    : "next" + date.op.slice(4);
                date.x = 1;
              }
          }
          break;
        case -1:
          switch (date.op) {
            case "today":
              date.op = "yesterday";
              break;
            case "tomorrow":
              date.op = "today";
              break;
            case "yesterday":
              date.op = "lastXDays";
              date.x = 2;
              break;
            default:
              date.x =
                typeof date.x != "undefined"
                  ? date.op.slice(0, 4) == "next"
                    ? date.x - 1
                    : date.x + 1
                  : 0;
              if (date.x < 0) {
                date.op =
                  date.op.slice(0, 4) == "next"
                    ? "last" + date.op.slice(4)
                    : "next" + date.op.slice(4);
                date.x = 1;
              }
          }
          break;
      }
      if (date.op == "nextXDays") {
        if (date.x == 0) {
          delete date.x;
          date.op = "today";
        }
        if (date.x == 1) {
          delete date.x;
          date.op = "tomorrow";
        }
        if (date.x == -1) {
          delete date.x;
          date.op = "yesterday";
        }
      } else if (date.op == "lastXDays") {
        if (date.x == 0) {
          delete date.x;
          date.op = "today";
        }
        if (date.x == 1) {
          delete date.x;
          date.op = "yesterday";
        }
        if (date.x == -1) {
          delete date.x;
          date.op = "tomorrow";
        }
      }
      this.$emit("update:modelValue", date);
      // this.$emit("update", this.date);
    },
    modifyRelativeTimespan(dir, date) {
      switch (dir) {
        case 1:
          switch (date.op) {
            case "today":
            case "tomorrow":
            case "yesterday":
            case "nextXDays":
              date.op = "nextXWeeks";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "nextXWeeks":
              date.op = "nextXMonths";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "nextXMonths":
              date.op = "nextXYears";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "lastXDays":
              date.op = "lastXWeeks";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "lastXWeeks":
              date.op = "lastXMonths";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "lastXMonths":
              date.op = "lastXYears";
              if (typeof date.x == "undefined") date.x = 1;
              break;
          }
          break;
        case -1:
          switch (date.op) {
            case "nextXWeeks":
              date.op = "nextXDays";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "nextXMonths":
              date.op = "nextXWeeks";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "nextXYears":
              date.op = "nextXMonths";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "lastXWeeks":
              date.op = "lastXDays";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "lastXMonths":
              date.op = "lastXWeeks";
              if (typeof date.x == "undefined") date.x = 1;
              break;
            case "lastXYears":
              date.op = "lastXMonths";
              if (typeof date.x == "undefined") date.x = 1;
              break;
          }
          break;
      }
      if (date.op) {
        if (date.op == "nextXDays") {
          if (date.x == 0) {
            delete date.x;
            date.op = "today";
          }
          if (date.x == 1) {
            delete date.x;
            date.op = "tomorrow";
          }
          if (date.x == -1) {
            delete date.x;
            date.op = "yesterday";
          }
        } else if (date.op == "lastXDays") {
          if (date.x == 0) {
            delete date.x;
            date.op = "today";
          }
          if (date.x == 1) {
            delete date.x;
            date.op = "yesterday";
          }
          if (date.x == -1) {
            delete date.x;
            date.op = "tomorrow";
          }
        }
      }
      this.$emit("update:modelValue", date);
    },
    relativeTimespan(date) {
      if (date && date.op) {
        switch (date.op) {
          case "today":
            return [0, "days"];
          case "tomorrow":
            return [1, "day"];
          case "yesterday":
            return [-1, "day"];
          case "lastXDays":
          case "nextXDays":
            return [date.x, "day" + (date.x != 1 ? "s" : "")];
          case "lastXWeeks":
          case "nextXWeeks":
            return [date.x, "week" + (date.x != 1 ? "s" : "")];
          case "lastXMonths":
          case "nextXMonths":
            return [date.x, "month" + (date.x != 1 ? "s" : "")];
          case "lastXYears":
          case "nextXYears":
            return [date.x, "year" + (date.x != 1 ? "s" : "")];
        }
      }
    },
  },
};
