<template>
  <div
    ref="card"
    class="overflow-hidden rounded-lg bg-neutral-50 shadow dark:bg-black dark:shadow-none dark:ring-1 dark:ring-neutral-700"
    @mouseenter="$anime({ targets: $refs.card, scale: 1.05 })"
    @mouseleave="$anime({ targets: $refs.card, scale: 1 })"
  >
    <div class="px-4 py-5 sm:p-6">
      <h1 :class="`text-${color}-500`" class="text-lg font-bold">
        <slot name="title" />
      </h1>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "neutral",
    },
  },
};
</script>
