<!-- eslint-disable vue/no-unused-components -->
<template>
  <div :class="$style.root">
    <Listbox v-model="status" :disabled="disabled" v-slot="{ open }">
      <ListboxButton
        ref="reference"
        class="w-full rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-neutral-300"
        :data-test="
          'entry_status_' + (status ? $slugify(status.name) : 'no-status')
        "
      >
        <slot name="button">
          <div
            @mousedown="onMouseDown"
            @mouseup="onMouseUp"
            @click="
              (e) =>
                quickStatusSwapEnabled
                  ? [e.stopPropagation(), $emit('click', e)]
                  : null
            "
            @contextmenu.stop.prevent
            v-touch:hold.stop.prevent="
              (e) => {
                if (
                  quickStatusSwapEnabled &&
                  !['mouseup', 'mousedown'].includes(e.type)
                ) {
                  $refs.reference.$el.click();
                }
              }
            "
            v-touch:press.stop
            v-touch:release.stop
            :class="{
              'p-1.5': position != 'calendar',
              'p-1': position == 'calendar',
            }"
            class="group/status relative flex items-center"
            data-test="entry_status_select"
          >
            <StatusIndicator :status="status" :size="size" />

            <div
              v-if="position != 'calendar'"
              class="pointer-events-none absolute bottom-0 left-0 z-10 w-max translate-y-full truncate rounded-md bg-white px-2 py-1 text-sm opacity-0 drop-shadow-md group-hover/status:block group-hover/status:opacity-100 group-hover/status:transition-opacity group-hover/status:delay-300 dark:bg-black dark:text-neutral-300 dark:ring-1 dark:ring-neutral-700"
            >
              <div class="flex flex-wrap items-center justify-center gap-2">
                <span
                  v-if="status"
                  abel="status?.name || 'No status'"
                  :class="[
                    `bg-${status?.color}-500`,
                    'inline-block h-2 w-2 flex-shrink-0 rounded-full transition-all',
                  ]"
                />
                <span class="">{{ status?.name || "No status" }}</span>
                <span
                  v-if="quickStatusSwapEnabled && !disabled"
                  class="flex items-center gap-2"
                >
                  <ChevronRightIcon class="inline h-3 w-3" />

                  <span
                    :aria-label="
                      statuses.find((s) => s.id != status?.id).name ||
                      'No status'
                    "
                    :class="[
                      `bg-${
                        statuses.find((s) => s.id != status?.id).color
                      }-500`,
                      'inline-block h-2 w-2 flex-shrink-0 rounded-full transition-all',
                    ]"
                  />
                  {{ statuses.find((s) => s.id != status?.id).name }}
                </span>
              </div>
              <span
                v-if="quickStatusSwapEnabled && !disabled"
                class="mt-2 flex flex-wrap items-center justify-center gap-1 text-xs text-neutral-500"
              >
                Hold <IconHelper name="mouse" size="16" strokeWidth="1.5" /> for
                more options
              </span>
            </div>
          </div>
        </slot>
      </ListboxButton>

      <Teleport to="body">
        <OverlayComponent v-if="open" class="z-[100000]" />
        <Transition
          @enter="animationEnterDropdown"
          @leave="animationLeaveDropdown"
          :css="false"
        >
          <ListboxOptions
            ref="floating"
            class="ps_dropdown__menu z-[100000]"
            :style="floatingStyles"
          >
            <InputWithLabel
              v-if="statuses.length > 3"
              ref="search"
              v-model="searchStatusQuery"
              placeholder="Search..."
              class="mb-2"
              @mounted="onMountSearch"
              data-delay="0"
            />
            <div
              class="max-h-64 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-neutral-300 dark:scrollbar-thumb-neutral-700"
            >
              <!-- <TransitionGroupHelper
              :enter="{
                delay: (el) => el.dataset.delay,
                opacity: [0, 1],
                translateX: [50, 0],
              }"
            > -->

              <ListboxOption
                v-if="
                  status && statuses?.findIndex((s) => s.id == status?.id) == -1
                "
                v-slot="{ active, selected }"
                :value="status"
                as="template"
                :disabled="true"
              >
                <li
                  :class="[
                    active
                      ? 'bg-neutral-50 font-bold text-black dark:bg-neutral-900 dark:text-white'
                      : 'text-neutral-800 dark:text-neutral-200',
                    'relative select-none rounded-md py-1.5 pr-10 transition-colors',
                  ]"
                >
                  <StatusOption
                    :modelValue="status"
                    :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate',
                    ]"
                    class="pointer-events-none pl-4 text-sm"
                  />
                  <span
                    v-if="selected"
                    class="absolute inset-y-0 right-0 flex items-center pr-3 text-neutral-900"
                  >
                    <IconHelper name="check" size="20" strokeWidth="1.5" />
                  </span>
                </li>
              </ListboxOption>

              <ListboxOption
                v-slot="{ active, selected }"
                v-for="(option, index) in statuses.filter((s) =>
                  s.name
                    .toLowerCase()
                    .includes(searchStatusQuery.toLowerCase()),
                )"
                :key="option.id"
                :value="option"
                as="template"
                :data-test="'entry_status_select_' + $slugify(option.name)"
                :data-delay="index * 100"
              >
                <li
                  :class="[
                    active
                      ? 'bg-neutral-50 font-bold text-black dark:bg-neutral-900 dark:text-white'
                      : 'text-neutral-800 dark:text-neutral-200',
                    'relative cursor-pointer select-none rounded-md py-1.5 pr-10 transition-colors',
                  ]"
                >
                  <StatusOption
                    :modelValue="option"
                    :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate',
                    ]"
                    class="pointer-events-none pl-4 text-sm"
                  />
                  <span
                    v-if="selected"
                    class="absolute inset-y-0 right-0 flex items-center pr-3 text-neutral-900"
                  >
                    <IconHelper name="check" size="20" strokeWidth="1.5" />
                  </span>
                </li>
              </ListboxOption>

              <ListboxOption
                v-if="status || !entry"
                as="template"
                :value="null"
                v-slot="{ active, selected }"
                :data-test="'entry_status_select_clear'"
              >
                <li
                  :class="[
                    active
                      ? 'bg-neutral-50 font-bold text-black dark:bg-neutral-900 dark:text-white'
                      : 'text-neutral-800 dark:text-neutral-200',
                    'relative cursor-pointer select-none rounded-md py-1.5 pr-10 transition-colors',
                  ]"
                >
                  <div
                    :class="[
                      selected ? 'font-medium' : 'font-normal',
                      'block truncate text-neutral-700',
                    ]"
                    class="pointer-events-none flex items-center pl-4 text-sm"
                  >
                    <span class="ml-6 block truncate"> No status </span>
                  </div>
                </li>
              </ListboxOption>
              <!-- </TransitionGroupHelper> -->
            </div>
          </ListboxOptions>
        </Transition>
      </Teleport>
    </Listbox>
  </div>
</template>

<script setup>
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";

import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  transform: false,
  placement: "bottom-start",
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>

<script>
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { Routining } from "../mixins/Routining";

export default {
  mixins: [Statusing, Scheduling, Routining],
  props: {
    modelValue: Object,
    entry: Object,
    full: Boolean,
    label: Boolean,
    statuses: {
      type: Array,
    },
    teleport: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    position: String,
    size: String,
  },
  data() {
    return {
      disableQuickSwap: false,
      skipQuickSwapTimeout: null,

      // search related
      showSearchInput: false,
      searchStatusQuery: "",
    };
  },
  computed: {
    status: {
      get() {
        return this.modelValue;
      },
      set(status) {
        if (this.entry) {
          this.setStatus(this.entry, status);
          if (!this.entry.temp)
            this.$store.dispatch("push", {
              event: "entry_update",
              params: { entry: this.entry },
              entry: this.entry,
            });
        } else {
          this.$emit("update:modelValue", status);
        }
      },
    },
    quickStatusSwapEnabled() {
      return (
        typeof Cypress == "undefined" &&
        this.status &&
        this.statuses?.length == 2
      );
    },
  },
  methods: {
    onMouseDown(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled) {
        this.mouseDown = true;
        this.skipQuickSwapTimeout = setTimeout(() => {
          if (this.mouseDown) {
            this.disableQuickSwap = true;
            this.$refs.reference.$el.click();
          }
        }, 500);
      }
    },
    onMouseUp(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled) {
        this.mouseDown = false;
        if (this.skipQuickSwapTimeout) {
          clearTimeout(this.skipQuickSwapTimeout);
          if (!this.disableQuickSwap && !this.disabled)
            this.status = this.statuses.find((s) => s.id != this.status.id);
          this.disableQuickSwap = false;
        } else {
          clearTimeout(this.skipQuickSwapTimeout);
        }
      }
    },
    animationEnterDropdown(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [20, 0],
        complete: done,
      });
    },
    animationLeaveDropdown(el, done) {
      this.$anime({
        targets: el,
        opacity: [1, -0.2],
        translateY: [0, 20],
        complete: done,
      });
    },
    onMountSearch() {
      setTimeout(() => {
        this.$refs.search?.focus();
      }, 100);
    },
  },
};
</script>

<style module>
@container calendarEventContent (width < 96px) {
  .root {
    display: none;
  }
}
</style>
