<template>
  <div class="relative">
    <img
      v-if="entry.cover?.type == 'external'"
      :src="entry.cover.url"
      loading="lazy"
      :alt="entry.name"
      class="pointer-events-none h-full w-full object-cover object-center dark:brightness-50"
    />
    <slot />
  </div>
</template>

<script>
import { Coloring } from "../mixins/Coloring";
export default {
  mixins: [Coloring],
  props: {
    modelValue: Object,
    entry: Object,
  },
  computed: {
    cover: {
      get() {
        return this.modelValue;
      },
    },
  },
};
</script>
