<template>
  <div class="flex items-center">
    <StatusIndicator :status="status" />
    <span class="ml-3 block truncate">
      {{ status?.name || "No status" }}
    </span>
    <span
      v-if="status && entry"
      class="ml-1 block truncate text-xs text-neutral-500"
    >
      <IconHelper name="dot" size="12" class="inline" />
      {{ entry?.name }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Object,
  },
  computed: {
    status() {
      return this.modelValue;
    },
    entry() {
      return this.$store.getters.entries.find(
        (entry) => entry.id === this.modelValue.entry_id,
      );
    },
  },
};
</script>
