<template>
  <div>
    <ListboxHelper v-model="level">
      <template v-slot:button>
        <button
          role="button"
          class="w-full"
          data-test="entry_priority_settings"
        >
          <slot name="button">
            <span class="sr-only">Set priority</span>
            <ButtonComponent
              title="Set priority"
              :variant="variant"
              :size="size"
              :color="prioColor"
              :colorWeight="colorWeight"
            >
              <IconHelper name="flag" size="20" />
              <span v-if="showLabel && variant != 'round'">
                Priority {{ priority?.level }}
              </span>
            </ButtonComponent>
          </slot>
        </button>
      </template>

      <ListboxOption
        as="template"
        v-for="(priority, index) in priorities"
        :key="index"
        :value="priority"
        v-slot="{ active, selected }"
        :data-test="'set_priority_' + priority"
      >
        <li
          :class="[
            selected ? 'font-semibold' : 'font-normal',
            active
              ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300'
              : 'text-neutral-900 dark:text-neutral-400',
            'relative cursor-default select-none whitespace-nowrap py-2 pl-3 pr-9',
          ]"
        >
          Priority {{ priority }}
        </li>
      </ListboxOption>
      <ListboxOption :value="null" v-slot="{ active, selected }">
        <li
          :class="[
            selected ? 'font-semibold' : 'font-normal',
            active
              ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300'
              : 'text-neutral-900 dark:text-neutral-400',
            'relative cursor-default select-none whitespace-nowrap py-2 pl-3 pr-9',
          ]"
        >
          No Priority
        </li>
      </ListboxOption>
    </ListboxHelper>
  </div>
</template>

<script>
import { addonBlueprint } from "@/addonBlueprint";
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  data() {
    return {
      level: this.modelValue?.level,
      priorities: [1, 2, 3, 4],
    };
  },
  watch: {
    modelValue(priority) {
      this.level = priority?.level;
    },
    level(value) {
      if (value) {
        if (this.priority) this.priority = { ...this.priority, level: value };
        else {
          this.priority = {
            ...addonBlueprint("priority", this.entry, this),
            ...{ level: value },
          };
        }
      } else {
        this.priority = null;
      }
    },
  },
  computed: {
    priority: {
      get() {
        return this.modelValue;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.priority = value;

        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
        //this.$emit("update:modelValue", value);
      },
    },
    prioColor() {
      switch (this.priority?.level) {
        case 1:
          return "red";
        case 2:
          return "orange";
        case 3:
          return "yellow";
        default:
          return this.color;
      }
    },
  },
};
</script>
