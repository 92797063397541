<template>
  <div :class="$style.root">
    <TransitionGroupHelper>
      <div v-if="!userHasInEntryActiveTimeTracking" key="settings">
        <PopoverHelper>
          <template v-slot:button>
            <ButtonComponent
              variant="minimal"
              size="sm"
              data-test="time_trackings_display_action_settings"
            >
              <IconHelper name="timer" size="16" key="icon" />
              <span
                class="font-mo/no te/xt-xs font-normal"
                data-test="time_trackings_display_total_time"
              >
                {{
                  $moment
                    .utc(
                      time_trackings
                        ?.filter((tt) => tt.end_at !== null)
                        .map((tt) => {
                          if (
                            !$moment.utc(tt.start_at).isValid() ||
                            !$moment.utc(tt.end_at).isValid()
                          )
                            return 0;
                          return (
                            $moment.utc(tt.end_at).unix() -
                            $moment.utc(tt.start_at).unix()
                          );
                        })
                        .reduce((a, b) => a + b, 0) * 1000,
                    )
                    .format("H:mm:ss")
                }}
              </span>
            </ButtonComponent>
          </template>

          <div class="flex flex-col gap-2 p-2">
            <LabelHelper> Time Trackings </LabelHelper>
            <TimeTrackingsWrapper
              v-model="time_trackings"
              :entry="entry"
              class="max-h-64 min-w-64 overflow-y-auto"
            />
          </div>
        </PopoverHelper>
      </div>

      <ButtonComponent
        v-if="!activelyTimeTrackedEntry"
        @click.stop="() => startTimeTracking(entry)"
        variant="minimal"
        size="sm"
        title="Start time tracking"
        key="start"
      >
        <IconHelper name="play" size="16" strokeWidth="2" />
      </ButtonComponent>

      <ButtonComponent
        v-if="
          activelyTimeTrackedEntry && activelyTimeTrackedEntry.id == entry.id
        "
        type="button"
        ref="reference"
        @click.stop="() => stopTimeTracking(entry)"
        data-test="time_trackings_display_action_stop"
        variant="minimal"
        size="sm"
        title="Stop time tracking"
        key="stop"
      >
        <StopIcon class="mt-[-0.0625rem] h-4 w-4" />
      </ButtonComponent>

      <div
        v-if="
          activeTimeTrackingByUser &&
          activeTimeTrackingByUser.entry_id == entry.id
        "
        role="group"
        class="flex items-center gap-x-2"
        key="active"
      >
        <div
          v-if="entry.schedule?.duration"
          class="relative w-32 bg-slate-100 md:rounded-full"
          style="position: relative; touch-action: none"
        >
          <div
            class="h-1.5 bg-slate-700 md:rounded-l-xl md:rounded-r-md"
            :style="{ width: 'calc(' + progressPercentage + '% - 0.25rem)' }"
          ></div>
          <div
            class="absolute top-1/2 -translate-x-1/2"
            :style="{ left: progressPercentage + '%' }"
          >
            <div
              class="h-4 w-1 rounded-full bg-slate-700"
              style="
                position: absolute;
                transform: translate(-50%, -50%);
                touch-action: none;
                left: 32.8125%;
              "
            >
              <div
                style="
                  border: 0px;
                  clip: rect(0px, 0px, 0px, 0px);
                  clip-path: inset(50%);
                  height: 1px;
                  margin: -1px;
                  overflow: hidden;
                  padding: 0px;
                  position: absolute;
                  width: 1px;
                  white-space: nowrap;
                "
              ></div>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-1.5">
          <output
            aria-live="off"
            class="rounded-md font-mono text-sm text-neutral-500 dark:text-neutral-400"
            data-test="time_trackings_display_active_time"
          >
            {{
              getDurationOfTimeTracking(activeTimeTrackingByUser).format(
                "hh:mm:ss",
                {
                  stopTrim: "m",
                },
              )
            }}
          </output>
          <span
            v-if="entry.schedule?.duration"
            style="font-family: none"
            class="hidden text-sm text-neutral-300 md:block dark:text-neutral-500"
            aria-hidden="true"
            >/</span
          >
          <span
            v-if="entry.schedule?.duration"
            class="hidden rounded-md font-mono text-sm text-neutral-500 md:block dark:text-neutral-400"
          >
            {{ scheduleDuration }}
          </span>
        </div>
      </div>
    </TransitionGroupHelper>
  </div>
</template>

<script>
import TimeTrackingsWrapper from "./partials/TimeTrackingsWrapper.vue";

import { StopIcon } from "@heroicons/vue/24/outline";
import { TimeTrackingsMixin } from "./mixins/TimeTrackingsMixin";
import { Tracking } from "../mixins/Tracking";

export default {
  mixins: [TimeTrackingsMixin, Tracking],
  props: {
    modelValue: Object,
    entry: Object,
  },
  computed: {
    time_trackings: {
      get() {
        return this.modelValue;
      },
      set(time_trackings) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.time_trackings = time_trackings;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    user() {
      return this.$store.state.user;
    },
    userHasActiveTimeTracking() {
      return this.user.time_trackings.find(
        (tt) => tt.user_id == this.user.id && !tt.end_at,
      );
    },
    userHasInEntryActiveTimeTracking() {
      return this.entry.time_trackings.find(
        (tt) => tt.user_id == this.user.id && !tt.end_at,
      );
    },
  },
  components: { TimeTrackingsWrapper, StopIcon },
};
</script>

<style module lang="scss">
.root {
  @apply relative flex items-center gap-x-2;
}

@container calendarEventContent (width < 480px) {
  .root {
    display: none;
  }
}
@container calendarEventContent (height < 48px) {
  .root {
    display: none;
  }
}
</style>
