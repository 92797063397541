<template>
  <svg
    class="progress-ring"
    :viewBox="`0 0 ${radius} ${radius}`"
    preserveAspectRatio="xMidYMid meet"
  >
    <circle
      class="progress-ring__circle"
      :stroke="colors[color ? color : 'neutral'][500]"
      :stroke-width="strokeWidth"
      fill="transparent"
      :r="(radius - padding) / 2 - strokeWidth / 2"
      :cx="(radius - padding) / 2"
      :cy="(radius - padding) / 2"
      stroke-linecap="round"
    />
  </svg>
</template>

<!-- stroke-linecap="round" -->
<script>
import colors from "tailwindcss/colors";
export default {
  props: {
    percentage: {
      type: Number,
      default: 50,
      validator: (value) => value >= 0 && value <= 100,
    },
    color: {
      type: String,
      default: "neutral",
    },
    radius: {
      type: Number,
      default: 32,
    },
    strokeWidth: {
      type: Number,
      default: 8,
    },
    padding: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.updateProgress();
  },
  watch: {
    percentage: function () {
      this.updateProgress();
    },
  },
  data() {
    return {
      colors: colors,
    };
  },
  methods: {
    updateProgress() {
      const circle = this.$el.querySelector(".progress-ring__circle");
      const radius = circle.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset =
        circumference - (this.percentage / 100) * circumference;
    },
  },
};
</script>
<style scoped>
.progress-ring__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>
