<template>
  <Teleport to="body">
    <Transition
      @enter="
        (el, done) => {
          $anime({
            targets: el,
            opacity: [0, 1],
            complete: done,
          });
        }
      "
      :css="false"
    >
      <!-- <TransitionRoot
      as="template"
      :show="draggedEntriesInterpreted?.length ? true : false"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
      > -->
      <div
        id="draggable-wrapper"
        v-if="draggedEntriesInterpreted.length"
        ref="draggableWrapper"
        class="pointer-events-none fixed left-0 top-0 z-[999999] flex w-64 flex-col gap-2 rounded-md bg-white p-2 shadow-xl will-change-transform dark:bg-black dark:ring-1 dark:ring-neutral-700"
        :style="{
          transform:
            'translate(' + cursorPosition.x + 'px, ' + cursorPosition.y + 'px)',
        }"
      >
        <!-- {{ cursorPosition }} -->
        <!-- {{ $cursorPosition }} -->
        <div class="relative">
          <!-- {{ $store.getters.draggedBL }} /// {{ $store.getters.hovered }} /// {{ $store.getters.schema }} -->

          <EntryDisplay
            v-for="(_, index) in draggedEntriesInterpreted.slice(0, 3)"
            :key="index"
            :modelValue="draggedEntriesInterpreted[index]"
            position="draggable"
            :display="{
              name: true,
              status: true,
              status_name: true,
              description: false,
              schedule: true,
              links: true,
              output: false,
              input: false,
              columns: false,
              leftover: false,
              routine: false,
              time_trackings: false,
              settings: false,
            }"
            :disableViewportChecking="true"
            :disableInitialization="true"
          />
        </div>
        <div
          v-if="draggedEntriesInterpreted.length > 3"
          class="flex justify-center"
        >
          <span class="text-sm text-neutral-700 dark:text-neutral-400"
            >+{{ draggedEntriesInterpreted.length - 3 }} more</span
          >
        </div>
      </div>
      <!-- </TransitionChild>
    </TransitionRoot> -->
    </Transition>
  </Teleport>
</template>

<script>
// import EntryComponent from "../entries/EntryComponent_Deprecated.vue";
import { Applicators } from "../mixins/Applicators";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Statusing } from "../mixins/Statusing";

export default {
  mixins: [Schema, Applicators, Statusing, Scheduling],
  data() {
    return {
      draggedEntriesInterpreted: [],
      cursorPosition: {
        x: 0,
        y: 0,
      },
      transitioningCursorPosition: {
        x: 0,
        y: 0,
      },
    };
  },
  watch: {
    "$store.getters.dragged.length": function (n, o) {
      if (n > 0 && o == 0) {
        this.interpretEntries();
        if (navigator.userAgent.includes("Mobile")) {
          this.$nextTick(() => {
            this.updateCursorPosition({
              type: "touchmove",
              touches: [
                {
                  pageX: this.$cursorPosition.x,
                  pageY: this.$cursorPosition.y,
                },
              ],
            });
          });
        }
      }
      if (n == 0) {
        this.draggedEntriesInterpreted = [];
      }
    },
    "$store.getters.schema": function () {
      if (this.$store.getters.dragged.length) {
        if (this.interprationTimeout) clearTimeout(this.interprationTimeout);
        this.interprationTimeout = setTimeout(() => {
          this.interpretEntries();
        }, 50);
      }
    },
  },
  computed: {
    // dragged() {
    //   return this.$store.getters.dragged
    //     ? JSON.parse(JSON.stringify(this.$store.getters.dragged))?.map(
    //         (entry) =>
    //           this.$store.getters.schema &&
    //           entry.id != this.$store.getters.hovered.id
    //             ? this.applyInput(
    //                 this.inputSchema(this.$store.getters.schema, entry),
    //                 entry,
    //               )
    //             : entry,
    //       )
    //     : [];
    // },
  },
  mounted() {
    window.addEventListener("mousemove", this.updateCursorPosition);
    window.addEventListener("touchmove", this.updateCursorPosition);
  },

  beforeUnmount() {
    window.removeEventListener("mousemove", this.updateCursorPosition);
    window.removeEventListener("touchmove", this.updateCursorPosition);
  },
  methods: {
    interpretEntries() {
      this.draggedEntriesInterpreted = this.$store.getters.dragged
        ? JSON.parse(JSON.stringify(this.$store.getters.dragged))?.map(
            (entry) =>
              this.$store.getters.schema &&
              entry.id != this.$store.getters.hovered.id
                ? this.applyInput(
                    this.inputSchema(this.$store.getters.schema, entry),
                    entry,
                  )
                : entry,
          )
        : [];
    },
    updateCursorPosition(e) {
      if (e.type == "touchmove") {
        e = e.touches[0];

        this.cursorPosition.x =
          this.$cursorPosition.x - this.$refs.draggableWrapper?.clientWidth / 2;

        this.cursorPosition.y =
          this.$cursorPosition.y -
          this.$refs.draggableWrapper?.clientHeight -
          10;

        if (
          this.cursorPosition.y <
          this.$refs.draggableWrapper?.clientHeight - 10
        )
          this.cursorPosition.y +=
            this.$refs.draggableWrapper?.clientHeight + 20;
      } else {
        this.cursorPosition.x = this.$cursorPosition.x + 10;
        this.cursorPosition.y = this.$cursorPosition.y + 10;
      }

      if (this.cursorPosition.x < 10) this.cursorPosition.x = 10;
      if (this.cursorPosition.y < 10) this.cursorPosition.y = 10;

      if (
        this.cursorPosition.x + this.$refs.draggableWrapper?.clientWidth >
        window.innerWidth - 10
      )
        this.cursorPosition.x =
          window.innerWidth - this.$refs.draggableWrapper?.clientWidth - 10;
      if (
        this.cursorPosition.y + this.$refs.draggableWrapper?.clientHeight >
        window.innerHeight + window.scrollY - 10
      )
        this.cursorPosition.y =
          window.innerHeight +
          window.scrollY -
          this.$refs.draggableWrapper?.clientHeight -
          10;
    },
  },
};
</script>
