<template>
  <div
    class="flex flex-wrap items-center justify-between gap-2 sm:flex-nowrap"
    data-tour="output_group_header"
  >
    <div class="flex flex-1 cursor-default flex-wrap items-center gap-2">
      <!-- <ButtonComponent
        @click="showConfig = !showConfig"
        class="flex flex-shrink items-center gap-2"
      > -->

      <h2
        v-if="group.name"
        class="text-sm font-semibold text-neutral-700 dark:text-neutral-300"
      >
        {{ group.name }}
      </h2>

      <div class="text-xs">
        {{
          groupEntries
            ? groupEntries?.length +
              (!group.name
                ? " " + (groupEntries?.length != 1 ? "Entries" : "Entry")
                : "")
            : ""
        }}
      </div>
      <!-- </ButtonComponent> -->

      <TourHelper
        v-if="entry.id == $route.params.id"
        hook="output_settings"
        size="sm"
        variant="minimal"
        class="h-5"
      />
      <OutputSettings
        v-if="editable && showToolbar && renderType != 'board'"
        v-model="output"
        :entry="entry"
        :showDelete="false"
        size="xs"
        variant="minimal"
        class="hidden flex-1 flex-wrap items-center justify-end gap-3 sm:flex"
      />
    </div>

    <Menu v-if="!disabled" as="div" v-slot="{ open }">
      <div>
        <MenuButton
          ref="reference"
          class="flex items-start rounded-full bg-gray-100 align-text-top text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 focus:ring-offset-gray-100 focus-visible:outline-none dark:bg-neutral-900 dark:focus:ring-offset-neutral-900"
          data-test="entry_output_options"
        >
          <span class="sr-only">Open options</span>
          <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <OverlayComponent v-if="open" @click="open = false" />

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems class="absolute right-0 z-[999] mt-2">
          <div
            ref="floating"
            :style="floatingStyles"
            class="w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:ring-neutral-700"
          >
            <MenuItem v-slot="{ active }">
              <a
                @click="
                  groupEntries.forEach((e) => $store.dispatch('selected', e))
                "
                :class="[
                  active
                    ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300'
                    : 'text-gray-700 dark:text-neutral-400',
                  'block cursor-pointer px-4 py-2 text-sm',
                ]"
              >
                Select all
              </a>
            </MenuItem>
            <MenuItem v-if="editable" v-slot="{ active }">
              <a
                @click="showModal = true"
                :class="[
                  active
                    ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300'
                    : 'text-gray-700 dark:text-neutral-400',
                  'block  cursor-pointer px-4 py-2 text-sm',
                ]"
                data-test="entry_output_edit"
              >
                Edit Output
              </a>
            </MenuItem>
            <MenuItem v-if="editable" v-slot="{ active }">
              <a
                @click="output = null"
                :class="[
                  active
                    ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300'
                    : 'text-gray-700 dark:text-neutral-400',
                  'block cursor-pointer px-4 py-2 text-sm',
                ]"
                data-test="entry_output_delete"
              >
                Delete Output
              </a>
            </MenuItem>
            <MenuItem
              v-for="(action, index) in group.actions"
              :key="index"
              v-slot="{ active }"
            >
              <a
                @click="action.fn(groupEntries)"
                :class="[
                  active
                    ? 'bg-gray-100 text-gray-900 dark:bg-neutral-900 dark:text-neutral-300'
                    : 'text-gray-700 dark:text-neutral-400',
                  'block cursor-pointer px-4 py-2 text-sm',
                ]"
              >
                {{ action.name }}
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>

    <ModalHelper :show="showModal" @close="showModal = false">
      <template v-slot:title> Edit Output </template>
      <OutputSettings v-model="output" :entry="entry" color="secondary" />
    </ModalHelper>
  </div>
</template>
<script setup>
import { EllipsisVerticalIcon } from "@heroicons/vue/24/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

import { ref } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";

import OverlayComponent from "../../helpers/OverlayComponent.vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-start",
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>
<script>
export default {
  props: {
    modelValue: Object,
    entry: Object,
    group: Object,
    groupEntries: Array,
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
    renderType: {
      type: String,
      default: "list",
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showConfig: false,
      showModal: false,
    };
  },
  computed: {
    // entry: {
    //   get() {
    //     return this.modelValue;
    //   },
    //   // set(value) {
    //   //   this.$emit("update:modelValue", value);
    //   // },
    // },
    output: {
      get() {
        return this.modelValue;
      },
      set(output) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
  },
};
</script>
