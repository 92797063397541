<template>
  <div ref="reference" class="group/title">
    <slot />

    <div
      ref="floating"
      v-if="$slots.title && !navigator.userAgent.includes('Mobile')"
      :style="floatingStyles"
      class="pointer-events-none z-10 mx-auto flex w-max translate-y-full items-center gap-2 truncate rounded-md bg-white px-2 py-1 text-left text-xs text-neutral-700 opacity-0 drop-shadow-md group-hover/title:opacity-100 group-hover/title:transition-opacity group-hover/title:delay-300 dark:bg-black dark:text-neutral-300 dark:ring-1 dark:ring-neutral-700 dark:drop-shadow-none"
    >
      <slot name="title" />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { useFloating, shift, flip, autoUpdate, offset } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const props = defineProps({
  placement: {
    type: String,
    default: "bottom-center",
  },
});

const { floatingStyles } = useFloating(reference, floating, {
  placement: props.placement,
  whileElementsMounted: autoUpdate,
  middleware: [
    offset(2),
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>

<script>
export default {
  data() {
    return {
      navigator: navigator,
    };
  },
};
</script>
