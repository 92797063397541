<template>
  <PopoverHelper placement="right">
    <template v-slot:button>
      <slot name="button">
        <span class="sr-only"> Templates</span>
        <ButtonComponent
          :color="color"
          data-test="entry_template_settings_button"
        >
          Templates
        </ButtonComponent>
      </slot>
    </template>
    <div class="z-100 p-2">
      <UseTemplatePopover
        :entry="entry"
        :size="size"
        color="white"
        variant="menu"
        @templateSelected="$emit('close')"
      />
      <SaveTemplate :entry="entry" :size="size" color="white" variant="menu" />
    </div>
  </PopoverHelper>
</template>

<script>
import UseTemplatePopover from "./partials/UseTemplatePopover";
import SaveTemplate from "./partials/SaveTemplate";
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  components: {
    UseTemplatePopover,
    SaveTemplate,
  },
};
</script>
