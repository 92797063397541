<template>
  <div class="relative">
    <div
      :class="`bg-${color}-200`"
      class="flex h-2 overflow-hidden rounded text-xs"
    >
      <div
        v-for="(value, index) in values"
        :key="index"
        :style="{ width: value.value + '%' }"
        :class="`bg-${value.color}-500`"
        class="flex flex-col justify-center whitespace-nowrap text-center text-white shadow-none"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "neutral",
    },
    values: {
      type: Array,
    },
  },
};
</script>
