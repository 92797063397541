<!-- MyChart.vue -->
<template>
  <div>
    <canvas ref="myChart" class="h-full !w-full"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import colors from "tailwindcss/colors";

// eslint-disable-next-line no-unused-vars
let width, height, gradient;
function getGradient(color, ctx, chartArea) {
  //   const chartWidth = chartArea.right - chartArea.left;
  //   const chartHeight = chartArea.bottom - chartArea.top;
  //if (!gradient || width !== chartWidth || height !== chartHeight) {
  // Create the gradient because this is either the first render
  // or the size of the chart has changed
  //   width = chartWidth;
  //   height = chartHeight;
  gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
  gradient.addColorStop(1, colors[color][100]);
  gradient.addColorStop(0, colors[color][50]);
  //}

  return gradient;
}

export default {
  props: {
    labels: {
      type: Array,
      required: true,
    },
    datasets: {
      type: Array,
      required: true,
    },
  },
  data() {
    this.chart = null;
    return {
      test: 1,
    };
  },
  watch: {
    datasets: {
      handler(n, o) {
        if (JSON.stringify(n) == JSON.stringify(o)) {
          return;
        }
        this.setData(n);
        this.chart.update("none");
      },
      deep: true,
    },
  },
  mounted() {
    this.renderChart();
    this.setData(this.datasets);
  },
  methods: {
    renderChart() {
      const ctx = this.$refs.myChart.getContext("2d");

      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.labels,
        },
        maintainAspectRatio: false, // Disable aspect ratio
        responsive: true, // Disable responsiveness
        // devicePixelRatio: 2, // Adjust for higher resolution displays

        options: {
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          },
          elements: {
            point: {
              pointStyle: "line", // Set pointStyle to 'line' to hide data points
            },
          },
          plugins: {
            tooltip: {
              enabled: false, // Hide tooltips
            },
            legend: {
              display: false, // Hide legend
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                display: false, // Hide x-axis grid lines
                tickLength: 0,
              },

              border: {
                display: false,
              },
              ticks: {
                display: false, // Hide x-axis labelsbackdropPadding
                padding: 0,
                backdropPadding: 0,
              },
              axis: {
                display: false,
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                display: false,
                tickLength: 0,
              },
              border: {
                display: false,
              },
              ticks: {
                display: false, // Hide y-axis labels
                padding: 0,
                backdropPadding: 0,
              },
              axis: {
                display: false,
              },
            },
          },
        },
        elements: {
          point: {
            pointStyle: "line", // Hide data points
          },
        },
      });
    },
    setData(datasets) {
      this.chart.data.datasets = datasets.map((dataset) => {
        return {
          label: "Sample Chart",
          borderColor: colors[dataset.color][500],
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return;
            }
            return getGradient(dataset.color, ctx, chartArea);
          },
          data: [1, 1, 2, 1, 2],
          tension: 0.25,
          fill: true, // Fill the area under the line
          borderWidth: 0, // Set line width to 0 to hide the line

          ...dataset,
        };
      });
      this.chart.update();
    },
  },
  beforeUnmount() {
    // Destroy the chart instance to prevent memory leaks
    if (this.chart) {
      this.chart.destroy();
    }
  },
};
</script>
