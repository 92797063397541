<!-- eslint-disable no-unreachable -->
<template>
  <Transition @enter="animationEnter" @leave="animationLeave">
    <div
      v-if="selectedTemplate"
      class="flex flex-col gap-4 divide-y divide-neutral-200"
    >
      <TemplateDisplay
        :modelValue="selectedTemplate"
        :showPreview="true"
        @click="selectedTemplate = null"
      />
      <div class="flex justify-between gap-4 pt-4">
        <ButtonComponent @click="selectedTemplate = null" color="secondary">
          Close
        </ButtonComponent>
        <ButtonComponent
          @click="
            applyTemplateToEntry(selectedTemplate, entry),
              $emit('templateSelected', selectedTemplate),
              (selectedTemplate = null)
          "
          color="primary"
          data-test="button_use_template"
        >
          Use
        </ButtonComponent>
      </div>
    </div>
    <div
      v-else
      class="container relative mx-auto flex w-full max-w-5xl flex-col gap-4 divide-y divide-neutral-100 md:flex-row md:divide-x md:divide-y-0 dark:divide-neutral-900"
    >
      <div class="flex min-w-32 flex-wrap gap-2 md:flex-col">
        <h5
          class="mb-2 basis-full font-semibold text-neutral-900 md:basis-0 dark:text-neutral-300"
        >
          Filter
        </h5>
        <TransitionGroupHelper
          :enter="{
            // easing: 'easeOutExpo',
            translateY: [50, 0],
            opacity: [0, 1],
          }"
        >
          <div v-for="tag in tagsWithTemplates" :key="tag" class="font-mono">
            <ButtonComponent
              size="xs"
              :color="tagSelected == tag ? 'primary' : 'secondary'"
              @click="
                tagSelected == tag ? (tagSelected = null) : (tagSelected = tag)
              "
              class="!font-normal text-neutral-500"
            >
              #{{
                tag
                /*.replace("-", "-")
                 .toUpperCase()
                 .replace(/\b\w/g, (c) => c.toUpperCase()) */
              }}
            </ButtonComponent>
          </div>
        </TransitionGroupHelper>
      </div>
      <div class="flex flex-col gap-4 pt-4 md:pl-4 md:pt-0">
        <div
          v-if="!hideCustomTemplates"
          class="grid gap-4 md:grid-cols-2 lg:grid-cols-3"
        >
          <TransitionGroupHelper
            :enter="{
              // easing: 'easeOutExpo',
              translateY: [50, 0],
              opacity: [0, 1],
            }"
          >
            <div v-for="item in customTemplates" :key="item.id">
              <div
                class="group flex h-full cursor-pointer flex-col gap-2 rounded-xl border border-neutral-100 p-5 hover:border-neutral-200 hover:bg-neutral-50 dark:border-neutral-900 dark:hover:border-neutral-800 dark:hover:bg-neutral-950"
              >
                <TemplateDisplay
                  :modelValue="item"
                  :showPreview="false"
                  @click="handleTemplateClick(item)"
                  size="sm"
                >
                  <template #top>
                    <slot name="template_top" :template="item" />
                  </template>
                </TemplateDisplay>
              </div>
            </div>
          </TransitionGroupHelper>
        </div>
        <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          <TransitionGroupHelper
            :enter="{
              // easing: 'easeOutExpo',
              translateY: [50, 0],
              opacity: [0, 1],
            }"
          >
            <div
              v-for="item in templatesFiltered.filter((t) => t.global)"
              :key="item.id"
            >
              <div
                class="group flex h-full cursor-pointer flex-col gap-2 rounded-xl border border-neutral-100 p-5 hover:border-neutral-200 hover:bg-neutral-50 dark:border-neutral-900 dark:hover:border-neutral-800 dark:hover:bg-neutral-950"
              >
                <TemplateDisplay
                  :modelValue="item"
                  :showPreview="false"
                  @click="handleTemplateClick(item)"
                  size="sm"
                >
                  <template #top>
                    <slot name="template_top" :template="item" />
                  </template>
                </TemplateDisplay>
              </div>
            </div>
          </TransitionGroupHelper>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { Schema } from "../mixins/Schema";

// import templates from "@/templates";

import { Templating } from "../mixins/Templating";
import { Scheduling } from "../mixins/Scheduling";
// import { nanoid } from "nanoid";
import { Applicators } from "../mixins/Applicators";
import { Statusing } from "../mixins/Statusing";
export default {
  props: {
    modelValue: Object,
    hideCustomTemplates: Boolean,
  },
  mixins: [Schema, Statusing, Scheduling, Templating, Applicators],
  data() {
    return {
      selectedTemplate: null,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
    space() {
      return this.$store.getters.space;
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },
    entry() {
      return this.modelValue;
    },
  },
  watch: {
    "selected.length": function () {
      if (this.entry && this.selected.length == 1) {
        this.setTemplate(this.templates[this.selected[0]]);
        this.selected = [];
      }
      if (!this.entry) {
        if (
          this.tagSelected &&
          !this.tagsWithTemplates.includes(this.tagSelected)
        ) {
          this.tagSelected = null;
        }
      }
    },
  },
  methods: {
    handleTemplateClick(template) {
      this.$emit("clickTemplate", template);

      this.selectedTemplate = template;
      // if (this.enableMultipleSelection) {
      //   if (this.selected.includes(template.id)) {
      //     this.selected.splice(this.selected.indexOf(template.id), 1);
      //   } else {
      //     this.selected.push(template.id);
      //   }
      // }
    },
    animationEnter(el, done) {
      this.$anime({
        targets: el,
        translateX: [50, 0],
        opacity: [0, 1],
        delay: 500,
        // easing: "easeOutExpo",
        duration: 1000,
        complete: done,
      });
    },
    animationLeave(el, done) {
      this.$anime({
        targets: el,
        translateX: [0, -50],
        opacity: [1, -0.2],
        // easing: "easeOutExpo",
        duration: 1000,
        complete: done,
        begin: () => {
          el.style.width = "100%";
          el.style.position = "absolute";
        },
      });
    },
  },
};
</script>
