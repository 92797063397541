<template>
  <div>
    {{ entries.map((e) => e.name) }}
    <!-- <section
      v-if="entries.length"
      style="flex-direction: column; align-items: flex-start"
      data-test="output_display"
    >
      <button
        @click="showOutput = !showOutput"
        class="flex items-center gap-2"
        data-test="toggle_show_linked_button"
      >
        <span
          :class="{
            'bg-neutral-100 shadow-xl': showOutput,
          }"
          class="rounded-full p-1.5"
        >
          <span
            :class="{
              'text-pensive-blue': showOutput,
              'text-neutral-500': !showOutput,
            }"
          >
            <ChevronRightIcon
              :class="{ 'rotate-90': showOutput }"
              class="h-3 w-3 transition duration-200"
            />
          </span>
        </span>

        <span
          :class="{ 'font-bold text-neutral-900': showOutput }"
          class="text-xs uppercase text-neutral-500"
        >
          {{ entries?.length }} Linked Entries
        </span>
      </button>
      <OutputDisplay
        v-if="showOutput"
        v-model="defaultOutput"
        ref="output"
        :entry="entry"
        :filter="filter"
        :sorting="entry.output?.sorting"
        :editable="false"
        :showInput="false"
        :position="position"
        class="mt-5"
      />
    </section> -->
  </div>
</template>

<script>
import { Filtering } from "../mixins/Filtering";
import { Schema } from "../mixins/Schema";

export default {
  props: {
    modelValue: Object,
    inputs: Array,
    position: String,
  },
  watch: {
    modelValue: {
      handler(n) {
        this.entry = n;
      },
      immediate: true,
    },
    "entries.length": function (n, o) {
      if (n > o && o == 0) this.showOutput = true;
    },
  },
  data() {
    return {
      entry: this.modelValue,
      showOutput: !this.modelValue.output,
      filter: (e) =>
        !this.entry.output ||
        this.outputComputed.findIndex((i) => i.id == e.id) == -1,
    };
  },
  mounted() {
    this.pullData();
  },
  methods: {
    pullData() {
      this.$nextTick(() => {
        this.$store.dispatch("pull", {
          filters: this.defaultOutput.data,
        });
      });
    },
  },
  mixins: [Schema, Filtering],
  components: {},
  computed: {
    outputComputed() {
      return this.filterEntries(this.outputSchema(this.entry));
    },
    entries() {
      return this.filterEntries(this.defaultOutput.data).filter(this.filter);
    },
    defaultOutput() {
      return { data: [{ key: "links", op: "any", val: [this.entry.id] }] };
    },
  },
};
</script>
