<template>
  <TitleHelper
    :class="{
      '!text-xs': position == 'calendar',
    }"
    class="ps_procrastination_display flex cursor-default items-center rounded bg-red-50 px-1 py-0.5 text-sm text-red-500 dark:bg-red-950 dark:text-red-500"
  >
    <template #title>
      Procrastinated {{ procrastination?.count }} times
    </template>
    <span
      data-test="entry-procrastination"
      :data-procrastination-count="procrastination?.count"
    >
      {{ procrastination?.count }}
    </span>
    <!-- <SmoothText
      :text="procrastination?.count"
      data-test="entry-procrastination"
      :data-procrastination-count="procrastination?.count"
    /> -->
  </TitleHelper>
</template>

<script>
export default {
  props: {
    modelValue: Object,
    position: String,
  },
  computed: {
    procrastination: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
