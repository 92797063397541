<template>
  <div ref="boundaries">
    <!-- <div class="absolute left-0 top-0">
      </div> -->
    <!-- <span class="bg-red-500" v-if="!visible">!visible</span>
    <span class="bg-green-500" v-if="visible">visible</span> -->
    <slot v-if="visible" />

    <div v-else :class="[$style.test]">
      <div :class="$style.skeletonLoader" />
    </div>
  </div>
</template>

<script>
// import { Viewporting } from "../mixins/Viewporting";
export default {
  props: {
    visible: Boolean,
  },
  watch: {
    visible: {
      handler: function (n) {
        if (n === false)
          this.$refs.boundaries.style.minHeight =
            this.$refs.boundaries.getBoundingClientRect().height + "px";
        // else this.$refs.boundaries.style.minHeight = null;
      },
      immetiate: true,
    },
  },
  // mixins: [Viewporting],
};
</script>

<style module>
.skeletonLoader {
  composes: skeletonLoader from "@/styles/skeleton-loader.module.css";
  min-height: 24px;
  /* background-color: red; */
}
.test {
  margin: var(--ps-entry-display-padding-y) var(--ps-entry-display-padding-x);
  border-radius: var(--ps-entry-display-border-radius);
}
</style>
