<template>
  <MenuHelper class="ps_priority_display">
    <template v-slot:button>
      <span class="sr-only">Set priority</span>
      <div
        class="inline-flex items-center gap-x-0.5 rounded-md bg-neutral-50 px-1 py-0.5 text-sm font-semibold text-neutral-900"
        :class="{
          'bg-red-100 text-red-600 dark:bg-red-950 dark:text-red-400':
            priority?.level === 1,
          'bg-orange-100 text-orange-600 dark:bg-orange-950 dark:text-orange-400':
            priority?.level === 2,
          'bg-yellow-100 text-yellow-600 dark:bg-yellow-950 dark:text-yellow-400':
            priority?.level === 3,
          'bg-neutral-100 text-neutral-600 dark:bg-neutral-950 dark:text-neutral-400':
            priority?.level === 4,

          '!text-xs': position == 'calendar',
        }"
        :data-priority="priority?.level"
      >
        <FlagIcon class="h-3 w-3" />
        {{ priority?.level }}
      </div>
    </template>
    <PriorityMenu
      @update:modelValue="(value) => $emit('update:modelValue', value)"
      v-model="priority"
    />
  </MenuHelper>
</template>

<script>
import { FlagIcon } from "@heroicons/vue/20/solid";
import PriorityMenu from "./partials/PriorityMenu";
export default {
  props: {
    modelValue: Object,
    entry: Object,
    position: String,
  },
  components: {
    PriorityMenu,
    FlagIcon,
  },

  data() {
    return {
      priorities: [1, 2, 3, 4, null],
    };
  },
  computed: {
    priority: {
      get() {
        return this.modelValue;
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.priority = value;

        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
  },
};
</script>
