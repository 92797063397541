export const Touring = {
    data() {
        return {
            tour: null,
            step: 0,
        };
    },
    computed: {
        tours() {
            return this.$store.getters.tours;
        }
    },
    beforeUnmount() {
        if (this.tour) {
            this.tour.hide();
        }
    },
    watch: {
        tours: function () {
            if (this.autoStart && this.nextTour && !this.active && !this.$Cypress) {
                this.active = true;
                this.startTour(this.nextTour);
            }
        }
    },
    methods: {
        startTour(tour) {
            if (tour) {

                this.tour = this.$shepherd({
                    useModalOverlay: true,
                });

                // this.$store.dispatch('updateTour', { tour_id: tour.id, status: 'open' });

                this.tour.on('cancel', () => {
                    this.$store.dispatch('updateTour', { tour_id: tour.id, status: 'cancelled', step: this.step });
                });
                this.tour.on('complete', () => {
                    this.$store.dispatch('updateTour', { tour_id: tour.id, status: 'completed', step: this.step });
                });

                const settingsInitiallyShown = this.showSettings;

                this.tour.addSteps(tour.steps.map((step, i) => {
                    return {
                        title: step.title,
                        text: step.content,
                        modalOverlayOpeningRadius: 8,
                        modalOverlayOpeningPadding: 8,
                        scrollTo: true,
                        attachTo: { element: step.attach ? step.attach : null, on: 'bottom' },
                        canClickTarget: false,
                        beforeShowPromise: () => {
                            return new Promise((resolve) => {
                                this.step = i + 1;
                                this.$store.dispatch('updateTour', { tour_id: tour.id, status: 'open', step: this.step });
                                if (step.attach == '[data-tour="main_entry_toolbar"]' && !this.showSettings) {
                                    this.$emit('showSettings', true);
                                    setTimeout(() => {
                                        resolve();
                                    }, 1000);
                                    // } else if (step.attach?.includes('[data-tour="input_settings"]')) {
                                    //     document.querySelector('[data-tour="input_settings"]').click();
                                    //     setTimeout(() => {
                                    //         resolve();
                                    //     }, 1000);
                                } else {
                                    resolve();
                                }
                            });
                        },
                        buttons: [
                            i < tour.steps.length - 1 ? {
                                text: "Skip Tour",
                                secondary: true,
                                action: this.tour.cancel,
                            } : {
                                classes: "hidden",
                                text: "Skip Tour",
                                secondary: true,
                                action: this.tour.cancel,
                            },
                            i > 0 ? {
                                text: "Back",
                                secondary: true,
                                action: this.tour.back,
                            } : {
                                classes: "hidden",
                                text: "Back",
                                secondary: true,
                                action: this.tour.back,
                            }
                            ,
                            i == tour.steps.length - 1 ? {
                                text: "Finish",
                                action: this.tour.next,
                            } : {
                                text: "Next ",
                                action: this.tour.next,
                            },
                        ],
                        when: {
                            // show: function () {
                            //     if (window._paq)
                            //         window._paq.push(["trackEvent", "Tour", "Step", 1]);
                            // },

                            hide: () => {
                                if (step.attach == '[data-tour="main_entry_toolbar"]')
                                    this.$emit('showSettings', settingsInitiallyShown);
                            }
                        },
                    };


                }));

                this.tour.start();
                this.tours.splice(this.tours.findIndex(t => t.id == tour.id), 1);
            }
        },
        startTourIfExistsIn(hook) {
            // console.log("startTourIfExistsIn", hook)
            const tour = this.tours.find(t => t.hook == hook)
            if (tour) this.startTour(tour);
        },
        startTourIfOpen() {
            // console.log("startTourIfOpen")
            const tour = this.tours.find(t => t.status == 'open')
            if (tour) this.startTour(tour);
        }
    }
};
