<template>
  <div>
    <ListboxHelper v-model="entryColor" data-test="color_select">
      <template v-if="label" v-slot:label> Color </template>
      <template v-slot:button>
        <slot name="button">
          <ButtonComponent
            :variant="variant"
            :size="size"
            :color="color"
            :colorWeight="colorWeight"
            title="Set Color"
            :class="{ 'pr-8': variant != 'round' }"
            class="relative"
          >
            <span class="flex items-center">
              <IconHelper
                name="SwatchBook"
                v-if="variant == 'round'"
                size="20"
              />
              <span
                v-if="variant != 'round'"
                :class="[
                  'bg-' + (entryColor || 'neutral') + '-400',
                  'inline-block h-2 w-2' + ' flex-shrink-0 rounded-full',
                ]"
                aria-hidden="true"
              />
              <span
                v-if="variant != 'round'"
                class="ml-3 block truncate capitalize"
                >{{ entryColor || "No Color" }}</span
              >
            </span>
            <span
              v-if="variant != 'round'"
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
            >
              <ChevronUpDownIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ButtonComponent>
        </slot>
      </template>

      <ListboxOption
        as="template"
        v-if="entryColor"
        :value="null"
        v-slot="{ active, selected }"
      >
        <li
          :class="[
            active
              ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300'
              : 'text-neutral-900 dark:text-neutral-400',
            'relative cursor-default select-none py-2 pl-3 pr-9',
          ]"
          :data-test="`entry_aspect_color_none`"
        >
          <div class="flex items-center">
            <span
              :class="[
                'bg-neutral-400',
                'inline-block h-2 w-2 flex-shrink-0 rounded-full',
              ]"
              aria-hidden="true"
            />
            <span
              :class="[
                selected ? 'font-semibold' : 'font-normal',
                'ml-3 block truncate capitalize',
              ]"
            >
              Clear
            </span>
          </div>
        </li>
      </ListboxOption>

      <ListboxOption
        as="template"
        v-for="color in colors"
        :key="color"
        :value="color"
        v-slot="{ active, selected }"
      >
        <li
          :class="[
            active
              ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300'
              : 'text-neutral-900 dark:text-neutral-400',
            'relative cursor-default select-none py-2 pl-3 pr-9',
          ]"
          :data-test="'color_set_' + color"
        >
          <div class="flex items-center">
            <span
              :class="[
                'bg-' + color + '-400',
                'inline-block h-2 w-2 flex-shrink-0 rounded-full',
              ]"
              aria-hidden="true"
            />
            <span
              :class="[
                selected ? 'font-semibold' : 'font-normal',
                'ml-3 block truncate capitalize',
              ]"
            >
              {{ color }}
            </span>
          </div>

          <span
            v-if="selected"
            :class="[
              active
                ? 'bg-neutral-600 text-white dark:bg-neutral-800 dark:text-neutral-300'
                : 'text-neutral-900 dark:text-neutral-400',
              'absolute inset-y-0 right-0 flex items-center pr-4',
            ]"
          >
            <CheckIcon class="h-5 w-5" aria-hidden="true" />
          </span>
        </li>
      </ListboxOption>
    </ListboxHelper>
  </div>
</template>

<script setup>
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";

import { ListboxOption } from "@headlessui/vue";
const colors = [
  "gray",
  "red",
  "orange",
  "amber",
  "yellow",
  "lime",
  "green",
  "emerald",
  "teal",
  "cyan",
  "sky",
  "blue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
];
</script>
<script>
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
  props: {
    label: Boolean,
  },
  computed: {
    entryColor: {
      get() {
        return this.modelValue;
      },
      set(color) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.color = color;

        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
  },
};
</script>
