<template>
  <slot v-if="disabled" />
  <TransitionGroup
    v-else
    :appear="appear"
    name="list"
    @enter="onEnter"
    @leave="onLeave"
  >
    <slot />
  </TransitionGroup>
</template>

<script>
import anime from "animejs/lib/anime.es.js";

export default {
  // inject: ["position"],
  props: {
    enter: Object,
    leave: Object,
    tag: {
      type: String,
      default: "",
    },
    appear: Boolean,
  },
  computed: {
    disabled() {
      return false;
      // return ["draggable"].includes(this.position);
    },
  },
  // data() {
  //   return {
  //     initialAppearance: true,
  //     show: false,
  //   };
  // },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.show = true;
  //   });
  //   setTimeout(() => {
  //     this.initialAppearance = false;
  //   }, 500);
  // },
  methods: {
    onEnter(el, done) {
      if (this.enter) {
        anime({
          ...{
            targets: el,
            // easing: "easeOutExpo",
            complete: () => {
              el.style.transform = "";
              done();
            },
            duration:
              typeof Cypress === "undefined"
                ? this.enter.duration
                  ? this.enter.duration
                  : 1000
                : 0,
          },
          ...this.enter,
        });
      } else done();
    },
    onLeave(el, done) {
      if (this.leave)
        anime({
          ...{
            targets: el,
            // easing: "easeOutExpo",
            complete: () => {
              el.style.transform = "";
              done();
            },
            duration:
              typeof Cypress === "undefined"
                ? this.leave.duration
                  ? this.leave.duration
                  : 1000
                : 0,
          },
          ...this.leave,
        });
      else done();
    },
  },
};
</script>

<style>
.list-move
/* .list-enter-active,
.list-leave-active*/ {
  transition: transform 0.8s;
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
.list-leave-active {
  position: absolute;
}
.list-move .list-move {
  transition: none;
}
</style>
