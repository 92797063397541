<template>
  <Menu as="div" class="relative" v-slot="{ open }">
    <MenuButton
      ref="reference"
      @click.stop
      class="flex w-full focus-visible:outline-none"
    >
      <slot name="button" />
    </MenuButton>
    <OverlayComponent v-if="open" @click="open = false" />

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="absolute right-0 z-[999] mt-2">
        <div
          ref="floating"
          :style="floatingStyles"
          class="w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-black dark:ring-neutral-700"
        >
          <slot :open="open" />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { useFloating, shift, flip } from "@floating-ui/vue";
import OverlayComponent from "../helpers/OverlayComponent.vue";

const reference = ref(null);
const floating = ref(null);

const props = defineProps({
  placement: {
    type: String,
    default: "bottom-start",
  },
});

const { floatingStyles } = useFloating(reference, floating, {
  placement: props.placement,
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>
