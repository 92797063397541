import moment from "moment";

export function formattedDateOp(val, beautify = true) {

  if (val) {
    if (val.op == "today") return "today";
    if (val.op == "tomorrow") return "tomorrow";
    if (val.op == "yesterday") return "yesterday";
    if (val.op == "null") return "no date";

    if (val.op == "lastXDays")
      return val.x + " day" + (val.x != 1 ? "s" : "") + " ago";
    if (val.op == "lastXWeeks")
      return val.x + " week" + (val.x != 1 ? "s" : "") + " ago";
    if (val.op == "lastXMonths")
      return val.x + " month" + (val.x != 1 ? "s" : "") + " ago";
    if (val.op == "lastXYears")
      return val.x + " year" + (val.x != 1 ? "s" : "") + " ago";

    if (val.op == "nextXDays")
      return "in " + val.x + " day" + (val.x != 1 ? "s" : "");
    if (val.op == "nextXWeeks")
      return "in " + val.x + " week" + (val.x != 1 ? "s" : "");
    if (val.op == "nextXMonths")
      return "in " + val.x + " month" + (val.x != 1 ? "s" : "");
    if (val.op == "nextXYears")
      return "in " + val.x + " year" + (val.x != 1 ? "s" : "");

    if (beautify && val == moment().local().format("YYYY-MM-DD")) {
      return "today";
    }
    if (beautify && val == moment().local().add(1, "day").format("YYYY-MM-DD")) {
      return "tomorrow";
    }
    if (beautify && val == moment().local().add(-1, "day").format("YYYY-MM-DD")) {
      return "yesterday";
    }
    if (val.length) return moment(val).local().format("DD·MM·YYYY");
  }

  return "no date";
}

export function getDateSpan(mode, date) {
  switch (mode) {
    case "daily":
      return moment(date).format("YYYY-MM-DD")
    case "weekly":
      return moment(date).startOf("week").format("YYYY-MM-DD")
    case "monthly":
      return moment(date).startOf("month").format("YYYY-MM-DD")
  }
}