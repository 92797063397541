<template>
  <div
    tabindex="0"
    @mouseenter="$el.focus()"
    class="-mx-3 -my-1 flex cursor-pointer justify-between gap-2 px-3 py-1 focus:bg-neutral-100 focus:outline-none dark:focus:bg-neutral-900"
  >
    <slot />
    <span v-if="selected"> <IconHelper name="check" /> </span>
  </div>
</template>

<script>
export default {
  props: {
    selected: Boolean,
  },
};
</script>
