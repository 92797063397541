<template>
  <div>
    <p>{{ message }}</p>
    <router-link v-if="success && token" :to="{ name: 'home' }">
      <ButtonComponent>Go Home</ButtonComponent>
    </router-link>
    <router-link v-if="success && !token" :to="{ name: 'login' }">
      <ButtonComponent>Login</ButtonComponent>
    </router-link>
    <router-link v-if="resend && token" :to="{ name: 'profile' }">
      <ButtonComponent> To your profile </ButtonComponent>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "EmailVerifiation",
  data() {
    return {
      message: "",
      success: null,
      expired: false,
    };
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.getters.user;
    },
    id() {
      return this.$route.params.verification_id;
    },
    hash() {
      return this.$route.params.verification_hash;
    },
  },
  mounted() {
    if (this.id && this.hash) {
      this.$store
        .dispatch("verifyEmail", {
          id: this.id,
          hash: this.hash,
          expires: this.$route.query.expires,
          signature: this.$route.query.signature,
        })
        .then(() => {
          this.$store.dispatch("retrieveUser");
          this.message = "Email Verified!";
          this.success = true;
        })
        .catch((data) => {
          if (data.response?.data?.message == "Invalid signature.") {
            this.message =
              "This link is expired, please send yourself a new one";
          } else {
            this.message = "An error appeared";
          }

          this.resend = true;
        });
    }
  },
};
</script>
