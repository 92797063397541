<template>
  <div
    class="flex flex-col gap-2 divide-y dark:divide-neutral-700"
    data-test="output_builder"
  >
    <fieldset
      v-for="(o, k) in value"
      :key="k"
      class="flex flex-wrap items-center gap-2 pt-2 first:pt-0"
      data-test="output_builder_row"
    >
      <SelectMenu
        :ref="'key_select_' + k"
        v-model="o.key"
        :options="keys"
        size="sm"
        :openWhenEmpty="true"
      />

      <!-- <OptionPicker
        v-model="o.key"
        :options="keys"
        :ref="'key_select_' + k"
        size="sm"
      /> -->

      <SelectMenu
        v-if="o.key || o.op"
        v-model="o.op"
        :options="ops(o.key)"
        :render="(val) => opToSign(val)"
        size="sm"
        :openWhenEmpty="true"
      />

      <!-- <OptionPicker
        v-if="o.key || o.op"
        v-model="o.op"
        :options="ops(o.key)"
        :render="(val) => opToSign(val)"
        size="sm"
      /> -->

      <component
        v-if="
          vals(o.key, o.op).length &&
          ['DatePicker', 'TimePicker'].includes(vals(o.key, o.op)[0])
        "
        :is="vals(o.key, o.op)[0]"
        v-bind="vals(o.key, o.op)[1]"
        v-model="o.val[0]"
        @update:modelValue="
          (val) => {
            $console.log(val);
            o.val = [val];
          }
        "
        data-test="output_builder_val"
        size="sm"
        class="rounded border px-2 py-1 text-xs"
      />

      <component
        v-if="
          vals(o.key, o.op).length &&
          !['DatePicker', 'TimePicker'].includes(vals(o.key, o.op)[0])
        "
        :is="vals(o.key, o.op)[0]"
        v-bind="vals(o.key, o.op)[1]"
        v-model="o.val"
        @update:modelValue="
          (val) => {
            o.val = val;
          }
        "
        data-test="output_builder_val"
        :class="{
          'rounded border px-2 py-1 text-xs':
            false && vals(o.key, o.op)[0] == 'LinksBuilder',
        }"
        size="sm"
        class=""
      />
      <div class="flex flex-1 basis-full justify-end md:basis-auto">
        <ButtonComponent
          @click="[value.splice(k, 1)]"
          data-test="output_builder_delete_row"
          size="sm"
          color="secondary"
          title="Remove filter"
        >
          <IconHelper name="trash" size="16" stroke-width="1.5" />
        </ButtonComponent>
      </div>
    </fieldset>

    <div
      v-if="value.length == validatedValue.length"
      class="flex justify-between pt-2"
    >
      <ButtonComponent
        id="output_builder_add"
        @click.stop="addElement"
        data-test="output_builder_add"
        color="secondary"
      >
        <IconHelper name="PlusCircle" size="20" stroke-width="1.25" /> Add
      </ButtonComponent>

      <ButtonComponent
        v-if="JSON.stringify(value) != JSON.stringify(modelValue)"
        @click.stop="$emit('update:modelValue', value)"
        color="primary"
      >
        <IconHelper name="Save" size="20" stroke-width="1.25" /> Save
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import { Scheduling } from "../mixins/Scheduling";

import { AdjustmentsHorizontalIcon } from "@heroicons/vue/24/outline";
import { Statusing } from "../mixins/Statusing";
import { Filtering } from "../mixins/Filtering";

import OptionPicker from "../builders/OptionPicker.vue";
import DatePicker from "../builders/DatePicker.vue";
import TimePicker from "../builders/TimePicker.vue";
import LinksBuilder from "../links/partials/LinksBuilder.vue";
import StatusBuilder from "../builders/StatusBuilder.vue";
import TextPicker from "../builders/TextPicker.vue";
import { SaveIcon, PlusCircleIcon } from "lucide-vue-next";

export default {
  mixins: [Filtering, Scheduling, Statusing],
  props: {
    modelValue: null,
    entry: Object,
  },
  components: {
    OptionPicker,
    DatePicker,
    TimePicker,
    LinksBuilder,
    StatusBuilder,
    TextPicker,
    AdjustmentsHorizontalIcon,
    PlusCircleIcon,
    SaveIcon,
  },
  data() {
    return {
      showLinkSearch: false,
      value:
        typeof this.modelValue == "object"
          ? JSON.parse(JSON.stringify(this.modelValue))
          : this.modelValue,
      edit: false,
      cursor: null,
    };
  },

  watch: {
    /**
     * If any external cause of change happened,
     * we will adjust the value properly.
     */
    modelValue: {
      handler(n, o) {
        if (JSON.stringify(n) != JSON.stringify(o)) {
          if (this.$Cypress) {
            alert(JSON.stringify(o) + " >>> " + JSON.stringify(n));
          }
          this.value =
            typeof this.modelValue == "object"
              ? JSON.parse(JSON.stringify(this.modelValue))
              : this.modelValue;
        }
      },
      deep: true,
    },

    value: {
      handler() {
        if (JSON.stringify(this.value) != JSON.stringify(this.modelValue)) {
          setTimeout(() => {
            this.$emit("onChange", this.value);
          }, 0);
        }
      },
      deep: true,
    },
  },
  computed: {
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },
    statuses() {
      return this.getOwnAvailableStatuses(this.entry, true);
    },
    validatedValue() {
      return this.value?.filter((o) => o.key && o.op);
    },
  },
  methods: {
    addElement() {
      this.value.push({
        key: null,
        op: null,
        val: [],
      });

      // this.$nextTick(() => {
      //   // console.log(this.$refs["key_select_" + (this.value.length - 1)]);
      //   this.$refs[
      //     "key_select_" + (this.value.length - 1)
      //   ][0].button.$el.click();
      // });
    },
    cancelEdit() {
      this.value =
        typeof this.modelValue == "object"
          ? JSON.parse(JSON.stringify(this.modelValue))
          : this.modelValue;
      this.edit = false;
    },
    checkEditFocus(e) {
      if (!this.$el.contains(e.relatedTarget)) {
        this.edit = false;
      }
      this.$emit("onBlur");
    },
  },
};
</script>
