<template>
  <div
    v-if="output"
    ref="boundaries"
    :class="$style.outputDisplay"
    v-memo="[
      output,
      JSON.stringify(entries) /* this might cause performance issues */,
      isInitialized,
      $store.getters.dragged.length /* for the snap-x stuff */,
      maxGridColumns,
    ]"
    :style="styleRoot"
  >
    <div ref="root">
      <div
        v-if="!isInitialized && 3 > 4"
        data-initializing
        class="flex flex-col gap-y-4 px-2 py-1.5"
      >
        <div
          class="h-full min-h-6 w-full animate-pulse rounded-full bg-neutral-50 dark:bg-neutral-950"
        />
        <div
          class="h-full min-h-6 w-full animate-pulse rounded-full bg-neutral-50 dark:bg-neutral-950"
        />
        <div
          class="h-full min-h-6 w-1/2 animate-pulse rounded-full bg-neutral-50 dark:bg-neutral-950"
        />
      </div>

      <!-- <Transition
      @enter="
        (el, done) => {
          $anime({
            targets: el,
            opacity: [0, 1],
            translateY: [200, 0],
            complete: done,
          });
        }
      "
      :css="false"
    > -->
      <div
        v-else
        class="flex flex-col gap-2 font-normal"
        data-test="output_display"
        :class="{
          // disabled: disabled, TODO: remove this?
        }"
        @click.stop
        role="list"
      >
        <!-- @touchstart.stop
      @touchend.stop -->
        <slot name="output-before" :entries="entries" />
        <!-- @mouseup.stop
    @mousedown.stop -->
        <CalendarHelper
          v-if="
            [
              'calendar',
              'calendar_year',
              'calendar_month',
              'calendar_week',
              'calendar_day',
            ].includes(renderType)
          "
          :mode="output.type.split('_')[1]"
          :entry="entry"
          :entries="entries"
          :dateSelected="dateSelected"
          @update:dateSelected="$emit('update:dateSelected', $event)"
          @mode="
            (mode) => {
              output = { ...output, ...{ type: 'calendar_' + mode } };
            }
          "
          :schema="input?.data"
          :color="getColor(entry)"
          :position="position"
        >
          <template #header>
            <OutputSettings
              v-if="editable && showToolbar"
              v-model="output"
              :entry="entry"
              :showDelete="false"
              size="sm"
              variant="minimal"
              class="hidden flex-1 flex-wrap justify-center gap-3 sm:flex"
              :permissions="{
                type: true,
                group: false,
                filter: true,
                sort: false,
              }"
            />
          </template>
          <template v-slot:month-day="{ date }">
            <PopoverHelper class="absolute bottom-2 left-2">
              <template #button>
                <ButtonComponent
                  variant="round"
                  size="xs"
                  class="!bg-neutral-100 !text-neutral-700 dark:!bg-neutral-900 dark:!text-neutral-100"
                >
                  <PlusIcon size="20" />
                </ButtonComponent>
              </template>
              <template v-slot="{ close }">
                <div class="min-w-max bg-white p-2 dark:bg-black">
                  <InputDisplay
                    v-if="entry.input"
                    v-model="input"
                    :schema="{
                      ...input?.data,
                      ...{ schedule: { date: date.format('YYYY-MM-DD') } },
                    }"
                    :display="display"
                    :entry="entry"
                    :position="position"
                    :editable="true"
                    @created="
                      (entry) => {
                        close();
                        $emit('created', entry);
                      }
                    "
                    :disableRouting="disableRouting"
                  />
                  <!-- :inputs="inputs" -->
                </div>
              </template>
            </PopoverHelper>
          </template>

          <template v-slot:day-slot="{ date, time }">
            <PopoverHelper class="absolute inset-0">
              <template #button>
                <div
                  class="hover:_bg-neutral-50 dark:_bg-neutral-900 h-full w-full"
                />
                <!-- <ButtonComponent
                variant="round"
                size="xs"
                class="!bg-neutral-100 !text-neutral-700"
              >
                <PlusIcon size="20" />
              </ButtonComponent> -->
              </template>
              <template v-slot="{ close }">
                <div class="min-w-max bg-white p-2 dark:bg-black">
                  <InputDisplay
                    v-if="entry.input"
                    v-model="input"
                    :schema="{
                      ...input?.data,
                      ...{
                        schedule: {
                          date: date.format('YYYY-MM-DD'),
                          time: time,
                        },
                      },
                    }"
                    :display="display"
                    :entry="entry"
                    :position="position"
                    :editable="true"
                    @created="
                      (entry) => {
                        close();
                        $emit('created', entry);
                      }
                    "
                    :disableRouting="disableRouting"
                  />
                  <!-- :inputs="inputs" -->
                </div>
              </template>
            </PopoverHelper>
          </template>
        </CalendarHelper>

        <div
          v-else
          :class="{
            'flex flex-col gap-y-5': ['list', 'gallery'].includes(renderType),
            'flex h-full gap-x-0 divide-x overflow-x-auto overflow-y-hidden pb-4 dark:divide-neutral-700':
              renderType == 'board',
            '-ml-2': renderType == 'board' && !['focus'].includes(position),
            // _md:hover-hover:overflow-x-hidden _md:hover-hover:hover:overflow-x-auto
            'snap-x snap-mandatory':
              renderType == 'board' && $store.getters.dragged.length == 0,
          }"
          :data-skip-scrollparent="renderType == 'board'"
          class="relative"
          style="scrollbar-gutter: stable"
        >
          <!-- {{ getGroups(output?.grouping) }} -->
          <TransitionGroup
            name="section"
            @enter="transitionOnSectionEnter"
            @leave="transitionOnSectionLeave"
            :css="false"
          >
            <section
              v-for="(group, groupIndex) in groups /*.slice(
                renderType == 'board' && offset ? offset : 0,
                renderType == 'board' && limit
                  ? (offset ? offset : 0) + limit
                  : undefined,
              )*/"
              :key="groupIndex"
              :class="[
                $style['groupWrapper'],
                $style['groupWrapper_' + renderType],
                /*
// 'flex flex-col justify-start gap-y-0.5':
              //   renderType == 'list' && position != 'left',
              // 'flex flex-col': renderType == 'list' && position == 'left',

              // 'flex w-auto flex-wrap gap-2 overflow-y-auto':
              //   renderType == 'row',
              // Start: Board
              // 'flex flex-shrink-0 snap-start flex-col gap-y-4 px-4':
              //   renderType == 'board',
              // 'w-72 first:-ml-2':
              //   renderType == 'board' && position != 'focus',
              // 'w-full': renderType == 'board' && position == 'focus',
              // End: Board

              // 'grid gap-2 ': renderType == 'gallery', -> moved to css module
              // 'grid-cols-1': renderType == 'gallery' && maxGridColumns == 1,
              // 'grid-cols-2': renderType == 'gallery' && maxGridColumns == 2,
              // 'grid-cols-3': renderType == 'gallery' && maxGridColumns == 3,
              // 'grid-cols-4': renderType == 'gallery' && maxGridColumns == 4,
              // 'lg:grid-cols-4':
              //   renderType == 'gallery' &&
              //   $route.params.id == entry.id &&
              //   position == 'center',
              // 'flex flex-wrap gap-5': renderType == 'tiles',
              // 'rounded-xl ring-1 ring-inset ring-blue-500':
              //   group.schema &&
              //   $store.getters.dragged.filter((e) =>
              //     inputApplyable(group.schema, e),
              //   ).length,
              // '-m-2 p-2':
              //   !['board'].includes(renderType) &&
              //   group.schema &&
              //   $store.getters.dragged.filter((e) =>
              //     inputApplyable(group.schema, e),
              //   ).length,
                */
              ]"
              @mouseenter="
                (e) =>
                  onMouseEnter(e, {
                    id: entry?.id + '_' + groupIndex,
                    schema: group.schema,
                  })
              "
              :data-has-mouseenter="true"
              @mouseleave="onMouseLeave"
              @drop="() => onDrop(group.schema)"
              @dragover.prevent="null"
              @dragenter.prevent="null"
              :data-test="'group_' + $slugify(group.name)"
            >
              <!-- <TransitionGroupHelper> -->
              <OutputGroupHeader
                key="header"
                v-if="showHeader && !['search', 'left'].includes(position)"
                v-model="output"
                :entry="entry"
                :group="group"
                :editable="editable"
                :disabled="disabled"
                :showToolbar="showToolbar"
                :groupEntries="entries.filter(group.filter)"
                :class="
                  $style.outputGroupHeader
                  // '': renderType != 'row',
                  // 'w-full': renderType == 'gallery',
                  // 'col-span-1':
                  //   renderType == 'gallery' && maxGridColumns == 1,
                  // 'col-span-2':
                  //   renderType == 'gallery' && maxGridColumns == 2,
                  // 'col-span-3':
                  //   renderType == 'gallery' && maxGridColumns == 3,
                  // 'col-span-4':
                  //   renderType == 'gallery' && maxGridColumns == 4,

                  // 'lg:col-span-4':
                  //   renderType == 'gallery' && $route.params.id == entry.id,
                  // 'basis-full': ['tiles', 'row'].includes(renderType),
                "
                :renderType="renderType"
              />

              <IntersectionList
                :items="entries.filter(group.filter)"
                :preserveItems="renderOutput"
                :itemHeight="36"
                :itemClass="[
                  $style.entryWrapper,
                  $style['entryWrapper_' + renderType],
                ]"
              >
                <template v-slot:item="{ item: entry, index }">
                  <div
                    @click="$emit('onEntryClick', entry)"
                    :data-test="$slugify(entry?.name)"
                  >
                    <!-- {{ index }} {{ entry.name }} -->
                    <EntryDisplay
                      :modelValue="entry"
                      :position="position"
                      :disabled="entriesDisabled"
                      :renderType="
                        overrideEntryRenderType
                          ? overrideEntryRenderType
                          : renderType
                      "
                      :display="cachedDisplay"
                      :disableViewportChecking="disableViewportChecking"
                      :entryIndex="index"
                      :containingOutputGroup="entry?.id + '_' + groupIndex"
                      :size="size"
                      :isRenderOutput="renderOutput.includes(entry.id)"
                      :toggleRenderOutput="toggleRenderOutput"
                      :class="$style.entryDisplay"
                    />
                  </div>
                  <div
                    v-if="entry.output && renderOutput.includes(entry.id)"
                    :class="$style.outputDisplay_wrapper"
                  >
                    <OutputDisplay
                      :modelValue="entry.output"
                      :entry="entry"
                      :showHeader="false"
                      :sorting="entry.output.sorting"
                      :display="cachedDisplay"
                      :position="position"
                      :overrideEntryRenderType="
                        renderType /* note: this is for board > opening child entries showing links with basis-full */
                      "
                      :entriesDisabled="entriesDisabled"
                      :editable="false"
                      :size="size"
                    />
                  </div>
                </template>
              </IntersectionList>

              <!-- <EmptyState
                  v-if="entries.filter(group.filter).length === 0"
                  key="empty"
                /> -->
              <div
                v-if="
                  showInput &&
                  position != 'search' &&
                  entry.input &&
                  group.schema
                "
                :class="[$style.entryWrapper, $style.inputWrapper]"
              >
                <InputDisplay
                  key="input"
                  v-model="input"
                  :entry="entry"
                  :schema="/*$merge(input?.data, */ group.schema /*)*/"
                  :display="
                    display ? { ...display, ...renderTypeDisplay } : display
                  "
                  :position="position"
                  :renderType="renderType"
                  :color="entry.color"
                  :editable="position != 'left' && editable"
                  :disabled="disabled"
                  :class="{
                    'rounded-md p-2 shadow-lg': renderType == 'gallery',
                    // 'px-2 py-1': position == 'left',
                  }"
                  @created="(entry) => $emit('created', entry)"
                />
                <!-- :inputs="inputs" -->
              </div>
              <!--</TransitionGroupHelper>-->
            </section>
          </TransitionGroup>
        </div>

        <slot name="output-after" :entries="entries" :pages="totalPages" />
      </div>
      <!-- </Transition> -->
    </div>
  </div>
  <div v-else>
    <ButtonComponent
      @click="output = addonBlueprint('output', entry, this)"
      data-test="entry_output_create"
      class="w-full"
    >
      <ListPlus size="20" /> <span> Add Output</span>
    </ButtonComponent>
  </div>
</template>
<script>
import { Filtering } from "../mixins/Filtering.js";
import { Grouping } from "../mixins/Grouping.js";
import { Sorting } from "../mixins/Sorting.js";
import { Scheduling } from "../mixins/Scheduling.js";

import { Schema } from "../mixins/Schema.js";
import { Applicators } from "../mixins/Applicators.js";

import { Statusing } from "../mixins/Statusing";
import { DragAndDrop } from "../mixins/DragAndDrop";
import { Coloring } from "../mixins/Coloring";

// import CalendarComponent from "../helpers/CalendarComponent.vue";
import OutputGroupHeader from "./partials/OutputGroupHeader.vue";
import { addonBlueprint } from "@/addonBlueprint";

import { ChevronRightIcon, ListPlus, PlusIcon } from "lucide-vue-next";
import moment from "moment";
import { Lazying } from "../mixins/Lazying.js";
import { Routining } from "../mixins/Routining.js";
// import anime from "animejs";
// import { Viewporting } from "../mixins/Viewporting.js";

export default {
  // provide() {
  //   return {
  //     display: this.display,
  //   };
  // },

  inject: {
    overrideEntries: {
      default: null,
    },
  },
  mixins: [
    Filtering,
    Sorting,
    Scheduling,
    Schema,
    Applicators,
    Grouping,
    Statusing,
    DragAndDrop,
    Coloring,
    Routining,
    Lazying,
    // Viewporting,
  ],
  components: {
    OutputGroupHeader,
    // CalendarComponent,
    // eslint-disable-next-line vue/no-unused-components
    ChevronRightIcon,
    ListPlus,
    PlusIcon,
  },
  props: {
    modelValue: Object,
    entry: {
      type: Object,
      default: () => ({}),
    },

    disabled: Boolean,
    entriesDisabled: Boolean,

    display: Object,
    schema: Object,
    sorting: Array,
    showInput: {
      type: Boolean,
      default: true,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    inputs: Array,
    filter: {
      type: Function,
      default: () => true,
    },
    position: String,
    editable: {
      type: Boolean,
      default: true,
    },
    // highlightIndex: Number,
    disableRouting: Boolean,
    delay: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "md",
    },
    overrideEntryRenderType: String,

    dateSelected: {
      type: String,
      default: () => moment().format("YYYY-MM-DD"),
    },
    limit: {
      type: Number,
      default: 50,
    },
    offset: {
      type: Number,
      default: 0,
    },
    transitionSection: Object,
    showHeader: {
      type: Boolean,
      default: true,
    },
    disableViewportChecking: Boolean,
  },
  // // eslint-disable-next-line no-unused-vars
  // renderTracked(event) {
  //   // console.trace();
  //   console.log("renderTracked Output", this.entry.name, this.position, event);
  // },
  // // eslint-disable-next-line no-unused-vars
  // renderTriggered(event) {
  //   // console.trace();
  //   console.log(
  //     "renderTriggered Output",
  //     this.entry.name,
  //     this.position,
  //     event,
  //   );
  // },
  watch: {
    display: {
      handler: function (n, o) {
        if (JSON.stringify(n) !== JSON.stringify(o)) {
          this.cachedDisplay = this.display;
        }
      },
      deep: true,
    },
    "$store.getters.timestamp": function () {
      this.$onIdle(() => {
        this.pullData();
      });
    },
    "$store.getters.entriesJson": {
      handler: function () {
        if (
          JSON.stringify(this.entries) != JSON.stringify(this.computedEntries)
        ) {
          // console.log(this.entry.name, "entries changed");
          this.entries = this.computedEntries;
        }
      },
      immediate: true,
    },
    "$store.getters.delayedEntriesUpdate": {
      handler: function () {
        if (
          JSON.stringify(this.entries) != JSON.stringify(this.computedEntries)
        ) {
          // console.log(this.entry.name, "entries changed");
          this.entries = this.computedEntries;
        }
      },
      immediate: true,
    },
    output: {
      handler: function () {
        if (JSON.stringify(this.output) != JSON.stringify(this.cachedOutput)) {
          this.internalLimit = this.limit;
          this.cachedNewEntries = [];
          if (this.outputChangedTimeOut)
            clearTimeout(this.outputChangedTimeOut);
          this.outputChangedTimeOut = setTimeout(() => {
            this.pullData();
          }, 250);
          this.cacheEntries();
          this.cachedOutput = { ...this.output };
        }
      },
      deep: true,
    },
    // highlightIndex: function (n, o) {
    //   this.$nextTick(() => {
    //     if (this.$refs["entry_" + this.highlightIndex]) {
    //       this.$refs[
    //         "entry_" + this.highlightIndex
    //       ][0].$el.parentElement.scrollIntoView({
    //         /**
    //          * If the difference between the old and new index is 1, scroll smoothly.
    //          * Otherwise, we move fast fro top to bottom or vice versa and just jump to the element.
    //          */
    //         behavior: Math.abs(n - o) == 1 ? "smooth" : "auto",
    //         block: "center",
    //         inline: "center",
    //       });
    //     }
    //   });
    // },
  },
  data() {
    return {
      cachedDisplay: this.display,
      entries: [],
      transition: "",
      addonBlueprint: addonBlueprint,
      renderOutput: [],
      entriesCache: [],
      outputChangedTimeOut: null,

      internalLimit: this.limit,

      cachedGroupEntryLengths: [],
      cachedNewEntries: [],
      cachedOutput: null,

      maxGridColumns: 4,

      observeElement: null,
      entriesObserver: null,
    };
  },
  mounted: function () {
    // console.log("mounting output of " + this.entry?.name, this.position);

    // this.cacheEntries();
    this.entries = this.computedEntries;
    this.$emit("mounted");
    this.cachedOutput = { ...this.output };

    this.updateGridColumns();
    window.addEventListener("resize", this.updateGridColumns);
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.updateGridColumns);
  },
  computed: {
    output: {
      get: function () {
        return this.modelValue;
      },
      set(output) {
        // console.log("output (computed) changed", output);
        // console.log(this.modelValue, output);

        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    groups() {
      return this.getGroups(this.output?.grouping);
    },
    computedEntries() {
      if (this.overrideEntries !== null) return this.overrideEntries;

      const filteredAndSortedEntries = this.filterEntries(this.output.data)
        .filter(this.filter)
        .sort(this.sortEntries(this.sorting));

      // Ensure whitelisted entries are included in their original positions
      const resultEntries = [...filteredAndSortedEntries];

      // this.$store.getters.delayedEntriesUpdate.forEach((whitelistedEntry) => {
      //   const originalIndex = this.entries.findIndex(
      //     (entry) => entry.id === whitelistedEntry.id,
      //   );
      //   if (
      //     originalIndex !== -1 &&
      //     !filteredAndSortedEntries.some(
      //       (entry) => entry.id === whitelistedEntry.id,
      //     )
      //   ) {
      //     resultEntries.splice(originalIndex, 0, whitelistedEntry);
      //   } else if (
      //     originalIndex === -1 &&
      //     filteredAndSortedEntries.some(
      //       (entry) => entry.id === whitelistedEntry.id,
      //     )
      //   ) {
      //     const indexToRemove = resultEntries.findIndex(
      //       (entry) => entry.id === whitelistedEntry.id,
      //     );
      //     if (indexToRemove !== -1) {
      //       resultEntries.splice(indexToRemove, 1);
      //     }
      //   }
      // });

      return resultEntries;
    },
    input: {
      get: function () {
        return this.entry?.input;
      },
      set(input) {
        this.$emit("update:modelValue", this.output, input);
      },
    },
    renderType() {
      if (this.position == "builder") return "row";
      if (
        this.position == "center" ||
        this.position == "modal" ||
        this.position == "focus" ||
        this.position == "template"
      )
        return this?.output?.type || "list";
      return "list";
    },
    token() {
      return this.$store.getters.token;
    },
    // entriesRaw() {
    //   if (this.overrideEntries) return this.overrideEntries;
    //   return this.filterEntries(this.output.data)
    //     .filter(this.filter)
    //     .sort(this.sortEntries(this.sorting));
    // },
    // entries() {
    //   var entries = this.entriesRaw;

    //   // if (this.renderType != "board" && this.limit)
    //   //   entries = entries.slice(
    //   //     this.offset ? this.offset : 0,
    //   //     (this.offset ? this.offset : 0) + this.limit,
    //   //   );

    //   return entries;
    // },
    applyableInputsForAll() {
      var inputs = [];
      this.entries.forEach((entry) => {
        inputs = [
          ...inputs,
          ...this.applyableInputs(entry).filter(
            (input) => !inputs.map((i) => i.id).includes(input.id),
          ),
        ];
      });
      return inputs;
    },
    renderTypeDisplay() {
      switch (this.renderType) {
        case "tiles":
          return {
            name: true,
            description: false,
            output: false,
            schedule: false,
            status: false,
            routine: true,
            links: false,
            settings: false,
          };
        default:
          return {};
      }
    },
    totalPages() {
      switch (this.renderType) {
        case "gallery":
          return this.entries.length;
        case "board":
          return this.groups.length;
        case "list":
        default:
          return 1;
      }
    },
    styleRoot() {
      return {
        "--ps-entry-display-padding-x":
          this.output.type == "gallery" ? "0" : null,
        "--ps-entry-display-padding-y":
          this.output.type == "gallery" ? "0" : null,
        "--ps-output-display-grid-columns": this.maxGridColumns,
      };
    },
  },
  methods: {
    updateGridColumns() {
      const width = this.$refs.root.offsetWidth;

      if (width < 480) {
        this.maxGridColumns = 1;
      } else if (width < 720) {
        this.maxGridColumns = 2;
      } else if (width < 1100) {
        this.maxGridColumns = 3;
      } else {
        this.maxGridColumns = 4;
      }
    },
    toggleRenderOutput(entry, render) {
      if (this.renderOutput.includes(entry.id) && !render) {
        this.renderOutput = this.renderOutput.filter((id) => id !== entry.id);
      } else {
        this.renderOutput.push(entry.id);
      }
    },
    // checkForChangedEntries() {
    //   // console.time(this.entry.id);

    //   const entries = this.computedEntries;

    //   if (entries.length > this.entries.length) {
    //     this.groups.forEach((group, index) => {
    //       const groupEntries = entries.filter(group.filter);
    //       if (groupEntries.length > this.cachedGroupEntryLengths[index]) {
    //         // If there are more entries and the length of the cached entries is less than the new entries
    //         groupEntries
    //           .slice(this.cachedGroupEntryLengths[index], groupEntries.length)
    //           .forEach((entry) => {
    //             this.cachedNewEntries[index].push(entry);
    //           });
    //         // We will add them here to make the "Load More" button appear above.
    //         // this.cachedNewEntries[index] = [
    //         //   ...this.cachedNewEntries[index],
    //         //   ...groupEntries.slice(
    //         //     this.cachedGroupEntryLengths[index],
    //         //     groupEntries.length,
    //         //   ),
    //         // ]; //.filter((entry) => !entries.map((e) => e.id).includes(entry.id)); // ... but filter all cachedNewEntries that are not in the original entries
    //         this.cachedGroupEntryLengths[index] = groupEntries.length;
    //       }
    //     });
    //   } else if (JSON.stringify(entries) != JSON.stringify(this.entries)) {
    //     // this.cacheEntries(entries);
    //     this.entries = entries;
    //   }
    //   // console.timeEnd(this.entry.id);
    // },
    cacheEntries(entries) {
      // console.log("caching entries");
      if (entries) {
        this.entries = entries;
      } else {
        this.entries = this.filterEntries(this.output.data)
          .filter(this.filter)
          .sort(this.sortEntries(this.sorting));
      }

      this.groups.forEach((group, index) => {
        if (!this.cachedNewEntries[index]) this.cachedNewEntries[index] = [];
        const entries = this.entries.filter(group.filter);
        if (entries.length > this.cachedGroupEntryLengths[index]) {
          // If there are more entries and the length of the cached entries is less than the new entries

          // We will add them here to make the "Load More" button appear above.
          this.cachedNewEntries[index] = [
            ...this.cachedNewEntries[index],
            ...entries.slice(
              this.cachedGroupEntryLengths[index],
              entries.length,
            ),
          ].filter((entry) => !entries.map((e) => e.id).includes(entry.id)); // ... but filter all cachedNewEntries that are not in the original entries
        }

        this.cachedGroupEntryLengths[index] = entries.length;
      });

      this.entriesCache = [];
      // .slice(
      //   this.offset ? this.offset : 0,
      //   this.limit ? (this.offset ? this.offset : 0) + this.limit : undefined,
      // )
      // const groupNeedsMore = [];

      this.groups.map((group, index) => {
        // groupNeedsMore.push(false);
        const entries = this.entries.filter(group.filter);
        this.entriesCache[index] = entries; //.slice(0, this.internalLimit);

        // if (entries.length > this.internalLimit) {
        //   groupNeedsMore[index] = true;
        // }
      });

      // this.entriesCache.forEach((group, index) => {
      //   this.entriesCache[index] = group.map((entry) => {
      //     return {
      //       key: entry.id,
      //       component: "EntryDisplay",
      //       entry: entry,
      //       data: {
      //         "v-model": entry,
      //         display: this.display,
      //         position: this.position,
      //         renderType: this.renderType,
      //         size: this.size,
      //         containingOutputGroup: group.id,
      //       },
      //     };
      //   });

      // if (groupNeedsMore[index]) {
      //   this.entriesCache[index].push({
      //     key: "load-more",
      //     component: "ButtonComponent",
      //     entry: this.entry,
      //     children: "Load More",
      //     bind: {
      //       onClick: () => {
      //         this.internalLimit += 50;
      //       },
      //       variant: "minimal",
      //     },
      //   });
      // }

      // if (this.cachedNewEntries[index]?.length) {
      //   this.cachedNewEntries[index].forEach((entry) => {
      //     this.entriesCache[index].push({
      //       key: entry.id,
      //       component: "EntryDisplay",
      //       entry: entry,
      //       data: {
      //         "v-model": entry,
      //         display: this.display,
      //         position: this.position,
      //         renderType: this.renderType,
      //         size: this.size,
      //         containingOutputGroup: group.id,
      //       },
      //     });
      //   });
      // }

      /*
        v-if="
                showInput && position != 'search' && entry.input && group.schema
              "
              v-model="input"
              :entry="entry"
              :schema="group.schema"
              :inputs="inputs"
              :display="
                display ? { ...display, ...renderTypeDisplay } : display
              "
              :position="position"
              :renderType="renderType"
              :color="entry.color"
              :editable="position != 'left' && editable"
              :class="{
                'rounded-md p-2 shadow-lg': renderType == 'gallery',
                'px-2 py-1': position == 'left',
              }"
              @created="(entry) => $emit('created', entry)"

              */

      //   if (
      //     this.showInput &&
      //     this.position != "search" &&
      //     this.entry.input &&
      //     groups[index].schema
      //   ) {
      //     this.entriesCache[index].push({
      //       key: this.entry.id,
      //       component: "InputDisplay",
      //       entry: this.entry,
      //       bind: {
      //         vModel: this.input,
      //         entry: this.entry,
      //         schema: groups[index].schema,
      //         inputs: this.inputs,
      //         display: {
      //           ...this.display,
      //           ...this.renderTypeDisplay,
      //         },
      //         position: this.position,
      //         renderType: this.renderType,
      //         color: this.entry.color,
      //         editable: this.position != "left" && this.editable,
      //         class: {
      //           "rounded-md p-2 shadow-lg": this.renderType == "gallery",
      //           "px-2 py-1": this.position == "left",
      //         },
      //       },
      //     });
      //   }
      // });
    },
    onDrop(schema) {
      if (schema) {
        this.$store.getters.dragged
          .filter((e) => (this.modelValue ? e.id != this.modelValue.id : true))
          .forEach((entry) => {
            this.applyInput(this.inputSchema(schema, entry), entry, true);
          });
        this.$store.dispatch("dragged", []);
      }
    },
    pullData() {
      if (this.token) {
        if (this.output) {
          //this.disabled = true;
          this.$store.dispatch("pull", {
            filters: this.output.data,
            sorting: this.sorting,
            limit: this.limit,
            source: {
              file: "Output",
              entry: this.entry,
            },
          });

          // this.checkForChangedEntries();
        }
      }
    },
    applyInputForAll(input) {
      this.entries.forEach((entry) => {
        this.applyInput(this.inputSchema(input, entry), entry, true);
      });
    },
    transitionOnSectionEnter(el, done) {
      if (
        !this.$Cypress &&
        this.transitionSection &&
        this.transitionSection.enter
      ) {
        el.style.position = "absolute";
        el.style.width = "100%";
        this.$anime({
          ...{
            targets: el,
            complete: () => {
              done();
              el.style.position = ""; // Reset the position property after the animation
              el.style.width = ""; // Reset the width property after the animation
            },
          },
          ...this.transitionSection.enter,
        });
      } else {
        done();
      }
    },
    transitionOnSectionLeave(el, done) {
      if (
        !this.$Cypress &&
        this.transitionSection &&
        this.transitionSection.leave
      ) {
        el.style.position = "absolute";
        el.style.width = "100%";
        this.$anime({
          ...{
            targets: el,
            complete: () => {
              done();
              el.style.position = ""; // Reset the position property after the animation
              el.style.width = ""; // Reset the width property after the animation
            },
          },
          ...this.transitionSection.leave,
        });
      } else {
        done();
      }
    },
  },
};
</script>

<style module lang="scss">
.outputDisplay {
  margin-inline: var(--ps-output-display-padding-x);
  &_wrapper {
    /* This resets the margin of the output display */
    margin-inline: calc(
      var(--ps-entry-display-padding-x, 0px) - var(
          --ps-output-display-padding-x,
          0px
        )
    );
    padding-left: 1rem;
    // width: 100%;
    // @apply ring;

    // --ps-output-display-padding-x: 1rem;
    // --ps-entry-display-padding-x: 1rem;

    // padding-left: var(--ps-entry-display-padding-x);
    // --ps-entry-display-padding-x: 1rem 0.5rem;
    // margin-left: 1rem;
  }
}

/* GROUPS */

.groupWrapper {
  @media (min-width: 768px) {
    // height: 100%; // @note: this is causing the group to be too high on mobile (jumping scroll)
  }

  &_list {
    display: flex;
    flex-direction: column;
    justify-content: start;
    row-gap: var(--ps-output-display-group-wrapper-list-row-gap);
  }

  &_board {
    display: flex;
    flex-direction: column;
    row-gap: var(--ps-output-display-group-wrapper-list-row-gap);
    @apply flex-shrink-0 snap-start px-4;
    @apply w-72 first:-ml-2; /* no focus? */
    /* @apply w-full; /* focus? */
  }

  &_gallery {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(
      var(--ps-output-display-grid-columns, 1),
      minmax(0, 1fr)
    );
  }

  &_row {
    @apply flex w-auto flex-wrap gap-2 overflow-y-auto;
  }

  &_tiles {
    @apply flex flex-wrap gap-5;
  }

  .outputGroupHeader {
    grid-column: span var(--ps-output-display-grid-columns, 1) / span
      var(--ps-output-display-grid-columns, 1);
  }
}

/* ENTRIES */

.entryWrapper {
  margin-inline: calc(var(--ps-entry-display-padding-x) * -1);
  border-radius: var(--ps-entry-display-border-radius);
}

.entryWrapper_gallery {
  --ps-output-display-entry-display-background-color-hover: transparent;
  // height: 100%;
  @apply relative rounded-lg bg-white p-2 shadow-lg  dark:bg-neutral-950 dark:shadow-none dark:ring-1 dark:ring-neutral-700;
}

.entryDisplay {
  cursor: pointer;
  color: var(--ps-output-display-entry-display-text-color);
  background-color: var(--ps-output-display-entry-display-background-color);
}

@media (hover: hover) {
  .entryDisplay:hover {
    color: var(--ps-output-display-entry-display-text-color-hover);
    background-color: var(
      --ps-output-display-entry-display-background-color-hover
    );
  }
}

/* INPUT */
.inputWrapper {
  @apply mt-auto cursor-pointer hover:bg-neutral-50 dark:hover:bg-neutral-900;
}
</style>
