<template>
  <ButtonComponent
    :size="size"
    :variant="variant"
    :color="color"
    :colorWeight="colorWeight"
    data-test="settings_display"
  >
    <IconHelper name="settings" size="20" />
    <span v-if="variant != 'round'">Settings</span>
  </ButtonComponent>
</template>

<script>
import { Addon } from "../mixins/Addon";
export default {
  mixins: [Addon],
};
</script>
