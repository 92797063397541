<template>
  <div class="flex flex-wrap items-center gap-2" data-test="links_builder">
    <div
      v-for="(entry, index) in entries"
      :key="entry.id"
      data-test="links_builder_remove_link_button"
    >
      <LabelButton
        @click="
          $emit(
            'update:modelValue',
            (links = links.filter((e, i) => i != index)),
          )
        "
        :variant="variant == 'round' ? 'default' : variant"
        :size="size"
        :color="entry.color || 'white'"
        :title="'Unlink ' + entry.name"
        :data-test="'link_' + $slugify(entry.name) + '_button'"
        class="w-full"
      >
        {{
          entry.name
            ? entry.name.length > 20
              ? entry.name.slice(0, 17) + "..."
              : entry.name
            : "Unnamed"
        }}
      </LabelButton>
    </div>
    <div class="flex items-center leading-normal">
      <OverlayComponent v-if="showSearch" @click="showSearch = false" />

      <label v-if="showSearch" for="search-field" class="sr-only">Search</label>
      <SearchIcon
        v-if="showSearch"
        size="20"
        stroke-width="1.5"
        class="pointer-events-none ml-1 text-gray-400"
        aria-hidden="true"
      />
      <Search
        :ref="'search'"
        v-if="showSearch"
        @select="onSearchSelect"
        @cancel="showSearch = false"
        :clearOnSelect="true"
        class="relative z-40 min-w-[4rem] px-2"
        :filterSearchResults="
          (e) =>
            !entry || e.id != entry.id /* We don't want to link to ourselves */
        "
      />
      <ButtonComponent
        v-else
        @click="
          [
            (showSearch = true),
            $nextTick(() => {
              $refs.search.$refs.input.$refs.input.focus();
            }),
          ]
        "
        :variant="variant"
        :color="color"
        :colorWeight="colorWeight"
        :size="size"
        title="Add link"
        data-test="links_builder_add"
        class="w-full"
      >
        <IconHelper name="link-2-icon" size="20" />
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import LabelButton from "../../helpers/LabelButton.vue";

import { Coloring } from "../../mixins/Coloring";
import { SearchIcon } from "lucide-vue-next";
import { Addon } from "@/components/mixins/Addon";

export default {
  mixins: [Coloring, Addon],
  name: "LinksBuilder",
  components: {
    LabelButton,
    SearchIcon,
  },
  data() {
    return {
      entries: [],
      showSearch: false,
    };
  },
  mounted() {
    this.entries = this.links
      ?.map((id) => this.$store.getters.entries.find((e) => e.id == id))
      .filter((e) => e);
  },
  watch: {
    links: {
      handler(links) {
        this.entries = links
          ?.map((id) => this.$store.getters.entries.find((e) => e.id == id))
          .filter((e) => e);
      },
    },
  },
  computed: {
    links: {
      get() {
        return this.modelValue;
      },
      set(links) {
        this.$emit("update:modelValue", links);
        // eslint-disable-next-line vue/no-mutating-props
        // this.entry.links = links;
        // this.$store.dispatch("push", {
        //   event: "entry_update",
        //   params: { entry: this.entry },
        //   entry: this.entry,
        // });
      },
    },
  },
  methods: {
    onSearchSelect(entry) {
      this.links = [...this.links, entry.id];
      this.$emit("link", entry);
      this.showSearch = false;
    },
  },
};
</script>
