<template>
  <!--<img
        class="mx-auto h-10 w-auto"
        src="https://tailwindui.com/img/logos/mark.svg?color=gray&shade=600"
        alt="Your Company"
      />-->
  <h1 className="text-2xl font-black uppercase select-none">Pensive</h1>
</template>

<script>
export default {
  name: "LogoComponent",
};
</script>
