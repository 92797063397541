<template>
  <div>
    <aside class="mb-2 flex justify-between gap-x-3 px-3">
      <nav
        class="isolate inline-flex -space-x-px rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <a
          @click="
            cursor = $moment(cursor).add(-1, 'month').format('YYYY-MM-DD')
          "
          class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-neutral-400 dark:ring-neutral-700"
          data-test="calendar_previous_month_button"
        >
          <span class="sr-only">Previous</span>
          <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
        </a>
        <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
        <a
          class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 dark:text-neutral-400 dark:ring-neutral-700"
          >{{ $moment(cursor).format("MMMM") }}</a
        >
        <a
          @click="cursor = $moment(cursor).add(1, 'month').format('YYYY-MM-DD')"
          class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-neutral-400 dark:ring-neutral-700"
          data-test="calendar_next_month_button"
        >
          <span class="sr-only">Next</span>
          <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
        </a>
      </nav>

      <nav
        class="isolate inline-flex -space-x-px rounded-md shadow-sm"
        aria-label="Pagination"
      >
        <a
          @click="cursor = $moment(cursor).add(-1, 'year').format('YYYY-MM-DD')"
          class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-neutral-400 dark:ring-neutral-700"
          data-test="calendar_previous_year_button"
        >
          <span class="sr-only">Previous</span>
          <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
        </a>
        <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
        <a
          class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 dark:text-neutral-400 dark:ring-neutral-700"
        >
          {{ $moment(cursor).format("YYYY") }}</a
        >
        <a
          @click="cursor = $moment(cursor).add(-1, 'year').format('YYYY-MM-DD')"
          class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 dark:text-neutral-400 dark:ring-neutral-700"
          data-test="calendar_next_year_button"
        >
          <span class="sr-only">Next</span>
          <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
        </a>
      </nav>
    </aside>

    <!-- Calendar: Start -->
    <div class="grid grid-cols-7 justify-center gap-2 px-3 py-1">
      <div v-if="mode != 'months'" class="flex items-center justify-center">
        <span class="text-sm font-bold">Mon</span>
      </div>
      <div v-if="mode != 'months'" class="flex items-center justify-center">
        <span class="text-sm font-bold">Tue</span>
      </div>
      <div v-if="mode != 'months'" class="flex items-center justify-center">
        <span class="text-sm font-bold">Wed</span>
      </div>
      <div v-if="mode != 'months'" class="flex items-center justify-center">
        <span class="text-sm font-bold">Thu</span>
      </div>
      <div v-if="mode != 'months'" class="flex items-center justify-center">
        <span class="text-sm font-bold">Fri</span>
      </div>
      <div v-if="mode != 'months'" class="flex items-center justify-center">
        <span class="text-sm font-bold">Sat</span>
      </div>
      <div v-if="mode != 'months'" class="flex items-center justify-center">
        <span class="text-sm font-bold">Sun</span>
      </div>
      <span
        v-for="i in $moment(cursor).startOf('month').day() > 0
          ? $moment(cursor).startOf('month').day() - 1
          : 6"
        :key="i"
      >
      </span>
      <div
        v-for="(option, index) in options"
        :key="index"
        @click.prevent="[(edit = false), (value = option.date)]"
        :style="
          (option.span
            ? 'grid-column-end: span ' + Math.min(7, option.span) + ';'
            : '') + (mode == 'months' ? 'height:8rem' : '')
        "
        :class="{
          'aaspect-square': mode == 'days',
        }"
        class="relative flex min-h-[2rem] items-center justify-center"
      >
        <slot :date="option.date" :span="option.span - 1">
          {{ option.date }}
        </slot>
      </div>
    </div>
    <!-- Calendar: End -->
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "days",
    },
  },
  data() {
    return {
      cursor: this.$moment().local().format("YYYY-MM-DD"),
    };
  },

  methods: {
    getWeeksInMonth(date) {
      let momentDate = this.$moment(date);
      let month = momentDate.month() + 1; // month() returns a zero-based month (0 for January, 11 for December), so we add 1
      let year = momentDate.year();
      let startWeek = this.$moment([year, month - 1])
        .startOf("month")
        .week();
      let endWeek = this.$moment([year, month - 1])
        .endOf("month")
        .week();

      // Handle year wrap-around.
      if (startWeek > endWeek) {
        endWeek += this.$moment([year, month - 1]).weeksInYear();
      }

      let weeks = [];
      for (let week = startWeek; week <= endWeek; week++) {
        let weekDate = this.$moment().week(week).startOf("week");
        weeks.push(weekDate);
      }

      return weeks;
    },
    getDaysInWeek(date) {
      let momentDate = this.$moment(date);
      let week = momentDate.week();
      let month = momentDate.month();

      const startOfWeek = this.$moment(date).week(week).startOf("week");
      const endOfWeek = this.$moment(date).week(week).endOf("week");

      let daysInWeek = 0;
      for (let day = startOfWeek; day <= endOfWeek; day.add(1, "days")) {
        if (day.month() === month) {
          daysInWeek++;
        }
      }

      return daysInWeek;
    },
  },

  computed: {
    options() {
      switch (this.mode) {
        case "weeks":
          return this.getWeeksInMonth(this.cursor).map((week) => {
            if (week.month() !== this.$moment(this.cursor).month()) {
              week = this.$moment(this.cursor).startOf("month");
            }
            const _date = week.format("YYYY-MM-DD");
            return {
              date: _date,
              from: week.format("YYYY-MM-DD"),
              to: week.endOf("week").format("YYYY-MM-DD"),
              span: this.getDaysInWeek(_date),
            };
          });
        case "months":
          var month = this.$moment(this.cursor).startOf("month");
          return [
            {
              date: month.format("YYYY-MM-DD"),
              from: month.format("YYYY-MM-DD"),
              to: month.endOf("month").format("YYYY-MM-DD"),
              span: month.daysInMonth(),
            },
          ];

        default:
          return Array.from(
            { length: this.$moment(this.cursor).local().daysInMonth() },
            (_, i) => i + 1,
          ).map((day) => {
            const _date = this.$moment(
              this.$moment(this.cursor).set("date", day),
            ).format("YYYY-MM-DD");
            return {
              date: _date,
            };
          });
      }
    },
  },
};
</script>
