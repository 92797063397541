
export function buildRoutines(demo) {

  demo.addEntry({
    name: "Daily Routine",
    description: {
      content:
        "A place to check at the beginning (or end) of your day. Manage and schedule accordingly!\nLearn more about this in the [Best Practices](https://pensive.io/best-practices#routines)",
    },
    anchor: {
      position: demo.entries.filter((e) => e.anchor).length,
    },
    links: [
      demo.e("Inbox").id,
      demo.e("Tasks · Today").id,
      demo.e("In the Morning").id,
      demo.e("At Noon").id,
      demo.e("At Evening").id,
      demo.e("Events & Appointments · Tomorrow").id,
      demo.e("Tasks · Tomorrow").id,
    ],
  });

  demo.addEntry({
    name: "Weekly Routine",
    description: {
      content:
        "A place to check at the end of the week. Cleanup and arrange everything to stay on track!\nLearn more about this in the [Best Practices](https://pensive.io/best-practices#routines)",
    },
    output: {
      data: [
        { key: ["completion", "completed"], op: "eq", val: [0] },
        { key: ["schedule", "date"], op: "lte", val: [{ op: "today" }] },
      ],
    },
    anchor: {
      position: demo.entries.filter((e) => e.anchor).length,
    },
    links: [
      demo.e("Tasks · Obsolete").id,
      demo.e("Events & Appointments · Upcoming").id,
      demo.e("Tasks · Tomorrow").id,
      demo.e("Tasks · Upcoming").id,
    ],
  });
}
