<template>
  <div data-test="status_select">
    <StatusDisplay
      v-model="status"
      :entry="entry"
      :statuses="statuses"
      @click.stop="(e) => $emit('click', e)"
    >
      <template #button>
        <slot name="button">
          <ButtonComponent
            :variant="variant"
            :size="size"
            :color="color"
            title="Set Status"
            data-test="entry_status_select"
          >
            <span v-if="status" class="flex items-center">
              <span
                :class="[
                  'bg-' + (status?.color || color) + '-400',
                  variant == 'round' ? 'h-4 w-4' : 'ml-1 h-2 w-2',
                  'inline-block flex-shrink-0 rounded-full',
                ]"
                aria-hidden="true"
              />
              <span
                v-if="variant != 'round'"
                class="ml-2"
                :data-test="
                  'entry_status_' +
                  (status ? $slugify(status.name) : 'no-status')
                "
                >{{ status?.name || "Status" }}</span
              >
            </span>
            <span v-else>
              <IconHelper name="circle-dashed" size="20" />
            </span>
            <!-- <ChevronUpDownIcon
            v-if="variant != 'minimal' && variant != 'round'"
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          /> -->
          </ButtonComponent>
        </slot>
      </template>
    </StatusDisplay>
  </div>
</template>

<script>
import { Statusing } from "../mixins/Statusing";
import { Scheduling } from "../mixins/Scheduling";
import { Routining } from "../mixins/Routining";

import { Addon } from "../mixins/Addon";

export default {
  // inject: ["position"],
  mixins: [Addon, Statusing, Scheduling, Routining],
  props: {
    modelValue: Object,
    entry: Object,
    full: Boolean,
    label: Boolean,
    statuses: {
      type: Array,
    },
    teleport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      window: window,
      skipQuickSwapTimeout: null,

      // search related
      showSearchInput: false,
      searchStatusQuery: "",
    };
  },
  computed: {
    status: {
      get() {
        return this.modelValue;
      },
      set(status) {
        if (this.entry) {
          this.setStatus(this.entry, status);
          if (!this.entry.temp)
            this.$store.dispatch("push", {
              event: "entry_update",
              params: { entry: this.entry },
              entry: this.entry,
            });
        } else {
          this.$emit("update:modelValue", status);
        }
      },
    },
    entries() {
      return this.$store.getters.entries.filter((e) => e.deleted_at === null);
    },
    quickStatusSwapEnabled() {
      return (
        typeof Cypress == "undefined" &&
        this.status &&
        this.statuses.length == 2
      );
    },
  },
  methods: {
    onMouseDown(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled) {
        this.mouseDown = true;
        this.skipQuickSwapTimeout = setTimeout(() => {
          if (this.mouseDown) {
            this.$refs.listbox_button.$el.click();
          }
        }, 500);
      }
    },
    onMouseUp(e) {
      e.stopPropagation();
      e.preventDefault();
      if (e.button === 0 && this.quickStatusSwapEnabled) {
        this.mouseDown = false;
        if (this.skipQuickSwapTimeout) {
          clearTimeout(this.skipQuickSwapTimeout);
          this.status = this.statuses.find((s) => s.id != this.status.id);
        } else {
          clearTimeout(this.skipQuickSwapTimeout);
        }
      }
    },

    animationEnterDropdown(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [20, 0],
        complete: done,
      });
    },
    animationLeaveDropdown(el, done) {
      this.$anime({
        targets: el,
        opacity: [1, -0.2],
        translateY: [0, 20],
        complete: done,
      });
    },
  },
};
</script>
