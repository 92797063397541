import { applyCondition, resolveField } from "@/filterResolving";

export const Aggregate = {
  methods: {
    entriesCount(entry, filter = null) {
      if (filter === null) filter = () => true;
      return entry.output
        ? this.filterEntries(this.outputSchema(entry)).filter(filter).length || null
        : null;
    },

    getAggregatedResults(aggregates, entries) {

      const percentage = function (value, aggregate) {
        const target = aggregate.val[0]
        switch (aggregate.op) {
          case 'gt':
            return Math.min(100, (value / (target + 1)) * 100)
          case 'gte':
            return Math.min(100, (value / (target)) * 100)
          case 'eq':
            return (value == (target) ? 1 : 0) * 100
          case 'neq':
            return (value != (target) ? 1 : 0) * 100
          case 'lte':
            return !value ? 100 : Math.min(100, (target / value) * 100)
          case 'lt':
            return !value ? 100 : Math.min(100, ((target - 1) / value) * 100)
        }
        return -1
      }

      return aggregates.map((a) => {
        const val = entries
          .map((e) => (a.key == "count" ? 1 : resolveField(e, a.key) || 0))
          .reduce((a, b) => a + b, 0);

        return {
          filters: a,
          val: val,
          unit: this.getUnit(a.key),
          ok: applyCondition(val, a.op, a.val),
          percentage: percentage(val, a),
        };
      });
    }
  },
};
