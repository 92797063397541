import moment from "moment";
import { nanoid } from "nanoid";

export function buildCalendars(demo) {
  const calendar_id = nanoid();
  demo.addEntry({
    id: calendar_id,
    name: "Calendar",
    description: {
      content:
        "Its not always about tasks, sometimes its about events and appointments.",
    },
    output: {
      data: [{ key: "links", op: "any", val: [calendar_id] }],
    },
    input: {
      data: {
        links: [calendar_id],
      },
    },
    links: [],
  });

  demo.addEntry({
    name: "Events & Appointments · Today",
    description: {
      content: "Events & Appointments scheduled today.",
    },
    output: {
      data: [
        { key: ["schedule", "date"], op: "eq", val: [{ op: "today" }] },
        { key: ["completion", "completed"], op: "is not set", val: [] },
      ],
    },
    links: [demo.e("Calendar").id],
  });
  demo.addEntry({
    name: "Events & Appointments · Tomorrow",
    description: {
      content: "Events & Appointments scheduled tomorrow.",
    },
    output: {
      data: [
        { key: ["schedule", "date"], op: "eq", val: [{ op: "tomorrow" }] },
        { key: ["completion", "completed"], op: "is not set", val: [] },
      ],
    },
    links: [demo.e("Calendar").id],
  });

  demo.addEntry({
    name: "Events & Appointments · Upcoming",
    description: {
      content: "Events & Appointments scheduled in the next 7 days.",
    },
    output: {
      data: [
        { key: ["schedule", "date"], op: "gt", val: [{ op: "today" }] },
        {
          key: ["schedule", "date"],
          op: "lt",
          val: [{ op: "nextXDays", x: 7 }],
        },
        { key: ["completion", "completed"], op: "is not set", val: [] },
      ],
    },
    links: [demo.e("Calendar").id],
  });

  demo.e("Calendar").links.push(demo.e("Events & Appointments · Today").id)
  demo.e("Calendar").links.push(demo.e("Events & Appointments · Tomorrow").id)
  demo.e("Calendar").links.push(demo.e("Events & Appointments · Upcoming").id)
  demo.e("Calendar").links.push(demo.e("More Views").id)
  demo.addEntry({
    name: "Explore Pensive Features",
    description: {
      content: "Have fun :-)!",
    },
    schedule: {
      date: moment.utc().format("YYYY-MM-DD"),
      time: null,
    },
    links: [demo.e("Calendar").id],
  });

  demo.addEntry({
    name: "Dads Birthday",
    schedule: {
      date: moment.utc().add(1, "days").format("YYYY-MM-DD"),
      time: null,
    },
    links: [demo.e("Calendar").id],
  });
  demo.addEntry({
    name: "Random Holiday",
    description: {
      content: "Maybe go hiking?",
    },
    schedule: {
      date: moment.utc().add(5, "days").format("YYYY-MM-DD"),
      time: null,
    },
    links: [demo.e("Calendar").id],
  });
}
