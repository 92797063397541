<template>
  <div>
    <!-- <Transition
      :appear="true"
      @enter="
        (el, done) => {
          this.$anime({
            targets: el,
            translateY: [100, 0],
            opacity: [0, 1],
            duration: 1000,
            complete: done,
          });
        }
      "
      :css="false"
    > -->
    <div
      class="mx-auto w-full sm:max-w-xl md:max-w-2xl md:px-8 lg:max-w-3xl xl:max-w-5xl"
    >
      <div
        class="relative w-full overflow-y-visible rounded-3xl bg-neutral-50 transition-all dark:bg-neutral-900"
        data-tour="main_entry_settings"
      >
        <TourHelper
          hook="entry_settings"
          class="absolute right-0 top-0 mr-2 mt-2"
          auto-start
          :showSettings="showSettings"
          @showSettings="showSettings = $event"
        />
        <TransitionGroupHelper
          :enter="{
            opacity: [0, 1],
            translateY: [50, 0],
          }"
          :leave="{
            opacity: 0,
            translateY: [0, 50],
          }"
        >
          <div key="header" :class="$style.entryDisplay_wrapper">
            <CoverDisplay
              v-if="entry.cover"
              v-model="entry.cover"
              :entry="entry"
              class="group/cover mx-5 mb-4 h-48 overflow-hidden rounded-2xl shadow-lg md:h-[30vh]"
            >
              <CoverSettings
                v-model="entry.cover"
                key="cover"
                color="secondary"
                :entry="entry"
                class="absolute right-2 top-2 opacity-0 transition-opacity duration-200 ease-in-out group-hover/cover:opacity-100"
              />
            </CoverDisplay>
            <EntryDisplay
              id="header"
              :modelValue="entry"
              :position="position"
              :display="{
                links: true,
                output: false,
              }"
              size="4xl"
              :class="$style.entryDisplay"
              :data-test="$slugify(entry.name)"
              :disableViewportChecking="true"
            >
              <template #top-right>
                <ButtonComponent
                  @click="showSettings = !showSettings"
                  variant="round"
                  :color="showSettings ? 'secondary' : 'primary'"
                  class="duration-500 lg:mt-1"
                >
                  <IconHelper
                    name="chevron-down"
                    size="20"
                    :class="{
                      'rotate-180': showSettings,
                    }"
                    class="transition-transform"
                  />
                </ButtonComponent>
              </template>
              <template v-if="entry.description" #description>
                <div
                  class="-m-2 box-content w-full rounded-md p-2 ring-neutral-100 hover:ring-1 dark:ring-neutral-700"
                >
                  <DescriptionDisplay
                    v-model="entry.description"
                    :entry="entry"
                    :position="position"
                    class="w-full"
                  />
                </div>
              </template>
            </EntryDisplay>
          </div>
          <div
            v-if="showSettings || $Cypress"
            key="settings"
            class="mb-2 w-full px-5"
            data-tour="main_entry_toolbar"
          >
            <TransitionGroupHelper
              :enter="{
                opacity: [0, 1],
                translateY: [50, 0],
              }"
              :leave="{
                opacity: 0,
                translateY: 50,
              }"
            >
              <DescriptionSettings
                v-if="!entry.description"
                key="description"
                v-model="entry"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <StatusSettings
                v-if="!entry.status_id"
                key="status"
                v-model="status"
                :entry="entry"
                :full="true"
                :statuses="statuses"
                variant="round"
                color="white"
                data-test="entry_status_no-status"
                class="mb-2 mr-2 inline-block"
              />
              <ScheduleSettings
                v-if="!entry.schedule"
                key="schedule"
                v-model="entry.schedule"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <PrioritySettings
                v-if="!entry.priority"
                key="priority"
                v-model="entry.priority"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <TimeTrackingsSettings
                v-if="!entry.time_trackings.length"
                key="time_trackings"
                v-model="entry.time_trackings"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />

              <OutputSettings
                key="output"
                v-if="!entry.output"
                v-model="entry.output"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <InputSettings
                key="input"
                v-if="!entry.input"
                v-model="entry.input"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <ButtonComponent
                v-if="!showTemplates"
                @click="showTemplates = !showTemplates"
                variant="round"
                color="white"
                title="Show Templates"
                class="mb-2 mr-2 inline-block"
              >
                <IconHelper
                  name="puzzle"
                  size="20"
                  class="transition-transform"
                />
              </ButtonComponent>

              <CoverSettings
                v-if="!entry.cover"
                v-model="entry.cover"
                key="cover"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />

              <RoutineSettings
                v-model="entry.routine"
                key="routine"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              >
                <template v-slot:button_routine_configure="{ routine }">
                  <ButtonComponent color="neutral" colorWeight="900">
                    <IconHelper name="repeat" size="20" />
                    {{ routine.target }}x
                    <span class="capitalize"> {{ routine.frequency }} </span>
                  </ButtonComponent>
                </template>
              </RoutineSettings>

              <ButtonComponent
                key="senses"
                v-if="position != 'modal'"
                @click="showSenseModal = true"
                variant="round"
                color="white"
                title="Senses"
                data-test="entry_settings_action_senses"
                class="mb-2 mr-2 inline-block"
              >
                <IconHelper name="radio" size="20" />
              </ButtonComponent>

              <ButtonComponent
                key="focus"
                v-if="position != 'modal'"
                @click="$store.dispatch('focus', entry)"
                variant="round"
                color="white"
                title="Enter Focus Mode"
                shortcode="F"
                data-test="entry_settings_action_focus"
                class="mb-2 mr-2 inline-block"
              >
                <IconHelper name="maximize-2" size="20" />
              </ButtonComponent>

              <SettingsDisplay
                v-if="position != 'modal'"
                key="settings"
                v-model="entry"
                @click="$emit('showSettings')"
                color="primary"
                class="mb-2 inline-block"
              />
            </TransitionGroupHelper>
          </div>
          <div
            v-if="
              showTemplates ||
              entry.output ||
              entry.input ||
              entry.links.length > 0
            "
            key="panel"
          >
            <PanelHelper
              class="z-1 mb-5 box-content flex flex-col gap-y-5 divide-y dark:divide-neutral-700"
              :class="{
                // '!bg-transparent !mb-0 !shadow-none':
                //   showTemplates &&
                //   (entry.output || entry.input || entry.links.length > 0),
              }"
              ref="outer"
              style="transition: height 0.3s cubic-bezier(0.16, 1, 0.3, 1)"
            >
              <div ref="inner">
                <TransitionGroupHelper>
                  <div v-if="showTemplates" class="flex flex-col gap-y-4">
                    <LabelHelper> Get started with </LabelHelper>
                    <TemplateWrapper v-model="entry" />
                  </div>
                  <OutputDisplay
                    id="output"
                    v-if="entry.output"
                    v-model="entry.output"
                    :entry="entry"
                    :display="{
                      links: entry.output.type != 'gallery',
                      output: entry.output.type != 'gallery',
                    }"
                    :filter="(e) => e.id != entry.id"
                    :inputs="linkedEntries"
                    :sorting="entry.output.sorting"
                    :editable="true"
                    :position="position"
                    data-tour="main_entry_output"
                  />

                  <InputDisplay
                    id="input"
                    v-if="!entry.output && entry.input"
                    v-model="entry.input"
                    :entry="entry"
                    :schema="entry.input.data"
                    :color="entry.color"
                  />
                  <div
                    v-for="(linkedEntry, index) in linkedEntries"
                    :key="linkedEntry.id"
                  >
                    <div
                      class="mt-4 flex flex-col gap-4 md:grid md:grid-cols-4 md:divide-x dark:divide-neutral-700"
                    >
                      <div class="flex flex-col justify-between gap-2 md:gap-8">
                        <EntryDisplay
                          :modelValue="linkedEntries[index]"
                          :display="{
                            links: false,
                            output: false,
                            routine: false,
                            custom_fields: false,
                          }"
                          position="bound"
                          size="lg"
                          :class="$style.linkedEntryDisplay"
                        />
                        <div class="flex flex-wrap gap-2 md:justify-between">
                          <div>
                            <div
                              v-if="linkedEntries.length > 1"
                              class="mr-2 mt-1.5 flex gap-x-0.5"
                            >
                              <ButtonComponent
                                v-if="index > 0"
                                @click.stop="
                                  () => {
                                    const i = entry.links.indexOf(
                                      linkedEntry.id,
                                    );
                                    if (i > 0) {
                                      const item = entry.links.splice(i, 1)[0];
                                      entry.links.splice(i - 1, 0, item);
                                    }
                                    $store.dispatch('push', {
                                      event: 'entry_update',
                                      params: { entry: entry },
                                      entry: entry,
                                    });
                                  }
                                "
                                variant="minimal"
                                title="Move up"
                              >
                                <ArrowUpIcon class="h-4 w-4" />
                              </ButtonComponent>
                              <ButtonComponent
                                v-if="index < entry.links.length - 1"
                                @click.stop="
                                  () => {
                                    const i = entry.links.indexOf(
                                      linkedEntry.id,
                                    );
                                    if (i < entry.links.length - 1) {
                                      const item = entry.links.splice(i, 1)[0];
                                      entry.links.splice(i + 1, 0, item);
                                    }
                                    $store.dispatch('push', {
                                      event: 'entry_update',
                                      params: { entry: entry },
                                      entry: entry,
                                    });
                                  }
                                "
                                variant="minimal"
                                title="Move down"
                              >
                                <ArrowDownIcon class="h-4 w-4" />
                              </ButtonComponent>
                            </div>
                          </div>
                          <OutputSettings
                            v-model="linkedEntry.output"
                            :entry="linkedEntry"
                            size="xs"
                            variant="minimal"
                            class="hidden items-end gap-x-4 self-end md:flex"
                            :showDelete="false"
                          />
                        </div>
                      </div>
                      <div class="md:col-span-3 md:pl-4">
                        <OutputDisplay
                          v-if="linkedEntry.output"
                          v-model="linkedEntry.output"
                          :display="{
                            links: linkedEntry.output.type != 'gallery',
                            output: linkedEntry.output.type != 'gallery',
                          }"
                          :entry="linkedEntry"
                          :filter="(e) => e.id != entry.id"
                          :inputs="linkedEntries"
                          :editable="true"
                          :showToolbar="false"
                          :sorting="linkedEntry.output.sorting"
                          :position="position"
                          :dateSelected="dateCursor"
                          @update:dateSelected="dateCursor = $event"
                          :class="$style.linkedOutputDisplay"
                        />
                        <InputDisplay
                          v-if="!linkedEntry.output && linkedEntry.input"
                          v-model="linkedEntry.input"
                          :entry="linkedEntry"
                          :schema="linkedEntry.input.data"
                          :position="position"
                        />
                      </div>
                    </div>
                  </div>
                </TransitionGroupHelper>
              </div>
            </PanelHelper>
          </div>
          <LinksSettings
            key="links"
            v-model="entry.links"
            :entry="entry"
            variant="round"
            color="neutral"
            colorWeight="900"
            class="mx-5 pb-5"
            data-tour="main_entry_links"
          />
          <div key="rest">
            <RestDisplay
              v-model="entry"
              class="mx-5 pb-5"
              :position="position"
            />
          </div>
        </TransitionGroupHelper>
      </div>
    </div>
    <!-- </Transition> -->
    <ModalHelper :show="showSenseModal" @close="showSenseModal = false">
      <TitleHelper> Configure Senses for {{ entry.name }} </TitleHelper>
      <SensesSettings v-model="entry.senses" :entry="entry" />
    </ModalHelper>
  </div>
</template>

<script>
import { Routining } from "../mixins/Routining";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Touring } from "../mixins/Touring";

export default {
  // provide() {
  //   return {
  //     display: {
  //       links: true,
  //       output: false,
  //     },
  //   };
  // },
  emits: ["showSettings"],
  mixins: [Statusing, Scheduling, Routining, Touring],
  props: {
    modelValue: Object,
    position: String,
  },
  data() {
    return {
      // linkedEntries: [],
      showSettings:
        !this.modelValue.input &&
        !this.modelValue.output &&
        this.modelValue.links.length <= 1,
      showTemplates:
        !this.modelValue.input &&
        !this.modelValue.output &&
        this.modelValue.links.length <= 1 &&
        !this.modelValue.status_id &&
        !this.modelValue.schedule &&
        !this.modelValue.priority &&
        this.modelValue.time_trackings.length === 0,
      showSenseModal: this.$route.query.showModal === "true",
      dateCursor: this.$moment().format("YYYY-MM-DD"),
    };
  },
  mounted() {
    this.checkGetParameters();

    this.initResizeObserver();
    // setInterval(() => {
    //   this.$store.dispatch("pull", {
    //     filters: [{ key: "id", op: "eq", val: this.entry.id }],
    //     source: {
    //       file: "SETTINGSS",
    //       entry: this.entry.name,
    //     },
    //   });
    // }, 1000);
    // this.linkedEntries = this.entry.links
    //   .map((id) => this.$store.getters.entries.find((e) => e.id == id))
    //   .filter((e) => e);
  },
  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  watch: {
    // "entry.links": {
    //   deep: true,
    //   handler() {
    //     this.linkedEntries = this.entry.links
    //       .map((id) => this.$store.getters.entries.find((e) => e.id == id))
    //       .filter((e) => e);
    //   },
    // },
    entry: {
      deep: true,
      handler(n, o) {
        if (!this.showSettings || n.id != o.id) {
          this.showSettings =
            !this.modelValue.input &&
            !this.modelValue.output &&
            this.modelValue.links.length <= 1;
        }
        this.showTemplates =
          !this.modelValue.input &&
          !this.modelValue.output &&
          this.modelValue.links.length <= 1 &&
          !this.modelValue.status_id &&
          !this.modelValue.schedule &&
          !this.modelValue.priority &&
          this.modelValue.time_trackings.length === 0;
        this.checkGetParameters();
      },
    },
  },
  computed: {
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    entries() {
      return this.$store.getters.entries?.filter((e) => e.deleted_at === null);
    },
    linkedEntries() {
      return this.entry.links
        .map((id) => this.entries.find((e) => e.id == id))
        .filter((e) => e);
    },

    statuses() {
      return this.getAvailableStatuses(this.entry);
    },
    status: {
      get() {
        return this.getStatusById(this.entry.status_id);
      },
      set(status) {
        this.setStatus(this.entry, status);
      },
    },
  },
  methods: {
    checkGetParameters() {
      // console.log(this.$route.query);
      if (this.$route.query.sensesSettings == 1) {
        this.showSenseModal = true;
        this.$router.replace({
          query: { ...this.$route.query, sensesSettings: undefined },
        });
      }
    },

    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          // console.log("Height changed:", newHeight);
          // this.$anime({
          //   targets: this.$refs.outer,
          //   height: newHeight,
          //   easing: "easeOutExpo",
          // });
          if (this.$refs.outer)
            this.$refs.outer.$el.style.height = `${newHeight}px`;
        }
      });
      if (this.$refs.inner) this.resizeObserver.observe(this.$refs.inner);
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
  },
};
</script>

<style lang="scss" module>
.entryDisplay {
  --ps-entry-display-padding-x: 1.25rem;
  --ps-entry-display-padding-y: 0;

  --ps-name-display-font-size: var(--ps-font-size-4xl);
  --ps-name-display-line-height: var(--ps-line-height-4xl);
  --ps-name-display-font-weight: bold;

  @apply w-full rounded-lg;
  &_wrapper {
    @apply py-5;
  }
}
.linkedEntryDisplay {
  --ps-entry-display-padding-x: 0;
  --ps-entry-display-padding-y: 0;
}
.linkedOutputDisplay {
  // height: 100%;
}
</style>
