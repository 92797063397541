
export const Coloring = {
  methods: {
    getColor(entry, checkLinksToo = false) {

      if (checkLinksToo) {
        return entry?.color || this.$store.getters.entries.filter(e => entry?.links?.includes(e.id)).map(e => e.color).filter(e => e)[0] || 'neutral';
      } else {
        return entry?.color || 'neutral';
      }
    },
  },
};
