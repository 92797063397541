export const Searching = {

  data() {
    return {
      searchOutput: [],
      searchTimeOut: null,
      searchFocusIndex: -1,
      searchSorting: [{ field: 'updated_at', dir: -1 }],
    };
  },
  computed: {
    searchResults() {
      return this.filterEntries(this.searchOutput)
        .sort(this.sortEntries(this.searchSorting));
    },
  },
  methods: {

    searchEntriesByName(name) {

      this.inputText = name;
      if (this.inputText != "") {
        this.searchOutput = [
          { key: "name", op: "any", val: this.inputText.toLowerCase() },
        ];
      } else {
        this.searchOutput = [];
      }

      // if (this.searchTimeOut) {
      //   clearTimeout(this.searchTimeOut);
      //   this.searchTimeOut = null;
      // }

      // if (this.inputText && this.inputText.length) {
      //   if (!this.searchTimeOut) {
      //     this.searchTimeOut = setTimeout(() => {
      //       if (this.inputText.length) {
      //         this.searchOutput = [
      //           { key: "name", op: "any", val: this.inputText.toLowerCase() },
      //         ];
      //       }
      //       this.searchTimeOut = null;
      //     }, 500);
      //   }
      // } else {
      //   this.searchOutput = [];
      // }
    }
  }
};
