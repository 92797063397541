<template>
  <LinksBuilder
    v-model="links"
    :entry="entry"
    :variant="variant"
    :size="size"
    :color="color"
    :colorWeight="colorWeight"
    data-test="entry_links_section"
  />
</template>

<script>
import { Addon } from "../mixins/Addon";
import LinksBuilder from "./partials/LinksBuilder.vue";
export default {
  components: { LinksBuilder },
  mixins: [Addon],
  computed: {
    links: {
      get() {
        return this.entry.links;
      },
      set(links) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.links = links;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    linksObjects() {
      return this.entry.links
        .map((id) => this.$store.getters.entries.find((e) => e.id == id))
        .filter((e) => e);
      //.filter((e) => e.id != this.$route.params.id);
    },
  },
};
</script>
