<template>
  <Teleport to="body">
    <Transition name="focus-transition" @enter="enter" @leave="leave">
      <div
        v-if="node"
        @click="$store.dispatch('share', null)"
        class="safe-area-inset-x fixed inset-0 left-0 top-0 flex transform-gpu flex-col items-center justify-center p-4"
      >
        <div
          @click.stop
          class="max-w-screen grid max-h-full gap-5 overflow-hidden overflow-y-auto rounded-2xl bg-white/75 p-4 shadow-2xl ring-1 ring-neutral-100 backdrop-blur-sm sm:overflow-y-hidden md:p-6 lg:max-w-4xl lg:p-12 dark:bg-black/50"
        >
          Share...
          <div
            ref="screenshot"
            class="flex aspect-square items-center justify-center bg-gradient-to-br from-neutral-100 to-neutral-200 p-5"
          >
            <div
              ref="wrapper"
              class="overflow-hidden rounded-lg bg-white p-5 shadow-2xl ring-1 ring-neutral-100"
            />
          </div>

          <img ref="image" src="" class="rounded-2xl" />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import anime from "animejs";
import * as htmlToImage from "html-to-image";

export default {
  watch: {
    node: {
      immediate: false,
      handler(node) {
        if (node) {
          this.$nextTick(() => {
            const copy = node.cloneNode(true);
            copy.style.backgroundColor = "white";
            this.$refs.wrapper.appendChild(copy);
            this.$refs.wrapper.style.width = node.offsetWidth + "px";

            const screenshot = this.$refs.screenshot;
            htmlToImage
              .toPng(screenshot, {
                filter: (n) => {
                  return true;

                  // eslint-disable-next-line no-unreachable
                  if (n.nodeType !== 8) {
                    // Exclude comment nodes
                    return (
                      typeof n.hasAttribute == "undefined" ||
                      !n.hasAttribute("data-hide-from-share")
                    );
                  } else {
                    return false;
                  }
                },
                backgroundColor: "white",
                width: screenshot.offsetWidth,
                height: screenshot.offsetHeight,
              })
              .then((dataUrl) => {
                this.$refs.image.width = screenshot.offsetWidth;
                this.$refs.image.height = screenshot.offsetHeight;
                this.$refs.image.src = dataUrl;

                this.$refs.screenshot.classList.add("hidden");
              })
              .catch(function (error) {
                console.error("oops, something went wrong!", error);
              });
          });
        }
      },
    },
  },
  computed: {
    node() {
      return this.$store.getters.share;
    },
  },
  methods: {
    enter(el, done) {
      anime({
        targets: el,
        scale: [0.75, 1],
        translateY: ["-100%", "0%"],
        complete: done,
      });
    },
    leave(el, done) {
      anime({
        targets: el,
        scale: [1, 0.75],
        translateY: ["0%", "-150%"],
        complete: done,
      });
    },
  },
};
</script>
