import AuthenticationPage from "./components/auth/AuthenticationPage.vue";
import LoginProvider from "./components/auth/LoginProvider.vue";
// import Register from "./components/auth/Register.vue";
// import ForgotPassword from "./components/auth/ForgotPassword.vue";
// import ResetPassword from "./components/auth/ResetPassword.vue";
import Logout from "./components/auth/Logout.vue";
import ProfilePage from "./components/ProfilePage.vue";
import EmailVerification from "./components/EmailVerification.vue";

import SpacePage from "./components/SpacePage.vue";
import SubscriptionPage from "./components/SubscriptionPage.vue";
import OnboardingPage from "./components/OnboardingPage.vue";

import Start from "./components/Start.vue";
import EntryPage from "./components/EntryPage.vue";
// import FocusPage from "./components/FocusPage.vue";
// import EntryPageWithModal from "./components/EntryPageWithModal.vue";

const routes = [
  {
    path: "/",
    component: Start,
    name: "home",
    meta: {
      requiresAuth: true,
      title: "Start",
      showSidebar: false,
    },
  },
  {
    path: "/index.html",
    redirect: "/",
  },
  {
    path: "/auth/:provider/callback",
    component: LoginProvider,
    name: "login_provider",
    meta: {
      requiresVisitor: true,
      title: "Login",
      showSidebar: false,
    },
  },
  {
    path: "/login",
    component: AuthenticationPage,
    name: "login",
    meta: {
      requiresVisitor: true,
      title: "Login",
      showSidebar: false,
    },
  },
  {
    path: "/register",
    component: AuthenticationPage,
    name: "register",
    meta: {
      requiresVisitor: true,
      title: "Register",
      showSidebar: false,
    },
  },
  {
    path: "/forgot-password",
    component: AuthenticationPage,
    name: "forgot-password",
    meta: {
      requiresVisitor: true,
      title: "Forgot Password",
      showSidebar: false,
    },
  },
  {
    path: "/reset-password/:token",
    component: AuthenticationPage,
    name: "reset-password",
    meta: {
      requiresVisitor: true,
      title: "Reset Password",
      showSidebar: false,
    },
  },
  {
    path: "/logout",
    component: Logout,
    name: "logout",
    meta: {
      requiresAuth: true,
      title: "Logout",
      showSidebar: false,
    },
  },
  {
    path: "/profile",
    component: ProfilePage,
    name: "profile",
    meta: {
      requiresAuth: true,
      title: "Profile",
      showSidebar: true,
    },
  },
  {
    path: "/email/verify/:verification_id/:verification_hash",
    component: EmailVerification,
    name: "email-verification",
    meta: {
      title: "Verify Email",
      showSidebar: false,
    },
  },
  {
    path: "/space",
    component: SpacePage,
    name: "space",
    meta: {
      requiresAuth: true,
      title: "Space Settings",
      showSidebar: true,
    },
  },
  {
    path: "/space/subscription",
    component: SubscriptionPage,
    name: "subscription",
    meta: {
      requiresAuth: true,
      title: "Subscription",
      showSidebar: true,
    },
  },
  {
    path: "/onboarding",
    component: OnboardingPage,
    name: "onboarding",
    meta: {
      requiresAuth: true,
      title: "Onboarding",
      showSidebar: false,
    },
  },
  {
    path: "/:id/:id2?",
    component: EntryPage,
    name: "entry",
    meta: {
      requiresAuth: true,
      showSidebar: true,
    },
  },
];

export default routes;
