<template>
  <div id="left-sidebar" :class="$style.leftSidebar">
    <div class="flex shrink-0 items-center">
      <div class="mt-5 w-full">
        <div class="flex flex-1 items-center justify-between overflow-visible">
          <div class="flex-1 truncate px-2">
            <span class="text-neutral-900 dark:text-neutral-200">
              <Logo />
            </span>
            <p class="font-mono text-xs text-neutral-700 dark:text-neutral-400">
              {{ space?.active_subscription.name }}
            </p>
          </div>
          <div class="flex-shrink-0">
            <ButtonComponent
              @click="$router.push({ name: 'space' })"
              variant="round"
              class="inline-flex h-8 w-8 items-center justify-center !bg-transparent !text-neutral-500 hover:!text-neutral-700 dark:!text-neutral-300 dark:hover:!text-neutral-100"
              title="Space settings"
              data-test="space_options"
            >
              <span class="sr-only">Open options</span>
              <IconHelper
                name="sliders-horizontal"
                size="16"
                stroke-width="1.75"
                aria-hidden="true"
              />
            </ButtonComponent>
          </div>
        </div>
      </div>

      <!--<img
                    class="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=gray&shade=600"
                    alt="Your Company"
                  />-->
    </div>
    <nav class="flex flex-1 flex-col">
      <div class="relative flex flex-1 flex-col gap-y-7" data-test="menu">
        <!-- <div
          ref="background"
          class="absolute h-8 w-full rounded-xl bg-neutral-900 shadow-xl shadow-neutral-100 hover:bg-neutral-950 dark:bg-neutral-300 dark:!text-black dark:shadow-none dark:hover:!bg-neutral-200"
        /> -->
        <component :is="'style'">
          .{{ $style.leftSidebar }} [data-entry-id="{{ $route.params.id }}"] {
          --ps-output-display-entry-display-background-color: #0a0a0a;
          --ps-output-display-entry-display-background-color-hover: #0a0a0a;
          --ps-output-display-entry-display-text-color: white;
          --ps-output-display-entry-display-text-color-hover: white; } .{{
            $style.leftSidebar
          }}
          [data-entry-id="{{ $route.params.id }}"] .name_display {
          --ps-name-display-color: white!important; }
        </component>

        <OutputDisplay
          :id="outputId"
          v-model="originsEntry.output"
          :entry="originsEntry"
          :display="{
            status: false,
            links: false,
            description: false,
            schedule: false,
            output: true,
            output_count: true,
            input: false,
            columns: false,
            leftover: false,
            settings: true,
            senses: true,
            routine: false,
            time_trackings: false,
            custom_fields: false,
          }"
          :sorting="[{ field: 'anchor', dir: 1 }]"
          position="left"
          :disableViewportChecking="false"
          :entriesDisabled="true"
          @onEntryClick="$emit('onEntryClick')"
          :editable="false"
          size="sm"
          @created="
            (entry) =>
              $nextTick(() => {
                this.$router.push({
                  name: 'entry',
                  params: { id: entry.id },
                });
              })
          "
          :class="$style.outputDisplay"
        />
        <!-- @onInitialized="handleBackground"
          @onToggleRenderOutput="() => $nextTick(handleBackground)" -->
        <div
          class="mt-auto flex w-full flex-col gap-y-0.5 rounded-2xl bg-neutral-50 p-5 dark:bg-neutral-900"
        >
          <a
            v-for="(link, index) in links"
            :key="index"
            :href="link.url"
            target="_blank"
            :class="link.classes"
            class="text-sm leading-6 text-neutral-700 hover:text-neutral-950 focus-visible:outline-neutral-500 dark:text-neutral-300 dark:hover:text-neutral-200"
          >
            {{ link.text }}
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Logo from "../Logo.vue";
import { Schema } from "../mixins/Schema";
import { Filtering } from "../mixins/Filtering";
import { Grouping } from "../mixins/Grouping";

export default {
  mixins: [Schema, Filtering, Grouping],
  components: { Logo },
  props: {
    outputId: {
      type: String,
      default: () => {
        return "navigation";
      },
    },
  },
  data() {
    return {
      asdasd: "BxtpgGJ78fihdGWY5cENZ",
      originsEntry: {
        temp: true,
        name: "Add Entry",
        output: {
          type: "list",
          grouping: [],
          data: [{ key: "anchor", op: "is set", val: [] }],
          sorting: [],
        },
        input: {
          data: {
            anchor: {
              position: { op: "append" },
            },
          },
        },
        links: [],
      },
      links: [
        {
          url: "https://pensive.io/whats-new",
          text: "v." + this.$version,
          classes: "font-mono text-xs font-bold !text-black dark:!text-white",
        },
        {
          url: "https://forms.gle/B5hi53AM9PZsR4jz5",
          text: "Report a Bug",
          classes: "",
        },
        {
          url: "https://forms.gle/PQXmMKPwGQQhAin7A",
          text: "Request a Feature",
          classes: "",
        },
      ],
    };
  },
  computed: {
    space() {
      return this.$store.getters.space;
    },
    entries() {
      return this.$store.getters.entries;
    },
  },
  // methods: {
  //   handleBackground() {
  //     if (this) return;
  //     setTimeout(() => {
  //       this.$nextTick(() => {
  //         const oldActiveEntry = document.querySelector(
  //           '[data-is-left-sidebar-active="true"]',
  //         );

  //         if (oldActiveEntry) {
  //           oldActiveEntry.setAttribute("data-is-left-sidebar-active", "false");
  //           oldActiveEntry.classList.remove("!text-white");
  //         }

  //         const activeEntry = document.querySelector('[data-active="true"]');

  //         if (activeEntry) {
  //           this.$anime({
  //             targets: this.$refs.background,
  //             translateY: activeEntry.offsetTop,
  //             easing: "easeInOutCubic",
  //             duration: 300,
  //             complete: () => {
  //               activeEntry.setAttribute("data-is-left-sidebar-active", "true");
  //               activeEntry.classList.add("!text-white");
  //               activeEntry.classList.add("transition-colors");
  //             },
  //           });
  //           // this.$refs.background.style.top = activeEntry.offsetTop + "px";
  //         }
  //       });
  //     }, 100);
  //   },
  // },
  // watch: {
  //   "$route.params.id": function () {
  //     this.handleBackground();
  //   },
  // },
};
</script>

<style module>
.leftSidebar {
  @apply flex grow flex-col gap-y-5 overflow-y-auto overflow-x-hidden border-r border-neutral-200 bg-white px-4 pb-4 lg:hover-hover:overflow-y-hidden lg:hover-hover:hover:lg:overflow-y-auto dark:border-neutral-800 dark:bg-black;

  --ps-output-display-padding-x: 1rem;

  --ps-entry-display-padding-y: 0.5rem;
  --ps-entry-display-padding-x: 1rem;

  --ps-name-display-font-size: var(--ps-font-size-sm);
  --ps-name-display-line-height: var(--ps-line-height-sm);

  scrollbar-gutter: stable;
  overflow: auto;
}

.leftSidebar :global(.name_display) {
  --ps-name-display-color: var(--ps-color-black) !important;
}

.outputDisplay {
  --ps-output-display-group-wrapper-list-row-gap: 0;
}

/* .leftSidebar :global([data-entry-id="BxtpgGJ78fihdGWY5cENZ"]) {
  --ps-output-display-entry-display-background-color: #0a0a0a;
  --ps-output-display-entry-display-background-color-hover: #0a0a0a;
  --ps-output-display-entry-display-text-color: white;
  --ps-output-display-entry-display-text-color-hover: white;
} */
/* .leftSidebar :global([data-is-route-root="true"]:hover) {
  background-color: var(--ps-output-display-entry-display-background-color);
  color: white;
} */
</style>
style
