<template>
  <Listbox as="div" v-model="modelValueComputed" v-slot="{ open }">
    <ListboxLabel
      v-if="slots.label"
      class="block text-sm font-medium leading-6 text-gray-900"
    >
      <slot name="label" />
    </ListboxLabel>
    <div ref="reference" class="relative" :class="[slots.label ? 'mt-2' : '']">
      <ListboxButton class="flex w-full">
        <slot name="button" />
      </ListboxButton>

      <Teleport v-if="open" :disabled="!teleport" to="body">
        <OverlayComponent @click.stop="open = false" />
        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            :style="floatingStyles"
            ref="floating"
            class="z-40 max-h-60 min-w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-neutral-200 focus:outline-none sm:text-sm dark:bg-black dark:ring-neutral-700"
          >
            <slot />
          </ListboxOptions>
        </transition>
      </Teleport>
    </div>
  </Listbox>
</template>

<script setup>
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
} from "@headlessui/vue";
import { ref, useSlots } from "vue";

const slots = useSlots();

import { useFloating, shift, flip } from "@floating-ui/vue";
import OverlayComponent from "../helpers/OverlayComponent.vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-start",
  middleware: [
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});

// this const will be avaiable on template
</script>

<script>
export default {
  // inject: ["position"],
  props: {
    modelValue: null,
    teleport: Boolean,
  },
  computed: {
    modelValueComputed: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
