<template>
  <div>
    <div class="flex flex-wrap gap-2">
      <TextInput
        v-if="3 > 4"
        @onKeyUp="search = $event"
        class="text-sm"
        placeholder="Search Templates..."
      />

      <div class="flex-1">
        <TransitionGroupHelper
          :enter="{
            translateY: [20, 0],
            opacity: [0, 1],
          }"
          :leave="{
            translateY: [{ value: 20 }],
            opacity: [{ value: 0, duration: 500, easing: 'linear' }],
          }"
        >
          <div
            v-for="template in $store.getters.templates
              .filter((t) => t.global)
              // .filter(
              //   (t) =>
              //     t.name.toLowerCase().indexOf(search.toLowerCase()) !== -1,
              // )
              .slice(0, 4)"
            :key="template.id"
            class="mb-4 leading-none md:mb-0 md:mr-4 md:inline-block"
          >
            <ButtonComponent
              @click="
                applyTemplateToEntry(template, entry), (showTemplates = false)
              "
              size="sm"
              color="white"
              variant="minimal"
              :data-test="'template_' + $slugify(template.name)"
            >
              <span v-if="template.icon">
                <IconHelper
                  :name="template.icon"
                  :color="template.data.color"
                  size="20"
                />
              </span>
              {{ template.name }}
            </ButtonComponent>

            <!-- <TemplateDisplay
              :modelValue="item"
              size="xs"
              @click="
                applyTemplateToEntry(item, entry), (showTemplates = false)
              "
            /> -->
          </div>
        </TransitionGroupHelper>
      </div>

      <div class="self-end md:inline-block">
        <ButtonComponent
          key="more_templates"
          @click="showTemplates = true"
          size="sm"
          color="secondary"
          variant="minimal"
          data-test="entry_page_templates_button"
          class="text-neutral-500 hover:text-black dark:hover:text-white"
        >
          <span> <IconHelper name="puzzle" :size="20" /> </span>
          More
        </ButtonComponent>
      </div>
    </div>

    <Dialog
      v-if="showTemplates"
      as="div"
      :open="true"
      @close="showTemplates = false"
      class="relative z-[999] h-px w-px"
    >
      <Transition
        @enter="animationEnterBackdrop"
        @leave="animationLeaveBackdrop"
        :css="false"
        appear
      >
        <div class="fixed inset-0 backdrop-blur" />
      </Transition>
      <Transition
        @enter="animationEnter"
        @leave="animationLeave"
        :css="true"
        appear
      >
        <div
          class="fixed inset-0 z-10 w-screen overflow-y-auto overflow-x-hidden"
          style="scrollbar-gutter: stable"
        >
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <DialogPanel
              ref="outer"
              class="relative box-content max-w-[90vw] transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-2 text-left shadow-xl ring-1 ring-transparent transition-all sm:my-8 sm:w-full sm:px-6 sm:pb-6 md:max-w-3xl lg:max-w-4xl dark:bg-black dark:ring-neutral-700"
              style="transition: height 1s cubic-bezier(0.16, 1, 0.3, 1)"
            >
              <div ref="inner" class="relative">
                <!-- note: class relative is for the template builder transition effects -->
                <DialogTitle
                  as="h3"
                  class="sticky top-0 z-10 -mx-4 flex justify-between rounded-b bg-white px-4 py-4 dark:bg-black"
                >
                  <div class="flex items-center gap-4">
                    <ButtonComponent
                      v-if="$refs.template_builder?.selectedTemplate"
                      @click="$refs.template_builder.selectedTemplate = null"
                      color="secondary"
                      size="sm"
                      class="!px-1"
                    >
                      <IconHelper name="arrow-left" size="20" />
                    </ButtonComponent>
                    <span class="text-xl font-semibold">{{
                      //$refs.template_builder?.selectedTemplate?.name ||
                      "Templates"
                    }}</span>
                  </div>
                  <ButtonComponent
                    @click="showTemplates = false"
                    color="secondary"
                    size="sm"
                    class="!px-1"
                  >
                    <IconHelper name="x" size="20" />
                  </ButtonComponent>
                </DialogTitle>

                <TemplateBuilder ref="template_builder" :modelValue="entry" />
              </div>
            </DialogPanel>
          </div>
        </div>
      </Transition>
    </Dialog>

    <!-- <TransitionRoot as="template" :show="showTemplates">
      <Dialog as="div" class="relative z-[999]" @close="showTemplates = false">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 backdrop-blur transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="container relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 md:max-w-xl lg:max-w-2xl dark:bg-black dark:shadow-none dark:ring-1 dark:ring-neutral-700"
              >
                <DialogTitle
                  as="h3"
                  class="mb-2 text-base font-semibold leading-6 text-neutral-900 dark:text-neutral-300"
                >
                  Templates
                </DialogTitle>
                <TextInput
                  ref="search"
                  @onKeyUp="search = $event"
                  class="w-full text-sm"
                  placeholder="Search Templates"
                />
                <ul
                  role="list"
                  class="mt-2 divide-y divide-gray-200 overflow-y-auto dark:divide-neutral-700"
                >
                  <li
                    v-for="(item, index) in $store.getters.templates
                      ?.filter((t) => !t.global)
                      .filter(
                        (t) =>
                          t.name.toLowerCase().indexOf(search.toLowerCase()) !==
                            -1 ||
                          t.tags?.filter(
                            (tag) =>
                              tag
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1,
                          ).length > 0,
                      )"
                    :key="index"
                  >
                    <TemplateDisplay
                      :modelValue="item"
                      @click="
                        applyTemplateToEntry(item, entry),
                          (showTemplates = false)
                      "
                    />
                  </li>
                </ul>
                <LabelHelper> Global Templates </LabelHelper>
                <ul
                  role="list"
                  class="mt-2 divide-y divide-gray-200 overflow-y-auto dark:divide-neutral-700"
                >
                  <li
                    v-for="(item, index) in $store.getters.templates
                      ?.filter((t) => t.global)
                      .filter(
                        (t) =>
                          t.name.toLowerCase().indexOf(search.toLowerCase()) !==
                            -1 ||
                          t.tags?.filter(
                            (tag) =>
                              tag
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1,
                          ).length > 0,
                      )"
                    :key="index"
                  >
                    <TemplateDisplay
                      :modelValue="item"
                      @click="
                        applyTemplateToEntry(item, entry),
                          (showTemplates = false)
                      "
                    />
                  </li>
                </ul>

                <div class="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    class="rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2 dark:bg-black dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:ring-offset-black"
                    @click="showTemplates = false"
                  >
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot> -->
  </div>
</template>

<script>
// import TemplateBuilder from "../builders/TemplateBuilder.vue";

import { Applicators } from "../mixins/Applicators";
import { Scheduling } from "../mixins/Scheduling";
import { Schema } from "../mixins/Schema";
import { Statusing } from "../mixins/Statusing";
import { Templating } from "../mixins/Templating";
import TextInput from "./TextInput.vue";
import TemplateBuilder from "../builders/TemplateBuilder.vue";

export default {
  // inject: ["position"],
  props: {
    modelValue: Object,
  },
  components: {
    TextInput,
    TemplateBuilder,
  },
  mixins: [Schema, Applicators, Statusing, Scheduling, Templating],
  data() {
    return {
      search: "",
      showTemplates: false,
    };
  },
  watch: {
    showTemplates: function () {
      if (this.showTemplates) {
        this.$nextTick(() => {
          this.initResizeObserver();
        });
        this.$store.dispatch("retrieveTemplates");
        this.search = "";
      }
    },
  },
  mounted() {
    this.$store.dispatch("retrieveTemplates");
  },

  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  computed: {
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          if (this.$refs.outer)
            this.$refs.outer.$el.style.height = `${newHeight}px`;
        }
      });
      // if (this.$refs.inner)
      this.resizeObserver.observe(this.$refs.inner);
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
    animationEnterBackdrop(el, done) {
      this.$anime({
        duration: 300,
        easing: "linear",
        targets: el,
        opacity: [0, 1],
        complete: done,
      });
    },
    animationLeaveBackdrop(el, done) {
      this.$anime({
        duration: 300,
        easing: "linear",
        targets: el,
        opacity: [1, 0],
        complete: done,
      });
    },
    animationEnter(el, done) {
      this.$anime({
        targets: el,
        opacity: [0, 1],
        translateY: [200, 0],
        complete: done,
      });
    },
    animationLeave(el, done) {
      this.$anime({
        targets: el,
        opacity: [1, 0],
        translateY: [0, 200],
        complete: done,
      });
    },
  },
};
</script>
